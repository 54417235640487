import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmService } from '../../shared/components/confirm-box/confirm-box.service';
import { BannerService } from '../../core/services/banner.service';
import { MainService } from '../../core/services/main.service';
import { FormUtil } from '../../shared/components/form-control/form.util';
import { ValidationService } from '../../shared/components/form-control/validation.service';
import { DocumentService } from './document.service';
import { FILE_VALIDATORS_CONTEXT } from './validation.validation';
import { WalletService } from '../wallet/wallet.service';
import { OfflineService } from 'src/app/core/services/offline.service';

export interface DOCUMENT {
  id: number;
  name: string;
  createdDate: Date;
  description: string;
}

@Component({
  selector: 'vc-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  docs: DOCUMENT[] = [];
  documentForm: FormGroup;
  private readonly MAX_ALLOWED_SIZE = 16777216;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly confirm: ConfirmService,
    private readonly docService: DocumentService,
    private readonly mainService: MainService,
    private readonly validation: ValidationService,
    private readonly bannerService: BannerService,
    private readonly walletService: WalletService,
    private readonly offlineService: OfflineService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = this.route.snapshot.data.mainTitle;
    this.validation.setContext(FILE_VALIDATORS_CONTEXT);
    this.initForm();

  }

  ngOnInit() {
    this.walletService.updateNotificationCount({ partitionKey: "Document" })
    .subscribe(data => {
      this.walletService.emitWalletNotification(data.walletFlag);
    });
    this.getDcuments();
  }

  ngOnDestroy() {
    this.mainService.pageMainTitle = '';
  }

  initForm() {
    this.documentForm = this.fb.group({
      file: this.fb.control(''),
      fileName: this.fb.control(''),
      description: this.fb.control('')
    });
    
  }

  getDcuments() {
    this.docService.getDocuments().subscribe((res: any[]) => {
      this.docs = res;
    });
  }

  gotoPrev() {
    this.location.back();
  }

  delete(id: number, name: string) {
    this.confirm.confirm({
      header: 'Confirm',
      message: `Are you sure you want to delete ${name}?`,
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.docService.delete(id).subscribe(res => {
          this.resetForm();
          this.getDcuments();
          this.bannerService.success(['File deleted successfully'], true);
        }, error => {
          this.bannerService.warning(error.error.errorMessage, true);
        });
      },
      reject: () => {
        this.confirm.choose(false);
      }
    });
  }

  upload() {
    this.setValidators();
    if (this.documentForm.valid) {
      FormUtil.clearAllValidatorsAndErrors(this.documentForm);
      const data = this.parseSaveData();
      this.docService.uploadDocument(data.file, data.fileName, data.description, 'POST')
        .then(res => {
          this.getDcuments();
          this.resetForm();
          this.bannerService.success(['File uploaded successfully'], true);
        })
        .catch(error => {
          const rp = JSON.parse(error.response);
          if (error.status === 409) {
            this.showReplaceConfirm(data, rp.errorMessage);
          } else {
            this.bannerService.warning(rp.errorMessage, true);
          }
          this.resetForm();
        });
    }
  }

  setValidators() {
    const file = this.fileInput.nativeElement.files.item(0);
    this.documentForm.get('fileName').setValidators([Validators.required, Validators.maxLength(30),Validators.pattern(/^[^<>:"\/\\\|\?\*]*$/)]);
    this.documentForm.get('fileName').updateValueAndValidity();
    this.documentForm.get('fileName').markAsTouched();
    this.documentForm.get('description').setValidators([Validators.maxLength(100)]);
    this.documentForm.get('description').updateValueAndValidity();
    this.documentForm.get('description').markAsTouched();
    this.documentForm.get('file').setValidators(Validators.compose([Validators.required,
    ValidationService.maxFileSize(file, this.MAX_ALLOWED_SIZE)]));
    this.documentForm.get('file').updateValueAndValidity();
    this.documentForm.get('file').markAsTouched();
  }

  private showReplaceConfirm(data: any, message: string) {
    this.confirm.confirm({
      header: 'Confirm',
      message: message,
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.docService.uploadDocument(data.file, data.fileName, data.description, 'PUT')
          .then((res: any) => {
            this.bannerService.success(['File uploaded successfully'], true);
            this.getDcuments();
            this.resetForm();
          });
      },
      reject: () => {
        this.confirm.choose(false);
      }
    });
  }

  private parseSaveData(): any {
    const data = this.documentForm.getRawValue();
    data.file = this.fileInput.nativeElement.files.item(0);
    return data;
  }

  onFileChange() {
    const fl = this.fileInput.nativeElement.files.item(0) || {};
    this.documentForm.patchValue({
      file: fl.name
    });
  };

  resetForm() {
    this.fileInput.nativeElement.value = '';
    this.initForm();
  }

  download(id: number): void {
    this.offlineService.getWalletDocumentById(id).then(attachment => {
      this.downloadFile(URL.createObjectURL(attachment.data), attachment.fileName);
    });
  }

  downloadFile(url, name: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    const filename = name;
    a.download = filename;
    a.target = '_blank';
    const event = new MouseEvent('click', {
      'view': window,
      'bubbles': false,
      'cancelable': true
    });
    a.dispatchEvent(event);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

}