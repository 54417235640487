export const FILE_VALIDATORS_CONTEXT = {
    required: {
        file: 'Select a file to upload',
        fileName: 'File name cannot be blank'
    },
    maxFileSize: {
        file: 'The file is too large and cannot be uploaded. Please reduce the size of the file and try again'
    },
    maxlength: {
        fileName: 'Please enter 30 or less characters in file name',
        description: 'Please enter 100 or less characters in description'
    },
	pattern: {
		fileName: 'File name must not contain following characters < > : " / \\ | ? \*',
	}
}