import { ContentService } from './../content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from './../../../core/services/main.service';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { IAddedCourse } from '../content.interface';
import {Location} from '@angular/common';
import { ConfirmService } from '../../../shared/components/confirm-box/confirm-box.service';
import { BannerService } from './../../../core/services/banner.service';

@Component({
  selector: 'vc-my-content',
  templateUrl: './my-content.component.html',
  styleUrls: ['./my-content.component.scss']
})
export class MyContentComponent implements OnInit, OnDestroy {
  
  page: number = 0;
  size: number = 20;
  contents: IAddedCourse[] = [];
  @Input()
  item: IAddedCourse;

  @Output() 
  titleClick = new EventEmitter<IAddedCourse>();

  constructor(
    private readonly mainService: MainService,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly contentService: ContentService,
    private readonly confirmService: ConfirmService,
    private readonly bannerService: BannerService
  ) { 
    this.mainService.pageTitle = 'Content';
    this.mainService.pageMainTitle = 'Wallet';
  }
  ngOnDestroy(): void {
    this.mainService.pageMainTitle = '';
  }
  ngOnInit() {
    this.resolveMyContents(this.page, this.size);
  }

  resolveMyContents(page: number, size: number) {
    this.contentService.getMyContents(page, size).subscribe(data => {
      this.contents = data;
    });
  }

  onTitleClicked(item) {
    this.titleClick.emit(item);
  }

  showCourseDetails(course) {
    this.router.navigate([`/content/course/${course.title}/${course.contentID}`]);
  }

  backClicked() {
    this.location.back();
  }
  showPopup(course) {
    this.confirmService.confirm({
      message: 'Are you sure you want to remove from Wallet?',
      header: '',
      acceptLabel:'Yes',
      rejectLabel:'No',
      accept: () => {
        this.removeMyContent(course.contentID);
        this.confirmService.choose(true);
      }
    });
  }
  removeMyContent(id){
    this.contentService.removeMyContent(id).subscribe(response => {
      this.resolveMyContents(this.page, this.size);
      this.bannerService.success(response.successMessage, true);
    });
  }
}
