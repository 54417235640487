import { Injectable } from '@angular/core';
import { of, Observable, from } from 'rxjs';
import { ICategory, ICourse } from './content.interface';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { map } from 'rxjs/operators';
import { OfflineService } from 'src/app/core/services/offline.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private readonly http: HttpClient,
    private readonly offlineService: OfflineService) { }

  getReccomendations(categoryId: number): Observable<Array<any>> {
    if(this.offlineService.isOnline()) {
      const href = `${Url.CONTENT_LIBRARY}/content/recommendations`;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getRecommendedContents();
    }
  }

  getCategories(): Observable<Array<any>> {
    if(this.offlineService.isOnline()) {
      const href = `${Url.CONTENT_LIBRARY}/category`;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getPrimaryCategories();
    }
  }

  getSubCategories(subCategoryId: number): Observable<Array<any>> {
    if(this.offlineService.isOnline()) {
      const href = `${Url.CONTENT_LIBRARY}/category/${subCategoryId}/subCategoryLinkedWithContent`;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getSecondaryCategories(subCategoryId);
    }
  }

  getContents(subCategoryId: number, all?: boolean): Observable<Array<any>> {
    if(this.offlineService.isOnline()) {
      const href = `${Url.CONTENT_LIBRARY}/category/${subCategoryId}/content`;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getContent(subCategoryId);
    }
  }

  getContentDetails(contentId: number): Observable<any> {
    if(this.offlineService.isOnline()) {
      const href = `${Url.CONTENT_LIBRARY}/content/` + contentId;
      return this.http.get<ICourse>(href);
    } else {
      return this.offlineService.getContentDetails(""+contentId);
    }
  }

  // not to be used with large sets of data, not very efficient
  shuffleArray(array) {
    array.sort(() => Math.random() - 0.5);
    return array;
  }

  searchContents(payload: any, page?: number, size?: number) {
    if(this.offlineService.isOnline()) {
      const href = `${Url.CONTENT_LIBRARY}/elasticSearch/search?page=${page}&size=${size}`;
      return this.http.post<any>(href, payload);
    } else {
      return this.offlineService.searchContent(payload.keyword, page, size);
    }
  }

  addToPlan(contentId) {
    const href = `${Url.CONTENT_LIBRARY}/add-to-mycontent/`+ contentId;
    return this.http.post<any>(href,contentId);
  }
  
  getMyContents(page: number, size: number) {
    const href = `${Url.CONTENT_LIBRARY}/elasticSearch/userPlans`;
    return this.http.get<any>(href);
  }
  removeMyContent(contentId):  Observable<any>{
    const href = `${Url.CONTENT_LIBRARY}/add-to-mycontent/`+ contentId;
    return this.http.delete(href,contentId);
  }

  sendAnalyticsData(modulecountDTO){
    const href = `${Url.CONTENT_LIBRARY}/content/analytics/search`;
    return this.http.post<any>(href,modulecountDTO);
  }

  getReccomendationsByCategory(categoryId: number): Observable<Array<any>> {
    if(this.offlineService.isOnline()) {
      const href = `${Url.CONTENT_LIBRARY}/content/recommendations/`+categoryId;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getRecommendedContentsByCatId(categoryId);
    }
  }
  getRefData(){
    if(this.offlineService.isOnline()) {
      const href = `${Url.CONTENT_LIBRARY}/refData/levels/`;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getRefContentLevels();
    }
  }

  hasContents(subCategoryId:number){
    return this.offlineService.hasContents(subCategoryId);
  }

}
