import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../core/services/main.service';

@Component({
  selector: 'vc-induction-questionnaire',
  templateUrl: './induction-questionnaire.component.html',
  styleUrls: ['./induction-questionnaire.component.scss']
})
export class InductionQuestionnaireComponent implements OnInit {

  inductionQuestionsList: boolean;
  showInductionPage: boolean;

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly mainservice: MainService,
    private readonly router: Router
  ) {
    this.mainservice.pageTitle = this.route.snapshot.data.title;
  }

  ngOnInit() {
    this.showInductionPage = true;
  }
  backClicked() {
    this.router.navigateByUrl('/plan');
  }

  showQuestionnaire() {
    this.inductionQuestionsList = true;
    this.showInductionPage = false;
  }
}
