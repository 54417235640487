import { Component, OnInit } from '@angular/core';
import { MainService } from '../../core/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IPlanCourse } from './plan.interface';
import { PlanService } from './plan.service';
import { TabsService } from './../../shared/components/tabs/tabs.service';
import { PlanTabNavigationData } from './plan-nav';

@Component({
  selector: 'vc-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  learner: any;
  courseSize = 0;
  lddSize = 0;
 

  constructor(
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly planService: PlanService,
    private readonly tabService: TabsService,
    private readonly profileTabNav: PlanTabNavigationData,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.tabService.tab(this.profileTabNav.planTabNavigation);
  }

  ngOnInit() {
  }
}
