import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlanComponent } from './plan.component';
import { PlanContentCardComponent } from './plan-content-card/plan-content-card.component';
import { InductionQuestionnaireComponent } from './induction-questionnaire/induction-questionnaire.component';
import { InductionQuestionsListComponent } from './induction-questions-list/induction-questions-list.component';
import { PageOneComponent } from './induction-questions-list/page-one/page-one.component';
import { PageTwoComponent } from './induction-questions-list/page-two/page-two.component';
import { PageThreeComponent } from './induction-questions-list/page-three/page-three.component';
import { PageFourComponent } from './induction-questions-list/page-four/page-four.component';
import { PageFiveComponent } from './induction-questions-list/page-five/page-five.component';
import { DeactivateGuard } from '../../core/guards/deactivate.guard';
import { PageSixComponent } from './induction-questions-list/page-six/page-six.component';
import { PageSevenComponent } from './induction-questions-list/page-seven/page-seven.component';
import { PageEightComponent } from './induction-questions-list/page-eight/page-eight.component';
import { PageNineComponent } from './induction-questions-list/page-nine/page-nine.component';
import { PageTenComponent } from './induction-questions-list/page-ten/page-ten.component';
import { PageElevenComponent } from './induction-questions-list/page-eleven/page-eleven.component';
import { PageTwelveComponent } from './induction-questions-list/page-twelve/page-twelve.component';
import { PageThirteenComponent } from './induction-questions-list/page-thirteen/page-thirteen.component';
import { InductionCheckAnswersComponent } from './induction-check-answers/induction-check-answers.component';


const routes: Routes = [
  {
    path: '',
    component: PlanComponent,
    children: [
      {
        path: '',
        component: PlanContentCardComponent
      }
    ]
  },
  {
    path: 'induction',
    data: { title: 'Induction' },
    component: InductionQuestionnaireComponent
  },
  {
    path: 'induction-questions-list',
    data: { title: 'Induction' },
    component: InductionQuestionsListComponent
  },
  {
    path: 'induction/page-one',
    data: { title: 'Induction' },
    component: PageOneComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-two',
    data: { title: 'Induction' },
    component: PageTwoComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-three',
    data: { title: 'Induction' },
    component: PageThreeComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-four',
    data: { title: 'Induction' },
    component: PageFourComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-five',
    data: { title: 'Induction' },
    component: PageFiveComponent,
    canDeactivate: [DeactivateGuard]

  },
  {
    path: 'induction/page-six',
    data: { title: 'Induction' },
    component: PageSixComponent,
    canDeactivate: [DeactivateGuard]

  },
  {
    path: 'induction/page-seven',
    data: { title: 'Induction' },
    component: PageSevenComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-eight',
    data: { title: 'Induction' },
    component: PageEightComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-nine',
    data: { title: 'Induction' },
    component: PageNineComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-ten',
    data: { title: 'Induction' },
    component: PageTenComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-eleven',
    data: { title: 'Induction'},
    component: PageElevenComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-twelve',
    data: { title: 'Induction'},
    component: PageTwelveComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/page-thirteen',
    data: { title: 'Induction'},
    component: PageThirteenComponent,
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'induction/check-answers',
    data: { title: 'Induction'},
    component: InductionCheckAnswersComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlanRoutingModule { }
