import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../../../../shared/constants/url-constants';

@Injectable()
export class PageElevenService {

  constructor(private readonly http: HttpClient) { }

  getRefData() {
    const href = `${Url.PLAN}/plan/refData`;
    return this.http.get(href).toPromise();;
  }

}
