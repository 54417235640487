import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FileUtilService, FileEvent } from './file-util.service';
import { Subscription } from 'rxjs';
import { startWith, filter } from 'rxjs/operators';

@Component({
  selector: 'vc-file-util',
  templateUrl: './file-util.component.html',
})
export class FileUtilComponent implements OnInit {

  private formSubs: Subscription;
  @ViewChild('fileUtilHolder', { static: true }) holder: ElementRef;

  constructor(private readonly fileUtil: FileUtilService) { }

  ngOnInit() {
    this.formSubs = this.fileUtil.activator
      .pipe(
        filter(event => !!event)
      )
      .subscribe((event: FileEvent) => {
        if (event.state && event.url) {
          this.createAndSubmitForm(event.url, event.method);
        }
      });
  }

  createAndSubmitForm(url: string, method: string): void {
    try {
      const fd = document.createElement('form');
      fd.setAttribute('action', url);
      fd.setAttribute('method', method);
      const inputElem = document.createElement('input');
      inputElem.setAttribute('name', 'access_token');
      inputElem.setAttribute('value', 'Bearer ' + localStorage.getItem('sessionToken'));
      fd.appendChild(inputElem);
      this.holder.nativeElement.appendChild(fd);
      fd.submit();
      this.holder.nativeElement.removeChild(fd);
    } finally {
      this.fileUtil.activate(null, false, null);
    }

  }

}
