<div class="history-container">
  <div class="history-entry-container" *ngFor="let historyEntry of historyData; index as i">
    <ng-container *ngIf="i < maxHistoryShown">
      <ng-container [ngSwitch]="true">
        <!-- induction history entry -->
        <ng-container *ngSwitchCase="historyEntry.courseCode === null">
          <div
            [class.first-induction-history-entry-spacer]="i === 0"
            [ngClass]="setUpperHistoryEntrySpacer(historyEntry, i)"
          ></div>
          <div class="f-structure-container">
            <div 
              class="f-structure"
              [class.last-history-entry-f-structure]="i === historyData.length - 1"
            >
              <div class="empty-f-structure-notch"></div>
            </div>
            <div class="induction-history-inset">
              <h2 class="induction-entry-heading" id="induction-history-heading">{{historyEntry.header}}</h2>
              <p class="induction-entry-date">{{historyEntry.startDate | date : 'd MMMM yyyy'}}</p>
              <ng-container [ngSwitch]="historyEntry.header">
                <ng-container *ngSwitchCase="'Employment pathway changed'">
                  <div *ngFor="let answerArr of historyEntry.formattedAnsList">
                    <p *ngFor="let answer of answerArr" class="induction-history-body">
                      {{answer}}
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <ng-container *ngFor="let answer of historyEntry.ansList">
                    <p class="induction-history-body">
                      {{answer}}
                    </p>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div 
          [ngClass]="setLowerHistoryEntrySpacer(historyEntry, i)"
          ></div>
        </ng-container>
        <!-- course history entry -->
        <ng-container *ngSwitchDefault>
          <div class="history-entry-spacer"></div>
          <div class="f-structure-container">
            <div class="f-structure">
              <div class="f-structure-notch"></div>
            </div>
            <div class="course-history-container">
              <div class="course-history-left-container">
                <h2 class="induction-entry-heading" id="induction-history-heading">{{historyEntry.header}}</h2>
                <p class="induction-entry-date">{{historyEntry.startDate | date : 'd MMMM yyyy'}} - {{historyEntry.endDate | date : 'd MMMM yyyy'}}</p>
              </div>
              <div class="course-history-right-container">
                <span *ngIf="historyEntry.status"> 
                  <div class="status-tag" [ngClass] = "{'blue': historyEntry.status === 'Completed', 'inactive': historyEntry.status === 'Withdrawn'}">
                    {{historyEntry.status | uppercase}}
                  </div>
                </span>
                <span>
                  <div class="course-category-tag course-category-tag-{{historyEntry.cssLabel ? historyEntry.cssLabel : 'none'}}">
                    <span class="category-name">
                      {{historyEntry.categoryName | uppercase}}
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="history-entry-spacer"></div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="load-more-container" *ngIf="maxHistoryShown < historyData?.length">
    <a href="javascript:void(0)" (click)="loadMore()">Load more</a>
  </div>
</div>
