<ng-http-loader spinner="sk-rotationg-plane" [backgroundColor]="'#00823b'"></ng-http-loader>
<vc-header></vc-header>
<vc-main class="sign-in-page">

  <div class="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
    *ngIf="usernameEmpty || passwordEmpty || passwordOrPRNInvalid" #errorSummaryDiv>
    <h2 class="govuk-error-summary__title" id="error-summary-title">There is a problem</h2>
    <div class="govuk-error-summary__body">
      <ul class="govuk-list govuk-error-summary__list">
        <li *ngIf="usernameEmpty">
          <button class="error-list-button" (click)="errorListClick('username')">Enter your username</button>
        </li>
        <li *ngIf="passwordEmpty">
          <button class="error-list-button" (click)="errorListClick('password')">Enter your password</button>
        </li>
        <li *ngIf="passwordOrPRNInvalid">
          <button class="error-list-button" (click)="errorListClick('username')">Username or password is not valid,
            confirm and enter your username and password</button>
        </li>
      </ul>
    </div>
  </div>

  <div class="moj-banner-warning" *ngIf="backendErrorMessage">
    <svg class="moj-banner-icon" fill="currentColor" role="presentation" focusable="false"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" height="25" width="25">
      <path d="M13.6,15.4h-2.3v-4.5h2.3V15.4z M13.6,19.8h-2.3v-2.2h2.3V19.8z M0,23.2h25L12.5,2L0,23.2z" /></svg>

    <div class="moj-banner-message">
      <span class="moj-banner__assistive">Warning</span>{{ backendErrorMessage }}</div>
  </div>

  <div class="site-width-container" id="sign-in">
    <div class="grid-row" *ngIf="noticeBoardMessage">
      <div class="column-full">
        <div class="notice-board-banner">
          <svg class="notice-board-icon" fill="currentColor" role="presentation" focusable="false"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" height="25" width="25">
            <path
              d="M13.7,18.5h-2.4v-2.4h2.4V18.5z M12.5,13.7c-0.7,0-1.2-0.5-1.2-1.2V7.7c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v4.8
          C13.7,13.2,13.2,13.7,12.5,13.7z M12.5,0.5c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S19.1,0.5,12.5,0.5z" />
            </svg>
          <div class="mbanner-message">
            <h2 class="heading-m">{{noticeBoardMessage | truncate:[300, ''] }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="site-width-container" id="sign-in">
    <div class="grid-row">
      <div class="column-full">
        <form autocomplete="off" [formGroup]="signInForm" (ngSubmit)="signIn()" ngNativeValidate>
          <vc-form-control [control]="signInForm.get('userName')" [controlName]="'userName'" [label]="'Username'"
            [hint]="'Your PRN is your Username'" [formSubmitted]="formSubmitted">
            <input formControlName="userName" class="form-control left" type="text" id="id-userName" name="userName"
              value="" aria-describedby="id-section-help-userName id-section-error-userName" #username>
          </vc-form-control>
          <vc-form-control [control]="signInForm.get('password')" [controlName]="'password'" [label]="'Password'"
            [formSubmitted]="formSubmitted">
            <input formControlName="password" class="form-control" type="password" id="id-password" name="password"
              value="" aria-describedby="id-section-help-password id-section-error-password" #password>
          </vc-form-control>
          <div class="">
            <button id="id-submit-button" class="button button-start" type="submit" name="sign-in">
              Sign in
              <svg style="margin-left: 4px;" xmlns="http://www.w3.org/2000/svg" width="17.5" height="19" viewBox="0 0 33 40" aria-hidden="true" focusable="false">
                <path fill="currentColor" d="M0 0h13l20 20-20 20H0l20-20z"></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</vc-main>
<vc-footer></vc-footer>
<vc-confirm-box></vc-confirm-box>