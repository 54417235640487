import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { ValidationService } from '../form-control/validation.service';
import { startWith, map } from 'rxjs/operators';

/* 
  * Please provide a Formarray of FormControl
  * Instead of Formgroup to avoid unnecessery iterations
*/

@Component({
  selector: 'vc-input-chips',
  templateUrl: './input-chips.component.html',
  styleUrls: ['./input-chips.component.scss']
})
export class InputChipsComponent implements OnInit, OnChanges {

  @Input() control: FormArray;
  @Input() label: string;
  @Input() optional: string;
  @Input() hint: string;
  @Input() controlName: string;
  @Input() fromContext: boolean = false;
  @Input() formSubmitted: boolean = true;
  @Input() removable: boolean = true;
  @Input() dataList: string[] = [];

  @Input() restrictMax: boolean = false;
  @Input() max: number = 5


  selector: FormControl = new FormControl('');
  filtered: string[] = [];

  constructor(private readonly validationService: ValidationService) { }

  ngOnInit() {
    this.control.valueChanges.pipe(
      startWith(null),
      map((values: any[]) => {
        this.filtered = values && values.length > 0
          ? this.dataList.filter(each => !values.includes(each))
          : this.dataList;
      })
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filtered = this.dataList;
  }

  get errorMessage(): string {

    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return this.fromContext
          ? this.validationService.getValidationMessageFromContext(propertyName, this.controlName)
          : this.validationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }

  onSelectorKeydown() {
    if (this.selector.value === '' || this.selector.value === null || (this.restrictMax && this.control.value.length === this.max)) {
      this.selector.setValue('');
      return;
    }
    const values: any[] = this.control.value;
    if (!values.includes(this.selector.value)) {
      this.control.push(this.provide(this.selector.value));
    }
    this.control.updateValueAndValidity();
    this.control.markAsDirty();
    this.selector.setValue('');
  }

  drop(index: number) {
    this.control.markAsDirty();
    this.control.removeAt(index);
  }

  private provide(val: string): FormControl {
    const ctrl = new FormControl(val);
    ctrl.markAsTouched();
    return ctrl;
  }

}
