import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const profileInfoSchema: RxCollectionCreatorBase = {
    schema: {
        title: 'Service User profile',
        version: 0,
        description: 'Service User profile details',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                primary: true
            },
            title: {
                type: ['string', 'null']
            },
            firstName: {
                type: 'string'
            },
            surName: {
                type: 'string'
            },
            dateOfBirth: {
                type: 'string'
            },
            gender: {
                type: ['string', 'null']
            },
            prn: {
                type: 'string'
            },
            organizationName: {
                type: 'string'
            },
            deviceId: {
                type: ['string', 'null']
            },
            ethnicity: {
                type: ['string', 'null']
            },
            uln: {
                type: ['string', 'null']
            },
            lrn: {
                type: ['string', 'null']
            },
            lrnPrevious: {
                type: ['string', 'null']
            },
            selfAssessmentLdd: {
                type: ['string', 'null']
            },
            priorAttainment: {
                type: ['string', 'null']
            },
            mathInitialAssessment: {
                type: ['string', 'null']
            },
            mathsAssessmentDate: {
                type: ['string', 'null']
            },
            englishInitialAssessment: {
                type: ['string', 'null']
            },
            englishAssessmentDate: {
                type: ['string', 'null']
            },
            languageStatus: {
                type: ['string', 'null']
            },
            glhDtoList: {
                type: ['array', 'null'],
                items: {
                    type: 'object',
                    properties: {
                        attentedGLH: {
                            type: ['number', 'null']
                        },
                        atualGLH: {
                            type: ['number', 'null']
                        },
                        establishmentId: {
                            type: ['number', 'null']
                        },
                        establishmentName: {
                            type: ['string', 'null']
                        },
                        learnerId: {
                            type: ['number', 'null']
                        },
                        totalAttentedGLH: {
                            type: ['number', 'null']
                        },
                        totalAtualGLH: {
                            type: ['number', 'null']
                        }
                    }
                }
            },
            lldAndHealthProblemList: {
                type: ['array', 'null'],
                items: {
                    type: 'object',
                    properties: {
                        isPrimary: {
                            type: ['boolean', 'null']
                        },
                        isSecondary: {
                            type: ['boolean', 'null']
                        },
                        llldAndHealthProblem: {
                            type: ['string', 'null']
                        }
                    }
                }
            },
            courseList: {
                type: ['array', 'null'],
                items: {
                    type: 'object',
                    properties: {
                        courseCode: {
                            type: ['string', 'null']
                        },
                        courseName: {
                            type: ['string', 'null']
                        },
                        completionStatus: {
                            type: ['string', 'null']
                        },
                        effectiveFrom: {
                            type: ['string', 'null']
                        },
                        effectiveTo: {
                            type: ['string', 'null']
                        },
                        isAccredited: {
                            type: ['boolean', 'null']
                        },
                        outcome: {
                            type: ['string', 'null']
                        },
                        plannedEndDate: {
                            type: ['string', 'null']
                        },
                        status: {
                            type: ['string', 'null']
                        },
                        withdrawlReasons: {
                            type: ['string', 'null']
                        }
                    }
                }
            },
            totalAttentedGLH: {
                type: ['number', 'null']
            }
        },
        required: ['id', 'deviceId'],
        encrypted: ['deviceId']
    }
}