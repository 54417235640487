<div class="spacer text-right exit_button_align">
    <span>
        <button id="exit_button" class="button button-secondary" color="primary"
            (click)="onExitClicked(this)">Exit</button>
    </span>
</div>
<div role="group" class="errorMessagesdiv" id="error-message-summary" style="display: none;" aria-labelledby="errorSummary-heading">
    <input type="checkbox" />
    <div class="errorMessages">
        <p>There is problem</p>
        <div id="error-message-list">
            <div *ngFor="let surveyError of surveyErrors">
            {{surveyError}}
            </div>
        </div>
    </div>
</div>
<div class="govuk-panel govuk-panel--confirmation" *ngIf="isCompleted">
    <h1 class="govuk-panel__title">
       Assessment complete
    </h1>

    <div class="govuk-panel__body">
        Completed on <div><strong> {{updatedDate | date :  "dd MMMM y HH:mm" }} </strong></div>
    </div>

    <div class="govuk-panel__body">
        Completed by 
        <div><strong>{{updatedBy}}</strong></div>
        <div><strong>{{togetherModeUserName}}</strong></div>
    </div>

</div>

<div id='assessment_container'>
    <div id="surveyElement"></div>
</div>
<div *ngIf="!isCompleted" class="buttons-div">
    <button *ngIf="surveyItem?.isLastPage" type="submit" name="save-and-submit" class="button button-primary mr10" id="save_submit_user_button"
            (click)="completeSurveyResponse(this)">Save and submit</button>
    <button type="submit"name="save-as-draft" id="save_draft_user_button" class="button button-secondary"
            (click)="saveSurveyResponse(this)">Save as draft</button>
</div>
    