<a href="javascript:void(0)" [routerLink]="['view-jobs-for-prison-leavers',job.id]">
    <article class="job-card column-quarter recommended">
        <figure class="job-card-wrapper">
            <img *ngIf="job?.path == null" alt={{job.jobTitle}} src="../../../assets/images/employment/NFN.jpg">
            <img *ngIf="job?.path !== null" alt={{job.jobTitle}} [src]="getImagePath(job?.path)">
            <figcaption class="jobinfo">
                <h1 class="position">{{job.jobTitle | truncate:[36, '...'] }} </h1>
                <p class="location">{{job.location | truncate:[15, '...'] }}</p>
            </figcaption>
        </figure>
    </article>
</a>

