import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SrmRoutingModule } from './srm-routing.module';
import { SrmService } from './srm.service';
import { SrmComponent } from './srm.component';
import { CreateSrmComponent } from './create-srm/create-srm.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../core/pipes/pipes.module';


@NgModule({
    declarations:[SrmComponent, CreateSrmComponent],
    imports:[
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SrmRoutingModule,
        PipesModule
    ],
    providers:[
        SrmService
    ]
})
export class SrmModule{

}