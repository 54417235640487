import { Injectable, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  private _lastActivity = new Date().getTime();

  get lastActivity() { return this._lastActivity; }

  set lastActivity(activityTimestamp) { this._lastActivity = activityTimestamp; }

  constructor(private readonly http: HttpClient) { }

  checkHeartbeat() {
    return this.http.get<any>(`${Url.AUTHENTICATION}/checkHeartbeat`);
  }

  public openCountdownWarning(
    countdown: number, dialogTitle?: string, dialogMessage?: string, dialogActionMessage?: string): Observable<boolean> {
    return null;
  }
}
