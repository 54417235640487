import { FormGroup } from '@angular/forms';

export class Utility {
    static filterArrayByKeyAndValue(items: any[], key: any, value: any): any[] {
        return items.filter(function (item) {
            return item[key] === value;
        });
    }

    static filterArrayByKeyAndArray(items: any[], key: any, value: any[]): any[] {
        return items.filter(function (item) {
            return value.includes(item[key]);
        });
    }

    static getObjectFromArrayByKeyAndValue(items: any[], key: any, value: any): any {
        const list = items.filter(function (item) {
            return item[key] === value;
        });
        if (list.length === 1) {
            return list[0];
        }
        if (list.length <= 0) {
            return null;
        }
        if (list.length > 1) {
            throw new Error('The specified array has multiple objects with key: ' + key + ' and value: ' + value);
        }
        return null;
    }

    static isEmpty(value: string): boolean {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return false;
    }
    
    static replaceString(regex:string , relaceString:string , value:string){

        if(Utility.isEmpty(value)){
             return null;
         }   
         return value.replace(regex,relaceString);
     }

    static setValidators(form: FormGroup, validators: any): void {
        Object.keys(form.controls).forEach(name => {
            if (validators[name]) {
                const control = form.get(name);
                if (control) {
                    control.setValidators(validators[name]);
                    control.updateValueAndValidity();
                    control.markAsTouched();
                }
            }
        });
    }
    
    static clearAllValidatorsAndErrors(form: FormGroup): void {
        Object.keys(form.controls).forEach(name => {
            const control = form.get(name);
            control.clearValidators();
            control.setErrors(null);
            control.markAsTouched();
        });
    }
}
