import { Url } from './../../shared/constants/url-constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OfflineService } from 'src/app/core/services/offline.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private readonly http: HttpClient,
    private readonly offlineService: OfflineService
  ) {}

  getUserProfile() {
    const href = `${Url.PROFILE}/virtual-campus/user`;
    return this.http.get<any>(href);
  }

  changePassword(payload) {
    const href = `${Url.USER_MANAGEMENT}/service-user/changePassword`;
    return this.http.post<any>(href, payload);
 }
  getUserLearnerInfo() {
    const href = `${Url.PROFILE}/su-learner/suLearnerInfo?id=`;
    return this.http.get<any>(href);
  }

  getCachedLearnerInfo() {
    return this.offlineService.getProfileInfo();
  }
}
