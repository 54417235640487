import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../../../core/services/main.service';
import { ConfirmService } from '../../../../../shared/components/confirm-box/confirm-box.service';
import { ValidationService } from '../../../../../shared/components/form-control/validation.service';
import { TrainingService } from '../training.service';
import { EDITTRAINING_VALIDATION_CONTEXT } from './edit-training.validation';
import { ICanDeactivate } from '../../../../../core/guards/deactivate.interface';

const TRAININGFORM_VALIDATORS_SAVE = {
  qualification: [Validators.required, Validators.maxLength(100)],
  body: [Validators.required, Validators.maxLength(100)],
  grade: [Validators.maxLength(100)],
  details: [Validators.required, Validators.maxLength(200)],
  startDate: [Validators.required, ValidationService.monthYearValidator, ValidationService.futureDate, ValidationService.pastYear(80)],
  endDate: [ValidationService.monthYearValidator,
  ValidationService.invalidEndDateOrCurrent('startDate', 'endDate', 'isCurrent'),
  ValidationService.invalidEndDateAndCurrent('endDate', 'isCurrent'),
  ValidationService.futureDate],
}

const TRAININGFORM_VALIDATORS_DRAFT = {
  qualification: [Validators.maxLength(100)],
  body: [Validators.maxLength(100)],
  grade: [Validators.maxLength(100)],
  details: [Validators.maxLength(200)],
  startDate: [ValidationService.monthYearValidator],
  endDate: [ValidationService.monthYearValidator],
}

@Component({
  selector: 'vc-edit-training',
  templateUrl: './edit-training.component.html',
  styleUrls: ['./edit-training.component.scss']
})
export class EditTrainingComponent implements OnInit, ICanDeactivate, OnDestroy {


  trainingForm: FormGroup;
  createAnother = new FormControl('N');

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly main: MainService,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly confirm: ConfirmService,
    private readonly trainingService: TrainingService,
    private readonly validationService: ValidationService
  ) {
    this.validationService.setContext(EDITTRAINING_VALIDATION_CONTEXT);
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'Training';
    this.initForm();

  }
  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.resolveData(+params.id);
      }
    });
    this.trainingForm.get('startDate').valueChanges.subscribe(data => {
      this.trainingForm.get('endDate').updateValueAndValidity();
    });
    this.trainingForm.get('isCurrent').valueChanges.subscribe(data => {
      this.trainingForm.get('endDate').updateValueAndValidity();
    });
  }

  private initForm() {
    this.trainingForm = this.fb.group({
      id: this.fb.control(''),
      qualification: this.fb.control(''),
      body: this.fb.control(''),
      grade: this.fb.control(''),
      startDate: this.fb.control(''),
      endDate: this.fb.control(''),
      isCurrent: this.fb.control(false),
      details: this.fb.control('')
    })
  }

  private resolveData(id: number) {
    this.trainingService.getTraningDetails(id)
      .subscribe(data => {
        this.trainingForm.patchValue(data);
      });
  }

  save(): void {
    this.setValidators(this.trainingForm, TRAININGFORM_VALIDATORS_SAVE);
    if (this.trainingForm.valid) {
      const data = this.parseSaveData();
      this.trainingService.saveAndContinueTraining(data)
        .subscribe(res => {
          this.checkToAddNew();
        });
    }
    window.scrollTo(0, 0);
  }

  draft(): void {
    this.clearAllValidatorsAndErrors(this.trainingForm);
    this.setValidators(this.trainingForm, TRAININGFORM_VALIDATORS_DRAFT);
    if (this.trainingForm.valid) {
      const data = this.parseSaveData();
      if (this.isEmptyForm(data)) {
        this.router.navigate(['../'], { queryParams: {}, relativeTo: this.route });
      } else {
        this.trainingService.saveAndLaterTraining(data)
          .subscribe(res => {
            this.checkToAddNew();
          });
      }
    }
    window.scrollTo(0, 0);
  }

  isEmptyForm(data) {
    const filterData = Object.keys(data).filter(name => (data[name]));
    return filterData.length === 0;
  }

  parseSaveData() {
    const training = this.trainingForm.value;
    return training;
  }

  cvSummaryPage() {
    this.router.navigate(['/employment/cv-builder/guided']);
  }

  checkToAddNew() {
    this.resetConditionalForm();
    if (this.createAnother.value === 'N') {
      this.router.navigate(['../'], { queryParams: {}, relativeTo: this.route }); 
    } else {
      this.router.navigate([], { queryParams: {} });
    }
  }

  private clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      if (control) {
        control.clearValidators();
        control.setErrors(null);
        control.markAsTouched();
      }
    });
  }

  resetConditionalForm() {
    this.initForm();
  }

  private setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  canExit() {
    if (this.trainingForm.dirty) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  gotoPrev() {
    this.location.back();
  }

}
