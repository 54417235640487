import { ActivatedRoute } from '@angular/router';
import { MainService } from './../../../core/services/main.service';
import { ProfileTabNavigationData } from './../user-profile-nav';
import { UserProfileService } from './../user-profile.service';
import { TabsService } from './../../../shared/components/tabs/tabs.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DatabaseService } from 'src/app/core/services/database.service';

@Component({
  selector: 'vc-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {
  profile: any;
  userId: any;
  showChangePassword: boolean = false;
  learner: any;

  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly databaseService: DatabaseService,
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly location: Location
    ) {
      this.mainService.pageTitle = this.route.snapshot.data.title;
   }

  ngOnInit() {
    // this.resolveUserName();
    this.getLearnerBasic();
  }
  resolveUserName() {
    this.userProfileService.getUserProfile().subscribe(
        response => {
          this.profile = response;
      });
  }
  goToChange() {
    this.showChangePassword = true;
  }
  goToProfile() {
    this.showChangePassword = !this.showChangePassword;
  }
  getLearnerBasic() {
    this.userProfileService.getCachedLearnerInfo().subscribe(info => {
      this.learner = info;
    })
  }

}
