import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlModule } from './../../shared/components/form-control/form-control.module';
import { PlanComponent } from './plan.component';
import { PlanRoutingModule } from './plan-routing.module';
import { PlanService } from './plan.service';
import { PlanTabNavigationData } from './plan-nav';
import { AccordionModule } from './../../shared/components/accordion/accordion.module';
import { TabsModule } from './../../shared/components/tabs/tabs.module';
import { PlanContentCardComponent } from '../plan/plan-content-card/plan-content-card.component';
import { InductionQuestionnaireComponent } from './induction-questionnaire/induction-questionnaire.component';
import { InductionQuestionsListComponent } from './induction-questions-list/induction-questions-list.component';
import { PlanCurrentComponent } from './plan-content-card/plan-current/plan-current.component';
import { PlanHistoryComponent } from './plan-content-card/plan-history/plan-history.component';
import { InductionQuestionnaireService } from './induction-questionnaire/induction-questionnaire.service';
import { InputChipsModule } from './../../shared/components/input-chips/input-chips.module';
import { PageOneComponent } from './induction-questions-list/page-one/page-one.component';
import { PageTwoComponent } from './induction-questions-list/page-two/page-two.component';
import { PageThreeComponent } from './induction-questions-list/page-three/page-three.component';
import { PageFourComponent } from './induction-questions-list/page-four/page-four.component';
import { PageFiveComponent } from './induction-questions-list/page-five/page-five.component';
import { PipesModule } from './../../core/pipes/pipes.module';
import { EducationService } from '../cv-builder/guided-cv-builder/education/education.service';
import { PlanEducationComponent } from './induction-questions-list/page-five/plan-education/plan-education.component';
import { PageSixComponent } from './induction-questions-list/page-six/page-six.component';
import { PlanEmploymentComponent } from './induction-questions-list/page-six/plan-employment/plan-employment.component';
import { MultiSelectOptionsModule } from './../../shared/components/multi-select-options/multi-select-options.module';
import { WorkExperienceService } from '../cv-builder/guided-cv-builder/work-experience/work-experience.service';
import { EditWorkExperienceService } from '../cv-builder/guided-cv-builder/work-experience/edit-work-experience/edit-work-experience.service';
import { PageFiveService } from './induction-questions-list/page-five/page-five.service';
import { PageSevenComponent } from './induction-questions-list/page-seven/page-seven.component';
import { PageEightComponent } from './induction-questions-list/page-eight/page-eight.component';
import { PageNineComponent } from './induction-questions-list/page-nine/page-nine.component';
import { PageNineService } from './induction-questions-list/page-nine/page-nine.service';
import { PageTenComponent } from './induction-questions-list/page-ten/page-ten.component';
import { PageTenService } from './induction-questions-list/page-ten/page-ten.service';
import { PageElevenComponent } from './induction-questions-list/page-eleven/page-eleven.component';
import { PageElevenService } from './induction-questions-list/page-eleven/page-eleven.service';
import { PageTwelveComponent } from './induction-questions-list/page-twelve/page-twelve.component';
import { PageThirteenComponent } from './induction-questions-list/page-thirteen/page-thirteen.component';
import { InductionCheckAnswersComponent } from './induction-check-answers/induction-check-answers.component';
import { PlanTrackComponent } from './plan-content-card/plan-track/plan-track.component';
import { PlanScheduleComponent } from './plan-content-card/plan-schedule/plan-schedule.component';
import { EntrySystemComponent } from './plan-content-card/plan-track/f-structure-entries/entry-system/entry-system.component';
import { EntryCommentComponent } from './plan-content-card/plan-track/f-structure-entries/entry-comment/entry-comment.component';
import { EntryInductionComponent } from './plan-content-card/plan-track/f-structure-entries/entry-induction/entry-induction.component';
import { EntryCourseComponent } from './plan-content-card/plan-track/f-structure-entries/entry-course/entry-course.component';
import { EntryReviewComponent } from './plan-content-card/plan-track/f-structure-entries/entry-review/entry-review.component';

@NgModule({
  declarations: [
    PlanComponent,
    PlanContentCardComponent,
    PlanScheduleComponent,
    PlanTrackComponent,
    InductionQuestionnaireComponent,
    InductionQuestionsListComponent,
    PlanCurrentComponent,
    PlanHistoryComponent,
    PageOneComponent,
    PageTwoComponent,
    PageThreeComponent,
    PageFourComponent,
    PageFiveComponent,
    PlanEducationComponent,
    PageSixComponent,
    PlanEmploymentComponent,
    PageSevenComponent,
    PageEightComponent,
    PageNineComponent,
    PageTenComponent,
    PageElevenComponent,
    PageTwelveComponent,
    PageThirteenComponent,
    InductionCheckAnswersComponent,
    EntrySystemComponent,
    EntryCommentComponent,
    EntryInductionComponent,
    EntryCourseComponent,
    EntryReviewComponent,
  ],
  imports: [
    CommonModule,
    PlanRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlModule,
    TabsModule,
    AccordionModule,
    InputChipsModule,
    PipesModule,
    MultiSelectOptionsModule
  ],
  providers: [
    PlanService,
    PlanTabNavigationData,
    InductionQuestionnaireService,
    EducationService,
    WorkExperienceService,
    EditWorkExperienceService,
    PageFiveService,
    PageElevenService,
    PageNineService,
    PageTenService
  ]
})
export class PlanModule { }
