import { Routes } from '@angular/router';
import { AssessmentComponent } from './assessment.component';
import { ViewAssessmentComponent } from './view-assessment/view-assessment.component';
import { CompleteAssessmentComponent } from './complete-assessment/complete-assessment.component';
import { DeactivateGuard } from '../../core/guards/deactivate.guard';



export const AssessmentRoutes: Routes = [
  {
    path: '',
    component: AssessmentComponent,
    data: { title: 'Assessments', auth: [1] }
  },
  {
    path: 'view-assessment/:assessmentTemplateId',
    component: ViewAssessmentComponent,
    data: { title: 'View Assessment', auth: [1, 4] }
  },
  {
    path: 'complete-assessment',
    component: CompleteAssessmentComponent,
    data: { title: 'Complete Assessment', auth: [1, 4] },
    canDeactivate: [DeactivateGuard]
  }
];
