import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeactivateGuard } from '../../../core/guards/deactivate.guard';
import { GuidedCvBuilderComponent } from './guided-cv-builder.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { PersonalStatementComponent } from './personal-statement/personal-statement.component';
import { WorkExperienceComponent } from './work-experience/work-experience.component';
import { EditWorkExperienceComponent } from './work-experience/edit-work-experience/edit-work-experience.component';
import { PaidWorkComponent } from './work-experience/edit-work-experience/paid-work/paid-work.component';
import { VolunteerWorkComponent } from './work-experience/edit-work-experience/volunteer-work/volunteer-work.component';
import { PrisonWorkComponent } from './work-experience/edit-work-experience/prison-work/prison-work.component';
import { EducationHomeComponent } from './education/education-home.component';
import { TrainingComponent } from './training/training.component';
import { EditTrainingComponent } from './training/edit-training/edit-training.component';
import { ComputerSkillsHomeComponent } from './computer-skills/computer-skills-home.component';
import { EditEducationComponent } from './education/edit-education/edit-education.component';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { CvTemplatesComponent } from './cv-templates/cv-templates.component';
import { GenerateCvComponent } from './cv-templates/generate-cv/generate-cv.component';
import { CvBuilderMyCvsComponent } from './wrapper/cvs/cv-builder-my-cvs.component';

const CV_BUILDER = 'CV Builder';

const routes: Routes = [
  {
    path: '',
    component: GuidedCvBuilderComponent,
    data: { title: 'CV summary' , pageMainTitle: 'CV builder'},
  },
  {
    path: 'personal-details',
    component: PersonalDetailsComponent,
    data: { title: 'Personal details' },
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'personal-statement',
    component: PersonalStatementComponent,
    data: { title: 'Personal statement' },
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'experience',
    component: WorkExperienceComponent,
    data: { title: 'Work experience' },
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'experience/work',
    component: EditWorkExperienceComponent,
    children: [
      {
        path: '',
        redirectTo: 'paid',
        pathMatch: 'full'
      },
      {
        path: 'paid',
        component: PaidWorkComponent,
        data: { title: 'Paid work' },
        canDeactivate: [DeactivateGuard],
      },
      {
        path: 'volunteer',
        component: VolunteerWorkComponent,
        data: { title: 'Volunteer and community services' },
        canDeactivate: [DeactivateGuard],
      },
      {
        path: 'prison',
        component: PrisonWorkComponent,
        data: { title: 'Prison work' },
        canDeactivate: [DeactivateGuard],
      }
    ]
  },
  {
    path: 'education-home',
    component: EducationHomeComponent,
    data: { title: 'Education' }
  },
  {
    path: 'education-home/education',
    component: EditEducationComponent,
    data: { title: 'Add education' },
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'training-home',
    component: TrainingComponent,
    data: { title: 'Training' },
  },
  {
    path: 'training-home/training',
    component: EditTrainingComponent,
    data: { title: 'Training details' },
    canDeactivate: [DeactivateGuard]
  },
  {
    path: 'computer-skills',
    component: ComputerSkillsHomeComponent,
    data: { title: 'Computer skills' },
    canDeactivate: [DeactivateGuard],
  },
  {
    path: 'additional-info',
    component: AdditionalInfoComponent,
    data: { title: 'Additional Information' },
    canDeactivate: [DeactivateGuard],
  },
  {
    path: 'cv-templates',
    component: CvTemplatesComponent,
    data: { title: 'Template selection', mainTitle: CV_BUILDER },
    canDeactivate: [DeactivateGuard],
  },
  {
    path: 'generate',
    component: GenerateCvComponent,
    data: { title: 'Generate CV', mainTitle: CV_BUILDER },
  },
  {
    path: 'cvs',
    component: CvBuilderMyCvsComponent,
    data: { title: 'CVs', mainTitle: CV_BUILDER }
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuidedCvBuilderRoutingModule { }
