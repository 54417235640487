import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { IDocViewerConfig } from '../../../shared/components/doc-viewer/doc-viewer.config';
import { ModuleTypes } from '../../../shared/constants/types.constants';
import { NotificationService } from '../../../core/services/notification.service';
import { Url } from '../../../shared/constants/url-constants';
import { OfflineService } from 'src/app/core/services/offline.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgxExtendedPdfViewerComponent } from 'ngx-extended-pdf-viewer';
declare let ePub: any;

const docConfig: IDocViewerConfig = {
  showDownloadButton: false,
  showPrintButton: true,
  showOpenFileButton: false,
  showBookmarkButton: false,
  showSidebarButton: false,
  showSecondaryToolbarButton: false,
  showPresentationModeButton: true,
}

@Component({
  selector: 'vc-content-module',
  templateUrl: './content-module.component.html',
  styleUrls: ['./content-module.component.scss']
})
export class ContentModuleComponent implements OnInit, OnChanges {

  @Input() module: any;
  @Input() contentId: number;
  @ViewChild('pdfViewer') pdfViewer: NgxExtendedPdfViewerComponent;
  viewType: string;
  moduleType: string;
  config: IDocViewerConfig;
  option: any;
  idx: string;
  source: SafeUrl;
  pdfSrc;
  rendition;
  constructor(
    private readonly notification: NotificationService,
    private readonly offlineService: OfflineService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.idx = 'player';
    this.resolveTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  resolveTypes() {
    const type = ModuleTypes.find(elem => {
      return elem.types.includes(this.module.type)
    });
    this.viewType = type.viewType;
    this.moduleType = this.module.type;
    this.setLauncher();
  }

  private async setLauncher() {
    if (this.viewType === 'scorm') {
      this.moduleType = this.module.type.split(':')[1];
      return;
    }
    if (this.viewType === 'pdf') {
      this.config = docConfig;
      return;
    }
    if (this.viewType === 'video') {
      this.option = {
        preload: 'auto'
      };
      return;
    }
    if (this.viewType === 'audio') {
      this.option = {
        preload: 'auto'
      };
      return;
    }
    if (this.viewType === 'image') {
      this.option = {
        preload: 'auto'
      };
      return;
    }
    
    if(this.viewType === 'epub') {
      const book = ePub(this.module.path);
      this.rendition = book.renderTo("area", { width: "80%", height: "800"});
      const displayed = this.rendition.display();
      return;
    }
  }

  next() {
    this.rendition.next();
  }

  prev() {
    this.rendition.prev();
  }
  download(moduleId: number): void {
    const href = `${Url.ELECTRON_APP}/content/${this.contentId}/module/${moduleId}/download`;
    this.createAndSubmitForm(href);
  }

  createAndSubmitForm(url: string): void {
    const fd = document.createElement('form');
    fd.setAttribute('action', url);
    fd.setAttribute('method', 'POST');
    const inputElem = document.createElement('input');
    inputElem.setAttribute('name', 'access_token');
    inputElem.setAttribute('value', 'Bearer ' + localStorage.getItem('sessionToken'));
    fd.appendChild(inputElem);
    const holder = document.getElementById('form_holder');
    holder.appendChild(fd);
    fd.submit();
    holder.removeChild(fd);
  }

  getAttachment(moduleId,modulePath) {
    // this.offlineService.getModuleAttachement(moduleId, ""+this.contentId).then(attachment => {
      this.downloadFile(this.module.path, this.module.fileName);
    // })
  }

  downloadFile(url, name: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    const filename = name;
    a.download = filename;
    a.target = '_blank';
    const event = new MouseEvent('click', {
      'view': window,
      'bubbles': false,
      'cancelable': true
    });
    a.dispatchEvent(event);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

