<a (click)="back()" href="javascript:void(0);" id="backButton" class="link-back nomargintop">Back</a>
<div class="grid-row mt25">
    <div class="column-full prison-job-list-table" id="prison-job-list-table">
        <table>
            <thead>
                <tr>
                    <th scope="col">Job</th>
                    <th scope="col">Location</th>
                    <th scope="col">Posting date</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let s of prisonJobList | paginate: { itemsPerPage: size, currentPage: (page + 1), totalItems: totalItems  }">
                    <td scope="row" class="width-30">
                        <a id="jobTitle_{{s.id}}"
                            [routerLink]="['prison-jobs-details',s.id]">{{s?.jobTitle}}
                        </a>
                    </td>
                    <td class="width-30">
                        <span id="location_{{s.id}}">{{s?.location}}</span>
                    </td>
                    <td class="width-30">
                        <span id="addedDate_{{s.id}}">{{s?.postingDate | date : 'dd MMMM yyyy'}}</span>
                    </td>
                    <td class="width-22">
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="grid-row" *ngIf="totalItems > size">
            <pagination-controls [maxSize]="maxSize" (pageChange)="pageChange($event)">
            </pagination-controls>
        </div>
    </div>
</div>