import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { UsefulLinkComponent } from './useful-link.component';

export const UsefulLinkRoutes: Routes = [
  {
    path: '',
    component: UsefulLinkComponent,
  },
  {
    path: 'view-assessment/:assessmentTemplateId',
    component: UsefulLinkComponent,
    data: { title: 'Local links', auth: [1, 4] }
  },
  ];
