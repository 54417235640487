<article class="content-card column-quarter course mb15">
    <figure class="content-card-wrapper" (click)="goToSearch()">
        <img src="{{jobOrganization?.orgImgUrl}}" />
        <figcaption>
            <div class="caption-div">
                <div>
                    <span>{{jobOrganization?.name}}</span>
                </div>
            </div>
        </figcaption>
    </figure>
</article>