import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultComponent } from './search-result.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResultCardComponent } from './result-card/result-card.component';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    SearchResultComponent,
    ResultCardComponent
  ],
  imports: [
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    PipesModule
  ],
  exports: [
    SearchResultComponent
  ]
})
export class SearchResultModule { }
