import { Routes, RouterModule } from '@angular/router';
import { EmploymentComponent } from './employment.component';
import { NgModule } from '@angular/core';
import { SearchResultComponent } from './search-result/search-result.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { EmploymentDashboardComponent } from './employment-dashboard/employment-dashboard.component';
import { JobAttachmentComponent } from './job-application/job-attachment/job-attachment.component';
import { JobApplicationComponent } from './job-application/job-application.component';
import { DeactivateGuard } from '../../core/guards/deactivate.guard';
import { PrisonJobsComponent } from './prison-jobs/prison-jobs.component';
import { PrisonJobDetailComponent } from './prison-jobs/prison-job-detail/prison-job-detail.component';
import { JobsForPrisonLeaversComponent } from './jobs-for-prison-leavers/jobs-for-prison-leavers.component';
import { ViewJobsForPrisonLeaversComponent } from './jobs-for-prison-leavers/view-jobs-for-prison-leavers/view-jobs-for-prison-leavers.component';
import { ExpressionOfInterestComponent } from './jobs-for-prison-leavers/expression-of-interest/expression-of-interest.component';

const OFFENDER_JOBS = 'Jobs for prison leavers';

const JOB_DETAILS = 'Job details';

const routes: Routes = [
    {
        path: '',
        component: EmploymentDashboardComponent,
    },
    {
        path: 'job-market',
        component: EmploymentComponent,
        data: { title: 'Job market' }
    },
    {
        path: 'search-result',
        data: { title: 'Search Result' },
        component: SearchResultComponent,
    },
    {
        path: 'job-details',
        data: { title: JOB_DETAILS }, 
        component: JobDetailsComponent
    },
    {
        path: 'cv-builder/guided',
        loadChildren: () => import('../cv-builder/guided-cv-builder/guided-cv-builder.module').then(mod => mod.GuidedCvBuilderModule),
        data: { title: 'Guided CV builder' }
    },
    {
        path: 'application',
        component: JobApplicationComponent,
        data: { title: "Job application", mainTitle: 'Job market' },
    },
    {
        path: 'application/attachment',
        component: JobAttachmentComponent,
        data: { title: "CVs / Documents" , mainTitle: 'Job application'}, 
    },
    {
        path: 'prison-jobs',
        component: PrisonJobsComponent,
        data: { title: 'Prison jobs', mainTitle: 'Employment' }
    },
    {
        path: 'jobs-for-prison-leavers',
        component: JobsForPrisonLeaversComponent,
        data: { title: OFFENDER_JOBS, mainTitle: 'Employment' }
    },
    {
        path: 'prison-jobs/prison-jobs-details/:Id',
        component: PrisonJobDetailComponent,
        data: { title: JOB_DETAILS, mainTitle: 'Prison jobs' }
    },
    {
        path: 'jobs-for-prison-leavers/view-jobs-for-prison-leavers/:Id',
        component: ViewJobsForPrisonLeaversComponent,
        data: { title: 'Job details', mainTitle: OFFENDER_JOBS }
    },
    {
        path: 'jobs-for-prison-leavers/expression-of-interest',
        component: ExpressionOfInterestComponent,
        data: { title: 'Job interest', mainTitle: OFFENDER_JOBS }
    },
    {
        path: 'jobs-for-prison-leavers/expression-of-interest/attachment',
        component: JobAttachmentComponent,
        data: { title: "CVs / Documents", mainTitle: 'Job interest' }, 
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EmploymentRoutingModule { }