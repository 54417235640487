<a (click)="back()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="attachments">
	<div class="grid-row tabWrapper">
		<ul>
			<li class="tabHead active" tabindex="0" [ngClass]="{'active': state === 'cv'}" (click)="setActive('cv')">
				<a href="javascript:void(0)" class="link" id="favourite-tab"
					attr.aria-label="tab favourite job">CVs</a>
			</li>
			<li class="tabHead" tabindex="0" [ngClass]="{'active': state === 'doc'}" (click)="setActive('doc')">
				<a href="javascript:void(0)" class="link" id="applied-tab"
					attr.aria-label="tab applied job">Documents</a>
			</li>
		</ul>
		<section class="tabs-panel" *ngIf="state === 'cv'">
			<table *ngIf="cvs.length > 0">
				<thead>
					<tr>
						<th scope="colgroup" class="wid40">
							<div class="checkbox-wrapper multi-select">
								<input type="checkbox" [(ngModel)]="selectAllCV" (change)="selectAll('cv')" />
								<label class="checkboxes-label form-label" for="id-selctAll">
									<span class="vc-visually-hidden">Select All</span>
								</label>
							</div>
						</th>
						<th scope="colgroup">File name</th>
						<th scope="colgroup" class="wid150">Uploaded date</th>
						<th scope="colgroup">Description</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let cv of cvs">
						<td class="wid40">
							<div class="checkbox-wrapper multi-select">
								<input type="checkbox" [(ngModel)]="cv.selected" (change)="changeState()" />
								<label class="checkboxes-label form-label" for="id-{{cv.name}}">
									<span class="vc-visually-hidden">Select {{cv.name}}</span>
								</label>
							</div>
						</td>
						<td>{{cv.name}}</td>
						<td class="wid150">{{cv.uploadDate | date : shortDate}}</td>
						<td>{{cv.description}}</td>
					</tr>
				</tbody>
			</table>
			<div *ngIf="cvs.length === 0">
				<p class="tabsMessage">No CVs found</p>
			</div>
		</section>
		<section class="tabs-panel" *ngIf="state === 'doc'">
			<table *ngIf="documents.length > 0">
				<thead>
					<tr>
						<th scope="colgroup" class="wid40">
							<div class="checkbox-wrapper multi-select">
								<input type="checkbox" [(ngModel)]="selectAllDOC" (change)="selectAll('doc')" />
								<label class="checkboxes-label form-label" for="id-selctAll">
									<span class="vc-visually-hidden">Select All</span>
								</label>
							</div>
						</th>
						<th scope="colgroup">File name</th>
						<th scope="colgroup" class="wid150">Uploaded date</th>
						<th scope="colgroup">Description</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let doc of documents | paginate: { itemsPerPage: pageSize, currentPage: page }">
						<td class="wid40">
							<div class="checkbox-wrapper multi-select">
								<input type="checkbox" [(ngModel)]="doc.selected" (change)="changeState()" />
								<label class="checkboxes-label form-label" for="id-{{doc.name}}">
									<span class="vc-visually-hidden">Select {{doc.name}}</span>
								</label>
							</div>
						</td>
						<td>{{doc.name}}</td>
						<td class="wid150">{{doc.uploadDate | date : shortDate}}</td>
						<td>{{doc.description}}</td>
					</tr>
				</tbody>
			</table>
			<div *ngIf="documents.length > 0" class="mt25">
				<pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
			</div>
			<div *ngIf="documents.length === 0">
				<p class="tabsMessage">No Documents found</p>
			</div>
		</section>
	</div>
	<div class="grid-row mt25 mb25">
		<button id="id-done" class="button button-link" type="button" name="done" (click)="done()">Done</button>
		<button id="id-cancel" class="button button-secondary" type="button" name="cancel" 
			(click)="cancel()">Cancel</button>
	</div>

</div>