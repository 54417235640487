import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { ConnectionService } from 'ng-connection-service'; 
import { Router } from '@angular/router';
import { UserProfileService } from '../app/features/user-profile/user-profile.service';

@Component({
  selector: 'vc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  isConnected = true;  
  noInternetConnection: boolean;  
  learner: any;
  constructor(
    private readonly title: Title,
    private connectionService: ConnectionService,
    private readonly router: Router,
    private readonly userProfileService: UserProfileService,
    private readonly renderer: Renderer2
  ) {
  }

  ngOnInit() {
    if (!environment.enableCtrl) {
      this.disableControls();
    }
    this.title.setTitle('Virtual Campus 2.0 alpha');
    this.userProfileService.getCachedLearnerInfo().subscribe(info => {
      this.learner = info;
      this.connectionService.monitor().subscribe(isConnected => {  
        this.isConnected = isConnected;  
        if (this.isConnected) {
          this.noInternetConnection=false;
          this.renderer.addClass(document.body, 'showConnectionBar');
        }  
        else if (this.isConnected && this.learner.deviceId == null) {
          this.noInternetConnection=true; 
        }
        else {  
          this.noInternetConnection=true; 
          //this.router.navigate(['/plan']);
          this.renderer.removeClass(document.body, 'showConnectionBar');
        }  
      });
    })
    this.renderer.addClass(document.body, 'inherit-case');
    }
  
    ngOnDestroy() {
      this.renderer.removeClass(document.body, 'inherit-case');
    }

  disableControls() {

    function keyPressCheck(event) {
      if (event.keyCode === 123) {
        return false;
      }
    }

    function clickIE() {
      if (document.all) { return false; }
    }

    function clickNS(e) {
      if (((<any>document).layers || (document.getElementById && !document.all)) && (e.which === 2 || e.which === 3)) {
        return false;
      }
    }

    document.onkeypress = keyPressCheck;
    document.onkeydown = keyPressCheck;

    if ((<any>document).layers) {
      document.captureEvents();
      document.onmousedown = clickNS;
    } else {
      document.onmouseup = clickNS;
      document.oncontextmenu = clickIE;
    }

    document.oncontextmenu = function () {
      return false;
    }

    function disableCtrlKeyCombination(e) {
      //list all CTRL + key combinations you want to disable
      const forbiddenKeys = new Array('a', 'n', 'c', 'x', 'v', 'j', 'w');
      let key, isCtrl;

      if (window.event) {
        key = (<any>window.event).keyCode; //IE
        if ((<any>window.event).ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      } else {
        key = e.which; //firefox
        if (e.ctrlKey) {
          isCtrl = true;
        } else {
          isCtrl = false;
        }
      }
      //if ctrl is pressed check if other key is in forbidenKeys array
      if (isCtrl) {
        for (let i = 0; i < forbiddenKeys.length; i++) {
          //case-insensitive comparation
          if (forbiddenKeys[i].toLowerCase() === String.fromCharCode(key).toLowerCase()) {
            return false;
          }
        }
      }
      return true;
    }
  }

}
