export class Attachment {

    id: string;
    name: string;
    uploadDate: Date;
    description: string;
    type: string;
    private selected: boolean = false;

    constructor(id: string, name: string, uploadDate: Date, desc: string, type: string) {
        this.id = id;
        this.name = name;
        this.uploadDate = uploadDate;
        this.description = desc;
        this.type = type;
    }

    public select(): void {
        this.selected = true;
    }

    public unSelect(): void {
        this.selected = false;
    }

    public isSelected(): boolean {
        return this.selected;
    }

}

export interface ApplicationInfo {
    jobId?:string;
    message?: string;
    attachments?: Attachment[];
}