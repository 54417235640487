import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../../shared/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class EmploymentGoalService {

  constructor(private readonly http: HttpClient) { }

  create(data) {
    const href = `${Url.PLAN}/employment-goal`;
    return this.http.post<any>(href, data);
  }

  update(data) {
    const href = `${Url.PLAN}/employment-goal`;
    return this.http.put<any>(href, data);
  }

  delete(id) {
    const href = `${Url.PLAN}/employment-goal/${id}`;
    return this.http.delete<any>(href);
  }
  getAll(planTypeIdentifier) {
    const href = `${Url.PLAN}/employment-goal/all/${planTypeIdentifier}`;
    return this.http.get<any>(href);
  }
}
