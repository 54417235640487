import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from './form-control.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ValidationService } from './validation.service';
import { DateFormControlComponent } from './date-form-control/date-form-control.component';
import { MonthFormControlComponent } from './date-form-control/month-form-control.component';
import { AllErrorsComponent } from './all-errors/all-errors.component';
import { PipesModule } from '../../../core/pipes/pipes.module';

@NgModule({
  declarations: [
    FormControlComponent, 
    DateFormControlComponent,
    MonthFormControlComponent,
    AllErrorsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule
  ],
  providers: [
    ValidationService
  ],
  exports: [
    FormControlComponent, 
    DateFormControlComponent,
    MonthFormControlComponent,
    AllErrorsComponent
  ]
})
export class FormControlModule { }
