import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { IBreadCrumb } from './main.interfaces';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'vc-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  breadcrumbs: IBreadCrumb[];
  isShowPlan: boolean;
  private readonly mainTitle: string;

  excludeSearchUrls = [ '/employment' ];
  showSearch = true;
  showPlanRef = true;
  showMainLink = true;

  constructor(
    private readonly mainService: MainService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) { }

  ngOnInit() {
    this.breadcrumbs = this.mainService.resolveBreadCrumb(this.activatedRoute.root);
    this.resolveStates(this.router.url);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(event => {
      this.breadcrumbs = this.mainService.resolveBreadCrumb(this.activatedRoute.root);
      this.resolveStates(this.router.url);
    });
    this.mainService.addToPlanBarEvent.subscribe( (state: boolean) => {
      this.isShowPlan = state;
    });
  }
  resolveStates(currentUrl) {
    if (currentUrl.startsWith('/employment/job-activity')) { 
      this.showSearch = true;
      this.showMainLink = true;
      this.showPlanRef = false;
    } else {
      for (const url of this.excludeSearchUrls) {
        if (currentUrl.startsWith(url)) {
          this.showSearch = false;
          this.showMainLink = true;
          this.showPlanRef = false;
        } else {
          this.showSearch = true;
          this.showMainLink = true;
          this.showPlanRef = true;
        }
      }
    }
  }

}
