import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vc-job-organizations-link',
  templateUrl: './job-organizations-link.component.html',
  styleUrls: ['./job-organizations-link.component.scss']
})
export class JobOrganizationsLinkComponent implements OnInit {

  @Input() jobOrganizationsLast: any;

  constructor(private readonly router: Router) { }

  ngOnInit() {
  }

  goToSearchResults() {
    this.router.navigate([`/employment/search-result`], { queryParams: { orgIndustry: this.jobOrganizationsLast.name}});
  }
}
