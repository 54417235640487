import { Component, OnInit, Renderer2, ElementRef, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../../core/services/main.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { Location } from '@angular/common';
import { Utility } from '../../../../shared/utils/utility';
import { EmploymentGoalService } from './employment-goal.service';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';

const VALIDATION_CONTEXT = { 
  required: { goal: 'Goal cannot be blank' },
  maxlength: {
  goal: 'Please enter 100 or less characters in employment goal/s'
},
};
const FORM_VALIDATORS_SAVE = { goal: [Validators.required, Validators.maxLength(100)] };

@Component({
  selector: 'vc-page-seven',
  templateUrl: './page-seven.component.html',
  styleUrls: ['./page-seven.component.scss']
})
export class PageSevenComponent implements OnInit, AfterViewChecked {

  private readonly currentPageNumber = 7;
  pageSevenForm: FormGroup;
  currentPageData: any[] = [];
  goals: any[] = [];
  isDialogOpen = false;
  @Output() refreshList = new EventEmitter<any>();
  isEditActive = false;
  selectedIndex: number = null;
  continueValidation = false;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-six';
  routeNext = '/plan/induction/page-eight';
  isAssessmentInProgressFlag = false;

  routeState: any;

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly mainService: MainService,
    private readonly formBuilder: FormBuilder,
    private readonly validation: ValidationService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly employmentGoalService: EmploymentGoalService,
    private readonly confirm: ConfirmService,
    private readonly togetherMode: TogetherModeModalService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.initForm();
    this.validation.setContext(VALIDATION_CONTEXT);
  }

  ngOnInit() {
    this.resolveCurrentPageData();
    this.resolveRouteState();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageSevenForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  updateAssessmentInProgressFlag() {
    if (this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  resolveCurrentPageData() {
    this.getAllGoals();
    this.inductionQuestionnaireService.getQuestion(this.currentPageNumber).subscribe(data => {
      this.currentPageData = data;
    });
  }
  private getAllGoals() {
    this.employmentGoalService.getAll(1).subscribe(goals => {
      this.goals = goals;
    });
  }

  initForm() {
    this.pageSevenForm = this.formBuilder.group({
      id: this.formBuilder.control(null),
      goal: this.formBuilder.control('')
    });
  }

  addGoal() {
    this.continueValidation = false;
    Utility.setValidators(this.pageSevenForm, FORM_VALIDATORS_SAVE);
    if (this.pageSevenForm.valid) {
      const formattedPayload = this.createEmploymentPayload();
      if (this.pageSevenForm.get('id').value != null) {
        this.employmentGoalService.update(formattedPayload).subscribe(result => {
          this.refreshContent();
          this.isEditActive = false;
        });
      } else {
        this.employmentGoalService.create(formattedPayload).subscribe(result => {
          this.refreshContent();
          this.isEditActive = false;
          this.initForm();
        });
      }
    } else {
      this.setFocusToErrorSummary();
    }
  }
  refreshContent() {
    this.getAllGoals();
    Utility.clearAllValidatorsAndErrors(this.pageSevenForm);
    this.reset();
    window.scrollTo(0, 0);
  }
  saveDraft() {
    const formattedPayload = this.formatPayload(true);
    this.isSaveSubmitOperation = true;
    this.canExit(formattedPayload);
    this.saveInductionPlan(formattedPayload);
  }
  reset() {
    this.pageSevenForm.setValue({id: null, goal: ''});
  }
  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    });
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  continue() {
    if (this.goals.length === 0) {
      this.continueValidation = true;
    } else {
      const formattedPayload = this.formatPayload(false);
      this.isSaveSubmitOperation = true;
      this.saveInductionPlan(formattedPayload);
    }
  }
  edit(id) {
    const obj = Utility.getObjectFromArrayByKeyAndValue(this.goals, 'id', id);
    if (obj !== null) {
      this.pageSevenForm.get('id').setValue(obj.id);
      this.pageSevenForm.get('goal').setValue(obj.employementGoal);
    }
    this.isEditActive = true;
  }

  setIndex(index: number) {
     this.selectedIndex = index;
  }

  delete(id) {
    this.confirm.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete an entry?',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.isDialogOpen = false;
        this.employmentGoalService.delete(id).subscribe(rsp => {
          this.getAllGoals();
          this.refreshContent();
          this.isEditActive = false;
        });

      },
      reject: () => {
        this.isDialogOpen = false;
      }
    });
    return this.confirm.navigateSelection;
  }

  saveInductionPlan(formattedPayload){
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      if(formattedPayload.isSaveAsDraft){
      this.router.navigateByUrl('/plan');
      window.scrollTo(0, 0);
      }else
      {
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-eight');
      }
    });  
  }


  canExit(formattedPayload) {
    if (this.pageSevenForm.dirty && this.route.url && this.goals.length < 4 && this.isEditActive) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.saveInductionPlan(formattedPayload);
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    } else if (
      this.goals.length === 0 &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  createEmploymentPayload() {
    return {
      employmentGoal: this.pageSevenForm.get('goal').value,
      employmentGoalId: this.pageSevenForm.get('id').value,
      isSaveAsDraft: true,
      planTypeIdentifier: '1',
      questionDTOList: [
        { answer: '1', id: 11 }
      ],
      sectionIdentifier: '7'
    };
  }
  formatPayload(saveAsDraft) {
    return {
      isSaveAsDraft: saveAsDraft,
      planTypeIdentifier: '1',
      questionDTOList: [ 
        { answer: 1, id: 11 },
      ],
      sectionIdentifier: this.currentPageData[0].sectionIdentifier
    };
  }
}
