import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../../../../shared/constants/url-constants';
import { Observable } from 'rxjs';

@Injectable()
export class WorkExperienceService {

  deleteEmployment(jobId: any): any {
      const href = `${Url.CVB}/employments/${jobId}`;
      return this.http.delete<any>(href);
  }

  constructor(private readonly http: HttpClient) { }

  getWorkExperienceInfo() {
    const href = `${Url.CVB}/employments`;
    return this.http.get(href);
  }

  getSection() {
    const href = `${Url.CVB}/ref-section/3`;
    return this.http.get<any>(href);
  }

  saveWorkExperience(data) {
    const href = `${Url.CVB}/employments/unemployment/save`;
    return this.http.post<any>(href, data);
  }

  draftWorkExperience(data) {
    const href = `${Url.CVB}/employments/unemployment/draft`;
    return this.http.post<any>(href, data);
  }

  /* Autocomplete fields */
  getJobTitles(value) {
    const href = `${Url.JOBS}/ref-data/what/` + value;
    return this.http.get<any>(href);
  }

  getJobLocation(value) {
    const href = `${Url.JOBS}/ref-data/where/` + value;
    return this.http.get<any>(href);
  }

  /* Paid work experience */
  async getAllSkills(): Promise<any> {
    const href = `${Url.CVB}/ref-data/skills/3`;
    return this.http.get<any>(href).toPromise();
  }

  getPaidExperienceDetails(id: number): Observable<any> {
    const href = `${Url.CVB}/employments/paid/${id}`;
    return this.http.get<any>(href);
  }

  saveAndContinuePaidWork(data: any) {
    const href = `${Url.CVB}/employments/paid/save`;
    return this.http.post<any>(href, data);
  }

  saveAndLaterPaidWork(data: any) {
    const href = `${Url.CVB}/employments/paid/draft`;
    return this.http.post<any>(href, data);
  }

  /* Volunteer work experience */
  async getAllVolunteerSkills(): Promise<any> {
    const href = `${Url.CVB}/ref-data/skills/3`;
    return this.http.get<any>(href).toPromise();
  }

  getVolunteerExperienceDetails(id: number): Observable<any> {
    const href = `${Url.CVB}/employments/volunteer/${id}`;
    return this.http.get<any>(href);
  }

  saveAndContinueVolunteerWork(data: any) {
    const href = `${Url.CVB}/employments/volunteer/save`;
    return this.http.post<any>(href, data);
  }

  saveAndLaterVolunteerWork(data: any) {
    const href = `${Url.CVB}/employments/volunteer/draft`;
    return this.http.post<any>(href, data);
  }

  /* Prison work experience */
	refPrisonSections() {
    const href = `${Url.CVB}/ref-data/prison-sections`;
    return this.http.get<any>(href);
  }

  saveAndContinuePrisonWork(data: any) {
    const href = `${Url.CVB}/employments/prison/save`;
    return this.http.post<any>(href, data);
  }

  saveAndLaterPrisonWork(data: any) {
    const href = `${Url.CVB}/employments/prison/draft`;
    return this.http.post<any>(href, data);
  }

  getPrisonExperienceDetails(id: number): Observable<any> {
    const href = `${Url.CVB}/employments/prison/${id}`;
    return this.http.get<any>(href);
  }

  refPrisons() {
    const href = `${Url.CVB}/ref-data/prisons`;
    return this.http.get<any>(href);
  }

}
