<a (click)="gotoPrev()" href="javascript:void(0);" class="link-back nomargintop">Back</a>

<div class="grid-row">
    <div class="panel-wrapper mt25" *ngIf="job?.isApplied">
        <h1 class="heading-xlarge">
           Submitted
        </h1>
        <div class="panel-body heading-large">
            on <strong> {{job?.applicationStatusDate | date :  "dd MMMM yyyy" }} </strong>
            at <strong> {{job?.applicationStatusDate | date :  "HH:mm" }} </strong>
        </div>    
    </div>
</div>

<div class="grid-row mt25" id="jobDetail">
    <div class="column-two-thirds">
        <h3 class="heading-large nomargintop">{{job?.job_title}}</h3>
        <div class="grid-row ">
            <table class="table-font-small">
                <tbody>
                    <tr *ngIf="job?.date">
                        <th>Posting date:</th>
                        <td>{{job?.date | date : 'dd MMMM yyyy'}}</td>
                    </tr>
                    <tr *ngIf="job?.contract_type">
                        <th>Contract type:</th>
                        <td>{{job?.contract_type}}</td>
                    </tr>
                    <tr *ngIf="job?.organization_name">
                        <th>Company name:</th>
                        <td>{{job?.organization_name}}</td>
                    </tr>
                    <tr *ngIf="job?.location_name">
                        <th>Location:</th>
                        <td>{{job?.location_name}}</td>
                    </tr>
                    <tr *ngIf="job?.salary">
                        <th>Salary:</th>
                        <td>{{job?.salary | currency: 'GBP' : 'symbol' : '3.0'}}</td>
                    </tr>
                    <tr *ngIf="job?.advertiser_reference_number">
                        <th>Job reference:</th>
                        <td>{{job?.advertiser_reference_number}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="grid-row descBlock">
            <div *ngIf="job?.job_description">
                <label>Brief job description:</label>
                <p>{{job?.job_description}}</p>
            </div>
            <div *ngIf="job?.candidate_description">
                <label>Candidate description:</label>
                <p> {{job?.candidate_description}}</p>
            </div>
            <div *ngIf="job?.employer_description">
                <label>Employer description:</label>
                <p>{{job?.employer_description}}</p>
            </div>
            <div *ngIf="job?.conditions_description">
                <label>Conditions and benefits description:</label>
                <p>{{job?.conditions_description}}</p>
            </div>
        </div>
        <div class="grid-row actions">
            <p>
                <button type="button" class="button button-link" *ngIf="!job?.isApplied" (click)="applyForJob(job.id)" >Apply for this job</button>
                <button type="button" *ngIf="job?.isApplied" [disabled]="true" class="button button-link">Submitted</button>
            </p>
        </div>
    </div>
    <div class="column-one-third">
        <div class="actions heart-favourite-container" *ngIf="!job?.isApplied">
            <a href="javascript:void(0)" [ngClass]="job?.isFavourite ? 'heart-favourite-true' : 'heart-favourite-false'" aria-hidden="true" [title]="job?.isFavourite ? 'Remove from favourite jobs' : 'Save to favourite jobs'" (click)="job?.isFavourite ? removeFromFavourite(job) : saveToFavorite(job)"></a>
            <a href="javascript:void(0)" class="favourite-link" [title]="job?.isFavourite ? 'Remove from favourite jobs' : 'Save to favourite jobs'" (click)="job?.isFavourite ? removeFromFavourite(job) : saveToFavorite(job)">{{ job?.isFavourite ? 'Remove from favourite jobs' : 'Save to favourite jobs' }}</a>
        </div>

        <h3 class="heading-small" *ngIf="similarList.length > 0">Similar Jobs</h3>
        <div class="similarJobBlock" *ngFor="let s of similarList; let i=index">
            <div *ngIf = "i<5">
                <a class="link" href="javascript:void(0)" [routerLink]="['/employment/job-details']" [queryParams]="{job: s.id, what: s.job_title, where: s.location_name}">{{s?.job_title | truncate:[30, '...']}}</a>
                <span *ngIf="s.date">{{s?.date | date : 'dd MMMM yyyy'}}</span>
                <span *ngIf="s.contract_type">{{s?.contract_type}}</span>
                <span *ngIf="s.location_name">{{s?.location_name}}</span>
                <span *ngIf="s.salary">{{s?.salary | currency: 'GBP' : 'symbol' : '3.0'}}</span>
            </div>
        </div>
    </div>
</div>