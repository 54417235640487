<div *ngIf="learner.uln">
  <div class="openAllContainer">
    <button class="open-all-btn" (click)="toggleAll()">{{ allPanelsOpen ? 'Close all' : 'Open all' }}</button>
  </div>
  <hr class="opan-all-hr">
  <div class="accordion-header" id="learnerInfo" (click)="togglePanel($event)" tabindex="0">
    <h1 class="heading-medium" id="learnerInfo">Learner Information</h1>
    <img *ngIf="!learnerInfoIsOpen" class="accordion-icon"src="./../../../../assets/images/icon-plus.png" alt="Open Icon" id="learnerInfo">
    <img *ngIf="learnerInfoIsOpen" class="accordion-icon"src="./../../../../assets/images/icon-minus.png" alt="Open Icon" id="learnerInfo">
  </div>
    <div class="grid-row" *ngIf="learnerInfoIsOpen">
        <div class="column-four-grid">
              <!-- <div class="column-half"> -->
                  <div class="grid-row flex-column">
                      <label for="title">ULN </label>
                      <span>{{learner.uln || 'N/A'}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">Previous LRN </label>
                      <span> {{learner.lrnPrevious || 'N/A'}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">Learner Self Assessment LDD and Health Problem </label>
                      <span>{{learner.selfAssessmentLdd || 'N/A'}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">Maths Initial Assessment </label>
                      <span>{{learner.mathInitialAssessment || 'N/A'}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">Date of Maths Assessment </label>
                      <span>{{(learner.mathsAssessmentDate | date:'dd MMMM yyyy') || "N/A"}}</span>
                  </div>
  
              <!-- </div> -->
              <!-- <div class="column-half"> -->
                  <div class="grid-row flex-column">
                      <label for="title">LRN </label>
                      <span>{{learner.lrn || 'N/A'}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">Prior Attainment </label>
                      <span>{{learner.priorAttainment || 'N/A'}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">English Initial Assessment </label>
                      <span>{{learner.englishInitialAssessment || 'N/A'}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">Date of English Assessment </label>
                      <span>{{(learner.englishAssessmentDate | date:'dd MMMM yyyy') || "N/A"}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">Planned Learning Hours </label>
                      <span>{{learner.plannedHours || 'N/A'}}</span>
                  </div>
                  <div class="grid-row flex-column">
                      <label for="title">ESOL </label>
                      <span>{{learner.esol || 'No'}}</span>
                  </div>
              <!-- </div> -->
              <!-- <div class="column-half"></div> -->
        </div>
    </div>
    <hr class="accordion-divider">
    <div class="accordion-header" id="lldd" (click)="togglePanel($event)" tabindex="1">
      <h1 class="heading-medium" id="lldd">LLDD and Health Problem</h1>
      <img *ngIf="!llddIsOpen" class="accordion-icon"src="./../../../../assets/images/icon-plus.png" alt="Open Icon" id="lldd">
        <img *ngIf="llddIsOpen" class="accordion-icon"src="./../../../../assets/images/icon-minus.png" alt="Open Icon" id="lldd">
      <!-- <span class="spacer" id="lldd"></span> -->
    </div>
    <div class="grid-row" *ngIf="llddIsOpen">
        <div class="column-four-grid">
            <!-- <div *ngIf="courseSize > 0">
                <h1 class="heading-small">LLDD and Health Problem</h1>
            </div> -->
            <!-- <div class="column-half learnerInfo"> -->
                <div class="grid-row" *ngIf="lddSize > 0">
                    <label for="title">Primary LLDD and Health Problem </label>
                    <span *ngFor="let lld of learner.lldAndHealthProblemList">
                        <span *ngIf="lld.isPrimary">
                            <div class="card-row healthInfo" *ngIf="lld.llldAndHealthProblem!='Not provided'">
                                <span class="alert column-one-third"></span>
                                <span class="lldname column-two-thirds">{{lld.llldAndHealthProblem}}</span>
                            </div>
                            <div class="card-row noHealthInfo" *ngIf="lld.llldAndHealthProblem=='Not provided'">
                                    <span class="alert column-one-third"></span>
                                <span class="lldname column-two-thirds">{{lld.llldAndHealthProblem}}</span>
                            </div>
                        </span>
                    </span>
                </div>
            <!-- </div> -->
            <!-- <div class="column-half learnerInfo"> -->
                <div class="grid-row" *ngIf="lddSize > 0">
                    <label for="title">Additional LLDD and Health Problem(s) </label>
                    <span *ngFor="let lld of learner.lldAndHealthProblemList" class="lldd-row">
                        <span *ngIf="!lld.isPrimary">
                            <div class="card-row alert-container" *ngIf="lld.llldAndHealthProblem!='Not provided'">
                                <!-- <span class="column-one-third"> -->
                                  <img class="alert-icon"src="./../../../../assets/images/icon-important.png" alt="Open Icon" id="lldd">
                                <!-- </span> -->
                                <span class="lldname">{{lld.llldAndHealthProblem}}</span>
                            </div>
                            <div class="card-row noHealthInfo" *ngIf="lld.llldAndHealthProblem=='Not provided'">
                                    <span class="alert column-one-third"></span>
                                <span class="lldname column-two-thirds">{{lld.llldAndHealthProblem}}</span>
                            </div>
                        </span>
                    </span>
                </div>
            <!-- </div> -->
        </div>
    </div>
    <hr *ngIf="courseSize > 0" class="accordion-divider">
    <div class="accordion-header" *ngIf="courseSize > 0" id="courses" (click)="togglePanel($event)" tabindex="2">
      <h1 class="heading-medium" id="courses">Courses ({{courseSize}})</h1>
      <!-- <span class="spacer" id="courses"></span> -->
      <img *ngIf="!coursesIsOpen" class="accordion-icon"src="./../../../../assets/images/icon-plus.png" alt="Open Icon" id="courses">
      <img *ngIf="coursesIsOpen" class="accordion-icon"src="./../../../../assets/images/icon-minus.png" alt="Open Icon" id="courses">
    </div>
    <!-- <div *ngIf="courseSize > 0">
        <h1 class="heading-small">Courses ({{courseSize}})</h1>
    </div> -->
    <div class="grid-row" *ngIf="coursesIsOpen">
        <div class="column-four-grid">
            <div *ngFor="let course of learner.courseList">
            <!-- <div class="column-half" *ngFor="let course of learner.courseList"> -->
                <div class="grid-row">
                    <label for="title">{{course.courseName}}</label>
                    <p>{{course.effectiveFrom | date:'dd MMMM yyyy'}} - {{course.effectiveTo | date:'dd MMMM yyyy'}}</p>
                </div>
            </div>
        </div>
    </div>
    <hr class="accordion-divider">
</div>