import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const refContentLevelSchema: RxCollectionCreatorBase = {
    schema: {
        title: 'Ref Content Level',
        version: 0,
        description: 'Content Level',
        type: 'object',
        properties: {
            id: {
                type: 'number'
            },
            identifier: {
                type: ['string', 'null']
            },
            description: {
                type: ['string', 'null']
            }
        }
    }
}