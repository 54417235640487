<div id="applied-jobs">
	<h2 class="job-app">Job applications</h2>
	<table>
		<caption></caption>
		<thead>
			<tr>
				<th scope="colgroup">Date</th>
				<th scope="colgroup">Jobs</th>
				<th scope="colgroup"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let s of appliedJobList | paginate: { itemsPerPage: size, currentPage: (page + 1), totalItems: totalItems }"
				[ngClass]="{'disable': s.expired}">
				<td>
					<span>{{s?.applicationStatusDate | date : 'dd MMMM yyyy'}}</span>
				</td>
				<td>
					<span>
						<a class='link' href="javascript: void(0);" [routerLink]="['/employment/job-details']"
							[queryParams]="{job: s.jobId, what: s.jobTitle, where: s.location}">{{s?.jobTitle | truncate:[90, '...']}}
							<br /> {{s?.location | truncate:[24, '...']}}</a>
					</span>
				</td>
				<td>
					<span class="status-tag"
						[style.background-color]="s?.jobApplicationStatus.statusDescription =='DECLINED' ? 'red': s?.jobApplicationStatus.statusDescription =='APPLIED'? 'green' : '6f777b'">{{s?.jobApplicationStatus.statusDescription}}</span>
					<span *ngIf="s?.expired" class="status-tag status-expired">EXPIRED</span>
				</td>
			</tr>
		</tbody>
	</table>
	<p *ngIf="totalItems === 0">
		You have not applied to any jobs
	</p>
	<div class="grid-row" *ngIf="totalItems > size">
		<p></p>
		<pagination-controls [maxSize]="10" (pageChange)="pageChange($event)">
		</pagination-controls>
	</div>
</div>