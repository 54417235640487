<section class="entry-panel">
  <div class="left-container">
    <h2 class="entry-title">{{entryData?.title}}</h2>
    <div class="subtitle-container">
      <p class="entry-subtitle">{{entryData?.formattedSubtitle}}</p>
      <p class="entry-subtitle">{{entryData?.keyWorkerName | titlecase}}</p>
    </div>
    <p *ngIf="entryData?.interventionName" class="entry-notes-content"><span class="entry-notes-label">{{entryData?.interventionName}}</span> </p>
    <p *ngIf="entryData?.description" class="entry-notes-content"><span class="entry-notes-label">Notes:</span> {{entryData?.description}}</p>
  </div>
</section>
