<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="applyjob-inset-text">
    The jobs listed here are for reference only. If you would like to apply for a job, please go to the ‘Jobs for prison leavers’ link where you will find a list of jobs you can express interest in. This will be kept under continual review and a notification will appear once you can apply from jobs here.
</div>
<vc-search-job></vc-search-job>
<div class="clear">
    <h3 class="heading-large nomargintop">Latest jobs</h3>
    <section class="mar-right15">
        <div class="grid-row scrollable">
            <vc-new-job *ngFor="let job of newJobs" [job]="job"></vc-new-job>
        </div>
    </section>
</div>
<div class="clear">
    <h3 class="heading-large">Jobs by category</h3>
    <div>
        <div class="text-right">
            <a href="javascript: void(0)" class="link seeAll" [ngClass]= "{'hide' : isOpen, 'show': !isOpen}" *ngIf="jobOrganizations.length > 8" (click)="seeAllCategory();">See all <i class="down"></i></a>
            <a href="javascript: void(0)" class="link seeLess" [ngClass]= "{'show' : isOpen, 'hide': !isOpen}" (click)="seeLess();">See less <i class="up"></i></a>
        </div>
        
        <section class="mar-right15">
          <div class="grid-row scrollable" *ngIf="!seeAllButton">
              <vc-job-organizations *ngFor="let jobOrg of jobOrganizations | slice: 0: 8" [jobOrganization]="jobOrg" >      
              </vc-job-organizations>
          </div>
        </section>

        <div class="grid-row" *ngIf="seeAllButton">
            <div class="column-full">
                <vc-job-organizations *ngFor="let jobOrg of jobOrganizations | slice: 0: jobOrganizations.length" [jobOrganization]="jobOrg" >      
                </vc-job-organizations>
            </div>
        </div>

    </div>
</div>