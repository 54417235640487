import { Location } from '@angular/common';
import { ConfirmService } from './../../../shared/components/confirm-box/confirm-box.service';
import { NotificationService } from './../../../core/services/notification.service';
import { EmploymentService } from './../employment.service';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from './../../../core/services/main.service';

const POSTING_DATES = [
  {
    dateValue: 'Since yesterday',
    term: '1D'
  },
  {
    dateValue: 'Last 3 days',
    term: '3D'
  },
  {
    dateValue: 'Last 14 days',
    term: '14D'
  },
  {
    dateValue: 'Last 30 days',
    term: '30D'
  },
];

@Component({
  selector: 'vc-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnDestroy {


  searchRes: any[];
  data: any = {};
  filters: any = {};
  page: number = 0;
  size: number = 10;
  sort: any = '';
  totalItems: number = 0;
  noresults: boolean = false;
  clicked = false;
  jobName: string;
  jobLoc: string;
  maxSize: number = 10;
  industryFilter: any[];
  contractFilter: any[];
  showMore: Boolean = false;
  showLess: Boolean = false;
  postingDate = POSTING_DATES;
  industryLength: number = 4;
  isDialogOpen = false;
  first: Boolean = false;
  last: Boolean = false;

  constructor(
    private readonly employmentservice: EmploymentService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly mainservice: MainService,
    private readonly location: Location,
    private readonly zone: NgZone,
    private readonly mainService: MainService,
    private readonly confirmService: ConfirmService,
    private readonly notificationService: NotificationService
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainservice.pageMainTitle = 'Job market';
  }

  ngOnDestroy(): void {
    this.mainservice.pageMainTitle = '';
  }
  
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (!(params.what && params.where) && !params.orgIndustry) {
        this.navigateBack();
      }
      this.resolveFromParams(params);
      this.resolveFromFilters(params);

      if (this.data.what && this.data.where) {
        this.jobName = this.data.what;
        this.jobLoc = this.data.where;
        this.searchJobs(this.data, this.size, this.sort, this.page);
      } else if (this.data.orgIndustry) {
        this.jobName = this.data.orgIndustry;
        this.jobLoc = 'UK';
        this.searchJobs(this.data, this.size, this.sort, this.page);
      }
    });
  }

  navigateBack() {
    this.router.navigate(['./employment']);
  }

  resolveFromParams(params) {
    this.data.what = params.what ? params.what : '';
    this.data.where = params.where ? params.where : '';
    this.data.orgIndustry = params.orgIndustry ? params.orgIndustry : '';
    this.page = params.page ? +params.page : 0;
    this.size = params.size ? +params.size : 10;
    this.sort = params.sort ? params.sort : '';
  }

  resolveFromFilters(params) {
    this.filters.cType = params.cType ? params.cType : null;
    this.filters.industry = params.industry ? params.industry : null;
    this.filters.period = params.period ? params.period : null;
    this.data.filters = this.filters;
  }

  dynamicMaxSizeBasedOnPage(currentPage: number, totalItems: number, size: number): number {
    const totalPages = Math.ceil(totalItems / size);
    if (currentPage <= 3 || currentPage >= (totalPages - 4)) {
      return 10;
    } else if ((currentPage - 6) < 1) {
      return 10 + (currentPage - 3);
    } else if (currentPage < (totalPages - 4) && currentPage > (totalPages - 7)) {
      return 10 + (totalPages - 4) - currentPage;
    } else {
      return 13;
    }
  }

  searchJobs(data: any, size: any, sort: any, page: number) {
    this.employmentservice.searchJobs(data, size, sort, page).subscribe(response => {
      this.searchResults(response, size, page);
    });
  }

  searchResults(response, size, page) {
    this.searchRes = response.content;
    this.totalItems = response.totalElements;
    this.first = response.first;
    this.last = response.last;
    this.getFilterDetails(response);
    if (this.totalItems === 0) {
      this.noresults = true;
    } else {
      this.noresults = false;
    }
    this.maxSize = this.dynamicMaxSizeBasedOnPage(page, this.totalItems, size);
  }

  pageChange(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        page: (page - 1),
        size: this.size,
        sort: this.sort,
      }
    });
    window.scrollTo(0, 0);
  }

  saveToFavorite(s) {
    this.employmentservice.addToFavJobs(s.id).subscribe(response => {
      s.isFavourite = true;
    });
  }

  removeFromFavourite(s) {
    this.employmentservice.deleteFavJob(s.id).subscribe(response => {
      s.isFavourite = false;
    })
  }

  filterResults() {
    let params: any = {};
    params.page = 0;
    params.size = this.size;
    params.sort = this.sort;
    params = this.configureQueryParams(params);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: params,
    });
    return false;
  }

  private configureQueryParams(params: any): any {
    if (this.filters.period) {
      params.period = this.filters.period.trim();
    }
    if (this.filters.cType) {
      params.cType = this.filters.cType.trim();
    }
    if (this.filters.industry) {
      params.industry = this.filters.industry.trim();
    }
    return params;
  }

  getFilterDetails(response) {
    this.industryFilter = response.facets[0].terms;
    this.contractFilter = response.facets[1].terms;
  }

  showMoreTrue() {
    this.industryLength = this.industryFilter.length;
    this.showMore = true;
    this.showLess = false;
    return false;
  }

  showMoreLess() {
    this.industryLength = 4;
    this.showLess = true;
    this.showMore = false;
    return false;
  }
  gotoPrev() {
    this.location.back();
  }
}


