import { Location } from '@angular/common';
import { ConfirmService } from './../../../shared/components/confirm-box/confirm-box.service';
import { EmploymentService } from './../employment.service';
import { MainService } from './../../../core/services/main.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'vc-favourite-jobs',
  templateUrl: './favourite-jobs.component.html',
  styleUrls: ['./favourite-jobs.component.scss']
})
export class FavouriteJobsComponent implements OnInit {

  page: number = 0;
  size: number = 10;
  sort: any = 'date,desc';
  favJobList: any[];
  totalItems: number = 0;
  isDialogOpen = false;
  first: Boolean = false;
  last: Boolean = false;

  constructor(
    private readonly main: MainService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly employmentService: EmploymentService,
    private readonly location: Location,
    private readonly zone: NgZone,
    private readonly confirmService: ConfirmService) {
  }

  ngOnInit() {
    this.fetchFavJobList(this.page, this.size, this.sort);
  }

  pageChange(page: number) {
    this.page = page-1;
    this.fetchFavJobList((page-1), this.size, this.sort);
    window.scrollTo(0, 0);
  }

  fetchFavJobList(page, size, sort) {
    this.employmentService.getFavJobList(page, size, sort).subscribe(resp => {
      this.favJobList = resp.content;
      this.totalItems = resp.totalElements;
      this.first = resp.first;
      this.last = resp.last;
    });
  }

  deleteFavJob(id) {
    this.zone.run(() => {
      this.confirmService.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to remove from favourite jobs?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.isDialogOpen = false;
          this.employmentService.deleteFavJob(id).subscribe(resp => {
            this.page = 0;
            this.fetchFavJobList(this.page, this.size, this.sort);
          });
        },
        reject: () => {
          this.isDialogOpen = false;
        }
      });
    });
  }

  gotoPrev() {
    this.location.back();
  }
}

