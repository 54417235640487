<div [ngSwitch]="viewType">

    <!--Launch Media-->
    <div *ngSwitchCase="'video'">
      <vc-video-player [idx]="idx" [src]="module.path" [type]="moduleType" [option]="option"></vc-video-player>
    </div>
  
    <!--Launch SCORM -->
    <div  *ngSwitchCase="'scorm'">
      <iframe [id]="idx" [src]="module.path | safe" height="750px" width="100%"></iframe>
    </div>

    <!--Launch HTML-Package-->
    <div  *ngSwitchCase="'html-zip'">
      <iframe [id]="idx" [src]="module.path | safe" height="750px" width="100%"></iframe>
    </div>
  
    <!--Launch HTML-->
    <div  *ngSwitchCase="'html'">
      <iframe [id]="idx" [src]="module.path | safe" height="750px" width="100%"></iframe>
    </div>

    <!--Launch EPUB-->
    <div *ngSwitchCase="'epub'">
      <div id="area"></div>
      <button class="button button-secondary" id="prevepub" (click)="prev()">Previous</button>
      <button class="button button-secondary" id="nextepub" (click)="next()">Next</button>
    </div>
  
    <!--Launch PDF-->
    <div *ngSwitchCase="'pdf'">
      <!-- <vc-doc-viewer [src]="source" [height]="'80vh'" [config]="config"></vc-doc-viewer> -->
      <div class="docviewer-bottom">
        <ngx-extended-pdf-viewer #pdfViewer [height]="'80vh'" [useBrowserLocale]="true"
            [showDownloadButton]="config?.showDownloadButton" [showPrintButton]="showPrintButton"
            [showOpenFileButton]="config?.showOpenFileButton" [showSidebarButton]="config?.showSidebarButton"
            [showBookmarkButton]="config?.showBookmarkButton"
            [showSecondaryToolbarButton]="config?.showSecondaryToolbarButton"
            [showPresentationModeButton]="config?.showPresentationModeButton" [src]="module.path" >
        </ngx-extended-pdf-viewer>
    </div>
    </div>
  
      <!--Launch IMAGE-->
  <div *ngSwitchCase="'image'">
    <vc-image-view [images]="module.path" ></vc-image-view>
  </div>

  <div *ngSwitchCase="'audio'">
    <vc-video-player [idx]="idx" [src]="module.path" [type]="moduleType" [option]="option"></vc-video-player>
  </div>
  
    <!--Launch Others-->
    <div *ngSwitchDefault>
      <div class="info-text">
        <p>This content type is not yet supported, please select 'Download' to open this item.</p>
        <div>
          <button class="button button-link" type="button" color="primary" (click)="getAttachment(module.id)"> Download</button>
        </div>
        <div id="form_holder" style="display: none;"></div>
      </div>
    </div>
  
  </div>