import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WalletComponent } from './wallet.component';
import { MyContentComponent } from '../content/my-content/my-content.component';
import { AuthenticatedGuard } from '../../core/guards/authenticated.guard';
import { JobActivityComponent } from '../employment/job-activity/job-activity.component';
import { WalletMyCvsComponent } from './wallet-my-cvs/wallet-my-cvs.component';

const WALLET = 'Wallet';

const routes: Routes = [
  {
    path: '',
    component: WalletComponent,
  },
  {
    path: 'my-content',
    component: MyContentComponent
  },
  {
    path: 'job-activity',
    data: { title: 'My job activity' },
    component: JobActivityComponent
  },
  {
    path: 'assessments',
    loadChildren: () => import('../complete-assessment/assessment.module').then(mod => mod.AssessmentModule),
    data: { title: 'Assessment' },
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('../../features/document/document.module').then(mod => mod.DocumentModule),
    data: { title: 'Secure messaging' }
  },
  {
    path: 'cvs',
    component: WalletMyCvsComponent,
    data: { title: "CVs", mainTitle: WALLET }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WalletRoutingModule { }
