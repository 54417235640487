
<div class="grid-row wallet_div">
    <!-- <article class="wallet-wrapper column-half recommended" tabindex="0" [routerLink]="['/wallet/my-content']">
        <figure class="wallet">
            <img alt="Guided Content" src="../../../assets/images/wallet/My Content.png">
            <figcaption class="#d53880">
                <div class="caption1-div">
                    <div class="#d53880">
                        <span>Content</span>
                    </div>
                </div>
            </figcaption>
        </figure>
    </article> -->


    <article class="wallet-wrapper column-half recommended" tabindex="0" [routerLink]="['/wallet/documents']">
        <figure class="wallet">
            <img alt="Guided Document" src="../../../assets/images/wallet/My Documents.jpg">
            <figcaption class="#1d70b8">
                <div class="caption2-div">
                    <div class="#1d70b8">
                        <span class="relative">Documents
                            <span *ngIf="documentFlag" class="notificationBadge">!</span>
                        </span>
                    </div>
                </div>
            </figcaption>
        </figure>
    </article>


    <!-- <article class="wallet-wrapper column-half recommended" tabindex="0" [routerLink]="['/wallet/job-activity']">
        <figure class="wallet">
            <img alt="Guided Job Activity" src="../../../assets/images/wallet/Employment Activity.jpg">
            <figcaption class="#912b88">
                <div class="caption3-div">
                    <div class="#912b88">
                        <span class="relative">Job activity
                            <span *ngIf="jobFlag" class="notificationBadge">!</span>
                        </span>
                    </div>
                </div>
            </figcaption>
        </figure>
    </article>


    <article class="wallet-wrapper column-half recommended" tabindex="0" [routerLink]="['/wallet/assessments']">
        <figure class="wallet">
            <img alt="Guided Assessments" src="../../../assets/images/wallet/Assessments.png">
            <figcaption class="#4c2c92">
                <div class="caption4-div">
                    <div class="#4c2c92">
                        <span class="relative">Assessments
                            <span *ngIf="assessmetFlag" class="notificationBadge">!</span>
                        </span>
                    </div>
                </div>
            </figcaption>
        </figure>
    </article>


    <article class="wallet-wrapper column-half recommended" tabindex="0" [routerLink]="['/wallet/cvs']">
        <figure class="wallet">
            <img alt="Guided Curriculum Vitae" src="../../../assets/images/wallet/curriculum_vitae.jpg">
            <figcaption class="light-green">
                <div class="caption-div">
                    <div class="light-green">
                        <span>CVs</span>
                    </div>
                </div>
            </figcaption>
        </figure>
    </article> -->
</div>
  
