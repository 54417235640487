import { ContentUnavailableComponent } from './content-unavailable.component';
import { CoreModule } from './../../../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentUnavailableRoutingModule } from './content-unavailable-routing.module';

@NgModule({
  declarations: [ContentUnavailableComponent],
  imports: [
    CommonModule,
    ContentUnavailableRoutingModule
  ]
})
export class ContentUnavailableModule { }
