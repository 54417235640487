import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { JobAttachmentService } from './job-attachment/job-attachment.service';
import { MainService } from '../../../core/services/main.service';
import { EmploymentService } from '../employment.service';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { ValidationService } from '../../../shared/components/form-control/validation.service';
import { FormUtil } from '../../../shared/components/form-control/form.util';
import { JOB_APPLICATION_VALIDATION_CONTEXT } from './job-application.validation';
import { ConfirmService } from '../../../shared/components/confirm-box/confirm-box.service';

const APPLICATION_VALIDATORS = {
  message: [Validators.required, Validators.minLength(5), Validators.maxLength(500)],
  attachments: [ValidationService.arrayEmpty]
}

@Component({
  selector: 'vc-job-application',
  templateUrl: './job-application.component.html',
  styleUrls: ['./job-application.component.scss']
})
export class JobApplicationComponent implements OnInit, OnDestroy, AfterViewInit {

  application: FormGroup;
  job: any;
  currentUser: any;
  message: string;
  hasModified: boolean = false;

  private attachmentSub: Subscription;
  jobId: any;


  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly mainService: MainService,
    private readonly attachmentService: JobAttachmentService,
    private readonly empService: EmploymentService,
    private readonly auth: AuthenticationService,
    private readonly validation: ValidationService,
    private readonly confirmService: ConfirmService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = this.route.snapshot.data.mainTitle;
    this.validation.setContext(JOB_APPLICATION_VALIDATION_CONTEXT);
    this.currentUser = this.auth.currentUser();
    this.initForm();
    this.route.queryParams.subscribe(params => {
      if (!params.job) {
        this.router.navigate(['/employment']);
      } else {
        this.jobId = params.job;
        this.empService.getJobDetails(params.job)
          .subscribe(job => {
            if (!job) {
              this.router.navigate(['/employment']);
            } else {
              this.job = job;
              this.job.what = params.what;
              this.job.where = params.where;
              this.updateSM();
            }
          });
      }
    });
  }

  ngOnInit() {

    this.attachmentSub = this.attachmentService.onChangeApplicationInfo()
      .subscribe(data => {
        if (data && data.jobId === this.jobId) {
          data.message ? this.application.get('message').setValue(data.message) : this.updateSM();
          this.application.get('attachments').setValue(data.attachments);
        } else {
          this.updateSM();
        }
      });
  }

  ngAfterViewInit() {
    window.scroll({ top: 0, left: 0 });
  }

  ngOnDestroy() {
    this.mainService.pageMainTitle = '';
    if (this.attachmentSub) {
      this.attachmentSub.unsubscribe();
    }
  }

  private initForm() {
    this.application = this.fb.group({
      su: this.fb.control(this.currentUser.fullName),
      message: this.fb.control(''),
      attachments: this.fb.control([])
    });
    this.application.get('su').disable();
  }


  goToAttachment() {
    this.router.navigate(['./attachment'], { queryParamsHandling: 'preserve', relativeTo: this.route });
  }

  dropAttachment(index: number) {
    const attachments = this.application.get('attachments').value as any[];
    attachments.splice(index, 1);
    const appInfo = {
      jobId: this.job.id,
      message: this.application.get('message').value,
      attachments: attachments,
    }
    this.attachmentService.attach(appInfo);
  }

  updateState(val?: any) {
    const attachments = this.application.get('attachments').value as any[];
    const appInfo = {
      jobId: this.job.id,
      message: this.application.get('message').value,
      attachments: attachments
    }
    this.attachmentService.attach(appInfo);
  }


  back() {
    this.location.back();
  }

  sendApplication() {
    FormUtil.setValidators(this.application, APPLICATION_VALIDATORS);
    if (this.application.valid) {
      const payLoad = this.parseSendData(this.application.getRawValue());
      this.empService.applyForJobs(payLoad).subscribe(res => {
        this.revertToJobDetails(this.job.id, this.job.what, this.job.where);
      });
    }
    this.ngAfterViewInit();
  }

  revertToJobDetails(job: string, what: string, where: string) {
    this.attachmentService.attach(null);
    this.router.navigate(['../job-details'], {
      relativeTo: this.route,
      queryParams: {
        job: this.job.id,
        what: this.job.what,
        where: this.job.where
      }
    });
  }

  cancel() {
    FormUtil.clearAllValidatorsAndErrors(this.application);
    if (this.application.valid || this.application.touched) {
      this.confirmService.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to cancel your application?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.revertToJobDetails(this.job.id, this.job.what, this.job.where);
        },
        reject: () => {
          this.confirmService.choose(false);
        }
      });
    } else {
      this.revertToJobDetails(this.job.id, this.job.what, this.job.where);
    }
  }

  private updateSM() {
    if (!this.hasModified) {
      const message = `I am interested in the position for ${this.job ? this.job.job_title : ''}. ` +
        `Please kindly review my application and forward this to the employer.\n\nThank you\n${this.currentUser.fullName}`;
      this.application.get('message').setValue(message);
    }
  }

  private parseSendData(formData: any): any {
    const data: any = {};
    data.from = this.auth.currentUser().userId;
    data.message = formData.message;
    data.jobId = this.job.id;
    data.jobTitle = this.job.job_title;
    data.name = this.currentUser.fullName;
    data.attachments = (formData.attachments as any[]).map(each => {
      return {
        attachmentId: each.id,
        attachmentType: each.type
      }
    });
    return data;
  }

}
