import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Url } from '../../shared/constants/url-constants';
import { IAssessmentTemplate } from './assessment.interface';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AssessmentService {

  constructor(private readonly http: HttpClient) {

  }

  getAssessmentsTable(): Observable<IAssessmentTemplate[]> {
    return this.http.get<any>(`${Url.ASSESSMENT}/getAssessmentListByLoggedInUser`);
  }
  setStatus(isActive, isPublished) {

    if (isPublished && isActive) {
      return 'Published';
    }
    if (!isPublished && isActive) {
      return 'Active';
    }
    return 'Inactive';
  }
  saveAssessmentResult(data: any) {
    const body = data;
    return this.http.post(`${Url.ASSESSMENT}/saveAssessmentResult`, body);
  }
  getAssessment(id: any): Observable<IAssessmentTemplate> {
    return this.http.get<IAssessmentTemplate>(`${Url.ASSESSMENT}/getAssessment/${id}`);
  }
  getCompletedAssessment(id: any,id1?: any): Observable<IAssessmentTemplate> {
    return this.http.get<IAssessmentTemplate>(`${Url.ASSESSMENT}/getCompletedAssessment/${id}/${id1}`);
  }

  isCompletedAssessment(id: any): Observable<IAssessmentTemplate> {
    return this.http.get<IAssessmentTemplate>(`${Url.ASSESSMENT}/isCompletedAssessment/${id}`);
  }

  createAssessment(createAssessmentData: any) {
    const body = createAssessmentData;
    return this.http.post(`${Url.ASSESSMENT}/createAssessment`, body);
  }

  publishedAssessment(publishedAssessmentData: any) {
    const body = publishedAssessmentData;
    return this.http.post(`${Url.ASSESSMENT}/publishedAssessment`, body);
  }

  publishedAssessmentToAll(assessmentTemplateId: any) {
    return this.http.post(`${Url.ASSESSMENT}/publishAssessmentForOrg/${assessmentTemplateId}`, {});
  }

  activeAndInactiveAssessment(data: any) {
    const body = data;
    return this.http.post(`${Url.ASSESSMENT}/activeAndInactiveAssessment`, body);
  }
}
