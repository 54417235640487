import { Injectable } from "@angular/core";
import { addRxPlugin, createRxDatabase, RxDatabase } from "rxdb";
import { RxCategoryCollection } from "../rxdb/entity/category";
import idb from 'pouchdb-adapter-idb';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxProfileCollection } from "../rxdb/entity/profile";
import { RxContentCollection } from "../rxdb/entity/content";
import { RxCourseInfoCollection } from "../rxdb/entity/su-course-info";
import { RxCourseCategoryCollection } from "../rxdb/entity/ref-course-category";
import { profileInfoSchema } from "../rxdb/schema/profile";
import { categorySchema } from "../rxdb/schema/category";
import { contentSchema } from "../rxdb/schema/content";
import { courseInfoSchema } from "../rxdb/schema/su-course-info";
import { refCourseCategorySchema } from "../rxdb/schema/ref-course-category";
import { refContentLevelSchema } from "../rxdb/schema/ref-content-level";
import { fStructureSchema } from "../rxdb/schema/fstructure";
import { RxContentLevelCollection } from "../rxdb/entity/ref-content-level";
import { RxFStructureCollection } from "../rxdb/entity/fstructure";
import { RxInductionPlanCollection } from "../rxdb/entity/induction-plan";
import { inductionPlanSchema } from "../rxdb/schema/induction-plan";
import { walletDOcumentSchema } from "../rxdb/schema/wallet-document";
import { RxWalletDocumentCollection } from "../rxdb/entity/wallet-document";

addRxPlugin(idb);
addRxPlugin(RxDBQueryBuilderPlugin);

export type RxVirtualCampusDatabase = RxDatabase<RxCollections>;
export type RxCollections = {
    category: RxCategoryCollection,
    profile: RxProfileCollection,
    content: RxContentCollection,
    courseinfo: RxCourseInfoCollection,
    refcoursecategory: RxCourseCategoryCollection,
    refcontentlevel: RxContentLevelCollection,
    fstructure: RxFStructureCollection,
    inductionplan: RxInductionPlanCollection,
    walletdocument: RxWalletDocumentCollection
}
let DB_INSTANCE: RxVirtualCampusDatabase;

let initState: null | Promise<any> = null;
let collections = [
    { profile: profileInfoSchema },
    { category: categorySchema },
    { content: contentSchema },
    { courseinfo: courseInfoSchema },
    { refcoursecategory: refCourseCategorySchema },
    { refcontentlevel: refContentLevelSchema },
    { fstructure: fStructureSchema },
    { inductionplan: inductionPlanSchema },
    { walletdocument: walletDOcumentSchema }
];

/**
 * creates the database
 */
 async function _create(): Promise<RxVirtualCampusDatabase> {
    console.log('DatabaseService: creating database..');
    const db = await createRxDatabase<RxVirtualCampusDatabase>({
        name: 'virtual_campus2_db',
        adapter: 'idb',
        password: 'Meganexus@123'
    });
    console.log('DatabaseService: created database');
    console.log('DatabaseService: create collections');
    try{
        await Promise.all(collections.map(colData => db.addCollections(colData)));
    } catch(e) {
        console.error(e);
        
        if (e.code === 'DB6') {
            console.warn('DB Schema has changed. Dropping and recreating');
            db.remove().then(rsp => {
                initState = null;
                initDatabase();
            });
        }
    }
    
    db.profile.preInsert(function(profile) {
        profile.id = ''+profile.id;
    }, false);
    db.category.preInsert(function(category) {
        category.id = ''+category.id;
        category.categoryImgUrl = '';
    }, false);
    db.content.preInsert(o => {
        o.imagePath = '';
        if(!o.contentKeyWords) {
            o.contentKeyWords = '';
        }
    }, false);
    return db;
 }

/**
 * This is run via APP_INITIALIZER in app.module.ts
 * to ensure the database exists before the angular-app starts up
 */
export async function initDatabase() {
    /**
     * When server side rendering is used,
     * The database might already be there
     */
    if (!initState) {
        console.log('initDatabase()');
        initState = _create().then(db => DB_INSTANCE = db);
    }
    await initState;
}

@Injectable({
    providedIn: 'root'
})
export class DatabaseService {
    get db(): RxVirtualCampusDatabase {
        return DB_INSTANCE;
    }

    insertCategories(categories) {
        return this.db.category.bulkInsert(categories);
    }

    getCategoryById(id) {
        return this.db.category.findOne({selector: {id: id}}).exec();
    }
    getCategoriesByLevel(level) {
        return this.db.category.find({selector: { level: { $eq: level }}}).$;
    }

    getCategoriesByLevelAndParentId(level, parentId) {
        return this.db.category.find({selector: { level: { $eq: level }, parentId: {$eq: parentId }}}).$;
    }
    getContentBySubCategoryId(subCatId) {
        return this.db.content.find({selector: {subCategoryId: {$eq: parseInt(subCatId)}}}).$
    }

    getFstructures() {
        return this.db.fstructure.find().$
    }

    getRecommendedContents() {
        return this.db.content.find().limit(2).$
    }

    getRecommendedContentsByCatId(catId) {
        return this.db.content.find({selector: {mainCategoryId: {$eq: parseInt(catId)}}}).limit(2).$
    }

    getContentByContentId(contentId) {
        return this.db.content.findOne({selector: {contentID: {$eq: ""+contentId}}});
    }

    getSearchQuery(keyword) {
        const regex = new RegExp(`^.*${keyword}.*$`, 'i');
        return this.db.content.find({
            selector: {
                $or : [ 
                    { title: { $regex: regex } },
                    { description: { $regex: regex } },
                    { contentKeyWords: { $regex: regex } },
                    { modules: 
                        {
                            $elemMatch: {
                                $or : [ 
                                    { moduleDescription: { $regex: regex } },
                                    { name: { $regex: regex } }
                                ]
                            }
                        }
                    }
                ],
                
            }
        })
    }

    insertContent(content) {
        return this.db.content.insert(content);
    }
    insertProfile(profile) {
        this.db.profile.insert(profile);
    }

    getProfile() {
        return this.db.profile.findOne().$;
    }

    insertRefLevel(levels) {
        this.db.refcontentlevel.bulkInsert(levels);
    }

    insertWalletDocuments(documents) {
        return this.db.walletdocument.bulkInsert(documents);
    }

    getWalletDocuments() {
        return this.db.walletdocument.find().$;
    }

    getWalletDocumentById(id) {
        return this.db.walletdocument.findOne({selector: {id: {$eq: id}}});
    }

    getRefLevels() {
        return this.db.refcontentlevel.find().$;
    }

    insertCourseInfo(courses) {
        return this.db.courseinfo.bulkInsert(courses);
    }

    insertRefCourseCategory(categories) {
        return this.db.refcoursecategory.bulkInsert(categories);
    }

    insertFstructure(fstructures) {
        return this.db.fstructure.bulkInsert(fstructures);
    }

    insertInductionPlan(inductionPlan) {
        return this.db.inductionplan.insert(inductionPlan);
    }

    getCoursesInfo() {
        return this.db.courseinfo.find().$
    }

    getRefCourseCategories() {
        return this.db.refcoursecategory.find().$;
    }

    async clearDB() {
        await this.db.remove()
        initState = null;
        await initDatabase();
    }

    getGoals() {
        return this.db.inductionplan.findOne().$;
    }
    
    hasContentSubCategoryId(subCatId) {
        return this.db.content.findOne({selector: {subCategoryId: {$eq: parseInt(subCatId)}}});
    }
}