<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
  <p class="number-of-pages">Page 13 of 13</p>
  <vc-all-errors [groups]="[pageThirteenForm]" [fromContext]="true"></vc-all-errors>
  <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>
  <form [formGroup]="pageThirteenForm" (submit)="onClickContinue()">
    <ng-container *ngIf="currentPageData[0]">
      <vc-form-control [control]="pageThirteenForm.get('barriersToLearning')" [controlName]="'barriersToLearning'" [fromContext]="true">
        <div class="checkbox-wrapper" *ngFor="let answer of currentPageData[0].answersLabels; let i=index">
          <input type="checkbox" (change)="onCheckboxChange($event)" [value]="currentPageData[0]?.answersValues[i]" [id]="'id-'+setInputId(i)" [name]="setInputId(i)" [checked]="checkIfChecked(currentPageData[0]?.answersValues[i])">
          <label for="id-barriersToLearning-{{i}}" class="checkbox-label">{{currentPageData[0]?.answersLabels[i]}}</label>
        </div>
      </vc-form-control>
    </ng-container>
    <div class="grid-row alignLeft mt30">
      <button 
        id="id-continue" 
        class="button button-link ml0" 
        type="submit" 
        name="continue"
      >Continue</button>
      <button 
        *ngIf="routeState !== 'changeAnswer'"
        id="id-save-as-draft" 
        class="button button-secondary" 
        type="button"
        name="save-as-draft"
        (click)="onClickSave()"
      >Save as draft</button>
    </div>
  </form>
</div>
