<div id="prison-jobs">
	<h2 class="job-prison">Prison jobs</h2>
	<table>
		<caption></caption>
		<thead>
			<tr>
				<th scope="colgroup">Date</th>
				<th scope="colgroup">Jobs</th>
				<th scope="colgroup"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let s of prisonJobList | paginate: { itemsPerPage: size, currentPage: (page + 1), totalItems: totalItems }"
				[ngClass]="{'disable': s.expired}">
				<td>
					<span>{{s?.dateExpressed | date : 'dd MMMM yyyy'}}</span>
				</td>
				<td>
					<span>
						<a class='link' href="javascript: void(0);" [routerLink]="['/employment/prison-jobs/prison-jobs-details',s?.id]"
							>{{s?.jobTitle}}
							<br /> {{s?.location}}</a>
					</span>
				</td>
				<td>
					<span class="status-tag">INTERESTED</span>
				</td>
			</tr>
		</tbody>
	</table>
	<p *ngIf="totalItems === 0">
		You have not expressed interest in any prison jobs
	</p>
	<div class="grid-row" *ngIf="totalItems > size">
		<p></p>
		<pagination-controls [maxSize]="10" (pageChange)="pageChange($event)">
		</pagination-controls>
	</div>
</div>