<form [formGroup]="messageForm">
    <div class="messages-page">
        <div id="messages" class="messages-container-header">
            <div class="header">
                <h3 id="conversation_with" *ngIf="!selectedKw"> Conversation with</h3>
                <h3 id="conversation_with" *ngIf="selectedKw"> Conversation with {{selectedKw}}</h3>
            </div>
        </div>

        <div id="messages-container" class="messages-container">
            <div class="message-list-left">
                <div class=" kwTab" >
                    <select class="govuk-select" id="kw_dropdown" name="userLists" (change)="updateReference($event.target.value)"
                    formControlName="keyWorker">
                        <option class="mh-40" value='0' id="select_kw">Select teacher</option>
                        <option *ngFor="let user of userList;" [value]="user.id" id="kw_{{user.id}}" >{{user.userFullName }}</option>
                    </select>
                </div>
            </div>

            <div class="message-panel-right">
                <div class="bottom-contents" >
                    <textarea class="message-input" id="text_area" name="message" formControlName="message"  rows="4" maxlength="400"
                        #message></textarea>
                    <div class="hin-message" id="text_length">You have {{400-message.value.length}} characters remaining </div>
                </div>
                <div class="button-alignment">
                    <button type="button" title="Send" id="button-send" class="button button-link"
                    [disabled]="!messageForm.valid" (click)="sendMessage()"> Send </button>
                    <button title="Cancel" (click)= "cancelMessage()" id="button-cancel"
                        class="button button-secondary">
                        Cancel</button>
                </div>
            </div>
        </div>
    </div>
</form>