/* 
	* Validation error messages for paid works page
*/

export const VOLUNTEERWORKS_VALIDATION_CONTEXT = {

	required: {
		jobStartDate: 'Start date cannot be blank',
		otherSkillDescription: `Enter other skills description, it cannot be blank`,
		employerName: `Organisation / Employer cannot be blank`,
		jobLocation: `Job location cannot be blank`,
		jobTitle: `Job title cannot be blank`,
	},
	invalidDate: {
		jobStartDate: 'Enter a valid start date',
		jobEndDate: 'Enter a valid end date',
	},
	futureDate: {
		jobStartDate: 'Start date cannot be in the future',
		jobEndDate: 'End date cannot be in the future'
	},
	maxSelected: {
		answers: 'Select 3 skills from the available',
	},
	minSelected: {
		answers: 'Select 3 skills from the available',
	},
	invalidEndDateOrCurrent: {
		jobEndDate: 'Start date must be accompanied by either an end date or current employment selection',
	},
	afterEndDate: {
		jobEndDate: 'Start date cannot be after end date',
	},
	invalidEndDateAndCurrent: {
		jobEndDate: 'Start date cannot be accompanied by both end date and current employment selection',
	},
	invalidPastYear: {
		jobStartDate: 'Job start year cannot be more than 80 years in the past',
	},
	maxlength: {
		employerName: 'Please enter 100 or less characters in organisation / employer',
		jobTitle: 'Please enter 100 or less characters in job title', 
		otherSkillDescription: 'Please enter 200 or less characters in other skills description'
	},
	pattern: {
		otherSkillDescription: 'Other skills description contains disallowed characters'
	}
}