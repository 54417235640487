import { Component, OnInit } from '@angular/core';
import { UserProfileService } from './../user-profile.service';

@Component({
  selector: 'vc-learner-info',
  templateUrl: './learner-info.component.html',
  styleUrls: ['./learner-info.component.scss']
})
export class LearnerInfoComponent implements OnInit {

  learner: any;
  courseSize = 0;
  lddSize = 0;

  constructor(
    private readonly userProfileService: UserProfileService) { 
  }

  ngOnInit() {
    this.resolveUserName();
  }
  resolveUserName() {
    this.userProfileService.getUserLearnerInfo().subscribe(
        response => {
          this.learner = response;
          console.log('getUserLearnerInfo: ', response)
          this.courseSize = this.learner.courseList.length;
          this.lddSize = this.learner.lldAndHealthProblemList.length;
          console.log('learner', this.learner)
      });
  }
}
