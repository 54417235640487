import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MainService } from '../../core/services/main.service';
import { ActivatedRoute } from '@angular/router';
import { WalletService } from '../wallet/wallet.service';


@Component({
  selector: 'vc-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {
  
  assessmetFlag:boolean;
  documentFlag:boolean;
  jobFlag:boolean;
  walletFlag :boolean;
  


  constructor(
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly walletService: WalletService,
  ) {
   this.mainService.pageTitle = this.route.snapshot.data.title;
  }

  ngOnInit() {
    // this.walletService.getNotificationCount().subscribe( response=>{
    //   this.walletService.emitWalletNotification(response.walletFlag);
    //     this.assessmetFlag = response.assessmentFlag;
    //     this.jobFlag = response.jobFlag;
    //     this.documentFlag = response.documentFlag;
    //     this.walletFlag=response.walletFlag;
    //   });
  }


}
