import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { MainService } from './../../../core/services/main.service';
import { GuidedCvBuilderService } from './guided-cv-builder.service';
import { CVBRoutes } from './guided-cvb.routes';
import { Errors } from '../../../shared/components/form-control/all-errors/all-errors.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vc-guided-cv-builder',
  templateUrl: './guided-cv-builder.component.html',
  styleUrls: ['./guided-cv-builder.component.scss']
})
export class GuidedCvBuilderComponent implements OnInit, OnDestroy {

  sections: any[] = [];
  errorList: Errors[] = [];
  errorSub: Subscription;

  constructor(
    private readonly location: Location,
    private readonly mainservice: MainService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly gcvb: GuidedCvBuilderService,
    private readonly renderer: Renderer2
  ) {
    this.mainservice.pageTitle = this.route.snapshot.data.title;
    this.mainservice.pageMainTitle = this.route.snapshot.data.pageMainTitle;
  }

  async ngOnInit() {
    await this.gcvb.listSections().toPromise().then((rsp) => {
      this.sections = rsp;
    });

    this.errorSub = this.gcvb.validationNeeded.subscribe(val => {
      if (val) {
        this.errorList = [];
        this.sections
          .filter(each => each.status !== 'Completed')
          .forEach(each => {
            this.errorList.push({ key: each.sectionId, message: each.sectionName });
          });
      }
    });
    this.renderer.addClass(document.body, 'changeCaseOfTitle');
  }

  ngOnDestroy() {
    this.gcvb.setConditionalValidation(false);
    if (this.errorSub) {
      this.errorSub.unsubscribe();
    }
    this.mainservice.pageMainTitle = '';
    this.renderer.removeClass(document.body, 'changeCaseOfTitle');
  }

  goToSection(id: number) {
    const link = CVBRoutes.ROUTES.find(route => route.id === +id);
    this.router.navigate([link.path], { relativeTo: this.route });
  }

  gotoPrev() {
    this.location.back();
  }

  generate() {
    this.gcvb.setConditionalValidation(true);
    if (this.errorList.length === 0) {
      this.router.navigate(['./cv-templates'], { relativeTo: this.route });
    }
    window.scrollTo(0, 0);
  }

}
