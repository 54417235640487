import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entry-course',
  templateUrl: './entry-course.component.html',
  styleUrls: ['./entry-course.component.scss']
})
export class EntryCourseComponent implements OnInit {

  @Input() entryData: any;
  dateSeparator = ' - '
  textPrimaryHex = '#000000';
  textSecondaryHex = '#505a5f';

  constructor() { }

  ngOnInit() {
    this.formatTitle()
    this.formatCourseDates()
  }

  formatTitle() {
    this.entryData.title = this.entryData.title.charAt(0).toUpperCase() + this.entryData.title.slice(1);
  }

  formatCourseDates() {
    const hyphenIndex = this.entryData.subTitle.indexOf('-')
    this.entryData.startDate = this.entryData.subTitle.substring(0, hyphenIndex - 1)
    this.entryData.endDate = this.entryData.subTitle.substring(hyphenIndex + 2)
  }

  setPathwayBackgroundColor(pathwayName) {
    switch(pathwayName) {
      case 'Work Related Skills Development':
        return '#F47738'
      case 'Functional Skills':
        return '#28A197'
      case 'Personal Development':
        return '#5694CA'
      case 'Vocational / Technical Training':
        return '#F499BE'
      case 'Behaviour & Attitudes':
        return '#85994B'
      default:
        return '#EEEFEF'
    }
  }

  setDateFontColor(interventionType, dateType) {
    if (interventionType === 'Start') {
      switch(dateType) {
        case 'Start':
          return this.textPrimaryHex;
        case 'Separator':
          return this.textSecondaryHex;
        case 'End':
          return this.textSecondaryHex;
        default:
          return this.textPrimaryHex;
      }
    } else {
      switch(dateType) {
        case 'Start':
          return this.textSecondaryHex;
        case 'Separator':
          return this.textSecondaryHex;
        case 'End':
          return this.textPrimaryHex;
        default:
          return this.textPrimaryHex;
      }
    }
  }

}
