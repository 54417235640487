import { Component, OnInit, ViewChild, ElementRef, Renderer2, Output, AfterViewChecked } from '@angular/core';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from './../../../../shared/components/form-control/validation.service';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { PAIDWORKS_VALIDATION_CONTEXT } from '../../../cv-builder/guided-cv-builder/work-experience/edit-work-experience/paid-work/paid-work.validation';
import { WorkExperienceService } from '../../../cv-builder/guided-cv-builder/work-experience/work-experience.service';
import { EditWorkExperienceService } from '../../../cv-builder/guided-cv-builder/work-experience/edit-work-experience/edit-work-experience.service';
import { WORKEXPERIENCE_VALIDATION_CONTEXT } from './page-six.validation';
import { MainService } from '../../../../core/services/main.service';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';

export interface IRefData {
  employmentId: number,
  identifier: string,
}

const WORKEXPERIENCE_VALIDATORS_SAVE = {
  jobStartDate: [Validators.required, ValidationService.monthYearValidator, ValidationService.futureDate, ValidationService.pastYear(80)],
  jobEndDate: [ValidationService.monthYearValidator,
    ValidationService.futureDate,
    ValidationService.invalidEndDateOrCurrent('jobStartDate', 'jobEndDate', 'isJobCurrent'),
    ValidationService.invalidEndDateAndCurrent('jobEndDate', 'isJobCurrent'),
    ],
  employerName: [Validators.required, Validators.maxLength(100)],
  jobLocation: [Validators.required],
  jobTitle: [Validators.required, Validators.maxLength(100)],
  otherSkillDescription: [Validators.required, Validators.maxLength(200), Validators.pattern('[a-zA-Z0-9\\-\\s\&\(\)\/\.\'\,\"\:\;]*')],
};

const PAGESIXFORM_DIAGNOSED_YES_VALIDATORS_SAVE = {
  lldDetails: [Validators.required]
}

const PAIDFORM_VALIDATORS_DRAFT = {
  jobStartDate: [ValidationService.monthYearValidator],
  jobEndDate: [ValidationService.monthYearValidator],
  otherSkillDescription: [Validators.maxLength(200)],
}

const SKILL_DESCRIPTION_VALIDATORS = {
  answers: [ValidationService.minSelected(3), ValidationService.maxSelected(3)],
}

const PAGESIXFORM_VALIDATORS_SAVE = {
  workExperience: [Validators.required]
}

@Component({
  selector: 'vc-page-six',
  templateUrl: './page-six.component.html',
  styleUrls: ['./page-six.component.scss']
})
export class PageSixComponent implements OnInit, AfterViewChecked {

  form: FormGroup;
  currentPageNumber: number;
  currentPageData = [] as any;
  refData = [] as any;
  data: any = [];
  educationTypes: any[] = [];
  isDialogOpen = false;
  employmentList = [] as any;
  employmentId: number;
  routeState: any;

  pageSixForm: FormGroup;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-five';
  routeNext = '/plan/induction/page-seven';

  @ViewChild('question') questionDiv: ElementRef;
  @Output() isEditEmploymentClicked : boolean;

  employmentForm: FormGroup;
  createAnother = new FormControl('N');
  isAssessmentInProgressFlag = false;


  constructor(
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly confirm: ConfirmService,
    private readonly validation: ValidationService,
    private readonly editWEService: EditWorkExperienceService,
    private readonly workService: WorkExperienceService,
    private readonly mainService: MainService,
    private readonly togetherMode: TogetherModeModalService,
  ) {
    this.mainService.pageTitle = 'Induction';
    this.validation.setContext(WORKEXPERIENCE_VALIDATION_CONTEXT);
    this.initForm();
    this.initPageSixForm();
    this.route.queryParams.subscribe(params => {
      this.loadSkillsAndResolve(params.id);
    });
    }

  ngOnInit() {
    this.getRefData();
    this.resolveRouteState();
    this.getEmploymentPlanList();

    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.employmentId = params.id;
        this.isEditEmploymentClicked = true;
      } else {
        this.employmentId = null;
        this.isEditEmploymentClicked = false;
      }
    });
    this.subscribeValueChanges();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageSixForm.dirty && this.isAssessmentInProgressFlag === false || this.employmentForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  onSelectionChange(value) {
    if (value === '2' && this.employmentList.length > 0) {
      this.pageSixForm.get('workExperience').setValue('1');
      this.confirm.confirm({
        header: 'Warning',
        message: 'You must delete all entries before selecting no',
        showClose: false,
        acceptLabel: 'Ok',
        accept: () => {
          this.confirm.choose(true);
        }
      });
    }
  }
  async loadSkillsAndResolve(id: any) {
    await this.loadAllSkills();
    if (id) {
      this.resolveData(id);
    }
  }

  resolveData(id: number): void {
    this.inductionQuestionnaireService.getEmploymentById(id)
      .subscribe(data => {
        this.employmentForm.get('employmentId').setValue(data.id);
        this.employmentForm.get('employerName').setValue(data.employerName);
        this.employmentForm.get('jobTitle').setValue(data.jobTitle);
        this.employmentForm.get('isJobCurrent').setValue(data.isJobCurrent);
        this.employmentForm.get('isJobFulltime').setValue(data.isJobFulltime);
        this.employmentForm.get('jobStartDate').setValue(data.jobStartDate);
        this.employmentForm.get('jobEndDate').setValue(data.jobEndDate);
        this.resolveSelectedSkills(data.skillDescription);
        if (data.skillDescription && data.skillDescription.includes('20')) {
          this.employmentForm.addControl('otherSkillDescription', this.fb.control(''));
          this.employmentForm.get('otherSkillDescription').setValue(data.otherSkillDescription);
        }
      });
  }
  refrestList() {
    this.getEmploymentPlanList();
    this.initForm();
    this.loadAllSkills();
    this.router.navigate(['.'], {relativeTo: this.route});
  }
  private subscribeValueChanges(){
    this.employmentForm.get('skillDescription').get('answers').valueChanges
      .subscribe((data: any[]) => {
        const filtered = data.filter(each => each.checked);
        if (filtered.find(each => each.id === '20')) {
          this.employmentForm.addControl('otherSkillDescription', this.fb.control(''));
        } else {
          this.employmentForm.removeControl('otherSkillDescription');
        }
      });
    this.employmentForm.get('jobStartDate').valueChanges.subscribe(data => {
        this.employmentForm.get('jobEndDate').updateValueAndValidity();
      });

    this.employmentForm.get('isJobCurrent').valueChanges.subscribe(data => {
        this.employmentForm.get('jobEndDate').updateValueAndValidity();
      });
  }

  getEmploymentPlanList() {
    this.inductionQuestionnaireService.getPlanEmploymentList().subscribe(Response => {
      this.employmentList = Response;
    });
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      this.pageSixForm.get('workExperience').setValue(data[0].answer);
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          },
        )
      });
    })
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.currentPageNumber = 6;
      this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  setFocusToQuestionDiv() {
    this.questionDiv.nativeElement.focus();
  }

  initForm() {
    this.employmentForm = this.fb.group({
      employmentId: this.fb.control(''),
      employerName: this.fb.control(''),
      jobTitle: this.fb.control(''),
      isJobCurrent: this.fb.control(false),
      isJobFulltime: this.fb.control(false),
      jobStartDate: this.fb.control(''),
      jobEndDate: this.fb.control(''),
      skillDescription: this.fb.group({
        question: this.fb.control('What did you do?'),
        answers: this.fb.array([]),
      })
    });
  }

  private initPageSixForm() {
    this.pageSixForm = this.fb.group({
      workExperience: this.fb.control(''),
      lldDetails: this.fb.control(''),
    });
  }

  defineAndSetValidators() {
    const workExperienceYes = this.pageSixForm.controls.workExperience.value === '1';
    if (workExperienceYes && this.employmentList.length === 0) {
      this.confirm.confirm({
        header: 'Warning',
        message: 'Add atleast one employment record',
        showClose: false,
        acceptLabel: 'Ok',
        accept: () => {
          this.confirm.choose(true);
          this.renderer.removeClass(document.body, 'hideErrorMessage');
        }
      });
    }
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  parseSaveData() {
    const employment = this.employmentForm.value;
    const skills = employment.skillDescription.answers
      .filter(each => each.checked)
      .map(answer => answer.id);
      employment.skillDescription = skills;
    return employment;
  }

  formatPayload(saveAsDraft) {
    return {
      isSaveAsDraft: saveAsDraft,
      planTypeIdentifier: '1',
      questionDTOList: [
        {
          answer: this.pageSixForm.controls.workExperience.value,
          id: 10
        },
      ],
      sectionIdentifier: this.currentPageData[0].sectionIdentifier
    }
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  onClickContinue() {
    const formattedPayload = this.formatPayload(false);
    this.isSaveSubmitOperation = true;
    this.saveInductionPlan(formattedPayload);
  }

  onClickSave() {
    const formattedPayload = this.formatPayload(true);
    this.isSaveSubmitOperation = true;
    this.canExit(formattedPayload);
    this.saveInductionPlan(formattedPayload);
  }
  
  saveInductionPlan(formattedPayload){
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      if(formattedPayload.isSaveAsDraft){
      this.router.navigateByUrl(this.routeTo);
      window.scrollTo(0, 0);
      }else
      {
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-seven');
      }
    });  
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  addNewEmployment() {
    this.setValidators(this.employmentForm, WORKEXPERIENCE_VALIDATORS_SAVE);
    this.setValidators(this.employmentForm.get('skillDescription') as FormGroup, SKILL_DESCRIPTION_VALIDATORS);
    if (this.employmentForm.valid) {
      this.parseSaveData();
      const formattedPayload = this.formatEmploymentPayload(null);
      this.inductionQuestionnaireService.createPlanEmployment(formattedPayload).subscribe(result => {
        this.loadAllSkills();
        this.getEmploymentPlanList();
        this.resetEmploymentForm();
        window.scrollTo(0, 0);
      })
    }
    window.scrollTo(0, 0);
    this.setFocusToErrorSummary();
  }

  editEmployment() {
    this.setValidators(this.employmentForm, WORKEXPERIENCE_VALIDATORS_SAVE);
    const formattedPayload = this.formatEmploymentPayload(this.employmentId);
    this.setValidators(this.employmentForm.get('skillDescription') as FormGroup, SKILL_DESCRIPTION_VALIDATORS);
    if(this.employmentForm.valid) {
      this.inductionQuestionnaireService.editPlanEmployment(formattedPayload).subscribe(result => {
        this.getEmploymentPlanList();
        this.router.navigate(['.'], {relativeTo: this.route});
        this.resetEmploymentForm();
        window.scrollTo(0, 0);
      });
    }
    this.setFocusToErrorSummary();
  }
  resolveSelectedSkills(ids: string[]) {
    ((this.employmentForm.get('skillDescription') as FormGroup)
      .get('answers') as FormArray).controls.forEach(each => {
        if (ids.includes(each.get('id').value)) {
          each.get('checked').setValue(true);
        }
      });
  }

  async loadAllSkills() {
    await this.workService.getAllSkills()
      .then(res => this.loadAnswers(res));
  }

  loadAnswers(answers: any[]) {
    const farr = this.employmentForm.get('skillDescription').get('answers') as FormArray;
    this.resetFormArr(farr);
    answers.map(answer => {
      return {
        id: answer.identifier,
        text: answer.description,
        checked: false,
      }
    }).forEach(answer => {
      farr.push(this.singleAnswerForm(answer));
    });
  }

  resetFormArr(farr: FormArray) {
    while (farr.controls.length !== 0) {
      farr.removeAt(0);
    }
  }

  singleAnswerForm(data: any) {
    return this.fb.group({
      id: this.fb.control(data.id),
      text: this.fb.control(data.text),
      checked: this.fb.control(data.checked),
    });
  }
  
  canExit(formattedPayload) {
    if (this.employmentForm.dirty && this.route.url) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.saveInductionPlan(formattedPayload);
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    } else  if (
      (this.pageSixForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      this.isAssessmentInProgressFlag === true && this.isSaveSubmitOperation === false) ||
      (this.pageSixForm.controls.workExperience.value === '1' && 
      this.isSaveSubmitOperation === false && 
      this.employmentList.length === 0 && 
      this.isAssessmentInProgressFlag === true)) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  resetEmploymentForm() {
    this.initForm();
    this.subscribeValueChanges();
  }

  formatEmploymentPayload(id) {
    const skills = this.employmentForm.get('skillDescription').value.answers
      .filter(each => each.checked)
      .map(answer => answer.id);
    return {
      jobEndDate: this.employmentForm.controls.jobEndDate.value,
      jobStartDate: this.employmentForm.controls.jobStartDate.value,
      jobTitle: this.employmentForm.controls.jobTitle.value,
      createInductionPlanDTO: {
        isSaveAsDraft: true,
        planTypeIdentifier: '1',
        questionDTOList: [
          {
            answer: this.pageSixForm.controls.workExperience.value,
            id: 10
          }
        ],
        sectionIdentifier: this.currentPageData[0].sectionIdentifier
      },
      employmentId: id,
      isExperienced: false,
      isJobFulltime: this.employmentForm.controls.isJobFulltime.value,
      skillDescription: skills,
      otherSkillDescription: this.employmentForm.contains('otherSkillDescription') ?
        this.employmentForm.controls.otherSkillDescription.value : null,
      employerName: this.employmentForm.controls.employerName.value,
      isJobCurrent: this.employmentForm.controls.isJobCurrent.value,
      planTypeId: 1
    }
  }
  
}

