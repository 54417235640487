import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../../shared/constants/url-constants';


@Injectable()
export class PageTenService {

  constructor(private readonly http: HttpClient) { }

//   getPlanEmploymentList() {
//     const href = `${Url.PLAN}/employment/all/1`;
//     return this.http.get<any>(href)
//   }

  getRefData() {
    const href = `${Url.PLAN}/plan/refData`;
    return this.http.get<any>(href).toPromise();
  }

}
