<vc-content-card [data]="reccomendedCourses" [scrollable]="false" [ifCourses]="true" [gridColumn]="'column-half'"
  [type]="'recommended'" (titleClick)="showCourseDetails($event)">
  <h3 class="course-title nomargintop">Featured content</h3>

</vc-content-card>

<vc-content-card [data]="categories" [scrollable]="false" [gridColumn]="'column-one-quarter'"
  [type]="'category'" (titleClick)="showCategoryDetails($event)">
  <h3 class="course-title mt10">Categories</h3>
</vc-content-card>
