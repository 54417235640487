import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConnectionService } from 'ng-connection-service'; 

@Injectable({
  providedIn: 'root'
})

export class AuthenticatedGuard implements CanActivate, CanActivateChild {
  isConnected = true;  
  noInternetConnection: boolean; 
  constructor(
    private readonly router: Router,
    private connectionService: ConnectionService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    return true;
    // if (localStorage.getItem('sessionToken')) {
    //   return true;
    // }
    // this.router.navigate(['/sign-in']);
    // return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.canActivate(next, state);

  }

}
