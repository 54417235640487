import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { CustomEvent } from 'ngx-image-viewer';

export const CONFIG = {
  btnClass: 'default', // The CSS class(es) that will apply to the buttons
  zoomFactor: 0.1, // The amount that the scale will be increased by
  containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
  wheelZoom: true, // If true, the mouse wheel can be used to zoom in
  allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
  allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
  btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
    zoomIn: 'fa fa-plus',
    zoomOut: 'fa fa-minus',
    rotateClockwise: 'fa fa-repeat',
    rotateCounterClockwise: 'fa fa-undo',
  },
  btnShow: {
    zoomIn: true,
    zoomOut: true,
    rotateClockwise: true,
    rotateCounterClockwise: true,
  },
  customBtns: [
    {
      name: 'print',
    }
  ],
};

@Component({
  selector: 'vc-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit, OnChanges {

  @Input() images: string[];

  @ViewChild('image_print', { static: true }) printImageHolder: ElementRef;

  config = CONFIG;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) { }

  handleEvent(event: CustomEvent) {
    const imgUrl = this.images[event.imageIndex];
    if (event.name === 'print') {
      this.printImage(imgUrl);
    }
  }

  printImage(url: string): void {
    const body = document.createElement('body');
    const img = document.createElement('img');
    img.setAttribute('src', url);
    body.appendChild(img);

    const newIframe = document.createElement('iframe');
    this.printImageHolder.nativeElement.appendChild(newIframe);

    newIframe.contentWindow.document.write(body.innerHTML);
    newIframe.contentWindow.document.close();
    newIframe.contentWindow.focus();
    newIframe.contentWindow.print();
    this.printImageHolder.nativeElement.innerHTML = '';
  }

}
