<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
    <p class="number-of-pages">Page 9 of 13</p>
    <vc-all-errors [groups]="[pageNineForm, pageNineForm.get('pathwayDescription')]" [fromContext]="true"></vc-all-errors>
    <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>

    <form [formGroup]="pageNineForm" autocomplete="off" (submit)="onClickContinue()">
        <div class="column-full" id="planPathwayDescription"> 
            <vc-multi-select-options [formGroup]="pageNineForm.get('pathwayDescription')" id="id-answers"
                [restrictMax]="true" [max]="1"></vc-multi-select-options>
        </div>
        <div class="grid-row alignLeft">
            <button id="id-continue" class="button button-link ml0" type="submit" name="continue">Continue</button>
            <button *ngIf="routeState !== 'changeAnswer'" id="id-save-as-draft" class="button button-secondary" type="button" name="save-as-draft"
                (click)="draft()">Save as draft</button>
        </div>
    </form>
</div>