import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpUrlEncodingCodec } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthenticationDirective } from './directives/authentication.directive';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationService } from './services/notification.service';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { HttpRequestInterceptorModule } from './interceptors/request-interceptor.service';
import { MainComponent } from './components/main/main.component';
import { MainService } from './services/main.service';
import { FormsModule } from '@angular/forms';
import { ChatComponent } from './components/header/chat/chat.component';
import { BannerComponent } from './components/banner/banner.component';
import { BannerService } from './services/banner.service';
import { ConfirmService } from '../shared/components/confirm-box/confirm-box.service';


@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        NotificationComponent,
        AuthenticationDirective,
        MainComponent,
        ChatComponent,
        BannerComponent,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        HttpRequestInterceptorModule,
        FormsModule
    ],
    providers: [
        MainService,
        NotificationService,
        AuthenticatedGuard,
        HttpUrlEncodingCodec,
        BannerService,
        ConfirmService
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        MainComponent,
        NotificationComponent,
        AuthenticationDirective,
        BannerComponent
    ]
})
export class CoreModule { }
