import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const refCourseCategorySchema: RxCollectionCreatorBase = {
    schema: {
        title: 'Ref Course Category',
        version: 0,
        description: 'Course Cagtegory',
        type: 'object',
        properties: {
            id: {
                type: 'number'
            },
            identifier: {
                type: 'string'
            },
            value: {
                type: ['string', 'null']
            },
            isActive: {
                type: ['boolean', 'null']
            },
            sequence: {
                type: ['number', 'null']
            }
        }
    }
}