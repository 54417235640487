import { TabsModule } from './../../shared/components/tabs/tabs.module';
import { TabsComponent } from './../../shared/components/tabs/tabs.component';
import { AccordionModule } from './../../shared/components/accordion/accordion.module';
import { ProfileTabNavigationData } from './user-profile-nav';
import { UserProfileService } from './user-profile.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserProfileRoutingModule } from './user-profile-routing.module';
import { UserProfileComponent } from './user-profile.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlModule } from '../../shared/components/form-control/form-control.module';
import { LearnerInfoComponent } from './learner-info/learner-info.component';
import { PlanInductionComponent } from './plan-induction/plan-induction.component';

@NgModule({
  declarations: [UserProfileComponent, AboutMeComponent, ProfileDetailsComponent, ChangePasswordComponent, LearnerInfoComponent, PlanInductionComponent],
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    TabsModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlModule,
  ],
  providers: [
    UserProfileService,
    ProfileTabNavigationData
  ]
})
export class UserProfileModule { }
