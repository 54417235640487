import { ComputerSkillsService } from './computer-skills.service';
import { Url } from './../../../../shared/constants/url-constants';
import { ICanDeactivate } from './../../../../core/guards/deactivate.interface';
import { ConfirmService } from './../../../../shared/components/confirm-box/confirm-box.service';
import { ValidationService } from './../../../../shared/components/form-control/validation.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MainService } from '../../../../core/services/main.service';
import { Location, getLocaleDateFormat } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormArray, ValidatorFn, AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { COMPUTER_SKILLS_VALIDATION_CONTEXT } from './computer-skills.validation';
import { BehaviorSubject, Observable } from 'rxjs';


@Component({
  selector: 'vc-computer-skills-home',
  templateUrl: './computer-skills-home.component.html',
  styleUrls: ['./computer-skills-home.component.scss']
})
export class ComputerSkillsHomeComponent implements OnInit, ICanDeactivate, OnDestroy {
  

  form: FormGroup;
  data: any = [];
  revisedData: any = [];
  emptylist: boolean = false;
  isDialogOpen = false;
  routeTo = '/employment/cv-builder/guided/computer-skills';
  model   = {option: ''};
  skillPresentOnLoadFlag : boolean = false;
  showFlag : boolean = false;
  
  checkInfo: BehaviorSubject<boolean>;

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly main: MainService,
    private readonly computerSkillsService: ComputerSkillsService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly confirm: ConfirmService,
    private readonly validationService: ValidationService
  ) {
    this.checkInfo = new BehaviorSubject<boolean>(false);

    this.form = new FormGroup({
      id: new FormControl(),
      hasAddedValue: new FormControl(),
      skills: new FormGroup({
        question: new FormControl('Select the relevant computer skills'),
        answers: new FormArray([]),
      })
    });
    this.loadSkillsAndResolve();
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'CV builder';
    this.validationService.setContext(COMPUTER_SKILLS_VALIDATION_CONTEXT);
  }

  ngOnInit() { 
    this.getValue().subscribe((value) => {
      this.showFlag = value;
      if(!this.showFlag){
        this.setOption('N');
      }
    });
  }

  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }

  getValue(): Observable<boolean> {
    return this.checkInfo.asObservable();
  }
  setValue(newValue): void {
    return this.checkInfo.next(newValue);
  }

  async loadSkillsAndResolve() {
    await this.loadAllSkills();
    this.resolveData();
  }

  resolveData(): void {
    this.computerSkillsService.getComputerSkill()
      .subscribe((res: any) => {
        this.form.get('id').setValue(res.id);
        this.form.get('hasAddedValue').setValue(res.hasAddedValue);
        this.resolveSelectedSkills(res.skills);

        this.skillPresentOnLoadFlag = this.isSkillsPresent();
        if(!this.skillPresentOnLoadFlag){
          this.model.option = 'N';
          this.form.get('hasAddedValue').setValue(true);
        }
        else{
          this.setOption('Y');
        }

      });
  }

  resolveSelectedSkills(ids: string[]) {
    if (ids) {
      ((this.form.get('skills') as FormGroup)
        .get('answers') as FormArray).controls.forEach(each => {
          if (ids.includes(each.get('id').value)) {
            each.get('checked').setValue(true);
          }
        });
    }

  }

  isSkillsPresent() {
    const data = this.form.value;
    const skills = data.skills.answers.filter(each => each.checked);
    return skills.length > 0;
  }

  validateSkills() {
    const data = this.form.value;
    const hasAddedValue = data.hasAddedValue;
    const skills = data.skills.answers.filter(each => each.checked);
    return skills.length > 0 && !hasAddedValue;
  }

  async loadAllSkills() {
    await this.computerSkillsService.getComputerSkills()
      .then(res => this.loadAnswers(res));
  }

  loadAnswers(answers: any[]) {
    const farr = this.form.get('skills').get('answers') as FormArray;
    answers.map(answer => {
      return {
        id: answer.identifier,
        text: answer.description,
        checked: false,
      }
    }).forEach(answer => {
      farr.push(this.singleAnswerForm(answer));
    });
  }

  singleAnswerForm(data: any) {
    return new FormGroup({
      id: new FormControl(data.id),
      text: new FormControl(data.text),
      checked: new FormControl(data.checked),
    });
  }
  gotoPrev() {
    this.location.back();
  }

  cvSummaryPage() {
    this.router.navigate(['/employment/cv-builder/guided']);
  }

  canExit(route, state, nextState) {
    if (this.form.dirty) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  parseSaveData() {
    const data = this.form.value;
    const answers = data.skills.answers;
    if (answers) {
      const skills = answers
        .filter(each => each.checked)
        .map(answer => answer.id);
      data.skills = skills;
    }
    return data;
  }

  save() {
    const data = this.parseSaveData();
    if (!data.hasAddedValue && data.skills.length === 0) {
      this.form.get('hasAddedValue').markAsTouched();
      this.form.get('hasAddedValue').setErrors({ unChecked: true });
    } else if (data.hasAddedValue && data.skills.length > 0) {
      this.form.get('hasAddedValue').markAsTouched();
      this.form.get('hasAddedValue').setErrors({ skillsCheck: true });
    } else {
      this.computerSkillsService.saveComouterSkill(data).subscribe(res => {
        this.resetAllForms();
        this.router.navigate(['../additional-info'], { relativeTo: this.route });
      });
    }

    window.scrollTo(0, 0);
  }

  draft() {
    const data = this.parseSaveData();
    this.computerSkillsService.draftComouterSkill(data).subscribe(res => {
      this.resetAllForms();
      this.router.navigate(['../'], { relativeTo: this.route });
    });

    window.scrollTo(0, 0);
  }

  resetAllForms() {
    this.form.markAsPristine();
  }

  validateComputerSkills() {
    const hasAddedValue = this.form.get('hasAddedValue').value;
    if (hasAddedValue) {
      this.form.get('hasAddedValue').markAsTouched();
      this.form.get('hasAddedValue').setErrors({ skillsCheck: true });
    } else {
      this.router.navigateByUrl(this.routeTo);
    }
  }

  setOption(option){
    this.model.option = option;
    if(option === 'N'){
    this.form.get('hasAddedValue').setValue(true);
    }
    else if(option === 'Y'){
      this.form.get('hasAddedValue').setValue(false);
      this.setValue(true);
    }
  }


}
