import { NgModule } from '@angular/core';
import { TruncatePipe } from './truncate.pipe';
import { SafePipe } from './safe.pipe';
import { LabelPipe } from './label.pipe';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
    declarations: [
        TruncatePipe,
        SafePipe,
        LabelPipe,
        FileSizePipe
    ],
    exports: [
        TruncatePipe,
        SafePipe,
        LabelPipe,
        FileSizePipe
    ]
})
export class PipesModule { }