import { Router, ActivatedRoute } from '@angular/router';
import { EmploymentService } from './../employment.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MainService } from '../../../core/services/main.service';

@Component({
  selector: 'vc-search-job',
  templateUrl: './search-job.component.html',
  styleUrls: ['./search-job.component.scss']
})
export class SearchJobComponent implements OnInit {
  searchJob: FormGroup;
  jobTitle: any = [];
  noresults: boolean;
  jobLocation: any = [];
  constructor(
    private readonly fb: FormBuilder,
    private readonly employmentservice: EmploymentService,
    private readonly mainservice: MainService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.mainservice.pageTitle = this.route.snapshot.data.title;
  }

  ngOnInit() {
    this.initForm();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.what) {
        this.searchJob.get('what').setValue(params.what);
      }
      if (params.where) {
        this.searchJob.get('where').setValue(params.where);
      }
    });
  }

  initForm() {
    this.searchJob = this.fb.group({
      what: [''],
      where: ['']
    });
  }

  onKeyJob(event: any) {
    const actualVal = event.target.value;
    const letters = /^[A-Za-z]+$/;
    if (actualVal.match(letters)) {
      this.employmentservice.getJobTitles(event.target.value).subscribe(response => {
        this.jobTitle = response;
        if (this.jobTitle === '') {
          this.noresults = true;
        } else {
          this.noresults = false;
        }
      });
    }
  }

  onKeyLocation(event: any) {
    const actualLoc = event.target.value;
    const allowedletters = /^[A-Za-z]+$/;
    if (actualLoc.match(allowedletters)) {
      this.employmentservice.getJobLocation(event.target.value).subscribe(response => {
        this.jobLocation = response;
        if (this.jobLocation === '') {
          this.noresults = true;
        } else {
          this.noresults = false;
        }
      });
    }
  }

  goToSearch() {
    const data = this.searchJob.getRawValue();
    this.router.navigate([`/employment/search-result`], { queryParams: data });
  }
}
