import { environment } from '../../../environments/environment';

export const Url = {
    AUTHENTICATION: environment.api + '/sequation-authentication-api',
    CONTENT_LIBRARY: environment.api + '/sequation-content-management-api',
    PROFILE: environment.api + '/sequation-usermanagement-composer-api',
    USER_MANAGEMENT: environment.api + '/sequation-user-management-api',
    ASSESSMENT: environment.api + '/sequation-assessment-api',
    PLAN: environment.api + '/sequation-plan-api',
    JOBS : environment.api + '/sequation-job-api',
    SRM: environment.api + '/sequation-srm-api',
    CVB: environment.api + '/sequation-cv-builder-api',
    DOCUMENT: environment.api + '/sequation-document-api',
    ORGANIZATION: environment.api + '/sequation-organization-api',
    MOJ_PEF: environment.api + '/sequation-moj-pef-contract-api',
    ELECTRON_APP: 'http://127.0.0.1:9990'
};
