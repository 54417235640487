import { BannerService } from './../../../core/services/banner.service';
import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../core/services/main.service';
import { ContentService } from '../content.service';
import { ICourse } from '../content.interface';
import { Location, ViewportScroller  } from '@angular/common';
import { ContentModuleComponent } from '../content-module/content-module.component';
import { ConfirmService } from './../../../shared/components/confirm-box/confirm-box.service';
import { Utility } from '../../../shared/utils/utility';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vc-content-details',
  templateUrl: './content-details.component.html',
  styleUrls: ['./content-details.component.scss']
})
export class ContentDetailsComponent implements OnInit {

  courseId: number;
  courseName: string;
  course: ICourse;
  moduleName: string;
  isComingSoon: boolean;
  isOpenContent: boolean = false;
  selectedModule: any;
  isFavourite: boolean = null;
  refdata:any

  viewContainerRef: ViewContainerRef;

  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  set _viewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  @ViewChild('navContainer') navContainer: ElementRef;

  moduleComponentRef: ComponentRef<ContentModuleComponent>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly mainService: MainService,
    private readonly contentService: ContentService,
    private readonly bannerService: BannerService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly factory: ComponentFactoryResolver,
    private readonly confirmService: ConfirmService,
    private readonly viewportScroller: ViewportScroller,
    private readonly sanitizer: DomSanitizer,
  ) {
    this.courseId = +this.route.snapshot.paramMap.get('courseId');
    this.courseName = decodeURIComponent(this.route.snapshot.paramMap.get('courseTitle'));
    this.mainService.pageTitle = this.courseName;
  }

  ngOnInit() {
    this.mainService.showPlanBar();
    this.resolveContentDetails();
  }

  ngOnDestroy() {
    this.mainService.pageMainTitle = '';
  }
  // content-unavailable for no modules found
  resolveContentDetails() {
    this.contentService.getContentDetails(this.courseId).subscribe(async rsp => {
      const data = rsp.toJSON();
      this.isFavourite = data.isFavourite;
      this.course = data;
      
      this.contentService.getRefData().subscribe(
        response => {
          this.refdata = response;
          this.course.contentLevelId = this.refdata.find(one=> one.id === this.course.contentLevelId);
      });

      this.course.contentKeyWords = data.contentKeyWords ? data.contentKeyWords.split(',') : [];
      if ((this.course.modules !== null) && (this.course.modules.length > 0)) {
        this.selectedModule = this.course.modules[0];
        const attachment = await rsp.getAttachment(''+this.courseId);
        if(attachment) {
          const imageData = await attachment.getData();
          const blobUrl = URL.createObjectURL(imageData)
          this.course.courseImgUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl); 
        }
      } else {
        this.router.navigate(['/content-unavailable']);
      }
    });
  }

  toggleLaunch() {
    this.isOpenContent = !this.isOpenContent;
    this.injectReference(this.isOpenContent);
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  onModuleChange(event) {
    this.selectedModule = event;
    
    if(this.moduleName !== this.selectedModule.name){
      const moduleCountDTO = {
        contentId: this.course.contentID,
        contentTitle: this.course.title,
        moduleId: this.selectedModule.id,
        moduleTitle: this.selectedModule.name
      }
      // this.contentService.sendAnalyticsData(moduleCountDTO).subscribe();
    }
    
    this.injectReference(false);
    this.injectReference(true);
    this.moduleName = this.selectedModule.name;
    this.navContainer.nativeElement.focus();
  }

  injectReference(mode: Boolean) {
    if (mode) {
      const componentFactory = this.factory.resolveComponentFactory(ContentModuleComponent);
      const componentRef: ComponentRef<ContentModuleComponent> = this.viewContainerRef.createComponent(componentFactory);
      componentRef.instance.module = this.selectedModule;
      componentRef.instance.contentId = this.courseId;
      this.moduleComponentRef = componentRef;
    } else {
      if (this.moduleComponentRef) {
        this.viewContainerRef.remove(0);
      }
    }
  }

  addToPlan() {
    this.contentService.addToPlan(this.courseId).subscribe(response => {
        this.bannerService.success(response.successMessage, true)
        this.isFavourite = true;
      }, error => {
        this.bannerService.warning(error.error.errorMessage, true)
      }
    );
  }

  removeFromPlan() {
    this.contentService.removeMyContent(this.courseId).subscribe(response => {
        this.bannerService.success(response.successMessage, true);
        this.isFavourite = false;
      }, error => {
        this.bannerService.warning(error.error.errorMessage, true);
      }
    );
  }

  backClicked() {
    this.location.back();
  }

  getImage(item) {
    const url = item?.courseImgUrl || item?.categoryImgUrl || item?.imagePath;
    if(url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

}
