import { Component, OnInit } from '@angular/core';
import { MainService } from '../../core/services/main.service';
import { Router,ActivatedRoute } from '@angular/router';
import {UsefulLinkService} from './useful-link.service';

@Component({
  selector: 'vc-useful-link',
  templateUrl: './useful-link.component.html',
  styleUrls: ['./useful-link.component.scss']
})
export class UsefulLinkComponent implements OnInit {

links: boolean= false;
localLinks: boolean= false;
linkTitle="National";
linksData:any[];
page = 1;
public pageSize = 10;
maxSize = 10;

  constructor(
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly usefulLinkService : UsefulLinkService,
    private readonly router: Router,
  ) {
   this.mainService.pageTitle = this.route.snapshot.data.title;
  }

  ngOnInit() {
    this.getNationalUsefulLink();
  }

getNationalUsefulLink(){
  this.localLinks=false;
  this.links=true;
  this.linkTitle="National";
     this.page = 1;
      this.router.navigate([], { queryParams: { status: this.linkTitle, page: this.page } });
    this.usefulLinkService.getUsefulLinks(this.linkTitle).subscribe(response=>{
      this.linksData =response
      this.maxSize = this.dynamicMaxSizeBasedOnPage(this.page - 1, this.linksData.length, this.pageSize);
    });
}

getLocalUsefulLink(){
  this.links=false;
  this.localLinks=true;
  this.linkTitle="Local";
     this.page = 1;
     this.router.navigate([], { queryParams: { status: this.linkTitle, page: this.page } });
    this.usefulLinkService.getUsefulLinks(this.linkTitle).subscribe(response=>{
      this.linksData =response
      this.maxSize = this.dynamicMaxSizeBasedOnPage(this.page - 1, this.linksData.length, this.pageSize);
    });
}

  dynamicMaxSizeBasedOnPage(currentPage, totalItems, size): number {
    const totalPages = Math.ceil(totalItems / size);
    if (currentPage <= 3 || currentPage >= (totalPages - 4)) {
      return 10;
    } else if ((currentPage - 6) < 1) {
      return 10 + (currentPage - 3);
    } else if (currentPage < (totalPages - 4) && currentPage > (totalPages - 7)) {
      return 10 + (totalPages - 4) - currentPage;
    } else {
      return 13;
    }
  }

   pageChange(page) {
    this.page = page;
    this.maxSize = this.dynamicMaxSizeBasedOnPage(this.page - 1, this.linksData.length, this.pageSize);
  }

}
