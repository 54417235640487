<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
    <p class="number-of-pages">Page 10 of 13</p>
    <vc-all-errors [groups]="[pageTenHobbiesForm, pageTenHobbiesForm.get('hobbies')]" [fromContext]="true">
    </vc-all-errors>
    <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>

    <form [formGroup]="pageTenHobbiesForm" autocomplete="off" (submit)="onClickContinue()">
        <div class="column-full" id="planPathwayDescription">
            <vc-multi-select-options [formGroup]="pageTenHobbiesForm.get('hobbies')" id="id-answers"
                [hint]="'Please select up to 3 options'" [restrictMax]="true" [max]="3"></vc-multi-select-options>
        </div>
        <div class="column-full" *ngIf="pageTenHobbiesForm.get('otherHobbies')">
            <vc-form-control [control]="pageTenHobbiesForm.get('otherHobbies')" [controlName]="'otherHobbies'"
                [label]="'Please specify other hobbies and interests'" [fromContext]="true">
                <textarea formControlName="otherHobbies" rows="5" class="form-control" type="text" id="id-otherHobbies"
                    name="otherHobbies" aria-describedby="id-section-help-otherHobbies id-section-error-otherHobbies">
                </textarea>

                <div *ngIf="pageTenHobbiesForm.get('otherHobbies').value?.length <= 200"
                    class="form-label form-label-count">You
                    have
                    {{200 - pageTenHobbiesForm.get('otherHobbies').value?.length}} characters remaining</div>
                <div *ngIf="pageTenHobbiesForm.get('otherHobbies').value?.length === 201" class="error-message">You
                    have {{ pageTenHobbiesForm.get('otherHobbies').value?.length - 200}} character too many</div>
                <div *ngIf="pageTenHobbiesForm.get('otherHobbies').value?.length > 201" class="error-message">You
                    have {{ pageTenHobbiesForm.get('otherHobbies').value?.length - 200}} characters too many</div>
            </vc-form-control>
        </div>
        <div class="grid-row alignLeft">
            <button id="id-continue" class="button button-link ml0" type="submit" name="continue">Continue</button>
            <button *ngIf="routeState !== 'changeAnswer'" id="id-save-as-draft" class="button button-secondary" type="button" name="save-as-draft"
                (click)="draft()">Save as draft</button>
        </div>
    </form>
</div>