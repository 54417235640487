import { Component, OnInit } from '@angular/core';
import { PlanInductionService } from './plan-induction.service';

@Component({
  selector: 'vc-plan-induction',
  templateUrl: './plan-induction.component.html',
  styleUrls: ['./plan-induction.component.scss']
})
export class PlanInductionComponent implements OnInit {

  inductionData = [];
  noInductionMessage: string;
  planStatus: string;
  planDate: string;
  inductionHistory = [];
  isPlanCreated: boolean = true;


  constructor(
    private readonly planInductionService: PlanInductionService,
  ) { }

  ngOnInit() {
    this.getInductionData();
  }

  getInductionData() {
    this.planInductionService.getPlanInductionDetails().subscribe(data => {
      this.planStatus = data.planStatus;
      this.planDate = data.planDate;
      if(this.planDate === null){
        this.isPlanCreated = false;
      }
      data.ansDtoList.forEach(pageData => {
        this.inductionData.push(
          {
            label: pageData.sectionDescription,
            value: pageData.ansList.length === 0 ? 
              this.formatEmptyValue(pageData.ansList, pageData.sectionDescription) : 
              this.formatValue(pageData.sectionIdentifier, pageData.ansList, pageData.sectionDescription),
            panelStateOpen: false,
            pageNumber: this.setPageNumber(pageData.sectionIdentifier),
            hasHistory: pageData.isHistory,
            sectionIdentifier: pageData.sectionIdentifier
          }
        )
      });
    }, error => {
      this.noInductionMessage = error.error.errorMessage
    })
  }

  getInductionHistory(sectionIdentifier, sectionDescription) {
    this.inductionHistory = [];
    this.planInductionService.getPlanInductionHistory(sectionIdentifier).subscribe(data => {
      data.forEach(element => {
        this.inductionHistory.push(
          {
            ...element,
            formattedAnsList: this.formatHistoryAnswers(sectionIdentifier, element.ansList, sectionDescription)
          }
        )
      });
    })
  } 

  formatHistoryAnswers(sectionIdentifier, ansList, sectionDescription) {
    switch(sectionIdentifier) {
      case "1":
        return this.formatHistoryAnswerOne(sectionIdentifier, ansList, sectionDescription)
      case "8":
        return this.formatHistoryAnswerEight(sectionIdentifier, ansList, sectionDescription)
      case "9":
        return this.formatHistoryAnswerNine(ansList)
      default:
        return this.formatValue(sectionIdentifier, ansList, sectionDescription)
    }
  }

  formatHistoryAnswerOne(sectionIdentifier, ansList, sectionDescription) {
    const ansArr = this.formatAnswerWithCommas(ansList)
    return this.formatValue(sectionIdentifier, ansArr, sectionDescription);
  }

  formatHistoryAnswerEight(sectionIdentifier, ansList, sectionDescription) {
    const ansArr = this.formatAnswerWithCommas(ansList)
    return this.formatValue(sectionIdentifier, ansArr, sectionDescription);
  }

  formatHistoryAnswerNine(ansList) {
    const splitAnswerArr = this.formatAnswerWithCommas(ansList)
    const tempAnswerArr = [];
    splitAnswerArr.forEach(element => {
      const otherStringChecker = 'Other:';
      const arrayItemStringToCheck = element.substring(0,6);
      if (otherStringChecker === arrayItemStringToCheck) {
        const tempString = element.slice(6);
        tempAnswerArr.push(tempString.charAt(1).toUpperCase() + tempString.slice(2))
      } else {
        tempAnswerArr.push(element)
      }
    });
    return tempAnswerArr
  }

  formatEmptyValue(answerArr, label) {
    answerArr.push(`There is no entry in '${label}'`);
    return answerArr
  }

  formatValue(pageNumber, answerArr, label) {
    switch(pageNumber) {
      case "1":
        if (answerArr[1] === '') {
          answerArr.pop();
          return answerArr
        } else {
          const formattedTempOneArr = [];
          answerArr.forEach(element => {
            formattedTempOneArr.push(element.charAt(0).toUpperCase() + element.slice(1));
          });
          return formattedTempOneArr
        }
      case "2":
        const tempTwoArr = [];
        answerArr.forEach(element => {
          const colonIndex = element.indexOf(':');
          const formattedString = element.slice(0, colonIndex - 1) + element.slice(colonIndex, element.length);
          tempTwoArr.push(formattedString)
        });
        return tempTwoArr
      case "3":
        return this.formatAnswerThree(answerArr)
      case "4":
        return this.formatAnswerFour(answerArr, label)
      case "5":
      case "6":
        if (answerArr.length > 0) {
          const tempFiveArr = this.formatAnswerWithCommas(answerArr);
          const formattedTempFiveArr = [];
          tempFiveArr.forEach(element => {
            formattedTempFiveArr.push(element.charAt(0).toUpperCase() + element.slice(1));
          });
          return formattedTempFiveArr
        } else {
          return answerArr
        }
      case "7":
      case "13":
        return this.formatAnswerSeven(answerArr)
      case "8":
        if (answerArr[0] === '') {
          answerArr[0] = `There is no entry in '${label}'`;
          return answerArr
        } else {
          const tempEightArr = [];
          answerArr.forEach(element => {
            tempEightArr.push(element.charAt(0).toUpperCase() + element.slice(1).toLowerCase())
          });
          return tempEightArr
        }
      case "9":
        if (answerArr.length === 1) {
          const formattedArr = this.checkAndFormatOtherValue(answerArr);
          return formattedArr[0].split(',')
        } else {
          return this.checkAndFormatOtherValue(answerArr)
        }
      case "10":
        const tempTenArr = [];
        answerArr.forEach(element => {
          tempTenArr.push(element.charAt(0).toUpperCase() + element.slice(1).toLowerCase().split(',').join(', '))
        });
        return tempTenArr
      case "12":
        return this.formatAnswerWithCommas(answerArr)
      default:
        return this.formatAnswerDefault(answerArr, label)
    }
  }

  formatAnswerDefault(answerArr, label) {
    if (answerArr[0] === '') {
      answerArr[0] = `There is no entry in '${label}'`;
      return answerArr
    } else { 
      return answerArr
    }
  }

  setPageNumber(sectionIdentifier): string | undefined {
    switch(sectionIdentifier) {
      case "1":
        return 'one'
      case "2":
        return 'two'
      case "3":
        return 'three'
      case "4":
        return 'four'
      case "5":
        return 'five'
      case "6":
        return 'six'
      case "7":
        return 'seven'
      case "8":
        return 'nine'
      case "9":
        return 'ten'
      case "10":
        return 'eleven'
      case "11":
        return 'twelve'
      case "12":
        return 'thirteen'
      case "13":
        return 'eight'
    }
  }

  formatAnswerThree(answerArr) {
    if (answerArr.length === 1 || answerArr.length === 2 && answerArr[0] === 'Yes' && answerArr[1] === 'Yes') {
      answerArr.push(`There is no entry in 'What languages do you speak'`)
      return answerArr
    } else if (answerArr.length === 2 && answerArr[1] === '') {
      answerArr.push(`There is no entry in 'Do you speak any other languages'`)
      return answerArr
    } else {
      answerArr[1] = answerArr[1].split(',').join(', ');
      return answerArr
    }
  }

  formatAnswerFour(answerArr, label) {
    if (answerArr[0].charAt(0) === '-') {
      answerArr[0] = '';
      return this.formatAnswerDefault(answerArr, label)
    } else {
      return this.formatAnswerDefault(answerArr, label)
    }
  }

  formatAnswerSeven(answerArr) {
    if (answerArr.length > 0) {
      const splitAnswerArr = this.formatAnswerWithCommas(answerArr);
      const formattedTempSevenArr = [];
      splitAnswerArr.forEach(element => {
        formattedTempSevenArr.push(element.charAt(0).toUpperCase() + element.slice(1).toLowerCase());
      })
      return formattedTempSevenArr
    } else {
      return answerArr
    }
  }

  formatAnswerWithCommas(answerArr) {
    if (answerArr.length > 0) {
      const tempCommaSplitIndex = [];
      const tempCommaNotSplitIndex = [];
  
      let i = 0;
      for (i = 0; i < answerArr[0].length; i++) {
        if (answerArr[0][i] === ',') {
          answerArr[0][i+1] === ' ' ? tempCommaNotSplitIndex.push([i]) : tempCommaSplitIndex.push([i]);
        }
      }

      return this.setTempFormattedAnswerArr(tempCommaSplitIndex, answerArr)
    } else {
      return answerArr
    }
  }

  setTempFormattedAnswerArr(tempCommaSplitIndex, answerArr) {
    const tempFormattedAnswerArr = [];
    switch(tempCommaSplitIndex.length) {
      case 0:
        tempFormattedAnswerArr.push(answerArr[0]);
        break;
      case 1:
        tempFormattedAnswerArr.push(answerArr[0].substring(0, tempCommaSplitIndex[0]))
        tempFormattedAnswerArr.push(answerArr[0].substring(parseInt(tempCommaSplitIndex[0])+1, answerArr[0].length))
        break;
      default:
        let i = 0;
        for (i = 0; i < tempCommaSplitIndex.length; i++) {
          if (i === 0) {
            tempFormattedAnswerArr.push(answerArr[0].substring(0, tempCommaSplitIndex[i]))
          } else if (i === tempCommaSplitIndex.length - 1) {
            tempFormattedAnswerArr.push(answerArr[0].substring(parseInt(tempCommaSplitIndex[i-1])+1, parseInt(tempCommaSplitIndex[i])))
            tempFormattedAnswerArr.push(answerArr[0].substring(parseInt(tempCommaSplitIndex[i])+1, answerArr[0].length))
          } else {
            tempFormattedAnswerArr.push(answerArr[0].substring(parseInt(tempCommaSplitIndex[i-1])+1, parseInt(tempCommaSplitIndex[i])))
          }
        }
    }
    return tempFormattedAnswerArr
  }

  checkAndFormatOtherValue(answerArr) {
    const tempNineArr = [];
    answerArr.forEach(element => {
      const otherStringChecker = 'Other :';
      const arrayItemStringToCheck = element.substring(0,7);
      if (otherStringChecker === arrayItemStringToCheck) {
        const tempString = element.slice(8);
        tempNineArr.push(tempString.charAt(0).toUpperCase() + tempString.slice(1))
      } else {
        tempNineArr.push(element)
      }
    });
    return tempNineArr
  }

  setAnswerMargin(index) {
    return {
      'margin-top': index > 0 ? '10px' : '0px',
      'margin-bottom': '0px' 
    }
  }

  setBorderBottom(panelStateOpen) {
    return {
      'border-bottom': panelStateOpen === true ? 'none' : '1px solid #b1b4b6',
      'padding-bottom': panelStateOpen === true ? '0px' : '10px'
    }
  }

  setMarginBottom(panelStateOpen) {
    return {
      'margin-bottom': panelStateOpen === true ? '0px' : '15px'
    }
  }

  checkIfAnswersShouldBeAccordion(questionNumber, answerArr) {
    switch(questionNumber) {
      case "one":
      case "seven":
      case "eight":
      case "nine":
      case "ten":
      case "twelve":
      case "thirteen":
        return true
      default:
        return false
    }
  }

  togglePanelState(pageNumber, panelStateOpen, sectionIdentifier, sectionDescription) {
    if (panelStateOpen) {
      const openPanelIndex = this.inductionData.findIndex(element => element.pageNumber === pageNumber)
      this.inductionData[openPanelIndex].panelStateOpen = false;
    } else {
      this.getInductionHistory(sectionIdentifier, sectionDescription);
      this.inductionData.forEach(element => {
        element.pageNumber === pageNumber ? element.panelStateOpen = true : element.panelStateOpen = false;
      });
    }
  }
}
