import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { INavigation } from '../components/header/header.interfaces';

const topNavigation: INavigation[] = [
  {
    id: 1,
    name: 'Plan',
    state: 'plan',
    showAuthed: true,
  },
  {
    id: 2,
    name: 'Content',
    state: 'content',
    showAuthed: true,
  },
  // {
  //   id: 3,
  //   name: 'Employment',
  //   state: 'employment',
  //   showAuthed: true,
  // },
  // {
  //   id: 4,
  //   name: 'Wallet',
  //   state: 'wallet',
  //   showAuthed: true,
  // },
  // {
  //   id: 5,
  //   name: 'Useful links',
  //   state: 'useful-link',
  //   showAuthed: true,
  // }

];

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  private readonly navigationSubject = new BehaviorSubject(topNavigation);
  currentNavigation = this.navigationSubject.asObservable();

  constructor() {
  }

  setNavigation(data: any) {
    this.navigationSubject.next(data);
  }
}
