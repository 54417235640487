import { ConfirmService } from './../../../../shared/components/confirm-box/confirm-box.service';
import { MainService } from '../../../../core/services/main.service';
import { PersonalStatementService } from './personal-statement.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormArray, ValidatorFn, AbstractControl, FormGroup, Validators } from '@angular/forms';
import { ICanDeactivate } from '../../../../core/guards/deactivate.interface';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { PERSONAL_STATEMENT_VALIDATION_CONTEXT } from './personal-statement.validation';


@Component({
  selector: 'vc-personal-statement',
  templateUrl: './personal-statement.component.html',
  styleUrls: ['./personal-statement.component.scss', '../../../../shared/components/form-control/all-errors/all-errors.component.scss']
})
export class PersonalStatementComponent implements OnInit, ICanDeactivate, OnDestroy {

  jsondata: any;
  questions: any[];
  ps: any = [];
  name: any;
  multiselectform: FormGroup;

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly main: MainService,
    private readonly personalStatementService: PersonalStatementService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly confirm: ConfirmService,
    private readonly validationService: ValidationService,
  ) {
    this.validationService.setContext(PERSONAL_STATEMENT_VALIDATION_CONTEXT);
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'CV builder';
    this.multiselectform = this.formBuilder.group({
      questions: this.formBuilder.array([])
    });
    this.validationService.setContext(PERSONAL_STATEMENT_VALIDATION_CONTEXT);
  }
  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }
  ngOnInit() {
    this.personalStatementService.getPersonalStatementInfo().subscribe(res => {
      this.ps = res;
      this.ps.forEach(question => {
        const qf = this.getSingleQuestionForm(question);
        question.answers.forEach(answer => {
          (qf.get('answers') as FormArray).push(this.getSingleAnswerForm(answer));
        });
        (this.multiselectform.get('questions') as FormArray).push(qf);
      });
    });
  }

  get forms() {
    const forms: FormGroup[] = [];
    const farr = this.multiselectform.get('questions') as FormArray;
    for ( const f of farr.controls ) {
      forms.push(f as FormGroup);
    }
    return forms;
  }

  getSingleQuestionForm(data: any) {
    return this.formBuilder.group({
      id: this.formBuilder.control(data.questionId),
      question: this.formBuilder.control(data.questionText),
      answers: this.formBuilder.array([])
    });
  }

  getSingleAnswerForm(data: any) {
    const form =  this.formBuilder.group({
      id: this.formBuilder.control(data.id),
      text: this.formBuilder.control(data.text),
      checked: this.formBuilder.control(data.selected)
    });
    form.markAsTouched();
    return form;
  }

  gotoPrev() {
    this.location.back();
  }

  processChecked(data, i) {
    (this.multiselectform.get('questions') as FormArray).controls[i].get('answers').setValue(data);
  }

  save(form: FormGroup) {
    const data: any[] = form.value.questions;
    const dataToSave = this.prepareResponse(data);
    this.setValidator(form);
    if (form.valid) {
      this.personalStatementService.savePersonalStatement(dataToSave).subscribe(data => {
        this.multiselectform.markAsPristine();
        this.router.navigate(['../experience'], { relativeTo: this.route });
      });
    }
    window.scrollTo(0, 0);
  }

  draft(form) {
    const data: any[] = form.value.questions;
    const dataToSave = this.prepareResponse(data);
    const questions = form.get('questions') as FormArray;

    this.clearAllValidatorsAndErrors(questions);
    this.personalStatementService.draftPersonalStatement(dataToSave).subscribe(data => {
      this.multiselectform.markAsPristine();
      this.cvSummaryPage();

    });

  }

  prepareResponse(list: any[]): any {
    const finalRes = [];
    list.forEach(question => {
      question.answers.filter(each => each.checked).forEach(answer => {
        finalRes.push({
          questionId: question.id,
          answerId: answer.id
        });
      });
    });
    return finalRes;
  }

  clearAllValidatorsAndErrors(form: FormArray): void {
    for (let index = 0; index < form.length; index++) {
      const element = form.at(index) as FormGroup;
      this.clearFormGrp(element);
    }

  }

  clearFormGrp(form: FormGroup) {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  private setValidator(form: FormGroup) {
    const questions = form.get('questions') as FormArray;
    for (let index = 0; index < questions.length; index++) {
      const element = questions.at(index);
      element.get('answers').setValidators([ValidationService.minSelected(3), ValidationService.maxSelected(3)]);
      element.get('answers').updateValueAndValidity();
    }
  }

  cvSummaryPage() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  get errors() {
    const err = (this.multiselectform.get('questions') as FormArray).controls;
    return err;
  }

  goToQuestion(allQuestions) {
    for (const a of allQuestions) {
      if (a.status === 'INVALID') {
        const abc = 'question-' + (a.value.id - 1);
        document.querySelector('#' + abc).scrollIntoView();
        break;
      }
    }
  }

  canExit() {
    if (this.multiselectform.dirty) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

}
