import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AssessmentComponent } from './assessment.component';
import { AssessmentRoutes } from './assessment.routing';
import { AssessmentService } from './assessment.service';
import { ViewAssessmentComponent } from './view-assessment/view-assessment.component';
import { AssessmentsCustomLocalisations } from './assessment.en.localisation';
import { CompleteAssessmentComponent } from './complete-assessment/complete-assessment.component';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    RouterModule.forChild(AssessmentRoutes)
  ],
  declarations: [
    AssessmentComponent,
    ViewAssessmentComponent,
    CompleteAssessmentComponent
  ],
  providers: [AssessmentService,
    AssessmentsCustomLocalisations],
  entryComponents: []
})
export class AssessmentModule { }
