import { MainService } from './../../core/services/main.service';
import { TabsService } from './../../shared/components/tabs/tabs.service';
import { Component, OnInit } from '@angular/core';
import { ProfileTabNavigationData } from './user-profile-nav';

@Component({
  selector: 'vc-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  constructor(
    private readonly tabService: TabsService,
    private readonly profileTabNav: ProfileTabNavigationData,
    private readonly mainService: MainService) {
    this.tabService.tab(this.profileTabNav.profileTabNavigation);
   }

  ngOnInit() {
    this.mainService.showPlanBar();
  }
}
