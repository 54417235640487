import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'vc-job-organizations',
  templateUrl: './job-organizations.component.html',
  styleUrls: ['./job-organizations.component.scss', '../../content/content-card/content-card-colors.component.scss']
})
export class JobOrganizationsComponent implements OnInit {

  @Input() jobOrganization: any;

  constructor(private readonly router: Router) { }

  ngOnInit() {
  }

  goToSearch() {
    this.router.navigate([`/employment/search-result`], { queryParams: { orgIndustry: this.jobOrganization.name, sort: 'date,desc' }});
    window.scrollTo(0, 0);
  }

}
