import { Url } from './../../../../shared/constants/url-constants';
import { ICanDeactivate } from './../../../../core/guards/deactivate.interface';
import { WORK_EXPERIENCE_VALIDATION_CONTEXT } from './work-experience.validation';
import { ConfirmService } from './../../../../shared/components/confirm-box/confirm-box.service';
import { ValidationService } from './../../../../shared/components/form-control/validation.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MainService } from '../../../../core/services/main.service';
import { WorkExperienceService } from './work-experience.service';
import { Location, getLocaleDateFormat } from '@angular/common';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormArray, ValidatorFn, AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';

const VALIDATORS = {
  hasAddedValue: ValidationService.ifChecked
}

@Component({
  selector: 'vc-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss']
})
export class WorkExperienceComponent implements OnInit, ICanDeactivate, OnDestroy {

  form: FormGroup;
  data: any = [];
  revisedData: any = [];
  emptylist: boolean = false;
  isDialogOpen = false;
  routeTo = '/employment/cv-builder/guided/experience/work';
  model   = {option: ''};

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly main: MainService,
    private readonly workExperienceService: WorkExperienceService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly confirm: ConfirmService,
    private readonly validationService: ValidationService
  ) {
    this.form = new FormGroup({
      id: new FormControl(),
      hasAddedValue: new FormControl()
    });
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'CV builder';
    this.validationService.setContext(WORK_EXPERIENCE_VALIDATION_CONTEXT);
  }
  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }

  ngOnInit() {
    this.getdata();
  }

  getdata() {
    this.workExperienceService.getWorkExperienceInfo().subscribe(res => {
      this.data = res;
      console.log(res);
      this.form.get('hasAddedValue').setValue(this.data.hasAddedValue);
      this.revisedData = this.data.employments;
      if(this.revisedData.length === 0 ){
        this.model.option = 'N';
        this.form.get('hasAddedValue').setValue(true);
      }

      
    });
  }

  gotoPrev() {
    this.location.back();
  }

  cvSummaryPage() {
    this.router.navigate(['/employment/cv-builder/guided']);
  }

  canExit(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    if (this.form.dirty && nextState.url.indexOf(this.routeTo) !== 0) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => this.confirm.choose(true),
        reject: () => this.confirm.choose(false),
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  save() {
    this.setValidators(this.form, VALIDATORS);
    if (this.form.valid && this.revisedData.length === 0) {
      this.workExperienceService.saveWorkExperience(this.form.value).subscribe(res => {
        this.resetAllForms();
        this.router.navigate(['../education-home'], { relativeTo: this.route });
      });
    } else if (this.revisedData.length !== 0) {
      this.resetAllForms();
      this.router.navigate(['../education-home'], { relativeTo: this.route });
    }
    window.scrollTo(0, 0);
  }

  draft() {
    this.clearAllValidatorsAndErrors(this.form);
    if (this.form.valid && this.revisedData.length === 0) {
      this.workExperienceService.draftWorkExperience(this.form.value).subscribe(res => {
        this.resetAllForms();
        this.router.navigate(['../'], { relativeTo: this.route });
      });
    }
    window.scrollTo(0, 0);
  }

  resetAllForms() {
    this.form.markAsPristine();
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  addNewEmployment(type: string) {
    const isEmployed = this.form.get('hasAddedValue').value;
    if (this.revisedData.length === 0 && isEmployed) {
      this.form.get('hasAddedValue').markAsTouched();
      this.form.get('hasAddedValue').setErrors({ employmentCheck: true });
    } else {
      this.router.navigate([`./work/${type}`], { queryParams: {}, relativeTo: this.route });
    }
  }

  goToEditSection(jobId, jobType: string) {
    this.router.navigate(['./work/', jobType.toLowerCase()], { queryParams: { id: jobId }, relativeTo: this.route });
  }

  deleteEmployment(jobId) {
    this.confirm.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete the work experience?',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.isDialogOpen = false;
        this.workExperienceService.deleteEmployment(jobId).subscribe(res => {
          this.getdata();
        });
      },
      reject: () => {
        this.isDialogOpen = false;
      }
    });
    return this.confirm.navigateSelection;
  }
  setOption(option){
    this.model.option = option;
    if(option === 'N'){
    this.form.get('hasAddedValue').setValue(true);
    }
    else if(option === 'Y'){
      this.form.get('hasAddedValue').setValue(false);
    }
    
  }

}
