<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="education mt25">
    <div class="grid-row">
        <vc-all-errors [groups]="[educationForm]" [fromContext]="true"></vc-all-errors>
        <form [formGroup]="educationForm" autocomplete="off">
            
            <div class="column-full">
				<vc-form-control [control]="educationForm.get('educationType')" [controlName]="'educationType'"
					[label]="'Education type'" [fromContext]="true" >
					<input formControlName="educationType" class="form-control" type="text" id="id-educationType"
						name="educationType" aria-describedby="id-section-help-educationType id-section-error-educationType" list="educationTypes" >
				</vc-form-control>
				<datalist id="educationTypes">
					<option *ngFor="let type of educationTypes" value="{{type.description}}">{{type.description}}</option>
				</datalist>
            </div>
            
            <div class="column-full" *ngIf="educationForm.get('otherEducationType')">
                <vc-form-control [control]="educationForm.get('otherEducationType')"
                    [controlName]="'otherEducationType'" [label]="'Please specify other education type'" [fromContext]="true">
                    <textarea formControlName="otherEducationType" rows="5" class="form-control" type="text"
                        id="id-otherEducationType" name="otherEducationType"
                        aria-describedby="id-section-help-otherEducationType id-section-error-otherEducationType"
                        [ngClass]="{'errorfocus': educationForm.get('otherEducationType')?.errors }">
					</textarea>

                    <div *ngIf="educationForm.get('otherEducationType')?.value?.length <= 200"
                        class="form-label form-label-count">You
                        have
                        {{200 - educationForm.get('otherEducationType')?.value?.length}} characters remaining</div>
                    <div *ngIf="educationForm.get('otherEducationType')?.value?.length === 201" class="error-message">
                        You have
                        {{ educationForm.get('otherEducationType')?.value?.length - 200}} character too many</div>
                    <div *ngIf="educationForm.get('otherEducationType')?.value?.length > 201" class="error-message">You
                        have
                        {{ educationForm.get('otherEducationType')?.value?.length - 200}} characters too many</div>
                </vc-form-control>
            </div>
            <div class="column-full">
                <vc-form-control [control]="educationForm.get('institute')" [controlName]="'institute'"
                    [label]="'School, college or university name'" [fromContext]="true">
                    <input formControlName="institute" class="form-control" type="text" id="id-institute" name="institute" value=""
              aria-describedby="id-section-help-institute id-section-error-institute">
                </vc-form-control>
            </div>
            <div class="column-full">
                <vc-form-control [control]="educationForm.get('courseTitle')" [controlName]="'courseTitle'"
                    [label]="'Course title'" [fromContext]="true">
                    <input formControlName="courseTitle" class="form-control" type="text" id="id-courseTitle" name="courseTitle" value=""
              aria-describedby="id-section-help-courseTitle id-section-error-courseTitle">
                </vc-form-control>
            </div>
            <div class="column-full">
                <vc-form-control [control]="educationForm.get('grade')" [controlName]="'grade'" [label]="'Grade'"
                    [fromContext]="true" [optional]="'(optional)'">
                    <input formControlName="grade" class="form-control" type="text" id="id-grade" name="grade" value=""
              aria-describedby="id-section-help-grade id-section-error-grade">
                </vc-form-control>
            </div>
            <div class="column-full">
                <vc-month-form-control [control]="educationForm.get('courseStartDate')" [controlName]="'courseStartDate'"
                    id="id-courseStartDate" [label]="'Start date'" [fromContext]="true">
                </vc-month-form-control>
            </div>
            <div class="column-full">
                <vc-month-form-control [control]="educationForm.get('courseEndDate')" [controlName]="'courseEndDate'"
                    id="id-courseEndDate" [label]="'End date'" [fromContext]="true">
                </vc-month-form-control>
            </div>
            <div class="column-full">
                <vc-form-control [control]="educationForm.get('isStudying')" [controlName]="'isStudying'"
                    [fromContext]="true">
                    <div class="checkbox-wrapper">
                        <input id="id-isStudying-check" name="isStudying" type="checkbox" formControlName="isStudying">
                        <label class="form-label-b19" for="id-isStudying-check">
                            Currently studying
                        </label>
                    </div>
                </vc-form-control>
            </div>

        </form>
        <div class="column-full">
            <vc-form-control [control]="createAnother" [controlName]="'createAnother'"
                [label]="'Do you want to add more educational qualifications?'" [fromContext]="true">
                <div class="radio-wrapper">
                    <input type="radio" [formControl]="createAnother" value="Y" id="id-createAnother-true"
                        name="createAnother"
                        aria-describedby="id-section-help-createAnother-true id-section-error-createAnother-true" />
                    <label for="id-createAnother-true">Yes</label>
                </div>
                <div class="radio-wrapper">
                    <input type="radio" [formControl]="createAnother" value="N" id="id-createAnother-false"
                        name="createAnother"
                        aria-describedby="id-section-help-createAnother-false id-section-error-createAnother-false" />
                    <label for="id-createAnother-false">No</label>
                </div>
            </vc-form-control>
        </div>
    </div>
    <div class="grid-row mb25">
        <button id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
        (click)="saveAndContinue()">Save and
        continue</button>
        <button id="id-save-and-come-back-later" class="button button-secondary mr15" type="button"
            name="save-and-come-back-later" (click)="saveAndLater()">Save and come back
            later</button>
        <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
            (click)="cvSummaryPage()">CV summary</button>
    </div>
</div>