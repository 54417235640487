import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, DoCheck } from '@angular/core';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { IResult } from './search-result.interface';

@Component({
  selector: 'vc-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  providers: [HttpUrlEncodingCodec]
})
export class SearchResultComponent implements OnInit, OnChanges {

  @Input() resultBlock: any;
  @Input() totalCount: number;

  @Output() loadMore: EventEmitter<string> = new EventEmitter();

  searchResults: IResult[] = [];

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resultBlock && this.resultBlock.data) {

      if (this.resultBlock.state === 'initial') {
        this.searchResults = [];
      }
      if (this.resultBlock.data.length > 0) {
        this.resultBlock.data.forEach(item => this.searchResults.push(item));
      }
    }
  }

  loadMoreResult() {
    this.loadMore.emit(this.searchResults.length.toString());
  }

  restResult() {
    this.searchResults.length = 0;
    this.searchResults = Object.assign([]);
  }

  isLoadMoreVisible() {
    return (this.searchResults && (this.searchResults.length > 0 && this.searchResults.length < this.totalCount));
  }

}
