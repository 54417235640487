import { NgModule } from '@angular/core';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { CoreModule } from '../core/core.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { HttpClientModule } from '@angular/common/http';
import { UserActivityComponent } from './user-activity/user-activity.component';
import { UserActivityService } from './user-activity/user-activity.service';
import { ConfirmBoxModule } from '../shared/components/confirm-box/confirm-box.module';
import { TogetherModeModalModule } from '../shared/components/together-mode-modal/together-mode-modal.module';
import { ContentUnavailableModule } from '../shared/components/content-unavailable/content-unavailable.module';
import { FileUtilModule } from '../shared/components/file-util/file-util.module';


@NgModule({
  declarations: [
    HomeComponent,
    UserActivityComponent
  ],
  imports: [
    HomeRoutingModule,
    HttpClientModule,
    CoreModule,
    ContentUnavailableModule, 
    ConfirmBoxModule,
    TogetherModeModalModule,
    NgHttpLoaderModule.forRoot(),
    FileUtilModule,
  ],
  providers: [
    UserActivityService,
  ]
})
export class HomeModule { }
