export const FILE_VALIDATORS_CONTEXT = {
    required: {
        file: "Select a file to upload",
        fileName: "File name cannot be blank"
    },
    maxFileSize: {
        file: 'This file is too large to upload. The maximum supported file size is 16MB'
    },
    maxlength: {
        description: 'Please enter 100 or less characters in description',
        fileName: 'Please enter 30 or less characters in file name'
    },
    pattern: {
        fileName: 'File name must not contain following characters < > : " / \\ | ? \*',
    }
}
