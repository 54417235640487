import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmploymentService {
    
  constructor(private readonly http: HttpClient) { }

  findLatestJobs() {
    const href = `${Url.JOBS}/search`;
    return this.http.get<any>(href);
  }

  getJobOrganizations() {
    const href = `${Url.JOBS}/ref-data/organizations`;
    return this.http.get<any>(href);
  }

  getJobTitles(value) {
    const href = `${Url.JOBS}/ref-data/what/` + value;
    return this.http.get<any>(href);
  }

  getJobLocation(value) {
    const href = `${Url.JOBS}/ref-data/where/` + value;
    return this.http.get<any>(href);
  }

  searchJobs(data, size, sort, page) {
    const href = `${Url.JOBS}/search?page=${page}&size=${size}&sort=${sort}&sort=_score,desc`;
    return this.http.post<any>(href, data);
  }

  addToFavJobs(jobId) {
    const href = `${Url.JOBS}/job-user/favourites/${jobId}`;
    return this.http.post<any>(href, jobId);
  }
  getJobDetails(id: string) {
    const href = `${Url.JOBS}/jobs/${id}`;
    return this.http.get<any>(href);
  }
  getSimilarJobs(data) {
    const href = `${Url.JOBS}/search/similar`;
    return this.http.post<any>(href, data);
  }

  applyForJobs(payLoad: any) {
    const href = `${Url.JOBS}/job-user/applications`;
    return this.http.post<any>(href, payLoad);
  }

  getFavJobList(page, size, sort) {
    const href = `${Url.JOBS}/job-user/favourites`;
    return this.http.get<any>(href, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  deleteFavJob(jobId) {
    const href = `${Url.JOBS}/job-user/favourites/${jobId}`;
    return this.http.delete<any>(href);
  }

  getAppliedJobList(page, size, sort) {
    const href = `${Url.JOBS}/job-user/applications`;
    return this.http.get<any>(href, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  removeJobApplication(jobId: string) {
    const href = `${Url.JOBS}/job-user/applications/${jobId}`;
    return this.http.delete<any>(href);
  }

  deleteAppliedJob(jobId) {
    const href = `${Url.JOBS}/job-user/applications/${jobId}`;
    return this.http.delete<any>(href);
  }

  getAllKws() {
    const href = `${Url.JOBS}/job-user/key-workers`;
    return this.http.get<any[]>(href);
  }

  getAdvertPrisonJobDetails(jobId) {
    const href = `${Url.JOBS}/job-adverts/prisons/${jobId}`;
    return this.http.get<any>(href);
  }

  getPrisonJobList(page, size, sort, hideInActive) {
    const href = `${Url.JOBS}/job-adverts/prisons`;
    return this.http.get<any>(href, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
        .set('hideInActive', hideInActive)
    });
  }

  getAdvertNfnJobDetails(jobId: any) {
    const href = `${Url.JOBS}/job-adverts/nfn/${jobId}`;
    return this.http.get<any>(href);
  }
  
  getNFNJobLocation() {
    const href = `${Url.JOBS}/job-adverts/ref-data/nfn-locations`;
    return this.http.get<any>(href);
  }

  getNFNJobList(page, size, sort, hideInActive) {
    const href = `${Url.JOBS}/job-adverts/nfn`;
    return this.http.get<any>(href, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
        .set('hideInActive', hideInActive)
    });
  }

  searchNFNJobs(data, page, size, sort, hideInActive) {
    const href = `${Url.JOBS}/job-adverts/nfn/search`;
    return this.http.post<any>(href, data,  {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
        .set('hideInActive', hideInActive)
    });
  }

  getFeaturedNFNJobs() {
    const href = `${Url.JOBS}/job-adverts/nfn/recommendations`;
    return this.http.get<any>(href);
  }

  getInterestedPrisonJobList(page: any, size: any, sort: any) {
    const href = `${Url.JOBS}/express-interest/prison`;
    return this.http.get<any>(href, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  getInterestedNFNJobList(page: any, size: any, sort: any) {
    const href = `${Url.JOBS}/express-interest/nfn`;
    return this.http.get<any>(href, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  expressInterestNFN(payLoad) {
    const href = `${Url.JOBS}/express-interest/nfn`;
    return this.http.post<any>(href, payLoad);
  }

  expressInterestPrison(jobId: any) {
    const href = `${Url.JOBS}/express-interest/prison/${jobId}`;
    return this.http.post<any>(href, jobId);
  }

  getAdvertPrisonJobStatus(jobId: any) {
    const href = `${Url.JOBS}/express-interest/prison/status/${jobId}`;
    return this.http.get<any>(href);
   }

   getAdvertNFNJobStatus(jobId: any) {
    const href = `${Url.JOBS}/express-interest/nfn/status/${jobId}`;
    return this.http.get<any>(href);
   }

}
