<ng-content></ng-content>
<div class="grid-row result-container">
        <div class="column-half" *ngFor="let item of searchResults">
                <div class="result-card">
                        <vc-result-card [item]="item"></vc-result-card>
                </div>
        </div>
</div>
<div class="grid-row column-full">
        <a class="link right" (click)="loadMoreResult()" *ngIf="isLoadMoreVisible()">See More</a>
</div>