import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './content.component';
import { ContentCategoryComponent } from './content-category/content-category.component';
import { ContentDetailsComponent } from './content-details/content-details.component';
import { ContentSearchComponent } from './content-search/content-search.component';
import { MyContentComponent } from './my-content/my-content.component';

const routes: Routes = [
  {
    path: '',
    component: ContentComponent,
  },
  {
    path: 'category/:categoryColor/:categoryName/:categoryId',
    component: ContentCategoryComponent,
  },
  {
    path: 'course/:courseTitle/:courseId',
    component: ContentDetailsComponent
  },
  {
    path: 'search',
    data: { title: 'Search Content' },
    component: ContentSearchComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
