import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentService } from './content.service';
import { ICourse, ICategory } from './content.interface';
import { MainService } from '../../core/services/main.service';
import { Utility } from '../../shared/utils/utility';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vc-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  reccomendedCourses: ICourse[] = [];
  categories: any[] = [];
  ifCourses: boolean;
  mainCategory: any = [];
  recomCategory: any = [];

  constructor(
    private readonly contentService: ContentService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly mainService: MainService,
    private readonly spinner: SpinnerVisibilityService,
    private sanitizer: DomSanitizer
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
  }

  ngOnInit() {
    this.mainService.showPlanBar();
    this.resolveReccomendedCourses();
    this.resolveCategories();
  }

  resolveCategories() {
    this.spinner.show();
    this.contentService.getCategories().subscribe(data => {
      data.forEach(async cat => {
        const attachment = await cat.getAttachment(cat.id);
        const category = cat.toJSON();
        if(attachment) {
          const data = await attachment.getData();
          const blobUrl = URL.createObjectURL(data)
          category.categoryImgUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);  
        }
        this.mainCategory.push(category);
        this.categories.push(category);
      })
      this.spinner.hide();
    });
  }

  resolveReccomendedCourses() {
    this.spinner.show();
    this.contentService.getReccomendations(0).subscribe((data:any) => {
      data.forEach(async o => {
        const attachment = await o.getAttachment(o.contentID);
        const obj = o.toJSON();
        if(attachment) {
          const data = await attachment.getData();
          const blobUrl = URL.createObjectURL(data)
          obj.imagePath = this.sanitizer.bypassSecurityTrustUrl(blobUrl);  
        }
        this.recomCategory.push(obj);
        this.reccomendedCourses.push(obj);
      })
    });
    this.ifCourses = true;
    this.spinner.hide();
  }

  showCategoryDetails(item: ICategory) {
    this.router.navigate([`/content/category/${item.categoryColor}/${item.categoryTitle}/${item.id}`]);
  }

  showCourseDetails(course) {
    this.router.navigate([`/content/course/${course.title}/${course.contentID}`]);
  }

}
