import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationService } from './../../../../shared/components/form-control/validation.service';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { INDUCTION_QUESTIONS_VALIDATION_CONTEXT } from './../induction-questions-list.validation';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from "../../../../shared/components/confirm-box/confirm-box.service";

const PAGETHREEFORM_VALIDATORS = {
  englishPrimary: [Validators.required]
}
const PAGETHREEFORM_VALIDATORS_OTHER_LANGUAGES = {
  otherLanguages: [Validators.required]
}
const PAGETHREEFORM_VALIDATORS_WHICH_OTHER_LANGUAGES = {
  languages: [ValidationService.minInserted(1)],
}

const PAGETHREEFORM_VALIDATORS_WHICH_OTHER_LANGUAGES_ADDED = {
  languages: [ValidationService.maxInserted(0)],
}

@Component({
  selector: 'vc-page-three',
  templateUrl: './page-three.component.html',
  styleUrls: ['./page-three.component.scss']
})
export class PageThreeComponent implements OnInit, AfterViewChecked {
  currentPageNumber: number;
  currentPageData = [] as any;
  refData = [] as any;
  routeState: any;
  pageThreeForm: FormGroup;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-two';
  routeNext = '/plan/induction/page-four';
  @ViewChild("question") questionDiv: ElementRef;
  isAssessmentInProgressFlag = false;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly validation: ValidationService,
    private readonly renderer: Renderer2,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly togetherMode: TogetherModeModalService,
    private readonly confirm: ConfirmService,
  ) {
    this.initForm();
    this.validation.setContext(INDUCTION_QUESTIONS_VALIDATION_CONTEXT);
  }

  ngOnInit() {
    this.currentPageNumber = 3;
    this.getRefData();
    this.resolveRouteState();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageThreeForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  initForm() {
    this.pageThreeForm = this.formBuilder.group({
      englishPrimary: this.formBuilder.control(''),
      otherLanguages: this.formBuilder.control(''),
      languages: this.formBuilder.array([])
    })
  }

  defineAndSetValidators() {
    const isEnglishPrimaryYes = this.pageThreeForm.controls.englishPrimary.value === '1';
    const isEnglishPrimaryNo = this.pageThreeForm.controls.englishPrimary.value === '2';
    const isOtherLanguagesYes = this.pageThreeForm.controls.otherLanguages.value === '1';

    if (isEnglishPrimaryYes && isOtherLanguagesYes) {
      this.setValidators(this.pageThreeForm, PAGETHREEFORM_VALIDATORS_WHICH_OTHER_LANGUAGES);
    } else if (isEnglishPrimaryYes) {
      this.setValidators(this.pageThreeForm, PAGETHREEFORM_VALIDATORS_OTHER_LANGUAGES);
    } else if (isEnglishPrimaryNo) {
      this.setValidators(this.pageThreeForm, PAGETHREEFORM_VALIDATORS_WHICH_OTHER_LANGUAGES);
    } else {
      this.setValidators(this.pageThreeForm, PAGETHREEFORM_VALIDATORS);
    }
  }

  canExit(route, state, nextState) {
    if (this.pageThreeForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  onClickContinue() {
    this.defineAndSetValidators();
    if (this.pageThreeForm.valid) {
      const formattedPayload = this.formatPayload(false);
      this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
        this.isSaveSubmitOperation = true;
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-four');
        window.scrollTo(0, 0);
      })
    } else {
      this.setFocusToErrorSummary();
    }
  }

  onClickSave() {
    const formattedPayload = this.formatPayload(true);
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      this.isSaveSubmitOperation = true;
      this.router.navigateByUrl(this.routeTo);
      window.scrollTo(0, 0);
    })
  }

  formatPayload(saveAsDraft) {
    if (this.pageThreeForm.controls.englishPrimary.value !== '' 
        && this.pageThreeForm.controls.otherLanguages.value === '' 
        && this.pageThreeForm.controls.languages.value === '') {
      return {
        "isSaveAsDraft": saveAsDraft,
        "planTypeIdentifier": "1",
        "questionDTOList": [
          {
            "answer": this.pageThreeForm.controls.englishPrimary.value,
            "id": 5
          },
        ],
        "sectionIdentifier": this.currentPageData[0].sectionIdentifier
      }
    } else if (this.pageThreeForm.controls.englishPrimary.value === '1' && this.pageThreeForm.controls.otherLanguages.value === '2') {
      return {
        "isSaveAsDraft": saveAsDraft,
        "planTypeIdentifier": "1",
        "questionDTOList": [
          {
            "answer": this.pageThreeForm.controls.englishPrimary.value,
            "id": 5
          },
          {
            "answer": this.pageThreeForm.controls.otherLanguages.value,
            "id": 7
          },
        ],
        "sectionIdentifier": this.currentPageData[0].sectionIdentifier
      }
    } else if (this.pageThreeForm.controls.englishPrimary.value === '2' 
            && this.pageThreeForm.controls.languages.value !== '') {
      return {
        "isSaveAsDraft": saveAsDraft,
        "planTypeIdentifier": "1",
        "questionDTOList": [
          {
            "answer": this.pageThreeForm.controls.englishPrimary.value,
            "id": 5
          },
          {
            "answer": JSON.stringify({
              choiceIds: this.resolveAnswersValues(this.pageThreeForm.controls.languages.value),
             // Other: null
            }),
            "id": 6
          },
        ],
        "sectionIdentifier": this.currentPageData[0].sectionIdentifier
      }
    }else {
      return {
        "isSaveAsDraft": saveAsDraft,
        "planTypeIdentifier": "1",
        "questionDTOList": [
          {
            "answer": this.pageThreeForm.controls.englishPrimary.value,
            "id": 5
          },
          {
            "answer": JSON.stringify({
              choiceIds: this.resolveAnswersValues(this.pageThreeForm.controls.languages.value),
             // Other: null
            }),
            "id": 6
          },
          {
            "answer": this.pageThreeForm.controls.otherLanguages.value,
            "id": 7
          },
        ],
        "sectionIdentifier": this.currentPageData[0].sectionIdentifier
      }
    }
  }

  resolveAnswersValues(languagesArr) {
    const answersValuesArr = [];
    languagesArr.forEach(language => {
      const filteredChoiceListArr = this.refData.domainList[2].choiceList.filter(choice => choice.choiceDescription === language);
      const choiceList = filteredChoiceListArr[0];
      answersValuesArr.push(choiceList.choiceId);
    })
    return answersValuesArr;
  }

  resolveAnswersNames(languagesValuesArr) {
    const answersNamesArr = [];
    languagesValuesArr.forEach(languageId => {
      const filteredChoiceListArr = this.refData.domainList[2].choiceList.filter(choice => choice.choiceId === languageId);
      const choiceList = filteredChoiceListArr[0];
      answersNamesArr.push(choiceList.choiceDescription);
    })
    return answersNamesArr;
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          }
        )
      });
      this.setAnswers(data);
      this.setFocusToQuestionDiv();
    })
  }

  setAnswers(data) {
    this.pageThreeForm.controls.englishPrimary.setValue(data[0].answer ? data[0].answer : '');
    this.pageThreeForm.controls.otherLanguages.setValue(data[2].answer && data[0].answer !== '2' ? data[2].answer : '');
    if (this.shouldResolveLanguagesAnswer(data[0].answer, data[2].answer)) {
      this.resolveLanguagesAnswer(data[1].answer);
    } else {
      this.pageThreeForm.controls.languages.setValue(new FormArray([]));
    }
  }

  shouldResolveLanguagesAnswer(firstAnswer, secondAnswer) {
    if ((firstAnswer === '1' && secondAnswer === '1') || firstAnswer === '2') {
      return true
    } else {
      return false
    }
  }

  resolveLanguagesAnswer(answerString) {
    const parsedAnswer = JSON.parse(answerString);
    const answerChoiceIds = parsedAnswer.choiceIds;
    if (answerChoiceIds.length > 0) {
      const languagesNamesArr = this.resolveAnswersNames(answerChoiceIds);
      languagesNamesArr.forEach(language => (this.pageThreeForm.get('languages') as FormArray).push(this.formBuilder.control(language)))
    }
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  setFocusToQuestionDiv() {
    this.questionDiv.nativeElement.focus();
  }

  checkForOtherLanguages() {
    const formArray = this.pageThreeForm.get('languages') as FormArray;
    if (this.pageThreeForm.value.englishPrimary === '1' && this.pageThreeForm.value.otherLanguages === '1' && formArray.length > 0) {
      this.pageThreeForm.controls.otherLanguages.setValue('1');
      this.setValidators(this.pageThreeForm, PAGETHREEFORM_VALIDATORS_WHICH_OTHER_LANGUAGES_ADDED);
      this.setFocusToErrorSummary();
    }
    if (this.pageThreeForm.value.englishPrimary === '2' && formArray.length > 0) {
      this.pageThreeForm.controls.englishPrimary.setValue('2');
      this.setValidators(this.pageThreeForm, PAGETHREEFORM_VALIDATORS_WHICH_OTHER_LANGUAGES_ADDED);
      this.setFocusToErrorSummary();
    }
  }

  resetValidators() {
    if (this.pageThreeForm.value.languages.length === 0) {
      this.clearAllValidatorsAndErrors(this.pageThreeForm);
      this.setValidators(this.pageThreeForm, PAGETHREEFORM_VALIDATORS);
    }
  }

}
