import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

function coerceToBoolean(input: string | boolean): boolean {
  return !!input && input !== 'false';
}

/**
 * The default pagination controls component. Actually just a default implementation of a custom template.
 */
@Component({
  selector: 'pagination-controls',
  templateUrl: './pagination-controls.component.html',
  styleUrls: ['./pagination-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PaginationControlsComponent {

  @Input() id: string;
  @Input() maxSize: number = 7;
  @Input() previousLabel: string = 'previous';
  @Input() nextLabel: string = 'next';
  @Input() screenReaderPaginationLabel: string = 'Pagination';
  @Input() screenReaderPageLabel: string = 'page';
  @Input() screenReaderCurrentLabel: string = `You're on page`;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() get directionLinks(): boolean {
    return this._directionLinks;
  }

  set directionLinks(value: boolean) {
    this._directionLinks = coerceToBoolean(value);
  }

  @Input() get autoHide(): boolean {
    return this._autoHide;
  }

  set autoHide(value: boolean) {
    this._autoHide = coerceToBoolean(value);
  }

  @Input() get responsive(): boolean {
    return this._responsive;
  }

  set responsive(value: boolean) {
    this._responsive = coerceToBoolean(value);
  }

  private _directionLinks: boolean = true;
  private _autoHide: boolean = false;
  private _responsive: boolean = false;

}
