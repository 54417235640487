<a href="javascript:void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<h2 class="heading-large">Content</h2>
<div *ngIf="contents" class="grid-row mt25">
    <div class="column-half" *ngFor="let item of contents; let index;">
        <div class="result-card">
            <a class="title link"  [routerLink]="['/content', 'course', item.title, item.contentID]">{{item.title | truncate:[35, '...']}}</a>
            <p class="description">
                <figure class="result-card-img-wrapper right">
                    <img src="{{item.imagePath}}">
                    <figcaption></figcaption>
                </figure>
                {{item.description | truncate:[175, '...'] }}
            </p>
            <div class="actions">
                <button title="Open" id="button-add-to-plan" class="button button-secondary" (click)="showCourseDetails(item)">Open</button>
                <div class="heart-favourite-container">
                    <a class="heart-favourite-true" aria-hidden="true" title='Remove from my content' (click)="showPopup(item)"></a>
                    <a class="favourite-link" title="Remove from my content" (click)="showPopup(item)">Remove from my content</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="column-full" *ngIf="contents?.length === 0">
    <p>You haven't added any content to your wallet.</p>
</div>
