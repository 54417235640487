<header role="banner" id="header" class="header full-width-container addtional-height">

  <div class="grid-row site-width-container header-container">

    <div class="header-logo">
      <a href="javascript:void(0)" title="Virtual Campus" class="header-link-home">
        <span class="header-logotype">
          <img src="./assets/images/logotype1.png" alt="VC2go">
        </span>
        <span class="header-logotype-text">
          VC2go
        </span>
      </a>
      <!-- <ng-container *ngIf="serviceUserLoggedIn" class="full-height">
        <a *ngIf="!togetherModeModalService.getTogetherModeEnabledStatus()" href="javascript:void(0)"
          (click)="openTogetherModeModal()" id="header-navigation-enter-together-mode" title="Enter Together mode"
          attr.aria-label="Enter Together mode" class="enter-together-mode-link">Together mode
        </a>
        <span *ngIf="togetherModeModalService.getTogetherModeEnabledStatus()" class="exit-together-mode-container">
          <img [src]="togetherModeIconSrc" class="together-mode-icon" (click)="onExitTogetherMode()"
            title="Exit Together mode" alt="Two hands shaking - icon representing together mode is enabled">
          <a href="javascript:void(0)" (click)="onExitTogetherMode()" id="header-navigation-exit-together-mode"
            title="Exit Together mode" attr.aria-label="Exit Together mode">Exit Together mode
          </a>
        </span>
      </ng-container> -->
    </div>
    <div class="column-half navigation-overlay" [ngClass]="{'navigation-overlay-show': isMobileMenuExpanded}"
      (click)="onMenuCloseIconClick()">
      <button *vcShowAuthed="null" (click)="onMenuCloseIconClick()" class="button button-icon header-button-close"
        id="header-button-close" role="button" title="Close menu" attr.aria-label="Close menu">
        <span class="icon-close"></span>
      </button>
    </div>
    <div>
      <nav class="header-navigation-container" role="navigation">
        <ul class="column-half rightNav">
          <li routerLinkActive="active" routerLink="/user-profile">
            <a routerLink="/user-profile" id="header-navigation-profile" class="link1 vcShowAuthed"
              title="Go to profile" attr.aria-label="Go to Profile">Profile
            </a>
          </li>
          <li routerLinkActive="active">
            <a href="#" [routerLink]="['/sign-out']" class="link2 vcShowAuthed" id="header-navigation-signout"
              title="Go to signout" attr.aria-label="Go to sign out" [ngClass]="{'offlineMode': noInternetConnection}">Sign out
            </a>
          </li>
          <li>
            <div class="column-one-half nomarginbottom vcShowAuthed">
              <form (submit)="onSearch()" *ngIf="showSearch" class="formstyle">
                <div class="border-box relative">
                  <input type="search" class="form-control form-control-1-1 search-input" [(ngModel)]="keyword"
                    placeholder="Search for content" name="keyword" autocomplete="off" />
                  <button type="button" class="search-icon-align" [ngClass]="{'searchPosition': keyword !=''}"
                    (click)="onSearch()">Search</button>
                </div>
              </form>
            </div>
          </li>
        </ul>
        <button *vcShowAuthed="null" (click)="onMenuIconClick()" class="button button-icon header-button-menu"
          id="header-button-menu" role="button" title="Show menu" attr.aria-label="Show menu">
          <span class="icon-menu"></span>
        </button>
      </nav>
    </div>

  </div>



  <div class=" site-width-container menu vcShowAuthed">
    <nav id="header-navigation-container" class="header-navigation-container header-navigation-container-background"
      role="navigation">
      <ul>
        <ng-container *ngFor="let item of navigation | async">
          <li routerLinkActive="active" routerLink="/{{item.state}}" class="header-list">
            <a class="navigation-item vcShowAuthed"
              [ngClass]="{'navigation-right' : item.positionRight}" id="header-navigation-{{item.id}}"
              title="Go to {{ item.name }}" attr.aria-label="Go to {{ item.name }}">{{ item.name }}
            </a>
            <span *ngIf="walletFlag" class="notificationBadge">!</span>
          </li>
        </ng-container>
        <!-- <li routerLinkActive="active" [ngClass]="getRoute(router)" class="smDiv" tabindex="0" (click)="$event.stopPropagation(); showChat()">
          <div *vcShowAuthed="true" class="chat-container">
            <a class="chat-icon" id="chat_link">
              <button #chatpopup3 type="button" id="chat_button" title="Secure messaging"
                class="btn" data-target="data">
                <span #chatpopup2 class="chat-text">Secure messaging</span>
                <div #chatpopup1 class="chat-div"> </div>
              </button>
            </a>
            <div>
              <vc-chat (clickedChat)="clickedChat($event)" [showPanel]="isChatWindow"></vc-chat>
            </div>
          </div>
          <span *ngIf="chatCount > 0" class="chatBadge" [title]="chatCount">{{chatCount}}</span>
        </li> -->

      </ul>
    </nav>
  </div>
</header>