import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentRoutingModule } from './document-routing.module';
import { DocumentComponent } from './document.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlModule } from '../../shared/components/form-control/form-control.module';
import { PipesModule } from './../../core/pipes/pipes.module';
import { DocumentService } from './document.service';


@NgModule({
  declarations: [
    DocumentComponent
  ],
  imports: [
    CommonModule,
    DocumentRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlModule,
    PipesModule
  ],
  providers: [DocumentService]
})
export class DocumentModule { }
