import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MainService } from '../../../../../core/services/main.service';
import { EditWorkExperienceService } from './edit-work-experience.service';

@Component({
  selector: 'vc-edit-work-experience',
  templateUrl: './edit-work-experience.component.html',
  styleUrls: ['./edit-work-experience.component.scss'],
  providers: [EditWorkExperienceService]
})
export class EditWorkExperienceComponent implements OnInit {

  forms: FormGroup[] = [];

  constructor(
    private readonly main: MainService,
    private readonly route: ActivatedRoute,
    private readonly editWEService: EditWorkExperienceService,
    private readonly location: Location,
  ) {
    this.main.pageTitle = this.route.snapshot.data.title;
    this.editWEService.formGroupsSubs
      .subscribe( (forms: FormGroup[]) => {
        this.forms = forms;
      });
  }

  ngOnInit() { }

  gotoPrev() {
    this.location.back();
  }

}
