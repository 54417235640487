<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
    <p class="number-of-pages">Page 5 of 13</p>
    <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>
    <form [formGroup]="pageFiveForm" autocomplete="off" (submit)="onClickContinue()">
        <vc-form-control [control]="pageFiveForm.get('diagnosed')" [controlName]="'diagnosed'" [fromContext]="true">
            <div class="two-radio-container" *ngIf="currentPageData[0]">
                <div class="radio-wrapper mr20">
                    <input type="radio" [formControl]="pageFiveForm.get('diagnosed')"
                        [value]="currentPageData[0]?.answersValues[0]" id="id-diagnosed" name="diagnosed"
                        formControlName="diagnosed" (change)="onDiagnosedChange(currentPageData[0]?.answersValues[0])">
                    <label class="radio-label" for="id-diagnosed">{{currentPageData[0]?.answersLabels[0]}}</label>
                </div>
                <div class="radio-wrapper">
                    <input type="radio" [formControl]="pageFiveForm.get('diagnosed')"
                        [value]="currentPageData[0]?.answersValues[1]"
                        id="id-diagnosed-{{currentPageData[0]?.answersLabels[1]}}" name="diagnosed"
                        formControlName="diagnosed" (change)="onDiagnosedChange(currentPageData[0]?.answersValues[1])">
                    <label class="radio-label"
                        for="id-diagnosed-{{currentPageData[0]?.answersLabels[1]}}">{{currentPageData[0]?.answersLabels[1]}}</label>
                </div>
            </div>
        </vc-form-control>
        <ng-container *ngIf="this.pageFiveForm.value.diagnosed === '1'">
            <div *ngIf="educationList.length !=='0'">
                <vc-plan-education [educationDetails]="educationList" (refreshList)="getEducationPlanList()"(refreshFormFields)="initForm()" ></vc-plan-education>
            </div>
            <div class="radio-conditional">
                <vc-form-control [control]="pageFiveForm.get('lldDetails')" [controlName]="'lldDetails'"
                    [label]="currentPageData[1]?.question" [fromContext]="true">
                    <div class="education mt25">
                        <div class="grid-row">
                            <legend class="question" *ngIf ="!isEditEducationClicked">Add Education</legend>
                            <legend class="question" *ngIf ="isEditEducationClicked">Edit Education</legend>
                            <vc-all-errors [groups]="[educationForm]" [fromContext]="true"></vc-all-errors>
                            <form [formGroup]="educationForm" autocomplete="off" >

                                <div class="column-full">
                                    <vc-form-control [control]="educationForm.get('educationType')"
                                        [controlName]="'educationType'" [label]="'Education type'" [fromContext]="true">
                                        <!-- <input type="hidden" formControlName="educationType" /> -->
                                        <input class="form-control" type="text" [formControl]="educationForm.get('educationType')"
                                            id="id-educationType" name="educationType" formControlName="educationType"
                                            aria-describedby="id-section-help-educationType id-section-error-educationType"
                                            list="educationTypes">
                                    </vc-form-control>
                                    <datalist id="educationTypes">
                                        <option *ngFor="let type of educationTypes" value="{{type.educationType}}">
                                    </datalist>
                                </div>

                                <div class="column-full" *ngIf="educationForm.get('otherEducationType')">
                                    <vc-form-control [control]="educationForm.get('otherEducationType')"
                                        [controlName]="'otherEducationType'"
                                        [label]="'Please specify other education type'" [fromContext]="true">
                                        <textarea formControlName="otherEducationType" rows="5" class="form-control"
                                            type="text" id="id-otherEducationType" name="otherEducationType"
                                            aria-describedby="id-section-help-otherEducationType id-section-error-otherEducationType"
                                            [ngClass]="{'errorfocus': educationForm.get('otherEducationType')?.errors }">
                            </textarea>

                                        <div *ngIf="educationForm.get('otherEducationType')?.value?.length <= 200"
                                            class="form-label form-label-count">You
                                            have
                                            {{200 - educationForm.get('otherEducationType')?.value?.length}} characters
                                            remaining</div>
                                        <div *ngIf="educationForm.get('otherEducationType')?.value?.length === 201"
                                            class="error-message">
                                            You have
                                            {{ educationForm.get('otherEducationType')?.value?.length - 200}} character
                                            too many</div>
                                        <div *ngIf="educationForm.get('otherEducationType')?.value?.length > 201"
                                            class="error-message">You
                                            have
                                            {{ educationForm.get('otherEducationType')?.value?.length - 200}} characters
                                            too many</div>
                                    </vc-form-control>
                                </div>
                                <div class="column-full">
                                    <vc-form-control [control]="educationForm.get('institute')"
                                        [controlName]="'institute'" [label]="'School, college or university name'"
                                        [fromContext]="true">
                                        <input formControlName="institute" class="form-control" type="text"
                                            id="id-institute" name="institute" value=""
                                            aria-describedby="id-section-help-institute id-section-error-institute">
                                    </vc-form-control>
                                </div>
                                <div class="column-full">
                                    <vc-form-control [control]="educationForm.get('courseTitle')"
                                        [controlName]="'courseTitle'" [label]="'Course title'" [fromContext]="true">
                                        <input formControlName="courseTitle" class="form-control" type="text"
                                            id="id-courseTitle" name="courseTitle" value=""
                                            aria-describedby="id-section-help-courseTitle id-section-error-courseTitle">
                                    </vc-form-control>
                                </div>
                                <div class="column-full">
                                    <vc-form-control [control]="educationForm.get('grade')" [controlName]="'grade'"
                                        [label]="'Grade'" [fromContext]="true" [optional]="'(optional)'">
                                        <input formControlName="grade" class="form-control" type="text" id="id-grade"
                                            name="grade" value=""
                                            aria-describedby="id-section-help-grade id-section-error-grade">
                                    </vc-form-control>
                                </div>
                                <div class="column-full">
                                    <vc-month-form-control [control]="educationForm.get('courseStartDate')"
                                        [controlName]="'courseStartDate'" id="id-courseStartDate" [label]="'Start date'"
                                        [fromContext]="true">
                                    </vc-month-form-control>
                                </div>
                                <div class="column-full">
                                    <vc-month-form-control [control]="educationForm.get('courseEndDate')"
                                        [controlName]="'courseEndDate'" id="id-courseEndDate" [label]="'End date'"
                                        [fromContext]="true">
                                    </vc-month-form-control>
                                </div>
                                <div class="column-full">
                                    <vc-form-control [control]="educationForm.get('isStudying')"
                                        [controlName]="'isStudying'" [fromContext]="true">
                                        <div class="checkbox-wrapper">
                                            <input id="id-isStudying-check" name="isStudying" type="checkbox"
                                                formControlName="isStudying">
                                            <label class="form-label-b19" for="id-isStudying-check">
                                                Currently studying
                                            </label>
                                        </div>
                                    </vc-form-control>
                                </div>

                            </form>
                            <div class="column-full">
                                <div class="grid-row">
                                    <div class="column-full">
                                        <button id="id-submit" class="button button-secondary" type="button"
                                            name="add-edu" (click)="addNewEducation()" *ngIf ="!isEditEducationClicked">Add </button>
                                        <button id="id-submit" class="button button-secondary" type="button"
                                            name="add-edu" (click)="editEducation()" *ngIf="isEditEducationClicked">Save </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vc-form-control>
            </div>
        </ng-container>
        <div class="grid-row alignLeft mt100">
            <button id="id-continue" class="button button-link ml0" type="submit" name="continue">Continue</button>
            <button *ngIf="routeState !== 'changeAnswer'" id="id-save-as-draft" class="button button-secondary" type="button" name="save-as-draft"
                (click)="onClickSave()">Save as draft</button>
        </div>
    </form>
</div>