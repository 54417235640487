import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApplicationInfo } from './job-attachment';
import { Url } from '../../../../shared/constants/url-constants';

@Injectable()
export class JobAttachmentService {

  private readonly _changeApplication: BehaviorSubject<ApplicationInfo> = new BehaviorSubject<ApplicationInfo>({});

  constructor(private readonly http: HttpClient) { }

  public attach(appInfo: ApplicationInfo) {
    this._changeApplication.next(appInfo);
  }

  public onChangeApplicationInfo() {
    return this._changeApplication;
  }

  getCvs(): Observable<any[]> {
    const href = `${Url.CVB}/cvs`;
    return this.http.get<any>(href);
  }

  getDocuments(): Observable<any[]> {
    const href = `${Url.DOCUMENT}/documents`;
    return this.http.get<any>(href);
  }

}
