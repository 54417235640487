<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
  <p class="number-of-pages">Page 2 of 13</p>
  <vc-all-errors [groups]="[pageTwoForm]" [fromContext]="true"></vc-all-errors>
  <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>
  <form [formGroup]=pageTwoForm (submit)="onClickContinue()">
    <vc-form-control [control]="pageTwoForm.get('mathsScore')" [controlName]="'mathsScore'" [fromContext]="true">
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwoForm.get('mathsScore')" [value]="currentPageData[0]?.answersValues[0]" id="id-mathsScore" name="mathsScore" formControlName="mathsScore">
        <label class="radio-label" for="id-mathsScore">{{currentPageData[0]?.answersLabels[0]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwoForm.get('mathsScore')" [value]="currentPageData[0]?.answersValues[1]" id="id-mathsScore-level2" name="mathsScore" formControlName="mathsScore">
        <label class="radio-label" for="id-mathsScore-level2">{{currentPageData[0]?.answersLabels[1]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwoForm.get('mathsScore')" [value]="currentPageData[0]?.answersValues[2]" id="id-mathsScore-level1" name="mathsScore" formControlName="mathsScore">
        <label class="radio-label" for="id-mathsScore-level1">{{currentPageData[0]?.answersLabels[2]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwoForm.get('mathsScore')" [value]="currentPageData[0]?.answersValues[3]" id="id-mathsScore-entry-level1" name="mathsScore" formControlName="mathsScore">
        <label class="radio-label" for="id-mathsScore-entry-level1">{{currentPageData[0]?.answersLabels[3]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwoForm.get('mathsScore')" [value]="currentPageData[0]?.answersValues[4]" id="id-mathsScore-entry-level2" name="mathsScore" formControlName="mathsScore">
        <label class="radio-label" for="id-mathsScore-entry-level2">{{currentPageData[0]?.answersLabels[4]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwoForm.get('mathsScore')" [value]="currentPageData[0]?.answersValues[5]" id="id-mathsScore-entry-level3" name="mathsScore" formControlName="mathsScore">
        <label class="radio-label" for="id-mathsScore-entry-level3">{{currentPageData[0]?.answersLabels[5]}}</label>
      </div>
    </vc-form-control>

    <legend class="question mt30">{{currentPageData[1]?.question}}</legend>

    <vc-form-control [control]="pageTwoForm.get('englishScore')" [controlName]="'englishScore'" [fromContext]="true">
      <div class="radio-wrapper" *ngIf="currentPageData[1]">
        <input type="radio" [formControl]="pageTwoForm.get('englishScore')" [value]="currentPageData[1]?.answersValues[0]" id="id-englishScore" name="englishScore" formControlName="englishScore">
        <label class="radio-label" for="id-englishScore">{{currentPageData[1]?.answersLabels[0]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[1]">
        <input type="radio" [formControl]="pageTwoForm.get('englishScore')" [value]="currentPageData[1]?.answersValues[1]" id="id-englishScore-level2" name="englishScore" formControlName="englishScore">
        <label class="radio-label" for="id-englishScore-level2">{{currentPageData[1]?.answersLabels[1]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[1]">
        <input type="radio" [formControl]="pageTwoForm.get('englishScore')" [value]="currentPageData[1]?.answersValues[2]" id="id-englishScore-level1" name="englishScore" formControlName="englishScore">
        <label class="radio-label" for="id-englishScore-level1">{{currentPageData[1]?.answersLabels[2]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[1]">
        <input type="radio" [formControl]="pageTwoForm.get('englishScore')" [value]="currentPageData[1]?.answersValues[3]" id="id-englishScore-entry-level1" name="englishScore" formControlName="englishScore">
        <label class="radio-label" for="id-englishScore-entry-level1">{{currentPageData[1]?.answersLabels[3]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[1]">
        <input type="radio" [formControl]="pageTwoForm.get('englishScore')" [value]="currentPageData[1]?.answersValues[4]" id="id-englishScore-entry-level2" name="englishScore" formControlName="englishScore">
        <label class="radio-label" for="id-englishScore-entry-level2">{{currentPageData[1]?.answersLabels[4]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[1]">
        <input type="radio" [formControl]="pageTwoForm.get('englishScore')" [value]="currentPageData[1]?.answersValues[5]" id="id-englishScore-entry-level3" name="englishScore" formControlName="englishScore">
        <label class="radio-label" for="id-englishScore-entry-level3">{{currentPageData[1]?.answersLabels[5]}}</label>
      </div>
    </vc-form-control>

    <div class="grid-row alignLeft mt30">
      <button 
        id="id-continue" 
        class="button button-link ml0" 
        type="submit" 
        name="continue"
      >Continue</button>
      <button
        *ngIf="routeState !== 'changeAnswer'"
        id="id-save-as-draft" 
        class="button button-secondary" 
        type="button"
        name="save-as-draft"
        (click)="onClickSave()"
      >Save as draft</button>
    </div>

  </form>
</div>
