<form [formGroup]="chatForm">
    <div class="messages-page">
        <div id="messages" class="messages-container-header">
            <div class="header">
                <h3 *ngIf="messageToName" id="chat_with"> Conversation with {{messageToName }}</h3>
                <h3 *ngIf="!messageToName" id="chat"> Secure messaging</h3>
            </div>
            <div class="text-right">
                <button id="create_button" class="button button-secondary mar0" (click)="onCreateClick()">Create secure
                    message</button>
            </div>

        </div>
        <div id="messages-container" class="messages-container">
            <div class="message-list-left">
                <dl class="govuk-grid-row">
                    <div class="govuk-grid-column-one-third">
                        <nav class="moj-side-navigation" aria-label="Side navigation">
                            <ul class="moj-side-navigation__list">
                                <li *ngFor="let chat of chatConversations" (click)="onClickChat(chat)"
                                    id="chat_{{chat.chatId}}" [ngClass]="{'active': chat.active}"
                                    class=" active moj-side-navigation__item moj-side-navigation__item--active">
                                    <div class="avatar-circle">
                                        <span class="initials"
                                            id="initial__{{chat.chatId}}">{{ chat.initials | uppercase }}</span>
                                    </div>

                                    <div class="message-item">
                                        <span id="last_msg_{{chat.chatId}}"> Last Message {{chat.status}}</span>
                                        <p><span
                                                id="date_{{chat.chatId}}">{{chat.lastMessageRecievedDate | date:'dd MMMM yyyy' }}</span><span *ngIf= "!chat.isRead" class="status_mark"></span>
                                        </p>
                                        <p><span
                                                id="time__{{chat.chatId}}">{{chat.lastMessageRecievedDate | date:'HH:mm' }}</span>
                                        </p>

                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </dl>
            </div>

            <div class="message-list-right">
                <div class="chat-wrapper">
                    <div class="conversation-div" (scroll)="scrolled($event)" #mainScreen id="messageBody">
                        <div class="upper-right-panel" *ngFor="let message of chatMessages"
                            id="message_{{message.messageId}}">
                            <div *ngIf="userId === message?.messageSenderId">
                                <time class="message-list__date" id="date__{{message.messageId}}"
                                    *ngIf="!message?.isSameDate">{{ message.messageTimestamp | date:'dd MMMM yyyy' }}</time>
                                <div class="message-item message-item--sent">
                                    <div class="message-item__text message-item__text--sent"
                                        id="msg_text_{{message.messageId}}">{{ message.messageText}}</div>
                                    <div class="message-item__meta">
                                        <span class="message-item__meta--sender"
                                            *ngIf="message?.messageSenderId === chat?.messageFromId"
                                            id="msg_from_name_{{message.messageId}}">{{chat.messageFromName}}</span>
                                        <span class="message-item__meta--sender"
                                            *ngIf="message?.messageSenderId === chat?.messageToId"
                                            id="msg_to_name_{{message.messageId}}">{{chat.messageToName}}</span>
                                        at
                                        <time class="message-item__meta--timestamp"
                                            id="time_{{message.messageId}}">{{ message.messageTimestamp | date:'HH:mm' }}</time>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="userId !== message?.messageSenderId">
                                <time class="message-list__date" *ngIf="!message?.isSameDate"
                                    id="date_{{message.messageId}}">{{ message.messageTimestamp | date:'dd MMMM yyyy' }}</time>
                                <div class="message-item message-item--received">
                                    <div class="message-item__text message-item__text--received"
                                        id="msg_text_{{message.messageId}}">{{ message.messageText}}</div>
                                    <div class="message-item__meta">
                                        <span class="message-item__meta--sender"
                                            *ngIf="message?.messageSenderId === chat?.messageFromId"
                                            id="msg_from_name_{{message.messageId}}">{{chat.messageFromName}}</span>
                                        <span class=" message-item__meta--sender"
                                            *ngIf="message?.messageSenderId === chat?.messageToId"
                                            id="msg_to_name_{{message.messageId}}">{{chat.messageToName}}</span>
                                        at
                                        <time class="message-item__meta--timestamp"
                                            id="time_{{message.messageId}}">{{ message.messageTimestamp | date:'HH:mm' }}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bottom-contents" *ngIf="messageToId">
                    <textarea class="message-input" id="text_area" name="message" formControlName="message" rows="4"
                        maxlength="400" #message></textarea>
                    <div class="hin-message" id="msg_length">You have {{400-message.value.length}} characters remaining
                    </div>
                </div>
                <div class="button-alignment">
                    <button type="button" title="Send" id="button-send" class="button button-link"
                    [disabled]="!chatForm.valid" (click)="sendMessage()"> Send </button>
                    <button title="Cancel" (click)="cancelMessage()" id="button-cancel"
                        class="button button-secondary" [disabled]="!chatForm.valid">
                        Cancel</button>
                </div>
            </div>
        </div>
    </div>
</form>