import { Itabs } from './../../shared/components/tabs/tabs.interface';
import { Injectable } from '@angular/core';

const dataInputRoute = '/user-profile';

@Injectable()
export class ProfileTabNavigationData {
    profileTabNavigation: Itabs[] = [
        {
          id: 1,
          name: 'Profile',
          state: `${dataInputRoute}`,
        }
      ];
}
