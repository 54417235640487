import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { of } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsefulLinkService {

  constructor(private readonly http: HttpClient) { }

  getUsefulLinks(provider: string): Observable<any> {
    return this.http.get<any>(`${Url.ORGANIZATION}/usefulLink/${provider}`);
  }

  }