<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="training-details mt25">
    <div class="grid-row">
        <vc-all-errors [groups]="[trainingForm]" [fromContext]="true"></vc-all-errors>
        <form [formGroup]="trainingForm" autocomplete="off">

             <div class="column-full">
                <vc-form-control [control]="trainingForm.get('qualification')" [controlName]="'qualification'"
                    [label]="'Qualification'" [fromContext]="true" >                 
                    <input formControlName="qualification" class="form-control" type="text" id="id-qualification" name="qualification" value=""
              aria-describedby="id-section-help-qualification id-section-error-qualification">
                </vc-form-control>
            </div>

            <div class="column-full">
                <vc-form-control [control]="trainingForm.get('body')" [controlName]="'body'" [label]="'Training body'"
                    [fromContext]="true" >                    
                    <input formControlName="body" class="form-control" type="text" id="id-body" name="body" value=""
              aria-describedby="id-section-help-body id-section-error-body">
                </vc-form-control>
            </div>


            <div class="column-full">
                <vc-form-control [control]="trainingForm.get('grade')" [controlName]="'grade'" [label]="'Grade'"
                    [fromContext]="true" [optional]="'(optional)'">
                    <input formControlName="grade" class="form-control" type="text" id="id-grade" name="grade"
                        aria-describedby="id-section-help-grade id-section-error-grade">
                </vc-form-control>
            </div>

            <div class="column-full">
                <vc-month-form-control [control]="trainingForm.get('startDate')" [controlName]="'startDate'"
                    id="id-startDate" [label]="'Start date'" [fromContext]="true">
                </vc-month-form-control>
            </div>

            <div class="column-full">
                <vc-month-form-control [control]="trainingForm.get('endDate')" [controlName]="'endDate'" id="id-endDate"
                    [label]="'End date'" [fromContext]="true">
                </vc-month-form-control>    
            </div>

            <div class="column-full">
                <vc-form-control [control]="trainingForm.get('isCurrent')" [controlName]="'isCurrent'"
                    [fromContext]="true">
                    <div class="checkbox-wrapper">
                        <input id="id-isCurrent-check" name="isCurrent" type="checkbox" formControlName="isCurrent">
                        <label class="form-label-b19" for="id-isCurrent-check">
                            Currently under training
                        </label>
                    </div>
                </vc-form-control>
            </div>

            <div class="column-full">
                <vc-form-control [control]="trainingForm.get('details')" [controlName]="'details'"
                    [label]="'Description'" [fromContext]="true" >
                    <textarea formControlName="details" rows="5" class="form-control" type="text" id="id-details"
                        name="details" aria-describedby="id-section-help-details id-section-error-details">
					</textarea>

                    <div *ngIf="trainingForm.get('details').value.length <= 200" class="form-label form-label-count">You
                        have
                        {{200 - trainingForm.get('details').value.length}} characters remaining</div>
                    <div *ngIf="trainingForm.get('details').value.length === 201" class="error-message">You have
                        {{ trainingForm.get('details').value.length - 200}} character too many</div>
                    <div *ngIf="trainingForm.get('details').value.length > 201" class="error-message">You have
                        {{ trainingForm.get('details').value.length - 200}} characters too many</div>
                </vc-form-control>
            </div>

        </form>
        <div class="column-full">
            <vc-form-control [control]="createAnother" [controlName]="'createAnother'"
                [label]="'Do you want to add more training qualifications?'" [fromContext]="true">
                <div class="radio-wrapper">
                    <input type="radio" [formControl]="createAnother" value="Y" id="id-createAnother-true"
                        name="createAnother"
                        aria-describedby="id-section-help-createAnother-true id-section-error-createAnother-true" />
                    <label for="id-createAnother-true">Yes</label>
                </div>
                <div class="radio-wrapper">
                    <input type="radio" [formControl]="createAnother" value="N" id="id-createAnother-false"
                        name="createAnother"
                        aria-describedby="id-section-help-createAnother-false id-section-error-createAnother-false" />
                    <label for="id-createAnother-false">No</label>
                </div>
            </vc-form-control>
        </div>

    </div>
    <div class="grid-row mb25">
        <button id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
        (click)="save()">Save and
        continue</button>
        <button id="id-save-and-come-back-later" class="button button-secondary mr15" type="button"
        name="save-and-come-back-later" (click)="draft()">Save and come back
        later</button>
        <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
            (click)="cvSummaryPage()">CV summary</button>
    </div>
</div>