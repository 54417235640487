import { Component, OnInit, OnDestroy } from '@angular/core';
import { TRAINING_VALIDATION_CONTEXT } from './training.validation';
import { Url } from '../../../../shared/constants/url-constants';
import { ICanDeactivate } from '../../../../core/guards/deactivate.interface';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { MainService } from '../../../../core/services/main.service';
import { TrainingService } from './training.service';
import { Location, getLocaleDateFormat } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormArray, ValidatorFn, AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';

const VALIDATORS = {
  hasNoTraining: ValidationService.ifChecked
}

@Component({
  selector: 'vc-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit,OnDestroy {
 

  form: FormGroup;
  data: any = [];
  revisedData: any = [];
  isDialogOpen = false;
  routeTo = '/employment/cv-builder/guided/training-home/training';
  model   = {option: ''};
  trueValue = true;


  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly main: MainService,
    private readonly trainingService: TrainingService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly confirm: ConfirmService,
    private readonly validationService: ValidationService
  ) {
    this.form = new FormGroup({
      id: new FormControl(),
      hasNoTraining: new FormControl()
    });
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'CV builder';
    this.validationService.setContext(TRAINING_VALIDATION_CONTEXT);
  }
  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }
  ngOnInit() {
    this.getData();
  }

  getData() {
    this.trainingService.getTrainingInfo().subscribe(res => {
      this.data = res;
      this.form.get('hasNoTraining').setValue(this.data.hasNoTraining);
      if ( this.data.training.length === 0) {
        this.model.option = 'N';
        this.form.get('hasNoTraining').setValue(true);
      } 
      this.revisedData = this.data.training;
      
    });
  }

  gotoPrev() {
    this.location.back();
  }

  cvSummaryPage() {
    this.router.navigate(['/employment/cv-builder/guided']);
  }

  canExit(route, state, nextState) {
    if (this.form.dirty && nextState.url !== this.routeTo) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  addNewTraining() {
    this.router.navigateByUrl(this.routeTo);
  }

  save() {
    this.setValidators(this.form, VALIDATORS);
    if (this.form.valid) {
      this.trainingService.saveNoTraining(this.form.value).subscribe(res => {
        this.resetAllForms();
        this.router.navigate(['../computer-skills'], { relativeTo: this.route });
      });
    } else if (this.revisedData.length !== 0) {
      this.resetAllForms();
      this.router.navigate(['../computer-skills'], { relativeTo: this.route });
    }

    window.scrollTo(0, 0);
  }

  draft() {
    this.clearAllValidatorsAndErrors(this.form);
    if (this.form.valid) {
      this.trainingService.draftNoTraining(this.form.value).subscribe(res => {
        this.resetAllForms();
        this.cvSummaryPage();
      });
    }

    window.scrollTo(0, 0);
  }

  resetAllForms() {
    this.form.markAsPristine();
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  goToEditSection(trId) {
    this.router.navigate([this.routeTo], { queryParams: { id: trId }, relativeTo: this.route });
  }

  deleteTraining(trId) {
    this.confirm.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete the training qualification details?',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.isDialogOpen = false;
        this.trainingService.deleteTraining(trId).subscribe(res => {
          this.getData();
        });
      },
      reject: () => {
        this.isDialogOpen = false;
      }
    });
    return this.confirm.navigateSelection;
  }

  setOption(option) {
    this.model.option = option;
    if(option === 'N'){
      this.form.get('hasNoTraining').setValue(true);
    }
    else if(option === 'Y'){
      this.form.get('hasNoTraining').setValue(false);
    }

    }
    




}
