<section class="entry-panel">
  <h2 class="entry-title">{{entryData?.title}}</h2>
  <p class="entry-subtitle">{{entryData?.subTitle}}</p>
  <ng-container [ngSwitch]="entryData?.title">
    <ng-container *ngSwitchCase="'Employment pathway changed'">
      <ng-container *ngFor="let answerArr of entryData?.formattedDescription">
        <p 
          *ngFor="let answer of answerArr" 
          class="entry-induction-content"
          [style.color]="setAnswerColor(answer)"
        >
          {{answer}}
        </p>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngFor="let answer of entryData?.formattedDescription">
        <p class="entry-induction-content">
          {{answer}}
        </p>
      </ng-container>
    </ng-container>
  </ng-container>
</section>
