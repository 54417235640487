<div>
    <div class="openAllContainer">
        <button 
            class="open-all-btn" 
            (click)="toggleAllPanels()"
        >{{ allPanelsOpen ? 'Close all' : 'Open all' }}
        </button>
    </div>
    <div class="accordion-divider-open-all"></div>
    <!-- Learner Information Panel Start -->
    <div>
        <button 
            class="accordion-header" 
            id="accordion-button-learnerInfo" 
            (click)="togglePanel($event)" 
            [attr.aria-expanded]="learnerInfoPanelIsOpen" 
            aria-controls="accordion-content-learnerInfo"
        >
            <h2 class="heading-medium" id="accordion-heading-learnerInfo">Learner Information</h2>
            <img 
                *ngIf="!learnerInfoPanelIsOpen" 
                class="accordion-icon"
                src="./../../../../assets/images/icon-plus.png" 
                alt="Toggle panel Open Icon"
                id="accordion-heading-learnerInfo-icon"
            >
            <img 
                *ngIf="learnerInfoPanelIsOpen" 
                class="accordion-icon"
                src="./../../../../assets/images/icon-minus.png" 
                alt="Toggle panel Close Icon"
                id="accordion-heading-learnerInfo-icon"                    
            >
        </button>
    </div>
    <div *ngIf="learnerInfoPanelIsOpen" id="accordion-content-learnerInfo">
        <div class="grid-row">
            <label for="uln" class="panel-label">ULN </label>
            <span class="panel-content"id="uln">{{learner.uln || 'N/A'}}</span>
        </div>
        <div class="grid-row">
            <label for="lrn" class="panel-label">LRN </label>
            <span class="panel-content" id="lrn">{{learner.lrn || 'N/A'}}</span>
        </div>
        <div class="grid-row">
            <label for="previous lrn" class="panel-label">Previous LRN </label>
            <span class="panel-content" id="previous lrn"> {{learner.lrnPrevious || 'N/A'}}</span>
        </div>
        <div class="grid-row">
            <label for="self assessment ldd" class="panel-label">Learner Self Assessment LDD and Health Problem </label>
            <span class="panel-content" id="self assessment ldd">{{learner.selfAssessmentLdd || 'N/A'}}</span>
        </div>
        <div class="grid-row">
            <label for="prior attainment" class="panel-label">Prior Attainment </label>
            <span class="panel-content" id="prior attainment">{{learner.priorAttainment || 'N/A'}}</span>
        </div>
        <div class="grid-row">
            <label for="maths initial assessment" class="panel-label">Maths Initial Assessment </label>
            <span class="panel-content" id="maths initial assessment">{{learner.mathInitialAssessment || 'N/A'}}</span>
        </div>
        <div class="grid-row">
            <label for="maths assesment date" class="panel-label">Date of Maths Assessment </label>
            <span class="panel-content" id="maths assesment date">{{(learner.mathsAssessmentDate | date:'dd MMMM yyyy') || "N/A"}}</span>
        </div>
        <div class="grid-row">
            <label for="english initial assessment" class="panel-label">English Initial Assessment </label>
            <span class="panel-content" id="english initial assessment">{{learner.englishInitialAssessment || 'N/A'}}</span>
        </div>
        <div class="grid-row">
            <label for="date of english assessment" class="panel-label">Date of English Assessment </label>
            <span class="panel-content" id="date of english assessment">{{(learner.englishAssessmentDate | date:'dd MMMM yyyy') || "N/A"}}</span>
        </div>
        <div class="grid-row">
            <label for="languageStatus" class="panel-label">Language Status </label>
            <span class="panel-content" id="languageStatus">{{learner.languageStatus || "N/A"}}</span>
        </div>
        <label for="attended learning hours">Attended Learning Hours </label>
        <div class="grid-row">
            <div class="attended-learning-hours-container panel-label">
                <label for="attended learning hours" *ngFor="let glh of learner.glhDtoList" id="attended learning hours" class="attended-learning-hours">@{{glh.establishmentName}}</label>
            </div>
            <div class="attended-learning-hours-container panel-content">
                <span *ngFor="let glh of learner.glhDtoList" id="attended learning hours">{{glh.attentedGLH}}</span>
            </div>
        </div>
        <div class="grid-row">
            <label for="total attended learning hours" class="panel-label">Total Attended Learning Hours </label>
            <span class="panel-content" id="total attended learning hours">{{learner.totalAttentedGLH}}</span>
        </div>
    </div>
    <div class="accordion-divider"></div>
    <!-- LLDD and Health Problem Panel Start-->
    <div>
        <button 
            class="accordion-header" 
            id="accordion-button-lldd-health-problems" 
            (click)="togglePanel($event)" 
            [attr.aria-expanded]="llddPanelIsOpen" 
            aria-controls="accordion-content-lldd-health-problems"
        >
            <h2 class="heading-medium" id="accordion-heading-lldd-health-problems">LLDD and Health Problem</h2>
            <img 
                *ngIf="!llddPanelIsOpen" 
                class="accordion-icon"
                src="./../../../../assets/images/icon-plus.png" 
                alt="Toggle panel Open Icon" 
                id="accordion-heading-lldd-health-problems-icon"
            >
            <img 
                *ngIf="llddPanelIsOpen" 
                class="accordion-icon"
                src="./../../../../assets/images/icon-minus.png" 
                alt="Toggle panel Close Icon" 
                id="accordion-heading-lldd-health-problems-icon"
            >
        </button>
    </div>
    <div *ngIf="llddPanelIsOpen" id="accordion-content-lldd-health-problems">
        <div class="grid-row" *ngIf="lddSize > 0">
            <label for="lld and health problem" class="panel-label">Primary LLDD and Health Problem </label>
            <div class="lld-container">
                <span *ngFor="let lld of learner.lldAndHealthProblemList" >
                    <span *ngIf="lld.isPrimary">
                        <div class="alert-container">
                            <img class="alert-icon"src="./../../../../assets/images/icon-important.png" alt="Alert Icon" id="lldd-alert-icon">
                            <span id="lld and health problem" class="additional-lldd-and-health">{{lld.llldAndHealthProblem}}</span>
                        </div>
                    </span>
                </span>
            </div>
        </div>
        <div class="grid-row" *ngIf="lddSize > 0">
            <label for="additional lldd and health problems" class="panel-label">Additional LLDD and Health Problem(s) </label>
            <div class="lld-container">
                <span *ngFor="let lld of learner.lldAndHealthProblemList">
                    <span *ngIf="!lld.isPrimary">
                        <div class="alert-container">
                            <img class="alert-icon"src="./../../../../assets/images/icon-important.png" alt="Alert Icon" id="lldd-alert-icon">
                            <span id="additional lldd and health problems" class="additional-lldd-and-health">{{lld.llldAndHealthProblem}}</span>
                        </div>
                    </span>
                </span>
            </div>
        </div>
        <div class="grid-row" *ngIf="lddSize === 0">
            <div class="panel-border-wide inset-text">
                <div class="label-inset-text">No LLDD and Health Problem recorded</div>
            </div>
        </div>
    </div>
    <!-- Courses Panel Start -->
    <div class="accordion-divider" *ngIf="!planRouteActive"></div>
    <div class="course-panel-container" *ngIf="!planRouteActive">
        <div>
            <button 
                class="accordion-header"
                id="accordion-button-courses" 
                (click)="togglePanel($event)" 
                [attr.aria-expanded]="coursesPanelIsOpen" 
                aria-controls="accordion-content-courses"
            >
                <h2 class="heading-medium" id="accordion-heading-courses">Courses ({{courseSize}})</h2>
                <img 
                    *ngIf="!coursesPanelIsOpen" 
                    class="accordion-icon"
                    src="./../../../../assets/images/icon-plus.png" 
                    alt="Toggle panel Open Icon" 
                    id="accordion-heading-courses-icon"
                >
                <img 
                    *ngIf="coursesPanelIsOpen" 
                    class="accordion-icon"
                    src="./../../../../assets/images/icon-minus.png" 
                    alt="Toggle panel Close Icon" 
                    id="accordion-heading-courses-icon"
                >
            </button>
        </div>
        <div *ngIf="coursesPanelIsOpen" id="accordion-content-courses">
            <div *ngIf="courseSize > 0">
                <div *ngFor="let course of learner.courseList">
                    <div class="grid-row">
                        <label for="course dates" class="panel-label">{{course.courseName}}</label>
                        <span class="panel-content" id="course dates">{{course.effectiveFrom | date:'dd MMMM yyyy'}} {{course.effectiveTo ? '-' : ''}} {{course.effectiveTo | date:'dd MMMM yyyy'}}</span>
                    </div>
                </div>
            </div>
            <div class="grid-row" *ngIf="courseSize === 0">
                <div class="panel-border-wide inset-text">
                    <div>No Courses added</div>
                </div>
            </div>
        </div>
        <div class="accordion-divider"></div>
    </div>
</div>