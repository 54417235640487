<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<vc-search-job></vc-search-job>
<div id="search-results">
    <div *ngIf="!noresults">
        <div class="grid-row topFilter">
            <div class="column-third clear"><span class="job-search-results">{{totalItems}} {{jobName}} jobs in {{jobLoc}}</span></div>
            <div class="column-two-thirds "><span>Results {{ page*size + 1 }} - {{ page*size + searchRes?.length }} of
                    {{totalItems}}</span><span></span>
                <label for="pageSize">Show </label>
                <select name="page" id="pageSize" [(ngModel)]="size" (change)="filterResults()">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                </select>
                <label for="sort"> per page and sort by </label>
                <select name="sortBy" id="sort" [(ngModel)]="sort" (change)="filterResults()">
                    <option value="">Most Relevant</option>
                    <option value="date,desc">Most Recent</option>
                </select>
            </div>
        </div>
        <div class="grid-row">
            <div class="column-third clear leftFilter">
                <h3>Posting date</h3>
                <ul>
                    <li *ngFor="let s of postingDate; let i = index"
                        [style.display]="(filters.period === s.term || !filters.period) ? 'block' : 'none' "
                        [ngClass]="{'active': (filters.period === s.term)}">
                        <a href="#" class="link" (click)="filters.period=s.term; filterResults()">{{s?.dateValue}}</a>
                        <span *ngIf="filters.period === s.term">

                            <a href="javascript:void(0)" class="crossSign"
                                (click)="filters.period=' '; filterResults()">x</a>
                        </span>
                    </li>
                </ul>


                <h3>Industry sector</h3>
                <ul>
                    <li *ngFor="let s of industryFilter | slice: 0: industryLength"
                        [ngClass]="{'active': (filters.industry === s.term)}">
                        <a class="link" href="#" (click)="filters.industry = s?.term; filterResults()">{{s?.term}}</a>
                        <span [ngClass]="{'hideCount': (filters.industry === s.term)}">({{s?.count}})</span>
                        <span *ngIf="filters.industry === s.term">
                            <a href="javascript:void(0)" class="crossSign"
                                (click)="filters.industry=' '; filterResults()">x</a>
                        </span>
                    </li>
                    <li *ngIf="industryFilter?.length > 4" [ngClass]="{'hide': showMore}">
                        <a class="link" href="#" (click)="showMoreTrue();">show more >></a>
                    </li>
                    <li *ngIf="industryFilter?.length === industryLength" [ngClass]="{'hide': showLess}">
                        <a class="link" href="#" (click)="showMoreLess();">show less >></a>
                    </li>
                </ul>

            <h3>Contract type</h3>
            <ul>
                <li *ngFor="let s of contractFilter; let i = index" [ngClass]="{'active': (filters.cType === s.term)}">
                    <a class="link" href="#" (click)="filters.cType=s.term; filterResults()">{{s?.term}}</a>
                    <span [ngClass]="{'hideCount': (filters.cType === s.term)}">({{s?.count}})</span>
                    <span *ngIf="filters.cType === s.term">
                        <a href="javascript:void(0)" class="crossSign" (click)="filters.cType=' '; filterResults()">x</a>
                    </span>
                </li>
            </ul>
        </div>
        <div class="column-two-thirds resultSection">
            <div class="jobInfo"
                *ngFor="let s of searchRes | paginate: { itemsPerPage: size, currentPage: (page + 1), totalItems: totalItems }">
                <p>
                    <span class="space-between">
                        <a class="link" href="javascript:void(0)" [routerLink]="['/employment/job-details']"
                            [queryParams]="{job: s.id, what: s.job_title, where: s.location_name}"
                        >{{s?.job_title | truncate:[70, '...']}}</a>
                        <a *ngIf="!s?.isApplied" href="javascript:void(0)" [ngClass]="s?.isFavourite ? 'heart-favourite-true' : 'heart-favourite-false'" aria-hidden="true" [title]="s?.isFavourite ? 'Remove from favourite jobs' : 'Save to favourite jobs'" (click)="s?.isFavourite ? removeFromFavourite(s) : saveToFavorite(s)"></a>
                        <span class="status-tag" *ngIf="s?.isApplied">Applied</span>
                    </span>
                </p>
                <span *ngIf="s.date">{{s?.date | date : 'dd MMMM yyyy'}}</span>
                <span *ngIf="s.contract_type">{{s?.contract_type}}</span>
                <span *ngIf="s.organization_name">{{s?.organization_name}}</span>
                <span *ngIf="s.location_name">{{s?.location_name}}</span>
                <span *ngIf="s.salary">{{s?.salary | currency: 'GBP' : 'symbol' : '3.0'}}</span>
                <span *ngIf="s.job_description && !s?.isApplied" class="jobDesc">
                    <p>{{s?.job_description | truncate:[180, '...']}}</p>
                </span>
            </div>
        </div>
        <div class="grid-row">
            <div class="column-third clear"></div>
            <div class="column-two-thirds">
                <pagination-controls [maxSize]="maxSize" *ngIf="totalItems > size" (pageChange)="pageChange($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
</div>
<div *ngIf="noresults">
    <div class="grid-row">
        <div class="column-full">
            <h1 class="heading-large">No results found</h1>
            <p>Please select another category or enter a valid job title/location and try again.</p>
        </div>
    </div>
</div>