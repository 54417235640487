import { HttpClient } from '@angular/common/http';
import { Url } from './../../../../shared/constants/url-constants';
import { Injectable } from '@angular/core';

@Injectable()
export class ComputerSkillsService {

  constructor(private readonly http: HttpClient) { }

  getComputerSkill() {
    const href = `${Url.CVB}/computer-skills`;
    return this.http.get(href);
  }

  saveComouterSkill(data) {
    const href = `${Url.CVB}/computer-skills/save`;
    return this.http.post<any>(href, data);
  }

  draftComouterSkill(data) {
    const href = `${Url.CVB}/computer-skills/draft`;
    return this.http.post<any>(href, data);
  }

  async getComputerSkills(): Promise<any> {
    const href = `${Url.CVB}/ref-data/skills/6`;
    return this.http.get<any>(href).toPromise();
  }
}
