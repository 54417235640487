import { OnInit, Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MainService } from '../../core/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SrmService } from './srm.service';
import { Location } from '@angular/common';
import { TabsService } from '../../shared/components/tabs/tabs.service';
import { ConfirmService } from '../../shared/components/confirm-box/confirm-box.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';


@Component({
    selector: 'vc-srm-app',
    templateUrl: './srm.component.html',
    styleUrls: ['./srm.component.scss']
})
export class SrmComponent implements OnInit, OnDestroy {
    protected myDate: Date;
    protected userId: number;
    public messageToName;
    readonly pageSize = 10;
    private pageIndex = 0;
    sortColumn = '';
    public chatConversations: any[];
    public chatMessages: any[];
    public chat;
    public isAllMessagesLoaded: boolean = false;
    chatForm: FormGroup;
    messageToId: any;
    chatWith: string;
    chatId;
    
    @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
    viewHeight: number;

    ngOnDestroy(): void {
        this.pageIndex = 0;
        this.chatMessages = [];
        this.chatConversations = [];
        this.chat = null;
        this.tabService.emitChatFocus(false);
    }

    constructor(
        private readonly mainService: MainService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly srmService: SrmService,
        private readonly location: Location,
        private readonly confirmService: ConfirmService,
        private readonly tabService: TabsService,
        private readonly builder: FormBuilder,

    ) {
        this.mainService.pageTitle = this.route.snapshot.data.title;
    }
    ngOnInit() {
        this.chatMessages = null;
        this.userId = JSON.parse(atob(localStorage.getItem('sessionToken').split('.')[1])).userId;
        this.tabService.setHideChatCount(true);
        this.tabService.emitChatFocus(true);
        this.route.queryParams.subscribe(params => {
            this.tabService.setHideChatCount(true);
            this.chatId = params.active;
            this.pageIndex = 0;
            this.isAllMessagesLoaded = false;
            this.chat = null;
            this.chatId ? this.getChatDisplay(+this.chatId) : this.getChatDisplay();
        });
        this.initForm();
    }

    getChatDisplay(id?: number) {
        this.srmService.getChatConversation().subscribe(data => {
            this.chatConversations = data;
            this.chatConversations.forEach(chat => {
                if (this.userId === chat.messageFromId) {
                    chat.initials = chat.messageToName.split(' ').map((n) => n[0]).join('');
                } else if (this.userId === chat.messageToId) {
                    chat.initials = chat.messageFromName.split(' ').map((n) => n[0]).join('');
                }
            });
            if (data && data.length > 0) {

                this.chatConversations.forEach(element => {
                    if (element.chatId === id) {
                        element.active = true;
                    } else {
                        element.active = false;
                    }
                });
                const chatObject = id ? this.chatConversations.find(obj => obj.chatId === id) : this.chatConversations[0];
                chatObject.active = true;
                this.chat = chatObject;
                this.loadMessages(chatObject);
            }
        });
    }


    loadMessages(chat) {
        const chatMessage = {
            chatId: chat.chatId, 
            userId: this.userId
        };
        this.srmService.getChatMessages(this.pageSize, this.pageIndex, chatMessage).subscribe(data => {
            this.chatMessages = data.content;
            if (this.userId === chat.messageFromId) {
                chat.initials = chat.messageToName.split(' ').map((n) => n[0]).join('');
                this.messageToName = chat.messageToName;
            } else if (this.userId === chat.messageToId) {
                chat.initials = chat.messageFromName.split(' ').map((n) => n[0]).join('');
                this.messageToName = chat.messageFromName;
            }
            this.groupByDate();
            this.setReceiverInfo();
            if (!chat.isRead) {
                this.srmService.readAllMessages(chatMessage).subscribe(data => {
                    chat.isRead = true;
                    this.tabService.emitChat(chat.chatId);
                })
            }
            this.scrollToBottom();
        });
    }


    groupByDate() {
        let messageDate = null;
        let messageCount = 0;
        const length = this.chatMessages.length;
        this.chatMessages.forEach(message => {

            if (messageCount < length - 1) {
                messageDate = this.chatMessages[messageCount + 1].messageTimestamp;
                if (length > 1 && this.compairDates(messageDate, message.messageTimestamp)) {
                    message.isSameDate = true;
                } else {
                    message.isSameDate = false;
                }
            } else {
                message.isSameDate = false;
            }

            if (messageCount === length - 1) {
                message.isSameDate = false;
            }

            messageCount++;
        });

    }


    compairDates(fromDate, toDate) {
        const firstDate = new Date(Date.parse(fromDate));
        const secondDate = new Date(Date.parse(toDate));

        const firstYear = firstDate.getFullYear();
        const firstMonth = firstDate.getMonth();
        const firstDay = firstDate.getDate();

        const secondYear = secondDate.getFullYear();
        const secondMonth = secondDate.getMonth();
        const secondtDay = secondDate.getDate();

        if (firstYear === secondYear && firstMonth === secondMonth && firstDay === secondtDay) {
            return true;
        }
        return false;
    }

    onClickChat(chat) {
        this.router.navigate(['/chat'], { queryParams: { active: chat.chatId } });
    }


    scrolled(event: any): void {
        const offsetHeight = this.elementView.nativeElement.offsetHeight;
        const scrollHeight = this.elementView.nativeElement.scrollHeight;
        const scrollTop = this.elementView.nativeElement.scrollTop;
        const scrollCeil = Math.ceil(Math.abs(scrollTop));
        const scrollFloor = Math.floor(Math.abs(scrollTop));
        const diff = scrollHeight - offsetHeight;
          if(((scrollCeil <= diff) || (scrollFloor <= diff)) && (scrollTop === 0 || scrollCeil === diff)  && !this.isAllMessagesLoaded && this.chat) {
            this.loadMoreChats(scrollTop);
          }
      }

    loadMoreChats(scrollTop) {
        this.pageIndex = this.pageIndex + 1;
            const chatMessage = {
                chatId: this.chat.chatId,
                userId: this.userId
            };
            this.srmService.getChatMessages(this.pageSize, this.pageIndex, chatMessage).subscribe(data => {
                if (data.content && data.content.length > 0) {
                    this.chatMessages = this.chatMessages.concat(data.content);
                    this.groupByDate();
                } else {
                    this.isAllMessagesLoaded = true;
                }
            });
            document.getElementById("messageBody").scrollTop = scrollTop + 10;
    }
    onCreateClick() {
        this.router.navigate(['srm/new-chat']);
    }
    backClicked() {
        this.location.back();
    }

    cancelMessage() {
        const message: string = this.chatForm.get('message').value;

        if (message.length > 0) {
            this.confirmService.confirm({
                message: 'Are you sure you want to cancel?',
                header: '',
                acceptLabel: 'Yes',
                rejectLabel: 'No',
                accept: () => {
                    this.chatForm.reset();
                }
            });
        }
    }
    initForm() {
        this.chatForm = this.builder.group({
            message: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(400)]]
        });
    }
    sendMessage() {
        let message: string = this.chatForm.get('message').value;
        const conversation = {
            status: 'SU',
            messageText: message,
            messageToId: this.messageToId
        };
        this.srmService.create(conversation).subscribe((data: any) => {
            if (data) {
                const newArray = new Array();
                message = '';
                newArray.push(data);
                this.chatMessages = newArray.concat(this.chatMessages);
                this.srmService.getChatConversation().subscribe(data => {
                    this.chatConversations = data;
                    this.chatConversations.forEach(chatObj => {
                        if (chatObj.chatId === this.chat.chatId) {
                            chatObj.active = true;
                        } else {
                            chatObj.active = false;
                        }
                        if (this.userId === chatObj.messageFromId) {
                            chatObj.initials = chatObj.messageToName.split(' ').map((n) => n[0]).join('');
                        } else if (this.userId === chatObj.messageToId) {
                            chatObj.initials = chatObj.messageFromName.split(' ').map((n) => n[0]).join('');
                        }
                    });
                    this.scrollToBottom();
                });
                this.groupByDate();
                this.chatForm.reset(); 
            }
        });

    }

    setReceiverInfo() {
        if (this.userId === this.chat.messageFromId) {
            this.messageToName = this.chat.messageToName;
            this.messageToId = this.chat.messageToId;
        } else if (this.userId === this.chat.messageToId) {
            this.messageToName = this.chat.messageFromName;
            this.messageToId = this.chat.messageFromId;
        }
    }

    scrollToBottom() {
        setTimeout(() => {
            const chatHistory = document.getElementById('messageBody');
            chatHistory.scrollIntoView(false);
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        }, 0);
    }
}
