<section *ngIf="data" class="" id="content-items-{{type}}" [ngClass]="{'mar-right15': scrollalign || scrollalignCourse, 'fixmarright': fixmarright}">
  <ng-content></ng-content>
  <div class="grid-row" [ngClass]="{'scrollable': scrollable, 'scrollable': addScroll}">
    <article *ngFor="let item of data; let index = index" class="content-card" class="{{gridColumn}} {{type}}"
      [ngClass]="{'active': item.active, 'inactive': item.disabled,
      'two-per-row': data.length == 2 || data.length == 4, 'odd-categories': data.length == 5 || data.length == 7, 'three-per-row': data.length == 3 || data.length == 6}"
       id="{{type}}-card-{{item.id}}" (click)="onTitleClicked(item)"
      (keyup.enter)="onTitleClicked(item)" tabindex="0" [attr.disabled] = "item.disabled">
      <figure class="content-card-wrapper">
        <img alt="{{item.courseTitle || categoryTitle || item.title}}" [src]="item.courseImgUrl || item.categoryImgUrl || item.imagePath" *ngIf="item.courseImgUrl || item.categoryImgUrl || item.imagePath" />
        <figcaption class="{{item?.categoryColor || none}}">
          <div class="caption-div"> 
            <div *ngIf="!item.disabled" class="{{item?.categoryColor || none}}">
              <span>{{item.courseTitle || item.categoryTitle || item.title}}</span>
              <ng-container *ngIf="!item.isFavourite">
                <!-- <a title="Add to my content" id="heart-add-to-my-content" class="heart-icon" *ngIf="ifCourses" (click)="$event.stopPropagation();goToPlan(item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="45" viewBox="0 0 50 45"><path fill="#FFFFFF" d="M45.1 5c-2.52-2.58-5.87-4-9.43-4-3.56 0-6.91 1.42-9.43 4L25 6.27 23.76 5c-2.52-2.58-5.87-4-9.43-4-3.56 0-6.91 1.42-9.43 4-5.2 5.32-5.2 13.99 0 19.31l18.63 19.08c.3.31.68.5 1.08.58.13.03.27.04.4.04.53 0 1.05-.21 1.46-.62L45.1 24.31c5.2-5.33 5.2-13.99 0-19.31zm-1.22 18.1L25.24 42.18c-.08.08-.17.1-.23.1-.02 0-.04 0-.06-.01-.09-.02-.15-.05-.19-.09L6.12 23.1c-4.55-4.66-4.55-12.24 0-16.9 2.19-2.24 5.11-3.48 8.21-3.48 3.1 0 6.01 1.24 8.21 3.48L25 8.73l2.47-2.53c2.19-2.24 5.11-3.48 8.21-3.48 3.1 0 6.01 1.24 8.2 3.48 4.55 4.66 4.55 12.24 0 16.9z"/></svg>
                </a> -->
              </ng-container>
              <ng-container *ngIf="item.isFavourite">
                <!-- <a title="Remove from my content" id="heart-remove-from-my-content" class="heart-icon" *ngIf="ifCourses" (click)="$event.stopPropagation();removeFromPlan(item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="45" viewBox="0 0 50 45"><path fill="#FFFFFF" d="M45.1 5c-2.52-2.58-5.87-4-9.43-4s-6.91 1.42-9.43 4L25 6.27 23.76 5c-2.52-2.58-5.87-4-9.43-4-3.56 0-6.91 1.42-9.43 4-5.2 5.32-5.2 13.99 0 19.31l18.63 19.08c.31.31.68.5 1.08.58.13.03.27.04.4.04.53 0 1.05-.21 1.46-.62L45.1 24.31c5.2-5.33 5.2-13.99 0-19.31"/><path fill="#FFFFFF" d="M24.95 42.27c-.09-.02-.15-.05-.19-.09L6.12 23.1c-4.55-4.66-4.55-12.24 0-16.9 2.19-2.24 5.11-3.48 8.21-3.48 3.1 0 6.01 1.24 8.2 3.48L25 8.73l2.47-2.53c2.19-2.24 5.11-3.48 8.21-3.48 3.1 0 6.01 1.24 8.2 3.48 4.55 4.66 4.55 12.24 0 16.9L25.24 42.18c-.08.08-.17.1-.23.1-.02 0-.04 0-.06-.01"/></svg>
                </a> -->
              </ng-container>
            </div>
          </div>
        </figcaption>
      </figure>
    </article>
  </div>
</section>
