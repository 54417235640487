import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsefulLinkComponent } from './useful-link.component';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { RouterModule } from '@angular/router';
import { UsefulLinkRoutes } from './useful-link.routing';

@NgModule({
    
  declarations: [
    UsefulLinkComponent]
    ,
  imports: [
    CommonModule,
    PaginationModule,
     RouterModule.forChild(UsefulLinkRoutes)
  ],
   entryComponents: []
})
export class UsefulLinkModule { }
