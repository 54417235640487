import { Component, OnInit, Renderer2, AfterViewChecked } from '@angular/core';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { PageNineService } from './page-nine.service';
import { WorkExperienceService } from '../../../cv-builder/guided-cv-builder/work-experience/work-experience.service';
import { EditWorkExperienceService } from '../../../cv-builder/guided-cv-builder/work-experience/edit-work-experience/edit-work-experience.service';
import { Utility } from '../../../../shared/utils/utility';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { PATHWAY_VALIDATION } from './page-nine.validation';
import { MainService } from '../../../../core/services/main.service';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from "../../../../shared/components/confirm-box/confirm-box.service";



const PATHWAY_VALIDATORS = {
  answers: [ValidationService.minSelected(1), ValidationService.maxSelected(1)],
}



@Component({
  selector: 'vc-page-nine',
  templateUrl: './page-nine.component.html',
  styleUrls: ['./page-nine.component.scss']
})
export class PageNineComponent implements OnInit, AfterViewChecked {

  pageNineForm: FormGroup;
  form: FormGroup;
  currentPageNumber = 8;
  currentPageData = [] as any;
  refData = [] as any;
  model = { option: '' };
  checkInfo: BehaviorSubject<boolean>;
  skillPresentOnLoadFlag: boolean = false;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-eight';
  routeNext = '/plan/induction/page-ten';
  isAssessmentInProgressFlag = false;
  routeState: any;


  constructor(
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly pageNineService: PageNineService,
    private readonly workService: WorkExperienceService,
    private readonly editWEService: EditWorkExperienceService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly validation: ValidationService,
    private readonly mainService: MainService,
    private readonly renderer: Renderer2,
    private readonly togetherMode: TogetherModeModalService,
    private readonly confirm: ConfirmService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.validation.setContext(PATHWAY_VALIDATION);
    this.pageNineForm = new FormGroup({
      id: new FormControl(),
      hasAddedValue: new FormControl(),
      pathwayDescription: new FormGroup({
        question: new FormControl(''),
        answers: new FormArray([]),
      })
    });
    this.loadSkillsAndResolve();
  }

  ngOnInit() {
    this.getRefData();
    this.resolveRouteState();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageNineForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      this.resolveSelectedSkills([parseInt(data[0].answer)]);
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          }
        )
      });
    })
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  async loadSkillsAndResolve() {
    await this.loadAllSkills();
  }

  resolveSelectedSkills(ids: any[]) {
    if(ids){
      ((this.pageNineForm.get('pathwayDescription') as FormGroup)
        .get('answers') as FormArray).controls.forEach(each => {
          if (ids.includes(each.get('id').value)) {
            each.get('checked').setValue(true);
          }
        });
      }
  }

  async loadAllSkills() {
    await this.pageNineService.getRefData()
      .then(res => {
        const answers = Utility.getObjectFromArrayByKeyAndValue(res.domainList, 'identifier', '4')['choiceList'];
        this.loadAnswers(answers)
      });
  }

  loadAnswers(answers: any[]) {
    const farr = this.pageNineForm.get('pathwayDescription').get('answers') as FormArray;
    answers.map(answer => {
      return {
        id: answer.choiceId,
        text: answer.choiceDescription,
        checked: false,
      }
    }).forEach(answer => {
      farr.push(this.singleAnswerForm(answer));
    });
  }

  singleAnswerForm(data: any) {
    return new FormGroup({
      id: new FormControl(data.id),
      text: new FormControl(data.text),
      checked: new FormControl(data.checked),
    });
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }


  draft() {
    this.isSaveSubmitOperation = true;
    const formattedPayload = this.formatPayload(true);
    this.saveInductionPlan(formattedPayload);
  }

  onClickContinue() {
    this.setValidators(this.pageNineForm.get('pathwayDescription') as FormGroup, PATHWAY_VALIDATORS);
    if (this.pageNineForm.valid) {
      this.isSaveSubmitOperation = true;
      const formattedPayload = this.formatPayload(false);
      this.saveInductionPlan(formattedPayload);
    }
    else {
      this.setFocusToErrorSummary();
    }
  }

  canExit(route, state, nextState) {
    if (this.pageNineForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  saveInductionPlan(formattedPayload) {
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      if (formattedPayload.isSaveAsDraft) {
        this.router.navigateByUrl('/plan');
        window.scrollTo(0, 0);
      } else {
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-ten');
      }
    });
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  formatPayload(saveAsDraft) {
    const skills = this.pageNineForm.get('pathwayDescription').value.answers
      .filter(each => each.checked)
      .map(answer => answer.id);

    return {
      isSaveAsDraft: saveAsDraft,
      planTypeIdentifier: '1',
      questionDTOList: [
        {
          answer: skills[0],
          id: 12
        },
      ],
      sectionIdentifier: this.currentPageData[0].sectionIdentifier
    }
  }

}
