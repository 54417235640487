import { ActivatedRoute } from '@angular/router';
import { MainService } from './../../../core/services/main.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vc-content-unavailable',
  templateUrl: './content-unavailable.component.html',
  styleUrls: ['./content-unavailable.component.scss']
})
export class ContentUnavailableComponent implements OnInit {

  constructor(
    private readonly route: ActivatedRoute,
    private readonly mainService: MainService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
  }

  ngOnInit() {
  }

}
