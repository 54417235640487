<vc-content-card [data]="reccomendedCourses" [scrollable]="false" [ifCourses]="true" [gridColumn]="'column-half'"
  [type]="'recommended'" (titleClick)="showCourseDetails($event)">
  <h2> <a href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a></h2>
  <h3 *ngIf='reccomendedCourses?.length > 0' class="course-title nomargintop">Featured content</h3>
</vc-content-card>

<vc-content-card [data]="subcategories" [scrollable]="false" [scrollalign]="scrollalign" [gridColumn]="'column-fifth'"
  [type]="'subcategory'" (titleClick)="showSubcategoryCourses($event)">
</vc-content-card>

<vc-content-card [data]="content|slice:0:defaultContent" [scrollable]="scrollable" [addScroll]="scrollable" [scrollalignCourse]="scrollalignCourse" [fixmarright]="fixmarright" [gridColumn]="'column-quarter'"
  [type]="'course'" (titleClick)="showCourseDetails($event)">
  <div class="text-right">
    <a [routerLink]="['./']" class="heading-xsmall link alignSeeAll" (click)="showAllSubcategoryCourses(activeCategoryId)" *ngIf="seeAllButton" [ngClass]="{'hide': isOpen}">See all <i class="down"></i></a>
    <a [routerLink]="['./']" class="heading-xsmall link alignSeeAll" (click)="showTopCourses(activeCategoryId)" *ngIf="seeTop8">See all <i class="up"></i></a>
  </div>
</vc-content-card>
