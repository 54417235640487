<div class="grid-row">
    <div class="header">
        <div class="tabWrapper">
            <ul>
                <li routerLink="" class="tabHead active" [ngClass]="{'active': links}" (click)="getNationalUsefulLink()">
                    <a class="tab-item" id="link-tab" attr.aria-label="tab Month">National</a>
                </li>
                <li routerLink="" class="tabHead active" [ngClass]="{'active': localLinks}" (click)="getLocalUsefulLink()">
                    <a class="tab-item" id="link-tab" attr.aria-label="tab Month">Local</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="link_table">
    <h2 class="linkTab">{{linkTitle}}</h2>
    <table>
        <thead>
            <tr>
                <th scope="col">{{linkTitle}} links</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let link of linksData | paginate: { itemsPerPage: pageSize, currentPage: page}">
                <td>
                    <a href="{{link?.links}}" target="_blank">{{link?.linkName}}</a>
                </td>
            </tr>
        </tbody>
    </table>
    <pagination-controls [maxSize]="maxSize" (pageChange)="pageChange($event)"></pagination-controls>
</div>