<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
  <p class="number-of-pages">Page 3 of 13</p>
  <vc-all-errors [groups]="[pageThreeForm]" [fromContext]="true"></vc-all-errors>
  <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>
  <form [formGroup]="pageThreeForm" (submit)="onClickContinue()">

    <vc-form-control [control]="pageThreeForm.get('englishPrimary')" [controlName]="'englishPrimary'" [fromContext]="true">
      <div class="two-radio-container" *ngIf="currentPageData[0]">
        <div class="radio-wrapper mr20" (click)="checkForOtherLanguages()">
          <input type="radio" [formControl]="pageThreeForm.get('englishPrimary')" [value]="currentPageData[0]?.answersValues[0]" id="id-englishPrimary" name="englishPrimary" formControlName="englishPrimary">
          <label class="radio-label" for="id-englishPrimary">{{currentPageData[0]?.answersLabels[0]}}</label>
        </div>
        <div class="radio-wrapper">
          <input type="radio" [formControl]="pageThreeForm.get('englishPrimary')" [value]="currentPageData[0]?.answersValues[1]" id="id-diagnosed-no" name="englishPrimary" formControlName="englishPrimary">
          <label class="radio-label" for="id-englishPrimary-{{currentPageData[0]?.answersLabels[1]}}">{{currentPageData[0]?.answersLabels[1]}}</label>
        </div>
      </div>
    </vc-form-control>

    <ng-container *ngIf="this.pageThreeForm.value.englishPrimary === '2'">
      <div class="radio-conditional" (click)="resetValidators()">
        <vc-input-chips 
          [control]="pageThreeForm.get('languages')" 
          id="id-languages" 
          [fromContext]="true"
          controlName="languages" 
          label="What languages do you speak?" 
          hint="Add up to five languages"
          [restrictMax]="true" 
          [dataList]="currentPageData[1]?.answersLabels">
        </vc-input-chips>
      </div>
    </ng-container>

    <ng-container *ngIf="this.pageThreeForm.value.englishPrimary === '1'">
      <legend class="question mt30">{{currentPageData[2]?.question}}</legend>
      <vc-form-control [control]="pageThreeForm.get('otherLanguages')" [controlName]="'otherLanguages'" [fromContext]="true">
        <div class="two-radio-container" *ngIf="currentPageData[2]">
          <div class="radio-wrapper mr20">
            <input type="radio" [formControl]="pageThreeForm.get('otherLanguages')" [value]="currentPageData[2]?.answersValues[0]" id="id-otherLanguages" name="otherLanguages" formControlName="otherLanguages">
            <label class="radio-label" for="id-otherLanguages">{{currentPageData[2]?.answersLabels[0]}}</label>
          </div>
          <div class="radio-wrapper" (click)="checkForOtherLanguages()">
            <input type="radio" (click)="checkForOtherLanguages()" [formControl]="pageThreeForm.get('otherLanguages')" [value]="currentPageData[2]?.answersValues[1]" id="id-otherLanguages-no" name="otherLanguages" formControlName="otherLanguages">
            <label class="radio-label" for="id-otherLanguages-{{currentPageData[2]?.answersLabels[1]}}">{{currentPageData[2]?.answersLabels[1]}}</label>
          </div>
        </div>
      </vc-form-control>
    </ng-container>

    <ng-container *ngIf="this.pageThreeForm.value.englishPrimary === '1' && this.pageThreeForm.value.otherLanguages === '1'">
      <div class="radio-conditional" (click)="resetValidators()">
        <vc-input-chips 
          [control]="pageThreeForm.get('languages')" 
          id="id-languages" 
          [fromContext]="true"
          controlName="languages" 
          label="What languages do you speak?" 
          hint="Add up to five languages"
          [restrictMax]="true" 
          [dataList]="currentPageData[1]?.answersLabels">
        </vc-input-chips>
      </div>
    </ng-container>

    <div class="grid-row alignLeft mt30">
      <button 
        id="id-continue" 
        class="button button-link ml0" 
        type="submit" 
        name="continue"
      >Continue</button>
      <button 
        *ngIf="routeState !== 'changeAnswer'"
        id="id-save-as-draft" 
        class="button button-secondary" 
        type="button"
        name="save-as-draft"
        (click)="onClickSave()"
      >Save as draft</button>
    </div>

  </form>
</div>
