import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCvsComponent } from './my-cvs.component';
import { MyCvsService } from './my-cvs.service';
import { PipesModule } from '../../../../core/pipes/pipes.module'
import { PaginationModule } from '../../../../shared/components/pagination/pagination.module';
import { FormControlModule } from '../../../..//shared/components/form-control/form-control.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MyCvsComponent
  ],
  imports: [
    CommonModule, 
    PipesModule,
    PaginationModule,
    FormControlModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers:[MyCvsService],
  exports: [
    MyCvsComponent
]
})
export class MyCVsModule { }
