import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, Input, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { INavigation } from './header.interfaces';
import { NavigationService } from '../../services/navigation.service';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { Base64 } from 'js-base64';
import { Router, ActivatedRoute } from '@angular/router';
import { SrmService } from '../../../features/srm/srm.service';
import { TabsService } from '../../../shared/components/tabs/tabs.service';
import { TogetherModeModalService } from './../../../shared/components/together-mode-modal/together-mode-modal.service';
import { NotificationService } from './../../services/notification.service';
import { ConfirmService } from "../../../shared/components/confirm-box/confirm-box.service";
import { WalletService } from '../../../features/wallet/wallet.service';
import { ConnectionService } from 'ng-connection-service'; 
import { UserProfileService } from '../../../features/user-profile/user-profile.service';

@Component({
  selector: 'vc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // providers: [ConfirmService],

  host: {
    '(document:click)': 'onClick()',
  },

})

export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('chatpopup1') chatpopup1: ElementRef;
  @ViewChild('chatpopup2') chatpopup2: ElementRef;
  @ViewChild('chatpopup3') chatpopup3: ElementRef;
  @ViewChild('chatpopup4') chatpopup4: ElementRef;
  @ViewChild('chatpopup5') chatpopup5: ElementRef;

  navigation: Observable<Array<INavigation>>;
  username: string;
  isUserMenuExpanded = false;
  isMobileMenuExpanded = false;
  isChatWindow: boolean = false;
  showOption: any;
  toChat: boolean;
  nChat: boolean;
  chatCount;
  chatFocus: boolean = false;
  keyword: string;
  showSearch = true;
  serviceUserLoggedIn: boolean;
  
  walletFlag:boolean;

  togetherModeIconSrc = './../../../../assets/images/together-mode-icon.png';
  isConnected = true;  
  noInternetConnection: boolean;  
  learner: any;
  constructor(
    private readonly navigationService: NavigationService,
    private readonly authenticationService: AuthenticationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly srmService: SrmService,
    private readonly tabService: TabsService,
    private readonly togetherModeModalService: TogetherModeModalService,
    private readonly notificationService: NotificationService,
    private readonly confirmService: ConfirmService,
    private readonly walletService: WalletService,
    private connectionService: ConnectionService,
    private readonly userProfileService: UserProfileService,
    private readonly renderer: Renderer2
  ) {  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if(params.keyword){
      this.keyword = decodeURIComponent(params.keyword);
      }
    });
    this.tabService.chatFocus.subscribe((flag: boolean) => {
      this.chatFocus = flag;
    });

    this.navigation = this.navigationService.currentNavigation;
    this.tabService.hideChatCount.subscribe(flag => {
      this.chatCount = 0;
    });
    this.resolveUserName();
    if ((this.router.url === '/chat') || (this.router.url === '/srm/new-chat')) {
      this.toChat = true;
    }
    if (this.authenticationService.isLoggedIn) {
      // this.srmService.getUnreadMessageCount().subscribe(count => {
      //   this.chatCount = count;
      // });

      //   this.walletService.getNotificationCount().subscribe(response => {
      //     this.walletFlag = response.walletFlag;
      //   });
       
      // this.walletService.walletFlag.subscribe(response => {
      //    this.walletFlag = response;
      // });

      this.serviceUserLoggedIn = true;
    } else {
      this.serviceUserLoggedIn = false;
    }
    this.togetherModeModalService.resolveTogetherModeStatus();
    this.userProfileService.getCachedLearnerInfo().subscribe(info => {
      this.learner = info;
      this.connectionService.monitor().subscribe(isConnected => {  
        this.isConnected = isConnected;  
        if (this.isConnected && this.learner.deviceId != null) {
          this.noInternetConnection=false;
          this.renderer.removeClass(document.body, 'sign-out-hide');
        }  
        else if (this.isConnected && this.learner.deviceId == null) {
          this.noInternetConnection=true; 
          this.renderer.removeClass(document.body, 'sign-out-hide');
        }
        else {  
          this.noInternetConnection=true; 
          //this.router.navigate(['/plan']);
          this.renderer.addClass(document.body, 'sign-out-hide');
        }  
      });
    })
    this.connectionStatus();
  }

  connectionStatus(){
    const statusDisplay = document.getElementById("header-navigation-signout");
    if (navigator.onLine){
      statusDisplay.style.visibility = "visible";
      console.log('online')
    } else {
      statusDisplay.style.visibility = "hidden";
      console.log('offline')
    }
  }

  ngOnDestroy(): void {

  }

  getRoute() {
    if (this.chatFocus) {
      return 'active';
    }
    else {
      return '';
    }
  }
  
  onClick() {
    this.isChatWindow = false;
  }

  onMenuIconClick() {
    this.isMobileMenuExpanded = !this.isMobileMenuExpanded;

    if (!this.isUserMenuExpanded) {
      this.isUserMenuExpanded = true;
    }
  }

  onMenuCloseIconClick() {
    this.isMobileMenuExpanded = false;

    if (this.isUserMenuExpanded) {
      this.isUserMenuExpanded = false;
    }
  }

  onUserIconClick() {
    this.isUserMenuExpanded = !this.isUserMenuExpanded;
    this.isChatWindow = false;

  }

  resolveUserName() {
    if (localStorage.getItem('sessionToken')) {
      const parsedToken = JSON.parse(Base64.decode(localStorage.getItem('sessionToken').split('.')[1])) || null;
      parsedToken.firstName && parsedToken.lastName ?
        this.username = `${parsedToken.firstName} ${parsedToken.lastName}` :
        this.username = `${parsedToken.userName}`;
    }
  }

  showChat() {
    this.isChatWindow = !this.isChatWindow;
  }


  clickedChat(event) {
    this.toChat = true;
  }

  onSearch() {
    if (this.keyword === null || this.keyword === "") {
      return;
    }
    this.keyword = this.keyword.trim();
    this.router.navigate([`/content/search`], { queryParams: { keyword: encodeURI(this.keyword).replace(/%20/g,' ').replace(/%22/g,' ').replace(/%5E/g,'^') } });
  }

  openTogetherModeModal() {
    this.togetherModeModalService.openModal();
  }

  onExitTogetherMode() {
    const isTogetherModeAssessment = this.togetherModeModalService.getTogetherModeEnabledStatus()
    const isAssessmentInProgress = JSON.parse(sessionStorage.getItem('isAssessmentChanged'));
    if (isTogetherModeAssessment && isAssessmentInProgress) {
      this.confirmService.confirm({
        message: 'Please save your progress before exiting together mode',
        header: 'Progress not saved',
        showClose: false,
      });
    }
    else {
      this.togetherModeModalService.exitTogetherMode().subscribe(response => {
      }, error => {
        this.notificationService.error([error.error.errorMessage], true);
      });
    }
  }
}

