import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUtilService } from './file-util.service';
import { FileUtilComponent } from './file-util.component';

@NgModule({
  declarations: [
    FileUtilComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FileUtilComponent
  ],
  providers: [
    FileUtilService
  ]
})
export class FileUtilModule { }
