<div class="gantt-container">
  <div class="upper-container">
    <div class="above-pathway-container"></div>
    <div class="months-container">
      <div class="previous-month">
        <span>{{previousMonthLabel}}</span>
      </div>
      <div class="current-month">
        <span>{{currentMonthLabel}}</span>
      </div>
      <div class="next-month">
        <span>{{nextMonthLabel}}</span>
      </div>
    </div>
  </div>
  <section 
    class="pathway-container"
    [style.height.px]="calculatePathwayContainerHeight(category.coursesData)"
    *ngFor="let category of categoryList; index as i;"
  >
    <div class="pathway-label-container pathway-label-{{category.cssLabel}}">
      <h3 class="pathway-label">{{category.value | uppercase}}</h3>
    </div>
    <div class="pathway-timeline pathway-timeline-{{category.cssLabel}}">
      <div 
        class="timeline-decorator-container"
        [style.height.px]="calculatePathwayContainerHeight(category.coursesData)"
      >
        <div class="gantt-previous-month"></div>
        <div class="gantt-current-month" #monthDiv>
          <span class="gantt-today-span" [style.left.px]="leftPixelsTodayLine"></span>
        </div>
        <div class="gantt-next-month"></div>
      </div>
      <div 
        class="timeline-course-container"
        [style.height.px]="calculatePathwayContainerHeight(category.coursesData)"
      >
        <div class="category-row-container" *ngFor="let categoryRow of category.coursesData">
          <div class="course-container" *ngFor="let courseData of categoryRow; index as i">
            <div class="content-details-container">
              <div 
                class="course-bar course-bar-{{category.cssLabel}}"
                [style.width.px]="calcCourseBarWidth(courseData.unixStartDate, courseData.unixEndDate, courseData.startDate, courseData.endDate)"
                [style.left.px]="calcCourseBarLeft(courseData.unixStartDate, courseData.startDate, courseData.endDate)"
                (click)="selectCourse(courseData)"
                [ngClass]="{'course-bar-selected': courseData === infoPanelCourse}">
              </div>
              <div 
                class="course-label"
                [style.width.px]="calCourseLabelWidth(courseData.unixStartDate, courseData.startDate, categoryRow[i+1]?.unixStartDate, categoryRow[i+1]?.startDate)"
                [style.left.px]="calcCourseLabelLeft(courseData.unixStartDate, courseData.startDate, courseData.endDate)"
                (click)="selectCourse(courseData)"
              >
                <span 
                  class="course-label-content"
                  (click)="selectCourse(courseData)"
                  [ngClass]="{'course-label-selected': courseData === infoPanelCourse}"
                >
                  {{courseData.courseName}}
                </span>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </section>
  <div class="today-label-container">
    <span class="today-label" [style.left.px]="leftPixelsTodayLine + monthDivWidth + 130">Today</span>
  </div>

  <div *ngIf="infoPanelCourse">
    <div class="key-information-panel key-information-panel-{{infoPanelCourse.cssLabel}}">
      <div class="key-info-panel-upper-container">
        <div class="three-quarters-section">
          <h2>{{infoPanelCourse.courseName}}</h2>
        </div>
        <div class="quarter-section" *ngIf="infoPanelCourse.status">
          <div class="govuk-tag">{{infoPanelCourse.status | uppercase}}</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="date-container">
        <span>Start date: {{infoPanelCourse.startDate | date : 'd MMMM yyyy'}}</span>
        <ng-container [ngSwitch]="infoPanelCourse.status">
          <span *ngSwitchCase="'Completed'">Completed date: {{infoPanelCourse.endDate | date : 'd MMMM yyyy'}}</span>
          <span *ngSwitchCase="'Withdrawn'">Withdrawal date: {{infoPanelCourse.endDate | date : 'd MMMM yyyy'}}</span>
          <span *ngSwitchDefault>Planned end date: {{infoPanelCourse.endDate | date : 'd MMMM yyyy'}}</span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
