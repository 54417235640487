/*
    Validation context for Job application
*/

export const JOB_APPLICATION_VALIDATION_CONTEXT = {
    required: {
        message: 'Message cannot be blank'
    },
    maxlength: {
        message: 'Please enter 500 or less characters in message'
    },
    minlength: {
        message: 'Message must contain minimum of 5 characters'
    },
    arrayEmpty: {
        attachments: 'Attach CVs / Documents'
    }
}