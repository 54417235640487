<div [style.display]="visible ? 'block' : 'none'" class="fade-in">
    <div class="modal show" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 tabindex="0" class="heading-large" id="myModalLabel" autofocus="autofocus">
                        {{header || 'Confirm'}}</h1>
                </div>
                <div class="modal-body">
                    <p tabindex="0" class="white-space-preline" title="{{message}}"><span *ngIf="alertIcon" class="govuk-warning-text__icon">!</span>{{message}}</p>
                    <p tabindex="0" class="white-space-preline" *ngIf="showTimer">
                        {{ (minutesDisplay) }}:{{ (secondsDisplay) && (secondsDisplay <=59) ? secondsDisplay : '00' }}.
                    </p>
                </div>
             <div class="modal-footer">
                <button *ngIf="hasSecondPopup" id="secondaryLast" type="button" class="button button-warning"
                    (click)="acceptOnHavingSecondpopup()">{{acceptLabel || 'Ok'}}</button>
                <button *ngIf="!hasSecondPopup && showOk" id="secondaryLast" type="button" class="button button-warning"
                    (click)="accept()">{{acceptLabel || 'Ok'}}</button>
                <button *ngIf="showClose" id="last" type="button" class="button button-secondary"
                    (click)="reject()">{{rejectLabel || 'Cancel'}}</button>
            </div>
            </div>
        </div>
        <div class="modal-backdrop fade in">
        </div>
    </div>
