import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../../shared/constants/url-constants';
import { Observable } from 'rxjs';

@Injectable()
export class TrainingService {

  constructor(private readonly http: HttpClient) { }

  getTraningDetails(id: number): Observable<any | null> {
    const href = `${Url.CVB}/training/${id}`;
    return this.http.get<any>(href);
  }

  saveAndContinueTraining(payLoad: any): Observable<any> {
    const href = `${Url.CVB}/training/save`;
    return this.http.post<any>(href, payLoad);
  }

  saveAndLaterTraining(payLoad: any): Observable<any> {
    const href = `${Url.CVB}/training/draft`;
    return this.http.post<any>(href, payLoad);
  }

  getTrainingInfo() {
    const href = `${Url.CVB}/training`;
    return this.http.get(href);
  }

  saveNoTraining(data) {
    const href = `${Url.CVB}/training/untraining/save`;
    return this.http.post<any>(href, data);
  }

   draftNoTraining(data) {
    const href = `${Url.CVB}/training/untraining/draft`;
    return this.http.post<any>(href, data);
  }

  deleteTraining(trId: any): any {
    const href = `${Url.CVB}/training/${trId}`;
    return this.http.delete<any>(href);
  }

}
