<footer class="footer full-width-container" id="footer" role="contentinfo">
  <div class="site-width-container footer-container ">

    <div class="grid-row">
      <div class="column-half">
        <p class="footer-copyright font-xsmall">© Meganexus Ltd {{currentYear}} | Version V1</p>
        <!-- <p>Version V1</p> -->
      </div>

      <div class="column-half footer-logo">
        <span class="footer-logotype">
          <img src="./assets/images/logo-meganexus.png" alt="Virtual Campus logotype">
        </span>
      </div>

    </div>

  </div>
</footer>