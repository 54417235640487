<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="personal-details mt25">
  <div class="grid-row">
    <vc-all-errors [groups]="[personalForm,addressForm]" [fromContext]="true"></vc-all-errors>
    <form [formGroup]="personalForm" autocomplete="off">
      <div class="column-full">
        <vc-form-control [control]="personalForm.get('title')" [controlName]="'title'" [label]="'Title'"
          [optional]="'(optional)'" [fromContext]="true">
          <input formControlName="title" class="form-control" type="text" id="id-title" name="title" value=""
            aria-describedby="id-section-help-title id-section-error-title">
        </vc-form-control>
      </div>

      <div class="column-full">
        <vc-form-control [control]="personalForm.get('firstName')" [controlName]="'firstName'" [label]="'First name'"
          [fromContext]="true">
          <input formControlName="firstName" [ngClass]="{'errorfocus': personalForm.get('firstName')?.errors }"
            class="form-control" type="text" id="id-firstName" name="firstName" value=""
            aria-describedby="id-section-help-firstName id-section-error-firstName" spellcheck="false">
        </vc-form-control>
      </div>

      <div class="column-full">
        <vc-form-control [control]="personalForm.get('lastName')" [controlName]="'lastName'" [label]="'Last name'"
          [fromContext]="true">
          <input formControlName="lastName" [ngClass]="{'errorfocus': personalForm.get('lastName')?.errors }"
            class="form-control" type="text" id="id-lastName" name="lastName" value=""
            aria-describedby="id-section-help-lastName id-section-error-lastName" spellcheck="false">
        </vc-form-control>
      </div>

      <div class="column-full">
        <vc-form-control [control]="personalForm.get('telephone')" [controlName]="'telephone'" [label]="'Telephone'"
          [optional]="'(optional)'" [fromContext]="true">
          <input formControlName="telephone" class="form-control" type="text" id="id-telephone" name="telephone"
            value="" aria-describedby="id-section-help-telephone id-section-error-telephone">
        </vc-form-control>
      </div>

      <div class="column-full">
        <vc-form-control [control]="personalForm.get('mobileNumber')" [controlName]="'mobileNumber'"
          [label]="'Mobile number'" [optional]="'(optional)'" [fromContext]="true">
          <input formControlName="mobileNumber" class="form-control" type="text" id="id-mobileNumber"
            name="mobileNumber" value="" aria-describedby="id-section-help-mobileNo id-section-error-mobileNo">
        </vc-form-control>
      </div>

      <div class="column-full">
        <vc-form-control [control]="personalForm.get('emailAddress')" [controlName]="'emailAddress'"
          [label]="'Email address'" [optional]="'(optional)'" [fromContext]="true">
          <input formControlName="emailAddress" class="form-control" type="email" id="id-email" name="email" value=""
            aria-describedby="id-section-help-emailAddress id-section-error-emailAddress" spellcheck="false">
        </vc-form-control>
      </div>

    </form>
    <form [formGroup]="addressForm" autocomplete="off">

      <div class="column-full">
        <vc-form-control [control]="addressForm.get('addressLineOne')" [controlName]="'addressLineOne'"
          [label]="'Address line one'" [fromContext]="true" [optional]="'(optional)'">
          <input formControlName="addressLineOne" class="form-control" type="text" id="id-addressLineOne"
            name="addressLineOne" value=""
            aria-describedby="id-section-help-addressLineOne id-section-error-addressLineOne">
        </vc-form-control>
      </div>

      <div class="column-full">
        <vc-form-control [control]="addressForm.get('town')" [controlName]="'town'" [label]="'Town or city'"
          [fromContext]="true" [optional]="'(optional)'">
          <input formControlName="town" class="form-control" type="text" id="id-town" name="town" value=""
            aria-describedby="id-section-help-town id-section-error-town">
        </vc-form-control>
      </div>

      <div class="column-full">
          <vc-form-control [control]="addressForm.get('county')" [controlName]="'county'" [label]="'County'"
            [fromContext]="true" [optional]="'(optional)'">
            <input formControlName="county" class="form-control" type="text" id="id-county" name="county" value=""
              aria-describedby="id-section-help-county id-section-error-county">
          </vc-form-control>
      </div>

      <div class="column-full">
          <vc-form-control [control]="addressForm.get('postCode')" [controlName]="'postCode'" [label]="'Post code'"
            [fromContext]="true" [optional]="'(optional)'">
            <input formControlName="postCode" class="form-control" type="text" id="id-postCode" name="postCode" value=""
              aria-describedby="id-section-help-postCode id-section-error-postCode">
          </vc-form-control>
      </div>
      
    </form>
  </div>
  <div class="grid-row mb25">
    <button id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
    (click)="saveAndContinue()">Save and
    continue</button>
    <button id="id-save-and-come-back-later" class="button button-secondary mr15" type="button"
    name="save-and-come-back-later"  (click)="saveAndLater()">Save and come back
    later</button>
    <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
      (click)="cvSummaryPage()">CV summary</button>
  </div>
</div>