import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const courseInfoSchema: RxCollectionCreatorBase = {
    schema: {
        title: 'Course Info',
        version: 0,
        description: 'Plan details',
        type: 'object',
        properties: {
            categoryId: {
                type: 'number'
            },
            courseCode: {
                type: 'string'
            },
            courseName: {
                type: ['string', 'null']
            },
            endDate: {
                type: ['string', 'null']
            },
            startDate: {
                type: ['string', 'null']
            },
            status: {
                type: ['string', 'null']
            },
            isAccredited: {
                type: ['boolean', 'null']
            }
        }
    }
}
