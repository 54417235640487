import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const walletDOcumentSchema: RxCollectionCreatorBase = {
    schema: {
        title: 'Wallet Documents',
        version: 0,
        description: 'Wallet Documents details',
        type: 'object',
        properties: {
            id: {
                type: ['number', 'null']
            },
            serviceUserId: {
                type: 'number'
            },
            attachmentType: {
                type: ['string', 'null']
            },
            createdDate: {
                type: ['string', 'null']
            },
            description: {
                type: ['string', 'null']
            },
            fileType: {
                type: ['string', 'null']
            },
            name: {
                type: ['string', 'null']
            }
        },
        required: ['serviceUserId'],
        attachments: {
            encrypted: false // if true, the attachment-data will be encrypted with the db-password
        }
    }
}