import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../../../core/services/main.service';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { INDUCTION_QUESTIONS_VALIDATION_CONTEXT } from '../induction-questions-list.validation';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from "../../../../shared/components/confirm-box/confirm-box.service";

const PAGETHIRTEENFORM_VALIDATORS_SAVE = {
  barriersToLearning: [Validators.required]
}

const PAGETHIRTEENFORM_VALIDATORS_DONTKNOW = {
  barriersToLearning: [ValidationService.maxSelected(1)]
}

@Component({
  selector: 'vc-page-thirteen',
  templateUrl: './page-thirteen.component.html',
  styleUrls: ['./page-thirteen.component.scss']
})
export class PageThirteenComponent implements OnInit, AfterViewChecked {
  currentPageNumber: number;
  currentPageData = [] as any;
  refData = [] as any;
  pageThirteenForm: FormGroup;
  dontKnowAnswerValue = '273';
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  checkboxChange: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-twelve';
  routeNext = '/plan/induction/check-answers';
  isAssessmentInProgressFlag = false;

  routeState: any;
  @ViewChild("question") questionDiv: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly mainService: MainService,
    private readonly formBuilder: FormBuilder,
    private readonly renderer: Renderer2,
    private readonly validation: ValidationService,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly togetherMode: TogetherModeModalService,
    private readonly confirm: ConfirmService,
  ) {
    this.mainService.pageTitle = 'Induction';
    this.initForm();
    this.validation.setContext(INDUCTION_QUESTIONS_VALIDATION_CONTEXT);
  }

  ngOnInit() {
    this.getRefData();
    this.resolveRouteState();
    this.isSaveSubmitOperation = false;
    this.checkboxChange = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageThirteenForm.dirty && this.isAssessmentInProgressFlag === false || this.checkboxChange === true && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.isSaveSubmitOperation = true;
    this.router.navigateByUrl(this.routeBack);
  }

  initForm() {
    this.pageThirteenForm = this.formBuilder.group({
      barriersToLearning: this.formBuilder.array([])
    })
  }
  
  onCheckboxChange(event) {
    const barriersToLearning: FormArray = this.pageThirteenForm.get('barriersToLearning') as FormArray;

    if (event.target.value === this.dontKnowAnswerValue && event.target.checked === true) {
      barriersToLearning.clear();
    }

    if (event.target.checked) {
      barriersToLearning.push(new FormControl(event.target.value));
    } else {
      let i = 0;
      barriersToLearning.controls.forEach((item: FormControl) => {
        if (item.value === event.target.value) {
          barriersToLearning.removeAt(i);
          return;
        }
        i++
      });
    }
    this.checkboxChange = true;
  }

  checkIfChecked(answerValue) {
    let checkedState = false;
    const barriersToLearning: FormArray = this.pageThirteenForm.get('barriersToLearning') as FormArray;
    let i = 0;
    barriersToLearning.controls.forEach((item: FormControl) => {
      if (item.value === answerValue) {
        checkedState = true
      }
      i++
    })
    return checkedState;
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.currentPageNumber = 12;
      this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier,
            questionId: question.id 
          }
        )
        if (question.answer) {
          const barriersToLearning: FormArray = this.pageThirteenForm.get('barriersToLearning') as FormArray;
          const savedAnswers = this.resolveSavedAnswers(question.answer);
          savedAnswers.forEach(answer => {
            barriersToLearning.push(new FormControl(answer.toString()))
          })
        }
      });
    })
  }

  resolveSavedAnswers(savedAnswer) {
    return JSON.parse(savedAnswer).choiceIds;
  }

  onClickSave() {
    const formattedPayload = this.formatPayload(true);
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      this.isSaveSubmitOperation = true;
      this.router.navigateByUrl(this.routeTo);
      this.checkboxChange = false;
      window.scrollTo(0, 0);
    })
  }

  onClickContinue() {
    if (this.pageThirteenForm.value.barriersToLearning.includes(this.dontKnowAnswerValue) && this.pageThirteenForm.value.barriersToLearning.length > 1) {
      this.setValidators(this.pageThirteenForm, PAGETHIRTEENFORM_VALIDATORS_DONTKNOW);
      this.pageThirteenForm.controls['barriersToLearning'].setErrors({'maxSelected': true});
    } else {
      this.setValidators(this.pageThirteenForm, PAGETHIRTEENFORM_VALIDATORS_SAVE);
    }

    if (this.pageThirteenForm.valid) {
      const formattedPayload = this.formatPayload(false);
      this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
        this.isSaveSubmitOperation = true;
        this.router.navigateByUrl('/plan/induction/check-answers');
        window.scrollTo(0, 0);
      })
    } else {
      this.setFocusToErrorSummary();
    }
    this.checkboxChange = false;
  }

  canExit(route, state, nextState) {
    if (this.checkboxChange === true &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      this.isSaveSubmitOperation === false &&
      !this.isCompleted) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  formatPayload(saveAsDraft) {
    const formattedBarriersArr = [];
    this.pageThirteenForm.controls.barriersToLearning.value.forEach(barrier => {
      formattedBarriersArr.push(parseInt(barrier))
    })
    return {
      "isSaveAsDraft": saveAsDraft,
      "planTypeIdentifier": "1",
      "questionDTOList": [
        {
          "id": this.currentPageData[0].questionId,
          answer: JSON.stringify({
            choiceIds: formattedBarriersArr
          })
        }
      ],
      "sectionIdentifier": this.currentPageData[0].sectionIdentifier
    }
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  setFocusToQuestionDiv() {
    this.questionDiv.nativeElement.focus();
  }

  setInputId(i) {
    let labelId = `barriersToLearning-${i}`;

    if (this.pageThirteenForm.controls.barriersToLearning.errors) {
      if (this.pageThirteenForm.controls.barriersToLearning.errors.maxSelected === true ) {
        if (i === 8) labelId = 'barriersToLearning';
      } else {
        if (i === 0) labelId = 'barriersToLearning';
      }
    } else {
      if (i === 0) labelId = 'barriersToLearning';
    }
    return labelId
  }

}
