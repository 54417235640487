import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/features/user-profile/user-profile.service';
import { ConnectionService } from 'ng-connection-service'; 

@Directive({
  selector: '[vcShowAuthed]'
})

export class AuthenticationDirective implements OnInit {

  condition: boolean;
  isConnected = true; 

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly userProfileService: UserProfileService,
    private connectionService: ConnectionService,
  ) {
  }

  @Input()
  set vcShowAuthed(condition: boolean) {
    this.condition = condition;
  }

  ngOnInit(): void {

    if (this.condition === null) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
      // this.userProfileService.getCachedLearnerInfo().subscribe(info => {
      //   this.connectionService.monitor().subscribe(isConnected => {    
      //     //if(!localStorage.getItem('sessionToken') && !this.condition || isConnected && (localStorage.getItem('sessionToken') && this.condition)) {
      //       if ((isConnected && info.deviceId != null) || !localStorage.getItem('sessionToken') && 
      //       this.condition || !localStorage.getItem('sessionToken') && !this.condition) {
      //       this.viewContainer.createEmbeddedView(this.templateRef);
      //     }  
      //     else {  
      //       this.viewContainer.clear();
      //     } 
      //   });
      // })

    
      if (localStorage.getItem('sessionToken') && this.condition || !localStorage.getItem('sessionToken') && !this.condition) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }    


  }

}
