import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const contentSchema: RxCollectionCreatorBase = {
    schema: {
        title: 'Content',
        version: 0,
        description: 'Content details',
        type: 'object',
        properties: {
            contentID: {
                type: 'string',
                primary: true
            },
            categoryColor: {
                type: ['string', 'null']
            },
            imagePath: {
                type: ['string', 'null']
            },
            contentKeyWords: {
                type: 'string'
            },
            isFavourite: {
                type: ['boolean', 'null']
            },
            description: {
                type: 'string'
            },
            title: {
                type: 'string'
            },
            imageID: {
                type: ['number', 'null']
            },
            mainCategoryId: {
                type: 'number'
            },
            subCategoryId: {
                type: ['number', 'null']
            },
            thumbnail: {
                type: ['string', 'null']
            },
            modifiedDate: {
                type: ['string', 'null']
            },
            uploadedDate: {
                type: ['string', 'null']
            },
            modules: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'number'
                        },
                        lmsCourseId: {
                            type: ['number', 'null']
                        },
                        moduleDescription: {
                            type: 'string'
                        },
                        name: {
                            type: 'string'
                        },
                        path: {
                            type: ['string', 'null']
                        },
                        scormType: {
                            type: ['string', 'null']
                        },
                        sequence: {
                            type: ['number', 'null']
                        },
                        status: {
                            type: ['string', 'null']
                        },
                        type: {
                            type: ['string', 'null']
                        },
                        fileName: {
                            type: ['string', 'null']
                        }
                    }
                }
            }
        },
        required: ['contentID'],
        attachments: {
            encrypted: false
        },
        indexes: ['contentID', 'title', 'description', 'mainCategoryId', 'contentKeyWords', 'modules.[].name', 'modules.[].moduleDescription']
    }
}