
export const PATHWAY_VALIDATION = {
    unChecked: {
        hasAddedValue: `Please select at least one employment pathway from the available`
    },
    minSelected: {
        answers: `Please select at least one employment pathway from the available options`
    },
    maxSelected: {
        answers: `Please select at least one employment pathway from the available options`
    },
}