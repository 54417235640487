import { HttpClient, HttpParams } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { Injectable, Output,EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

 @Output()  walletFlag: EventEmitter<boolean> = new EventEmitter();

constructor(private readonly http: HttpClient) { }

 getNotificationCount() {
    const href = `${Url.PROFILE}/notification`;
    return this.http.get<any>(href);
  }

  updateNotificationCount(payload: any): Observable<any> {
    const href = `${Url.PROFILE}/notification`;
    return this.http.post(href, payload);
  }

emitWalletNotification(flag) {
    this.walletFlag.emit(flag);
  }
}