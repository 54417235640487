import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { PlanService } from '../plan.service';
import { TogetherModeModalService } from '../../../shared/components/together-mode-modal/together-mode-modal.service';
import { InductionQuestionnaireService } from './../induction-questionnaire/induction-questionnaire.service';
import { InductionCheckAnswersService } from './../induction-check-answers/induction-check-answers.service';
import { AuthenticationService } from './../../../authentication/authentication.service';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'vc-plan-content-card',
  templateUrl: './plan-content-card.component.html',
  styleUrls: ['./plan-content-card.component.scss'],
})
export class PlanContentCardComponent implements OnInit, AfterViewChecked {

  planAgreeFlag = null;
  togetherModeFlag = false;
  activeTab: string;
  goalsData: any = undefined;
  noPlanMessage: string;
  categoryList: any = undefined;
  historyData: any = undefined;
  todaysDate: Date;
  timelineStartUnix: number;
  timelineEndUnix: number;
  fStructureData: any = undefined;
  timeTimeZoneConstant = '14:00:00 GMT+00:00';
  loadMoreVisible = false;
  fStructureDataPageNumber: number = undefined;
  learner: any;

  constructor(
    private readonly planService: PlanService,
    private readonly router: Router,
    public readonly togetherModeModalService: TogetherModeModalService,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly inductionCheckAnswersService: InductionCheckAnswersService,
    private readonly authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.activeTab = 'schedule';
    sessionStorage.setItem('isAssessmentInProgress', 'false');
    this.todaysDate = new Date();
    this.setTimelineUnixTimestamps();
    this.learner = this.authService.currentUser();
    this.getGoals();
  }

  ngOnChanges() { }

  ngAfterViewChecked() {
    if (this.togetherModeModalService.getTogetherModeEnabledStatus() && this.togetherModeFlag === false) {
      this.togetherModeFlag = true;
      this.checkIfPlanAgreed();
    }
  }

  onClickCreatePlan() {
    this.inductionQuestionnaireService.getAnsweredQuestion().subscribe(data => {
      let currentQuestionAnswered = ''
      if (this.planAgreeFlag === true) {
        currentQuestionAnswered = 'check-answers';
      } else {
        data[0].answer === null && data[0].sectionIdentifier === '1' ? currentQuestionAnswered = 'start-page' : currentQuestionAnswered = data[0].sectionIdentifier;
      }
      switch(currentQuestionAnswered) {
        case 'start-page':
          this.router.navigateByUrl('/plan/induction');
          break;
        case '1':
          this.router.navigateByUrl('/plan/induction/page-one');
          break;
        case '2':
          this.router.navigateByUrl('/plan/induction/page-two');
          break;
        case '3':
          this.router.navigateByUrl('/plan/induction/page-three');
          break;
        case '4':
          this.router.navigateByUrl('/plan/induction/page-four');
          break;
        case '5':
          this.router.navigateByUrl('/plan/induction/page-five');
          break;
        case '6':
          this.router.navigateByUrl('/plan/induction/page-six');
          break;
        case '14':
          this.router.navigateByUrl('/plan/induction/page-seven');
          break;
        case '8':
          this.router.navigateByUrl('/plan/induction/page-eight');
          break;
        case '9':
          this.router.navigateByUrl('/plan/induction/page-nine');
          break;
        case '10':
          this.router.navigateByUrl('/plan/induction/page-ten');
          break;
        case '11':
          this.router.navigateByUrl('/plan/induction/page-eleven');
          break;
        case '12':
          this.router.navigateByUrl('/plan/induction/page-twelve');
          break;
        case 'check-answers':
          this.router.navigateByUrl('/plan/induction/check-answers');
          break;
      }
    })
  }

  checkIfPlanAgreed() {
    this.inductionCheckAnswersService.checkPlanAgreed().subscribe(data => {
      this.planAgreeFlag = data.isMainPlanAgree;
    })
  }

  setActiveTab(activeTabLabel) {
    if (this.historyData) {
      this.activeTab = activeTabLabel;
    } else {
      this.getAndFormatTrackTabData();
      this.activeTab = activeTabLabel;
    }
  }

  getAndFormatTrackTabData() {
    this.fStructureDataPageNumber = 0;
    this.planService.getFstructureData(this.learner.userId, this.fStructureDataPageNumber).subscribe(data => {
      this.fStructureData = data.map(entry => (
        {
          ...entry, 
          entryDateUnix: this.formatEntryDateUnix(entry),
          formattedDescription: this.formatDescription(entry),
        }
      ))
      if (!data.empty) {
        this.addTodayLineEntryToFStructureData();
      }
      data.last ? this.loadMoreVisible = false : this.loadMoreVisible = true;
    })
  }

  addTodayLineEntryToFStructureData() {
    const todaysDateDay = this.todaysDate.getDate()
    const todaysDateMonth = this.todaysDate.getMonth()
    const todaysDateYear = this.todaysDate.getFullYear()
    const todaysDateString = `${todaysDateMonth + 1}/${todaysDateDay}/${todaysDateYear}`;
    const todayDateUnix = new Date(`${todaysDateString} ${this.timeTimeZoneConstant}`).getTime() / 1000;
    const firstHistoryEntryIndex = this.fStructureData.findIndex(entry => entry.entryDateUnix <= todayDateUnix)
    const todayLineEntry = {
      activityId: 'today',
      entryDateUnix: todayDateUnix,
      title: "Today",
    }
    if (firstHistoryEntryIndex === -1) {
      for (let i = 0; i < this.fStructureData.length; i++) {
        this.fStructureData[i].isFuture = true;
      }
      this.fStructureData.push(todayLineEntry)
    } else {
      this.fStructureData.splice(firstHistoryEntryIndex, 0, todayLineEntry);
      for (let i = 0; i < firstHistoryEntryIndex; i++) {
        this.fStructureData[i].isFuture = true;
      }
    }
  }

  formatEntryDateUnix(entry) {
    let entryDateString = '';
    switch(entry.activityId) {
      case 5:
        const hyphenIndex = entry.subTitle.indexOf('-')
        if (entry.interventionType === 'End') {
          entryDateString = entry.subTitle.substring(hyphenIndex + 1);
          return new Date(`${entryDateString} ${this.timeTimeZoneConstant}`).getTime() / 1000;
        } else {
          entryDateString = entry.subTitle.substring(0, hyphenIndex - 1);
          return new Date(`${entryDateString} ${this.timeTimeZoneConstant}`).getTime() / 1000;
        }
      case 3:
      case 6:
        const byIndex = entry.subTitle.search('by')
        entryDateString = entry.subTitle.substring(0, byIndex - 1);
        return new Date(`${entryDateString} ${this.timeTimeZoneConstant}`).getTime() / 1000;
      default:
        return new Date(`${entry.subTitle} ${this.timeTimeZoneConstant}`).getTime() / 1000;
    }
  }

  formatDescription(entry) {
    if (entry.activityId === -1 || entry.activityId === 4) {
      const descriptionArr = [];
      descriptionArr.push(entry.description.slice(1, entry.description.length - 1))
      if (entry.title === 'Employment pathway changed') {
        const tempFormattedDescriptionArr = this.formatGoals(descriptionArr);
        return tempFormattedDescriptionArr.map(answerString => {
          return this.splitTildeString(answerString)
        })
      } else {
        return this.formatGoals(descriptionArr)
      }
    } else {
      return null
    }
  }

  splitTildeString(string) {
    const tempAnswerArr = string.split("~~")
    return tempAnswerArr.map(answer => {
      return answer.trim();
    })
  }

  getGoals() {
    this.planService.getGoals().subscribe(data => {
      this.goalsData = [];
      if (data.planGoal !== null) {
        const formattedGoals = [];
        data.planGoal.forEach(goalType => {
          const goalsTypeArr = []
          goalsTypeArr.push(goalType)
          formattedGoals.push(this.formatInsetGoalsWithCommas(goalsTypeArr))
        });

        this.goalsData = formattedGoals.map(goalsArr => {
          return goalsArr.map(goal => {
            return goal.charAt(0).toUpperCase() + goal.slice(1)
          })
        })
      } else {
        this.goalsData = null;
      }
    }, error => {
      this.noPlanMessage = error.error.errorMessage
      this.goalsData = null;
    })
    this.getTimelineData();
  }

  formatInsetGoalsWithCommas(goalsArr) {
    const tempGoalsArr = [];
    goalsArr.forEach(element => {
      const colonIndex = element.indexOf(':');
      const formattedString = element.slice(0, colonIndex - 1) + ',' + element.slice(colonIndex + 2, element.length);
      tempGoalsArr.push(formattedString)
    });

    if (tempGoalsArr.length > 0) {
      const tempCommaSplitIndex = [];
      const tempCommaNotSplitIndex = [];
  
      for (let i = 0; i < tempGoalsArr[0].length; i++) {
        if (tempGoalsArr[0][i] === ',') {
          tempGoalsArr[0][i+1] === ' ' ? tempCommaNotSplitIndex.push([i]) : tempCommaSplitIndex.push([i]);
        }
      }
      const formattedGoalsArray = this.setTempFormattedGoalsArr(tempCommaSplitIndex, tempGoalsArr)
      formattedGoalsArray.shift();
      return formattedGoalsArray
    } else {
      return goalsArr
    }
  }

  formatGoals(goalsArr) {
    const splitAnswerArr = this.formatGoalWithCommas(goalsArr);
    const formattedTempGoalsArr = [];
    splitAnswerArr.forEach(element => {
      formattedTempGoalsArr.push(element.charAt(0).toUpperCase() + element.slice(1));
    })
    return formattedTempGoalsArr
  }

  formatGoalWithCommas(goalsArr) {
    const tempCommaSplitIndex = [];
    const tempCommaNotSplitIndex = [];

    let i = 0;
    for (i = 0; i < goalsArr[0].length; i++) {
      if (goalsArr[0][i] === ',') {
        goalsArr[0][i+1] === ' ' ? tempCommaNotSplitIndex.push([i]) : tempCommaSplitIndex.push([i]);
      }
    }
    return this.setTempFormattedGoalsArr(tempCommaSplitIndex, goalsArr)
  }

  setTempFormattedGoalsArr(tempCommaSplitIndex, goalsArr) {
    const tempFormattedAnswerArr = [];
    switch(tempCommaSplitIndex.length) {
      case 0:
        tempFormattedAnswerArr.push(goalsArr[0]);
        break;
      case 1:
        tempFormattedAnswerArr.push(goalsArr[0].substring(0, tempCommaSplitIndex[0]))
        tempFormattedAnswerArr.push(goalsArr[0].substring(parseInt(tempCommaSplitIndex[0])+1, goalsArr[0].length))
        break;
      default:
        let i = 0;
        for (i = 0; i < tempCommaSplitIndex.length; i++) {
          if (i === 0) {
            tempFormattedAnswerArr.push(goalsArr[0].substring(0, tempCommaSplitIndex[i]))
          } else if (i === tempCommaSplitIndex.length - 1) {
            tempFormattedAnswerArr.push(goalsArr[0].substring(parseInt(tempCommaSplitIndex[i-1])+1, parseInt(tempCommaSplitIndex[i])))
            tempFormattedAnswerArr.push(goalsArr[0].substring(parseInt(tempCommaSplitIndex[i])+1, goalsArr[0].length))
          } else {
            tempFormattedAnswerArr.push(goalsArr[0].substring(parseInt(tempCommaSplitIndex[i-1])+1, parseInt(tempCommaSplitIndex[i])))
          }
        }
    }
    return tempFormattedAnswerArr
  }

  getTimelineData() {
    this.planService.getRefCategoryList().subscribe(categoryList => {
      this.planService.getCourses().subscribe(data => {
        this.categoryList = categoryList.map(element => ({...element, cssLabel: element.value.replace(/\s+/g, '-').toLowerCase()}));
        this.categoryList.forEach(element => {
          element.cssLabel = element.cssLabel.replace("\/", "and");
          element.cssLabel = element.cssLabel.replace("&", "and")
        });
  
        const tempCoursesData = data;
        tempCoursesData.forEach(element => {
          element.startDate = element.startDate.replace(/-/g, "\/");
          element.endDate = element.endDate.replace(/-/g, "\/");
          element.unixStartDate = new Date(`${element.startDate} 14:00:00 GMT+00:00`).getTime() / 1000;
          element.unixEndDate = new Date(`${element.endDate} 14:00:00 GMT+00:00`).getTime() / 1000;
        });
        const sortedTempTimelineData = tempCoursesData.sort((a, b) => a.unixEndDate - b.unixEndDate);
  
        this.categoryList.forEach(element => {
          element.coursesData = [[],[],[],[],[]];
          element.tempCoursesData = [];
        });
        
        const orderedSortedTempTimelineData = this.orderSortedTimelineData(sortedTempTimelineData)
        this.formatCoursesData(orderedSortedTempTimelineData);
        this.removeTimelinesEmptyArrays();
      })
    })
  }

  removeTimelinesEmptyArrays() {
    this.categoryList.forEach(element => {
      element.coursesData = element.coursesData.filter(element => element.length > 0)
    });
  }

  orderSortedTimelineData(sortedTimelineData) {
    sortedTimelineData.forEach(element => {
      switch (element.categoryId) {
        case 2:
          this.categoryList[0].tempCoursesData.push(element)
          break;
        case 3:
          this.categoryList[1].tempCoursesData.push(element)
          break;
        case 4:
          this.categoryList[2].tempCoursesData.push(element)
          break;
        case 5:
          this.categoryList[3].tempCoursesData.push(element)
          break;
        case 6:
          this.categoryList[4].tempCoursesData.push(element)
          break;
      }
    })

    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].tempCoursesData.length > 5) {
        const wholeTimelineCourses = this.categoryList[i].tempCoursesData.filter(course =>
          course.unixStartDate < this.timelineStartUnix && course.unixEndDate > this.timelineEndUnix
        )
        const nonWholeTimelineCourses = this.categoryList[i].tempCoursesData.filter(course => 
          ((course.unixStartDate < this.timelineStartUnix || course.unixStartDate >= this.timelineStartUnix) && course.unixEndDate <= this.timelineEndUnix) ||
          (course.unixStartDate >= this.timelineStartUnix && (course.unixEndDate <= this.timelineEndUnix || course.unixEndDate > this.timelineEndUnix))
        )
        const coursesListWithWholeTimeslinesFirst = wholeTimelineCourses.concat(nonWholeTimelineCourses)
        const firstFiveCoursesToFinish = coursesListWithWholeTimeslinesFirst.slice(0, 5)
        const remainingCourses = coursesListWithWholeTimeslinesFirst.slice(5)
        const sortedRemainingCourses = remainingCourses.sort((a, b) => a.unixStartDate - b.unixStartDate);
        const tempOrderedCourses = firstFiveCoursesToFinish.concat(sortedRemainingCourses);
        this.categoryList[i].orderedTempCoursesData = tempOrderedCourses
      } else {
        this.categoryList[i].orderedTempCoursesData = this.categoryList[i].tempCoursesData
      }
    }
    return this.categoryList[0].orderedTempCoursesData.concat(
      this.categoryList[1].orderedTempCoursesData, 
      this.categoryList[2].orderedTempCoursesData, 
      this.categoryList[3].orderedTempCoursesData, 
      this.categoryList[4].orderedTempCoursesData
    )
  }

  formatCoursesData(coursesData) {
    coursesData.forEach(element => {
      const arrIndex = this.categoryList.findIndex(courseObject => courseObject.id === element.categoryId);
      element.cssLabel = this.categoryList[arrIndex].cssLabel;
      this.formatCategoryRows(element, arrIndex);
    });
  }

  formatCategoryRows(element, arrIndex) {
    for (let i = 0; i < this.categoryList[arrIndex].coursesData.length; i++) {
      if (
        this.categoryList[arrIndex].coursesData[i].length === 0 ||
        this.categoryList[arrIndex].coursesData[0].length > 0 && 
        this.categoryList[arrIndex].coursesData[1].length > 0 && 
        this.categoryList[arrIndex].coursesData[2].length > 0 && 
        this.categoryList[arrIndex].coursesData[3].length > 0 && 
        this.categoryList[arrIndex].coursesData[4].length > 0 &&
        element.unixStartDate >= this.categoryList[arrIndex].coursesData[i][this.categoryList[arrIndex].coursesData[i].length-1].unixEndDate &&
        (
          element.unixStartDate !== this.categoryList[arrIndex].coursesData[i][this.categoryList[arrIndex].coursesData[i].length-1].unixStartDate && 
          element.unixEndDate !== this.categoryList[arrIndex].coursesData[i][this.categoryList[arrIndex].coursesData[i].length-1].unixEndDate
        )
      ) {
        this.categoryList[arrIndex].coursesData[i].push(element)
        break;
      }
    }
  }

  setTimelineUnixTimestamps() {
    this.timelineStartUnix = this.setTimelineStartUnix();
    this.timelineEndUnix = this.setTimelineEndUnix();
  }

  setTimelineStartUnix() {
    if (this.todaysDate.getMonth() === 0) {
      const previousMonthsNum = 12;
      const previousMonthsYear = this.todaysDate.getFullYear() - 1;
      return new Date(`${previousMonthsYear}/${previousMonthsNum}/01 14:00:00 GMT+00:00`).getTime() / 1000
    } else {
      const previousMonthsNum = this.todaysDate.getMonth();
      const previousMonthsYear = this.todaysDate.getFullYear();
      return new Date(`${previousMonthsYear}/${previousMonthsNum}/01 14:00:00 GMT+00:00`).getTime() / 1000
    }
  }

  setTimelineEndUnix() {
    if (this.todaysDate.getMonth() === 11) {
      const nextMonthsNum = 1;
      const nextMonthsYear = this.todaysDate.getFullYear() - 1;
      const nextMonthTotalDays = new Date(nextMonthsYear, nextMonthsNum, 0).getDate();
      return new Date(`${nextMonthsYear}/${nextMonthsNum}/${nextMonthTotalDays} 14:00:00 GMT+00:00`).getTime() / 1000
    } else {
      const nextMonthsNum = this.todaysDate.getMonth() + 2;
      const nextMonthsYear = this.todaysDate.getFullYear();
      const nextMonthTotalDays = new Date(nextMonthsYear, nextMonthsNum, 0).getDate();
      return new Date(`${nextMonthsYear}/${nextMonthsNum}/${nextMonthTotalDays} 14:00:00 GMT+00:00`).getTime() / 1000
    }
  }

  onLoadMoreClicked($event: any) {
    this.fStructureDataPageNumber = this.fStructureDataPageNumber + 1
    this.planService.getFstructureData(this.learner.userId, this.fStructureDataPageNumber).subscribe(data => {
      const formattedData = data.content.map(entry => (
        {
          ...entry, 
          entryDateUnix: this.formatEntryDateUnix(entry),
          formattedDescription: this.formatDescription(entry),
        }
      ))
      this.fStructureData = this.fStructureData.concat(formattedData)
      data.last ? this.loadMoreVisible = false : this.loadMoreVisible = true;
    })
  }
  
}
