import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EditWorkExperienceService {

	_formGroupsSubs: BehaviorSubject<FormGroup[]> = new BehaviorSubject([]);

	constructor() { }

	get formGroupsSubs(): BehaviorSubject<any> {
		return this._formGroupsSubs;
	}

	setFormGroups(forms: FormGroup[]) {
		this._formGroupsSubs.next(forms);
	}

	reset() {
		this._formGroupsSubs.next([]);
  }
  
}