import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../../../app/core/services/main.service';
import { EmploymentService } from '../../employment.service';
import { Location } from '@angular/common';
import { PrisonJob } from './prison-job.interface';
import { ExpressedInterest } from '../../employment.interface';

@Component({
  selector: 'vc-prison-job-detail',
  templateUrl: './prison-job-detail.component.html',
  styleUrls: ['./prison-job-detail.component.scss']
})
export class PrisonJobDetailComponent implements OnInit {

  advertprisonJobDetails: PrisonJob;
  isInterested: boolean;
  expressedInterest: ExpressedInterest;
  
  constructor(private readonly route: ActivatedRoute,
    private readonly mainService: MainService,
    private readonly employmentService: EmploymentService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly renderer: Renderer2
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = this.route.snapshot.data.mainTitle;
    this.renderer.addClass(document.body, 'changeCaseOfTitle');
   }
  
  
  ngOnDestroy(): void {
    this.mainService.pageMainTitle = '';
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      if (params.hasOwnProperty('Id')) {
        this.employmentService.getAdvertPrisonJobDetails(params['Id']).subscribe(response => {
          this.advertprisonJobDetails = response;
        });
        this.employmentService.getAdvertPrisonJobStatus(params['Id']).subscribe(response => {
          this.expressedInterest= response;
        });
      }})
      window.scrollTo(0,0);
  };

  backClicked() {  
     this.location.back();
  }

  expressInterest(id: any){
    this.employmentService.expressInterestPrison(id).subscribe(res=> {
    this.expressedInterest.dateExpressed= res.dateExpressed;
    this.expressedInterest.status =true;
    window.scrollTo(0,0);
    });
  }
}
