<h2> <a href="javascript:void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a></h2>
<div class="grid-row">
  <div class="header">
    <div class="tabWrapper">
      <ul>
        <li routerLink="" class="tabHead active" [ngClass]="{'active': toDoAssessments}" (click)="getToDOAssessments()">
          <a class="tab-item" id="month-tab" attr.aria-label="tab Month">To-do</a>
        </li>
        <li routerLink="" class="tabHead" [ngClass]="{'active': completedAssessments}"
          (click)="getCompletedAssessments()">
          <a class="tab-item" id="year-tab" attr.aria-label="tab Year">Completed</a>
        </li>
        <li routerLink="" class="tabHead" [ngClass]="{'active': allAssessments}" (click)="getAllAssessments()">
          <a class="tab-item" id="year-tab" attr.aria-label="tab Year">All</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="assessment_table">
  <h2 class="to-do" [ngClass]="{'showTitle': toDoAssessments}">To-do</h2>
  <h2 class="to-do" [ngClass]="{'showTitle': completedAssessments}">Completed</h2>
  <h2 class="to-do" [ngClass]="{'showTitle': allAssessments}">All</h2>

  <table>
    <thead>
      <tr>
        <th scope="col">Assessments</th>
        <th scope="col">Date assigned</th>
        <th scope="col">Last updated</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of items | paginate: { itemsPerPage: pageSize, currentPage: page}">
        <td scope="row" class="width-40">
          <a [routerLink]="['complete-assessment']"
            [queryParams]="{'assessmentTemplateUserId':element.assessmentTemplateUserId,'assessmentTemplateId':element.assessmentTemplateId,'isCompleted':element.isCompleted, 'isPartiallyCompleted': element.isPartiallyCompleted,
          'assessmentName': element.assessmentName }">
            {{element.assessmentName}}</a>
        </td>
        <td class="width-22"> {{element.createdDate | date:'dd MMMM yyyy' }} </td>
        <td class="width-22">{{element.updatedDate | date:'dd MMMM yyyy' }} </td>
        <td class="width-22">
          <span *ngIf="element.isCompleted" class="status">Completed</span>
          <span *ngIf="!element.isCompleted && !element.isPartiallyCompleted && !element.updatedDate"
            class="status not-started">NOT STARTED</span>
          <span *ngIf="!element.isCompleted && (element.isPartiallyCompleted || element.updatedDate)"
            class="status in-progress">IN PROGRESS</span>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-controls [maxSize]="maxSize" (pageChange)="pageChange($event)"></pagination-controls>
</div>