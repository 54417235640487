export class CVBRoutes {

    static ROUTES: any[] = [
        {
            id : 1,
            path: 'personal-details'
        },
        {
            id : 2,
            path: 'personal-statement'
        },
        {
            id : 3,
            path: 'experience'
        },
        {
            id : 4,
            path: 'education-home'
        },
        {
            id : 5,
            path: 'training-home'
        },
        {
            id : 6,
            path: 'computer-skills'
        },
        {
            id : 7,
            path: 'additional-info'
        },
    ];
}
