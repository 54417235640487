import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entry-review',
  templateUrl: './entry-review.component.html',
  styleUrls: ['./entry-review.component.scss']
})
export class EntryReviewComponent implements OnInit {
  @Input() entryData: any;

  constructor() { }

  ngOnInit() {
    this.formatTitle()
    this.formatSubtitle()
  }

  formatTitle() {
    this.entryData.title = this.entryData.title.charAt(0).toUpperCase() + this.entryData.title.slice(1);
  }

  formatSubtitle() {
    const afterByIndex = this.entryData.subTitle.indexOf("by");
    this.entryData.formattedSubtitle = this.entryData.subTitle.slice(0, afterByIndex + 3)
    this.entryData.keyWorkerName = this.entryData.subTitle.slice(afterByIndex + 3)
  }

}
