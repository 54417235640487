import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from '../../../../core/services/main.service';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { ICanDeactivate } from '../../../../core/guards/deactivate.interface';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { PersonalDetailsService } from './personal-details.service';
import { PERSONAL_DETAILS_VALIDATION_CONTEXT } from './personal-details.validation';

const PERSONAL_FORM_VALIDATORS_SAVE = {
  title: Validators.maxLength(100),
  firstName: [Validators.required, Validators.maxLength(100)],
  lastName: [Validators.required, Validators.maxLength(100)],
  nationalInsuranceNumber: ValidationService.niNumberValidator,
  emailAddress: Validators.email,
  telephone: ValidationService.telephoneNumberValidator,
  mobileNumber : ValidationService.mobileNumberValidator
}

const ADDRESS_FORM_VALIDATORS_SAVE = {
  addressLineOne: Validators.maxLength(100),
  town: Validators.maxLength(50),
  county: Validators.maxLength(50),
  postCode: Validators.maxLength(20)
}

const ADDRESS_FORM_VALIDATORS_DRAFT = {
  addressLineOne: Validators.maxLength(100),
  town: Validators.maxLength(50),
  county: Validators.maxLength(50),
  postCode: Validators.maxLength(20)
}

@Component({
  selector: 'vc-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit, ICanDeactivate, OnDestroy {

  personalForm: FormGroup;
  addressForm: FormGroup;

  countries: string[] = [];
  counties: string[] = [];
  towns: string[] = [];

  constructor(
    private readonly location: Location,
    private readonly main: MainService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly confirm: ConfirmService,
    private readonly detailsService: PersonalDetailsService,
    private readonly validationService: ValidationService,
  ) {
    this.validationService.setContext(PERSONAL_DETAILS_VALIDATION_CONTEXT);
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'CV builder';
    this.initForm();
  }
  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }

  ngOnInit() {
    this.resolveRefData();
    this.resolvePersonalDetails();
  }

  initForm() {
    this.personalForm = this.fb.group({
      id: this.fb.control(''),
      title: this.fb.control(''),
      firstName: this.fb.control(''),
      lastName: this.fb.control(''),
      telephone: this.fb.control(''),
      mobileNumber: this.fb.control(''),
      emailAddress: this.fb.control(''),
    });
    this.addressForm = this.fb.group({
      id: this.fb.control(''),
      addressLineOne: this.fb.control(''),
      town: this.fb.control(''),
      county: this.fb.control(''),
      postCode: this.fb.control(''),
    });
  }

  resolveRefData() {
  }

  resolvePersonalDetails() {
    this.detailsService.getPersonalDetails().subscribe((res: any) => {
      if (res) {
        this.personalForm.patchValue(res);
        this.addressForm.patchValue(res.address ? res.address : {});
      }
    });
  }

  saveAndLater() {
    this.clearAllValidatorsAndErrors(this.personalForm);
    this.clearAllValidatorsAndErrors(this.addressForm);
    this.setValidators(this.addressForm, ADDRESS_FORM_VALIDATORS_DRAFT);
    if (this.personalForm.valid && this.addressForm.valid) {
      const data = this.parseSaveData();
      this.detailsService.saveAndLater(data)
        .subscribe(res => {
          this.resetAllForms();
          this.router.navigate(['..'], { relativeTo: this.route });
        });
    }
    window.scrollTo(0, 0);
  }

  saveAndContinue() {
    this.setValidators(this.personalForm, PERSONAL_FORM_VALIDATORS_SAVE);
    this.setValidators(this.addressForm, ADDRESS_FORM_VALIDATORS_SAVE);
    if (this.personalForm.valid && this.addressForm.valid) {
      const data = this.parseSaveData();
      this.detailsService.saveAndContinue(data)
        .subscribe(res => {
          this.resetAllForms();
          this.router.navigate(['../personal-statement'], { relativeTo: this.route });
        });
    }
    window.scrollTo(0, 0);
  }

  cvSummaryPage() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  gotoPrev() {
    this.location.back();
  }

  parseSaveData() {
    const personalDetails = this.personalForm.getRawValue();
    personalDetails.address = this.addressForm.getRawValue();
    return personalDetails;
  }

  canExit() {
    if (this.personalForm.dirty || this.addressForm.dirty) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    });
  }

  resetAllForms() {
    this.personalForm.markAsPristine();
    this.addressForm.markAsPristine();
  }

}
