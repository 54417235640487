import { MainService } from './../../core/services/main.service';
import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmploymentService } from './employment.service';
import { Location } from '@angular/common';

@Component({
  selector: 'vc-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.scss']
})
export class EmploymentComponent implements OnInit, OnDestroy {

  newJobs: any[] = [];
  jobOrganizations: any[] = [];
  jobOrganizationsLast: any[] = [];
  seeAllButton: Boolean = false;
  isOpen: Boolean = false;

  constructor(
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly employmentService: EmploymentService,
    private readonly location: Location
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = 'Employment';
  }
  ngOnDestroy(): void {
    this.mainService.pageMainTitle = '';
  }
  ngOnInit() {
    this.employmentService.findLatestJobs().subscribe(newJobs => {
      this.newJobs = newJobs;
    });
    this.employmentService.getJobOrganizations().subscribe(response => {
      this.jobOrganizations = response;
    });
  }

  seeAllCategory() {
    this.seeAllButton = true;
    this.isOpen = true;
  }
  seeLess() {
    this.seeAllButton = false;
    this.isOpen = !this.isOpen;
  }

  gotoPrev() {
    this.location.back();
  }
}
