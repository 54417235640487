import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { FileUtilService } from '../../shared/components/file-util/file-util.service';
import { OfflineService } from 'src/app/core/services/offline.service';

@Injectable()
export class DocumentService {

  constructor(
    private readonly http: HttpClient,
    private readonly fileUtils: FileUtilService,
    private readonly offlineService: OfflineService
  ) { }

  getDocuments() {
    if(this.offlineService.isOnline()) {
      const href = `${Url.DOCUMENT}/documents`;
      return this.http.get(href);
    } else {
      return this.offlineService.getWalletDocuments();
    }
  }


  uploadDocument(file: File, fileName: string, description: string, method: 'POST' | 'PUT') {
    const href = `${Url.DOCUMENT}/documents/upload`;
    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileName', fileName);
    fd.append('description', description);
    return this.fileUtils.uploadFileSynced(href, method, fd);
  }
  delete(id: number) {
    const href = `${Url.DOCUMENT}/documents/${id}`;
    return this.http.delete<any>(href);
  }


  download(id): any {
    const href = `${Url.DOCUMENT}/download/users/documents/${id}`;
    this.fileUtils.downloadWithDynamicForm(href);
  }

}
