<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>

<div class="grid-row" id="cv-landing">

  <vc-all-errors *ngIf="(gcvb.validationNeeded | async)" [errorList]="errorList" [isDirect]="true" 
    topLabelLink="Please complete the highlighted section(s)" ></vc-all-errors>

  <div class="column-full">
    <table>
      <caption></caption>
      <thead>
          <tr>
              <th scope="colgroup">Sections</th>
              <th scope="colgroup"></th>
          </tr>
      </thead>

      <tbody>
          <tr *ngFor="let s of sections" >
            <td>
              <div [ngClass] = "{'form-group-error': (gcvb.validationNeeded | async) && s.status !== 'Completed'}">
                <a class="link" href="javascript:void(0);" (click)="goToSection(s.sectionId)" id="id-{{s.sectionId}}">{{s.sectionName | label}}</a>
              </div>
            </td>
            <td class="alignRight">
              <span class="status-tag" [ngClass] = "{'green': s.status === 'In Progress', 'blue': s.status === 'Completed' }">{{s.status | uppercase}}</span>
            </td>
          </tr>
      </tbody>
  </table>
  </div>
  <div class="alignCenter">
    <button title="Generate CV" id="button-generate-cv" class="button button-link" (click)="generate()">Generate
      CV</button>
  </div>
</div>





