<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>

<vc-all-errors [groups]="[form]" [fromContext]="true"></vc-all-errors>
<div class="work-exp mt25">
    <div class="grid-row">
        <div  *ngIf="model.option=='Y' || revisedData.length !== 0 " class="column-full form-group">
            <button id="id-submit" class="button button-secondary" type="button" name="add-exp"
                (click)="addNewEmployment('paid')">Add paid work experience</button>
            <button id="id-submit" class="button button-secondary" style="margin-left: 7.5px;" type="button" name="add-exp"
                (click)="addNewEmployment('volunteer')">Add volunteer or community service experience</button>
            <button id="id-submit" class="button button-secondary" style="margin-left: 7.5px;" type="button" name="add-exp"
                (click)="addNewEmployment('prison')">Add prison work experience</button>
        </div>
    </div>

    <div class="grid-row">
        <table *ngIf="revisedData.length !== 0">
            <caption></caption>
            <thead>
                <tr>
                    <th scope="colgroup">Employer name</th>
                    <th scope="colgroup">Job title</th>
                    <th scope="colgroup">Start date</th>
                    <th scope="colgroup">End date</th>
                    <th scope="colgroup" class="width-15"></th>
                    <th scope="colgroup"></th>
                </tr>
            </thead>
            
            <tbody >
                    <tr *ngFor="let s of revisedData">
                        <td><span *ngIf="s?.employmentName">{{s.employmentName | truncate:[40, '...']}}</span></td>
                        <td><span *ngIf="s?.jobTitle">{{s.jobTitle | truncate:[40, '...'] }}</span></td>
                        <td><span *ngIf="s?.jobStartDate">{{s.jobStartDate | date : 'MMMM yyyy'}}</span></td>
                        <td>
                            <span *ngIf="s?.jobEndDate">{{s.jobEndDate | date : 'MMMM yyyy'}}</span>
                            <span *ngIf="!s?.jobEndDate && s?.isJobCurrent">Present</span>
                        </td>
                        <td>
                            <span class="status-tag blue" *ngIf="s?.status">COMPLETED</span>
                            <span class="status-tag green" *ngIf="!s?.status">IN PROGRESS</span>
                        </td>
                        <td>
                            <span>
                                <a href="javascript: void(0)" class="delLink link" (click)="goToEditSection(s.id,s.jobType)">Edit</a>
                            </span>
                            <span>
                                <a href="javascript: void(0)" (click)="deleteEmployment(s.id)" class="delLink link">Delete</a>
                            </span>
                        </td>
                    </tr>
                </tbody>

     </table>
    </div>


    <div class="grid-row" *ngIf="revisedData.length == 0">
        <form [formGroup]="form">
        <div *ngIf="model.option=='N'" class="column-full form-group">
            <div class="home-yes-no" >
                <label class="form-label-b19" > Do you have any employment and work experience? </label>
                    <div class="rwrap">
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="form.get('hasAddedValue')" [value]='false' id="id-createAnother-true"
                            name="form.get('hasAddedValue')" (click)="setOption('Y')"
                            [checked]="model.option=='Y'"
                            aria-describedby="id-section-help-createAnother-true id-section-error-createAnother-true" />
                        <label for="id-createAnother-true">Yes</label>
                    </div>
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="form.get('hasAddedValue')" [value]='true' id="id-createAnother-false"
                            name="form.get('hasAddedValue')" (click)="setOption('N')" [checked]="model.option=='N'"
                            aria-describedby="id-section-help-createAnother-false id-section-error-createAnother-false" />
                        <label for="id-createAnother-false">No</label>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>

    <div class="grid-row mb25">
        <button *ngIf="revisedData.length !== 0" id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
        (click)="save()">Continue</button>
        <button *ngIf="revisedData.length==0" id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
        (click)="save()">Save and continue</button>
        <button *ngIf="form.get('hasAddedValue') && revisedData.length == 0" id="id-save-and-come-back-later" class="button button-secondary mr15" type="button"
            name="save-and-come-back-later" (click)="draft()">Save and come back
            later</button>
        <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
            (click)="cvSummaryPage()">CV summary</button>
    </div>
</div>