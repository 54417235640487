import { MainService } from './../../core/services/main.service';
import { Component, OnInit,} from '@angular/core';

@Component({
  selector: 'vc-cv-builder',
  templateUrl: './cv-builder.component.html',
  styleUrls: ['./cv-builder.component.scss']
})
export class CvBuilderComponent implements OnInit{

  constructor(private readonly mainservice: MainService) {
    this.mainservice.pageTitle = ' ';
   }
  ngOnInit() {
  }

}
