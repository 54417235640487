import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { MainService } from '../../../core/services/main.service';
import { ContentService } from '../content.service';
import { SearchResultComponent } from '../../../shared/components/search-result/search-result.component';
import { Location } from '@angular/common';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'vc-content-search',
  templateUrl: './content-search.component.html',
  styleUrls: ['./content-search.component.scss'],
  providers: [HttpUrlEncodingCodec]
})
export class ContentSearchComponent implements OnInit {

  keyword: string;
  totalCount: number;
  suggestions: string[];
  resultBlock: any = {
    state: 'initial',
    data: []
  };
  page: number = 0;
  size: number = 4;

  @ViewChild('searchResult', { static: true }) searchComp: SearchResultComponent;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly main: MainService,
    private readonly contentService: ContentService,
    private readonly location: Location,
    private readonly spinner: SpinnerVisibilityService
  ) {
    this.main.pageTitle = 'Content';
  }

  ngOnInit() {
    this.main.showPlanBar();
    this.resolveKeyword();
  }

  resolveKeyword() {
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('keyword')) {
        this.keyword = decodeURIComponent(params.keyword);
        this.page = 0;
        this.searchComp.restResult();
        this.loadData(this.page, this.size, 'initial');
      } else { this.keyword = null; }

    });
  }

  loadData(page: number, size: number, state: string) {
    this.spinner.show();
    this.contentService.searchContents({ keyword: this.keyword }, page, size)
      .subscribe(data => {
        this.resultBlock = Object.assign({}, {
          state: state,
          data: data.content
        });
        this.suggestions = data.suggestions;
        this.totalCount = data.totalElements;
        this.spinner.hide();
      })
  }

  onLoadMore(event: any) {
    this.page += 1;
    this.loadData(this.page, this.size, 'existing');
  }

  resolveResultText() {
    return this.totalCount === 1 ? `${this.totalCount} result` : `${this.totalCount} results`;
  }

  backClicked() {
    this.location.back();
  }


  get showSuggestions() {
    if (this.suggestions && this.suggestions.length > 0) {
      return true;
    }
    return false;
  }

  searchWithSuggestion(suggestion: string) {
    this.router.navigate([`/content/search`], { queryParams: { keyword: encodeURIComponent(suggestion) } });
  }

}
