import { Component, OnInit } from '@angular/core';
import { INavigation } from '../header/header.interfaces';
import { Observable } from 'rxjs/internal/Observable';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'vc-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  navigation: Observable<Array<INavigation>>;
  currentYear = new Date().getFullYear();

  constructor(
    private readonly navigationService: NavigationService,
  ) { }

  ngOnInit() {
    this.navigation = this.navigationService.currentNavigation;
  }

}
