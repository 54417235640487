import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../../shared/constants/url-constants';

@Injectable()
export class CvTemplatesService {

  constructor(private readonly http: HttpClient) { }

  getAllTemplates() {
    const href = `${Url.CVB}/ref-data/ref-templates`;
    return this.http.get<any>(href);
  }

  validate() {
    const href = `${Url.CVB}/cv-status/validate`;
    return this.http.get<any>(href);
  }

  getPersonalDetails() {
    const href = `${Url.CVB}/personal-details`;
    return this.http.get(href);
  }

  save(data: any) {
    const href = `${Url.CVB}/cv-output`;
    return this.http.post<any>(href, data, { observe: 'response' });
  }

  replace(data: any) {
    const href = `${Url.CVB}/cv-output`;
    return this.http.put<any>(href, data);
  }

}
