import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfileService } from '../user-profile.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ValidationService } from './../../../shared/components/form-control/validation.service';
import { CHANGE_PASSWORD_VALIDATION_CONTEXT } from './change-password.validation';

const CHANGE_PASSWORD_FORM_VALIDATORS = {
  existingPassword: Validators.required,
  newPassword: Validators.compose(
    [
      Validators.required, 
      Validators.maxLength(12), 
      Validators.minLength(6), 
      ValidationService.passwordValidator, 
    ]
  ),
  confirmPassword: Validators.compose(
    [
      Validators.required, 
      ValidationService.passwordMatcher, 
      Validators.maxLength(12), 
      Validators.minLength(6), 
      ValidationService.passwordValidator
    ]
  )
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  changePasswordForm: FormGroup;
  @ViewChild('submitFormButton') submitFormButton: ElementRef;

  constructor(
    private readonly builder: FormBuilder,
    private readonly userProfileService: UserProfileService,
    private readonly notificationService: NotificationService,
    private readonly validationService: ValidationService
  ) {
    this.validationService.setContext(CHANGE_PASSWORD_VALIDATION_CONTEXT);
    this.initForm();
  }

  initForm() {
    this.changePasswordForm = this.builder.group({
      existingPassword: this.builder.control(''),
      newPassword: this.builder.control(''),
      confirmPassword: this.builder.control('')
    }, {
      updateOn: 'submit'
    })
  }

  changePassword() {
    this.clearAllValidatorsAndErrors(this.changePasswordForm);
    this.setValidators(this.changePasswordForm, CHANGE_PASSWORD_FORM_VALIDATORS)

    if (this.changePasswordForm.pristine === false && this.changePasswordForm.valid) {
      this.userProfileService.changePassword(this.changePasswordForm.value).subscribe(resp => {
        this.notificationService.success([resp.successMessage], true);
        this.initForm();
        this.submitFormButton.nativeElement.focus();
      }, error => {
        this.setErrorsFromBackend(error);
      })
    }
    window.scrollTo(0, 0);
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  setErrorsFromBackend(message) {
    switch (message.error.errorMessage) {
      case 'Password does not match with existing password.':
        this.changePasswordForm.controls.existingPassword.setErrors({ existingPassInvalid: true })
        break;
      case 'Password is the same as your current password, please change it.':
        this.changePasswordForm.controls.newPassword.setErrors({ newPasswordSameAsCurrent: true })
        this.changePasswordForm.controls.confirmPassword.setErrors({ newPasswordSameAsCurrent: true })
        break;
      case 'Password has been used already, please choose another.':
        this.changePasswordForm.controls.newPassword.setErrors({ newPassUsedRecently: true })
        this.changePasswordForm.controls.confirmPassword.setErrors({ newPassUsedRecently: true })
        break;
    }
  }

}
