import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ICanDeactivate } from '../../../../core/guards/deactivate.interface';
import { MainService } from '../../../../core/services/main.service';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { CvTemplatesService } from './cv-templates.service';
import { GuidedCvBuilderService } from '../guided-cv-builder.service';

interface template {
  id: number,
  identifier: string,
  description: string,
  imgSrc: string,
}

@Component({
  selector: 'vc-cv-templates',
  templateUrl: './cv-templates.component.html',
  styleUrls: ['./cv-templates.component.scss']
})
export class CvTemplatesComponent implements OnInit, OnDestroy, ICanDeactivate {

  templates: template[];
  selected: template;
  isTouched = false;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly confirm: ConfirmService,
    private readonly mainService: MainService,
    private readonly templateService: CvTemplatesService,
    private readonly gcvbService: GuidedCvBuilderService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = this.route.snapshot.data.mainTitle;

    this.templateService.validate().subscribe(data => {
      if(!data.status) {
        this.gcvbService.setConditionalValidation(true);
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    })
  }

  ngOnInit() {
    this.templateService.getAllTemplates()
      .subscribe((data: any[]) => {
        this.templates = data.map(each => {
          return {
            id: each.id,
            identifier: each.identifier, 
            description: each.description,
            imgSrc: `/assets/images/cv-builder/templates/${each.identifier}.png`
          }
        });
        this.selected = this.templates[0];
      });
  }

  ngOnDestroy() {
    this.mainService.pageMainTitle = '';
  }

  gotoPrev() {
    this.location.back();
  }

  cvSummaryPage() {
    this.router.navigate(['/employment/cv-builder/guided']);
  }

  selectTemplate(template) {
    this.isTouched = true;
    this.selected = template;
  }

  canExit(route, state, nextState) {
    if (this.selected && this.isTouched) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  generateCV() {
    this.isTouched = false;
    this.router.navigate(['../generate'], { relativeTo: this.route, queryParams: { template: this.selected.id } });
  }

}
