import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../../shared/constants/url-constants';

@Injectable()
export class PersonalDetailsService {

  constructor(private readonly http: HttpClient) { }

  saveAndContinue(data: any) {
    const href = `${Url.CVB}/personal-details/save`;
    return this.http.post(href, data);
  }

  saveAndLater(data: any) {
    const href = `${Url.CVB}/personal-details/draft`;
    return this.http.post(href, data);
  }

  getPersonalDetails() {
    const href = `${Url.CVB}/personal-details`;
    return this.http.get(href);
  }

  allCountries() {
    const href = `${Url.CVB}/ref-data/countries`;
    return this.http.get(href);
  }

  allCounties() {
    const href = `${Url.CVB}/ref-data/counties`;
    return this.http.get(href);
  }

  allTowns() {
    const href = `${Url.CVB}/ref-data/towns`;
    return this.http.get(href);
  }

}
