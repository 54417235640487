import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { MainService } from '../../../../core/services/main.service';
import { Attachment, ApplicationInfo } from './job-attachment';
import { JobAttachmentService } from './job-attachment.service';
import { EmploymentService } from '../../employment.service';

@Component({
  selector: 'vc-job-attachment',
  templateUrl: './job-attachment.component.html',
  styleUrls: ['./job-attachment.component.scss']
})
export class JobAttachmentComponent implements OnInit, OnDestroy {

  state: string = 'cv';
  selectAllCV: boolean = false;
  selectAllDOC: boolean = false;
  jobId: string;

  cvs: Attachment[] = [];
  documents: Attachment[] = [];
  applicationInfo: ApplicationInfo = {};
  pageSize: number = 10;
  page = 1;

  private attachmentSub: Subscription;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly mainService: MainService,
    private readonly attachmentService: JobAttachmentService,
    private readonly renderer: Renderer2,
    private readonly empservice: EmploymentService
  ) {
    this.route.queryParams.subscribe(params => {
      if (!params.job) {
        this.router.navigate(['/employment']);
      }
      this.jobId = params.job;
    });

    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = this.route.snapshot.data.mainTitle; 
    this.resolveDocs();
    this.renderer.addClass(document.body, 'changeCaseOfTitle');
  }

  ngOnInit() {  }

  ngOnDestroy() {
    this.mainService.pageMainTitle = '';
    if (this.attachmentSub) {
      this.attachmentSub.unsubscribe();
    }
    this.renderer.removeClass(document.body, 'changeCaseOfTitle');
  }

  private async resolveDocs() {
    await this.attachmentService.getCvs().toPromise().then(cvs => {
      this.cvs = cvs.map(each => {
        return new Attachment(each.id, each.cvName, each.createdDate, each.cvDescription, each.attachmentType);
      });
    });
    await this.attachmentService.getDocuments().toPromise().then(docs => {
      this.documents = docs.map(each => {
        return new Attachment(each.id, each.name, each.createdDate, each.description, each.attachmentType);
      });
    });
    this.attachmentSub = this.attachmentService.onChangeApplicationInfo().subscribe(data => {
      if (data && data.jobId === this.jobId) {
        this.applicationInfo = data;
        this.applicationInfo.attachments.forEach(each => {
          this.selectOne(this.cvs.find(one => one.id === each.id && one.type === each.type));
          this.selectOne(this.documents.find(one => one.id === each.id && one.type === each.type));
        })
      }
      this.selectAllCV = this.cvs.filter(each => each.isSelected()).length === this.cvs.length;
      this.selectAllDOC = this.documents.filter(each => each.isSelected()).length === this.documents.length;
    });
  }

  setActive(state: string) {
    this.state = state;
  }

  selectAll(type: 'cv' | 'doc') {
    switch (type) {
      case 'cv': {
        this.cvs.forEach(cv => this.selectAllCV ? cv.select() : cv.unSelect());
        break;
      }
      case 'doc': {
        this.documents.forEach(doc => this.selectAllDOC ? doc.select() : doc.unSelect());
        break;
      }
    }
    this.attachAllSelected();
  }

  changeState() {
    this.selectAllCV = this.cvs.filter(each => each.isSelected()).length === this.cvs.length;
    this.selectAllDOC = this.documents.filter(each => each.isSelected()).length === this.documents.length;
    this.attachAllSelected();
  }

  done() {
    this.applicationInfo.jobId = this.jobId;
    this.attachmentService.attach(this.applicationInfo);
    this.router.navigate(['../'], { queryParamsHandling: 'preserve', relativeTo: this.route });
  }

  cancel() {
    this.router.navigate(['../'], { queryParamsHandling: 'preserve', relativeTo: this.route });
  }

  back() {
    this.location.back();
  }

  pageChange(page: number) {
    this.page = page;
  }

  private attachAllSelected() {
    this.applicationInfo.attachments = [];
    this.cvs.filter(each => each.isSelected()).forEach(each => this.applicationInfo.attachments.push(each));
    this.documents.filter(each => each.isSelected()).forEach(each => this.applicationInfo.attachments.push(each));
  }

  private selectOne(attachment: Attachment) {
    if (attachment) {
      attachment.select();
    }
  }

}
