import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from './../../../../core/services/main.service';
import { ValidationService } from './../../../../shared/components/form-control/validation.service';
import { InductionQuestionnaireService } from './../../induction-questionnaire/induction-questionnaire.service';
import { INDUCTION_QUESTIONS_VALIDATION_CONTEXT } from './../induction-questions-list.validation';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from "../../../../shared/components/confirm-box/confirm-box.service";


const PAGEONEFORM_VALIDATORS_SAVE = {
  diagnosed: [Validators.required]
}
const PAGEONEFORM_DIAGNOSED_YES_VALIDATORS_SAVE = {
  lldDetails: [Validators.required, Validators.maxLength(200)]
}
const PAGEONEFORM_DIAGNOSED_YES_VALIDATORS_DRAFT = {
  lldDetails: [Validators.maxLength(200)]
}
@Component({
  selector: 'vc-page-one',
  templateUrl: './page-one.component.html',
  styleUrls: ['./page-one.component.scss']
})
export class PageOneComponent implements OnInit, AfterViewChecked {

  currentPageNumber: number;
  currentPageData = [] as any;
  refData = [] as any;
  routeState: any;

  pageOneForm: FormGroup;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction';
  routeNext = '/plan/induction/page-two';
  isAssessmentInProgressFlag = false;


  @ViewChild("question") questionDiv: ElementRef;
  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly mainService: MainService,
    private readonly formBuilder: FormBuilder,
    private readonly validation: ValidationService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly togetherMode: TogetherModeModalService,
    private readonly confirm: ConfirmService,
    ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.initForm();
    this.validation.setContext(INDUCTION_QUESTIONS_VALIDATION_CONTEXT);
  }

  ngOnInit() {
    this.getRefData();
    this.resolveRouteState();
     this.isSaveSubmitOperation = false;
     sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageOneForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  initForm() {
    this.pageOneForm = this.formBuilder.group({
      diagnosed: this.formBuilder.control(''),
      lldDetails: this.formBuilder.control('')
    })
  }

  resetLldDetails() {
    this.pageOneForm.controls.lldDetails.setValue('');
    this.setValidators(this.pageOneForm, PAGEONEFORM_VALIDATORS_SAVE);
    this.clearAllValidatorsAndErrors(this.pageOneForm);
  }

  defineAndSetValidators() {
    const diagnosedYes = this.pageOneForm.controls.diagnosed.value === '1';
    if (diagnosedYes) {
      this.setValidators(this.pageOneForm, PAGEONEFORM_DIAGNOSED_YES_VALIDATORS_SAVE);
    } else {
      this.setValidators(this.pageOneForm, PAGEONEFORM_VALIDATORS_SAVE);
    }
  }

  canExit(route, state, nextState) {
    if (this.pageOneForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  onClickContinue() {
    this.defineAndSetValidators();
    if (this.pageOneForm.valid) {
      const formattedPayload = this.formatPayload(false);
      this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
        this.isSaveSubmitOperation = true;
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-two');
        window.scrollTo(0, 0);
      })
    } else {
      this.setFocusToErrorSummary();
    }
  }

  onClickSave() {
    if (this.pageOneForm.controls.diagnosed.value === '') {
      this.isSaveSubmitOperation = true;
      this.router.navigateByUrl(this.routeTo);
      window.scrollTo(0, 0);
    } else {
      const diagnosedYes = this.pageOneForm.controls.diagnosed.value === '1';
      if (diagnosedYes) {
        this.setValidators(this.pageOneForm, PAGEONEFORM_DIAGNOSED_YES_VALIDATORS_DRAFT);
      }
      if (this.pageOneForm.valid) { 
        const formattedPayload = this.formatPayload(true);
        this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
          this.isSaveSubmitOperation = true;
          this.router.navigateByUrl(this.routeTo);
          window.scrollTo(0, 0);
        })
      } else {
        this.setFocusToErrorSummary();
      }
    }
  }

  formatPayload(saveAsDraft) {
    if (this.pageOneForm.controls.diagnosed.value === '2') {
      return {
        "isSaveAsDraft": saveAsDraft,
        "planTypeIdentifier": "1",
        "questionDTOList": [
          {
            "answer": this.pageOneForm.controls.diagnosed.value,
            "id": 1
          },
        ],
        "sectionIdentifier": this.currentPageData[0].sectionIdentifier
      }
    } else {
      return {
        "isSaveAsDraft": saveAsDraft,
        "planTypeIdentifier": "1",
        "questionDTOList": [
          {
            "answer": this.pageOneForm.controls.diagnosed.value,
            "id": 1
          },
          {
            "answer": this.pageOneForm.controls.lldDetails.value,
            "id": 2
          },
        ],
        "sectionIdentifier": this.currentPageData[0].sectionIdentifier
      }
    }
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          }
        )
      });
      this.pageOneForm.controls.diagnosed.setValue(data[0].answer ? data[0].answer : '');
      this.pageOneForm.controls.lldDetails.setValue(data[1].answer && data[0].answer === '1' ? data[1].answer : '');
      this.setFocusToQuestionDiv();
    })
  }

   getRefData() {
     this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
       this.currentPageNumber = 1;
    this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  setFocusToQuestionDiv() {
    this.questionDiv.nativeElement.focus();
  }
}
