<a (click)="backClicked()" href="javascript:void(0);" class="link-back nomargintop">Back</a>

<div class="grid-row">
    <div class="panel-wrapper mt25" *ngIf="expressedInterest?.status">
        <h1 class="heading-xlarge">
            I'm interested
        </h1>
        <div class="panel-body heading-large">
            on <strong> {{expressedInterest?.dateExpressed | date :  "dd MMMM yyyy" }} </strong>
            at <strong> {{expressedInterest?.dateExpressed | date :  "HH:mm" }} </strong>
        </div>    
    </div>
</div>

<div class="grid-row" id="prisonJobDetail">
    <div class="column-two-thirds">
        <h3 class="heading-large">{{advertprisonJobDetails?.jobTitle}}</h3>
        <div class="grid-row ">
            <table class="table-font-small">
                <tbody>
                    <tr *ngIf="advertprisonJobDetails?.postingDate">
                        <th>Posting date:</th>
                        <td>{{advertprisonJobDetails?.postingDate | date : 'dd MMMM yyyy'}}</td>
                    </tr>
                    <tr *ngIf="advertprisonJobDetails?.jobTitle">
                        <th>Job title:</th>
                        <td>{{advertprisonJobDetails?.jobTitle}}</td>
                    </tr>
                    <tr *ngIf="advertprisonJobDetails?.location">
                        <th>Location:</th>
                        <td>{{advertprisonJobDetails?.location}}</td>
                    </tr>
                    <tr *ngIf="advertprisonJobDetails?.pay">
                        <th>Pay:</th>
                        <td>{{advertprisonJobDetails?.pay}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="grid-row descBlock">
            <div *ngIf="advertprisonJobDetails?.jobDescription">
                <label>Job description:</label>
                <p>{{advertprisonJobDetails?.jobDescription}}</p>
            </div>
        </div>
        <div class="grid-row actions">
            <p>
                <button type="button" class="button button-link" (click)="expressInterest(advertprisonJobDetails?.id)"
                    [disabled]="expressedInterest?.status">I'm interested</button>
            </p>
        </div>
    </div>
</div>