import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { INotification } from './notification.interface';

@Component({
  selector: 'vc-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {

  notification: INotification;

  constructor(private readonly notificationService: NotificationService) {
  }

  ngOnInit() {
    this.notificationService.currentNotification.subscribe(
      notification => {
        this.notification = notification;
        if (notification) {
          window.scrollTo(0, 0);
        }
      });
  }

  onClose() {
    this.notificationService.close();
  }
}
