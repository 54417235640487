import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { MyCvsService } from './my-cvs.service';
import { BannerService } from '../../../../core/services/banner.service';
import { MainService } from '../../../../core/services/main.service';
import { FormUtil } from '../../../../shared/components/form-control/form.util';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { FILE_VALIDATORS_CONTEXT } from '../my-cvs/my-cvs.validation';



export interface CV {
  id: number;
  cvName: string;
  cvFileSize: number;
  createdDate: Date;
  description: string;
}

@Component({
  selector: 'vc-my-cvs',
  templateUrl: './my-cvs.component.html',
  styleUrls: ['./my-cvs.component.scss']
})
export class MyCvsComponent implements OnInit, OnDestroy {

  cvs: CV[] = [];
  cvUploadForm: FormGroup;
  private readonly MAX_ALLOWED_SIZE = 1048576;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly confirm: ConfirmService,
    private readonly cvservice: MyCvsService,
    private readonly mainService: MainService,
    private readonly validation: ValidationService,
    private readonly bannerService: BannerService,
    private readonly renderer: Renderer2
  ) {
    this.validation.setContext(FILE_VALIDATORS_CONTEXT);
    this.initForm();
    this.mainService.pageTitle = "CVs";
  }

  ngOnInit() {
    this.getMyCVs();
  }

  ngOnDestroy() {
    this.mainService.pageMainTitle = '';
    this.renderer.removeClass(document.body, 'inherit-case');
  }

  initForm() {
    this.cvUploadForm = this.fb.group({
      file: this.fb.control(''),
      fileName: this.fb.control(''),
      description: this.fb.control('')
    });
  }

  getMyCVs() {
    this.cvservice.getUploadedcvs().subscribe((res: any[]) => {
      this.cvs = res;
    });
    this.renderer.addClass(document.body, 'inherit-case');
  }

  gotoPrev() {
    this.location.back();
  }

  delete(id: number, name: string) {
    this.confirm.confirm({
      header: 'Confirm',
      message: `Are you sure you want to delete ${name}?`,
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.cvservice.delete(id).subscribe(res => {
          this.resetForm();
          this.getMyCVs();
          this.bannerService.success(['File deleted successfully'], true);
        }, error => {
          this.bannerService.warning(error.error.errorMessage, true);
        });
      },
      reject: () => {
        this.confirm.choose(false);
      }
    });
  }

  upload() {
    this.setValidators();
    if (this.cvUploadForm.valid) {
      FormUtil.clearAllValidatorsAndErrors(this.cvUploadForm);
      const data = this.parseSaveData();
      this.cvservice.upload(data.file, data.description,data.fileName, 'POST')
        .then(res => {
          this.getMyCVs();
          this.resetForm();
          this.bannerService.success(['File uploaded successfully'], true);
        })
        .catch(error => {
          const rp = JSON.parse(error.response);
          if (error.status === 409) {
            this.showReplaceConfirm(data, rp.errorMessage);
          } else {
            this.bannerService.warning(rp.errorMessage, true);
          }
          this.resetForm();
        });
    }
  }

  setValidators() {
    const file = this.fileInput.nativeElement.files.item(0);
    this.cvUploadForm.get('description').setValidators([Validators.maxLength(100)]);
    this.cvUploadForm.get('description').updateValueAndValidity();
    this.cvUploadForm.get('description').markAsTouched();
    this.cvUploadForm.get('fileName').setValidators([Validators.required, Validators.maxLength(30), Validators.pattern(/^[^<>:"\/\\\|\?\*]*$/)]);
    this.cvUploadForm.get('fileName').updateValueAndValidity();
    this.cvUploadForm.get('fileName').markAsTouched();
    this.cvUploadForm.get('file').setValidators(Validators.compose([Validators.required, ValidationService.maxFileSize(file, this.MAX_ALLOWED_SIZE)]));
    this.cvUploadForm.get('file').updateValueAndValidity();
    this.cvUploadForm.get('file').markAsTouched();
  }


  private showReplaceConfirm(data: any, message: string) {
    this.confirm.confirm({
      header: 'Confirm',
      message: message,
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.cvservice.upload(data.file, data.description, data.fileName , 'PUT')
          .then((res: any) => {
            this.bannerService.success(['File uploaded successfully'], true);
            this.getMyCVs();
            this.resetForm();
          });
      },
      reject: () => {
        this.confirm.choose(false);
      }
    });
  }

  private parseSaveData(): any {
    const data = this.cvUploadForm.getRawValue();
    data.file = this.fileInput.nativeElement.files.item(0);
    return data;
  }

  onFileChange() {
    const fl = this.fileInput.nativeElement.files.item(0) || {};
    this.cvUploadForm.patchValue({
      file: fl.name
    });
  };

  resetForm() {
    this.fileInput.nativeElement.value = '';
    this.initForm();
  }

  download(id: number): void {
    this.cvservice.download(id);
  }

}