import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { WorkExperienceService } from '../../work-experience.service';
import { ValidationService } from '../../../../../../shared/components/form-control/validation.service';
import { ICanDeactivate } from '../../../../../../core/guards/deactivate.interface';
import { ConfirmService } from '../../../../../../shared/components/confirm-box/confirm-box.service';
import { MainService } from '../../../../../../core/services/main.service';
import { EditWorkExperienceService } from '../edit-work-experience.service';
import { PAIDWORKS_VALIDATION_CONTEXT } from './paid-work.validation';

const PAIDFORM_VALIDATORS_SAVE = {
  jobStartDate: [Validators.required, ValidationService.monthYearValidator, ValidationService.futureDate, ValidationService.pastYear(80)],
  jobEndDate: [ValidationService.monthYearValidator,
    ValidationService.futureDate,
    ValidationService.invalidEndDateOrCurrent('jobStartDate', 'jobEndDate', 'isJobCurrent'),
    ValidationService.invalidEndDateAndCurrent('jobEndDate', 'isJobCurrent'),
    ],
  employerName: [Validators.required, Validators.maxLength(100)],
  jobLocation: [Validators.required],
  jobTitle: [Validators.required],
  otherSkillDescription: [Validators.required, Validators.maxLength(200), Validators.pattern('[a-zA-Z0-9\\-\\s\&\(\)\/\.\'\,\"\:\;]*')],
}

const PAIDFORM_VALIDATORS_DRAFT = {
  jobStartDate: [ValidationService.monthYearValidator],
  jobEndDate: [ValidationService.monthYearValidator],
  otherSkillDescription: [Validators.maxLength(200)],
}

const SKILL_DESCRIPTION_VALIDATORS = {
  answers: [ValidationService.minSelected(3), ValidationService.maxSelected(3)],
}

@Component({
  selector: 'vc-paid-work',
  templateUrl: './paid-work.component.html',
  styleUrls: ['./paid-work.component.scss']
})
export class PaidWorkComponent implements OnInit, ICanDeactivate, OnDestroy {

  paidForm: FormGroup;
  createAnother = new FormControl('N');
  jobTitle: any = [];
  jobLocation: any = [];
  endDateDisabled = false;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly main: MainService,
    private readonly route: ActivatedRoute,
    private readonly confirm: ConfirmService,
    private readonly validation: ValidationService,
    private readonly workService: WorkExperienceService,
    private readonly editWEService: EditWorkExperienceService,
    private readonly location: Location,
  ) {
    this.validation.setContext(PAIDWORKS_VALIDATION_CONTEXT);
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'Work experience';
    this.initForm();
    this.route.queryParams.subscribe(params => {
      this.loadSkillsAndResolve(params.id);
    });
    this.editWEService.setFormGroups([this.paidForm, this.paidForm.get('skillDescription') as FormGroup]);
  }
  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }
  ngOnInit() {
    this.paidForm.get('skillDescription').get('answers').valueChanges
      .subscribe((data: any[]) => {
        const filtered = data.filter(each => each.checked);
        if (filtered.find(each => each.id === '20')) {
          this.paidForm.addControl('otherSkillDescription', this.fb.control(''));
        } else {
          this.paidForm.removeControl('otherSkillDescription');
        }
      });
    this.paidForm.get('jobStartDate').valueChanges.subscribe(data => {
      this.paidForm.get('jobEndDate').updateValueAndValidity();
    });
    this.paidForm.get('isJobCurrent').valueChanges.subscribe(data => {
      this.paidForm.get('jobEndDate').updateValueAndValidity();
    });
  }

  initForm() {
    this.paidForm = this.fb.group({
      id: this.fb.control(''),
      employerName: this.fb.control(''),
      jobTitle: this.fb.control(''),
      isJobCurrent: this.fb.control(false),
      jobStartDate: this.fb.control(''),
      jobEndDate: this.fb.control(''),
      skillDescription: this.fb.group({
        question: this.fb.control('What did you do?'),
        answers: this.fb.array([]),
      })
    });
  }

  async loadSkillsAndResolve(id: any) {
    await this.loadAllSkills();
    if (id) {
      this.resolveData(id);
    }
  }

  resolveData(id: number): void {
    this.workService.getPaidExperienceDetails(id)
      .subscribe(data => {
        this.paidForm.get('id').setValue(data.id);
        this.paidForm.get('employerName').setValue(data.employerName);
        this.paidForm.get('jobTitle').setValue(data.jobTitle);
        this.paidForm.get('isJobCurrent').setValue(data.isJobCurrent);
        this.paidForm.get('jobStartDate').setValue(data.jobStartDate);
        this.paidForm.get('jobEndDate').setValue(data.jobEndDate);
        this.resolveSelectedSkills(data.skillDescription);
        if (data.skillDescription && data.skillDescription.includes('20')) {
          this.paidForm.addControl('otherSkillDescription', this.fb.control(''));
          this.paidForm.get('otherSkillDescription').setValue(data.otherSkillDescription);
        }
      });
  }

  resolveSelectedSkills(ids: string[]) {
    ((this.paidForm.get('skillDescription') as FormGroup)
      .get('answers') as FormArray).controls.forEach(each => {
        if (ids.includes(each.get('id').value)) {
          each.get('checked').setValue(true);
        }
      });
  }

  async loadAllSkills() {
    await this.workService.getAllSkills()
      .then(res => this.loadAnswers(res));
  }

  loadAnswers(answers: any[]) {
    const farr = this.paidForm.get('skillDescription').get('answers') as FormArray;
    this.resetFormArr(farr);
    answers.map(answer => {
      return {
        id: answer.identifier,
        text: answer.description,
        checked: false,
      }
    }).forEach(answer => {
      farr.push(this.singleAnswerForm(answer));
    });
  }

  singleAnswerForm(data: any) {
    return this.fb.group({
      id: this.fb.control(data.id),
      text: this.fb.control(data.text),
      checked: this.fb.control(data.checked),
    });
  }

  saveAndContinue() {
    this.setValidators(this.paidForm, PAIDFORM_VALIDATORS_SAVE);
    this.setValidators(this.paidForm.get('skillDescription') as FormGroup, SKILL_DESCRIPTION_VALIDATORS);
    if (this.paidForm.valid) {
      const data = this.parseSaveData();
      this.workService.saveAndContinuePaidWork(data)
        .subscribe(res => {
          this.createNewWorkExp();
        });
    }
    window.scrollTo(0, 0);
  }

  createNewWorkExp() {
    this.resetConditionalForm();
    if (this.createAnother.value === 'N') {
      this.router.navigate(['/employment/cv-builder/guided/experience']);
    } else {
      this.createAnother.setValue('N');
      this.router.navigate([], { queryParams: {} });
    }
  }

  saveAndLater() {
    this.clearAllValidatorsAndErrors(this.paidForm);
    this.clearAllValidatorsAndErrors(this.paidForm.get('skillDescription') as FormGroup);
    this.setValidators(this.paidForm, PAIDFORM_VALIDATORS_DRAFT);
    if (this.paidForm.valid) {
      const data = this.parseSaveData();
      if (this.isEmptyForm(data)) {
       this.router.navigate(['/employment/cv-builder/guided/experience']);
      } else {
        this.workService.saveAndLaterPaidWork(data)
          .subscribe(res => {
            this.createNewWorkExp();
          });
      }
    }
    window.scrollTo(0, 0);
  }

  isEmptyForm(data) {
    const filterData = Object.keys(data).filter(name => (data[name]));
    if (filterData.length === 1 && filterData[0] === 'skillDescription') {
      const skillDescription =  filterData[0];
      return data[skillDescription].length === 0;
    }
    return false;
  }

  parseSaveData() {
    const paidWorks = this.paidForm.value;
    const skills = paidWorks.skillDescription.answers
      .filter(each => each.checked)
      .map(answer => answer.id);
    paidWorks.skillDescription = skills;
    return paidWorks;
  }

  resetConditionalForm() {
    this.initForm();
    this.loadAllSkills();
  }

  canExit() {
    if (this.paidForm.dirty) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  cvSummaryPage() {
    this.router.navigate(['/employment/cv-builder/guided']);
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      if (control) {
        control.clearValidators();
        control.setErrors(null);
        control.markAsTouched();
      }
    });
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  onKeyJob(event: any) {
    const actualVal = event.target.value;
    const letters = /^[A-Za-z]+$/;
    if (actualVal.match(letters)) {
      this.workService.getJobTitles(event.target.value).subscribe(response => {
        this.jobTitle = response;
      });
    }
  }

  onKeyLocation(event: any) {
    const actualLoc = event.target.value;
    const allowedletters = /^[A-Za-z]+$/;
    if (actualLoc.match(allowedletters)) {
      this.workService.getJobLocation(event.target.value).subscribe(response => {
        this.jobLocation = response;
      });
    }
  }

  resetFormArr(farr: FormArray) {
    while (farr.controls.length !== 0) {
      farr.removeAt(0);
    }
  }
  
  gotoPrev() {
    this.location.back();
  }

}
