<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>

<div class="cv-templates mt25">
    <div class="grid-row scrollable">
        <article class="cv-wrapper column-half recommended" *ngFor="let template of templates; let i = index"
            id="template_{{i}}" [ngClass]="{'active': selected?.id === template.id}" tabindex="0"
            (click)="selectTemplate(template)">
            <figure class="cv-builder">
                <img alt="Template" src="{{template.imgSrc}}">
                <figcaption class="light-green">
                    <div class="caption-div">
                        <div class="light-green">
                            <span>{{template.description}} CV</span>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </article>
    </div>
</div>


<div class="grid-row mb25">
    <button id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
    (click)="generateCV()">Generate CV</button>
    <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
        (click)="cvSummaryPage()">CV summary</button>
</div>