<div class="grid-row">
    <table *ngIf="empList.length !== 0">
        <caption></caption>
        <thead>
            <tr>
                <th scope="colgroup">Employer Name</th>
                <th scope="colgroup">Job title</th>
                <th scope="colgroup">Start date</th>
                <th scope="colgroup">End date</th>
                <th scope="colgroup"></th>
            </tr>
        </thead>

        <tbody >
            <tr *ngFor="let s of empList">
                <td><span *ngIf="s?.employerName">{{s.employerName | truncate:[40, '...']}}</span></td>
                <td><span *ngIf="s?.jobTitle">{{s.jobTitle | truncate:[40, '...'] }}</span></td>
                <td><span *ngIf="s?.jobStartDate">{{s.jobStartDate | date : 'MMMM yyyy'}}</span></td>
                <td>
                    <span *ngIf="s?.jobEndDate">{{s.jobEndDate | date : 'MMMM yyyy'}}</span>
                    <span *ngIf="!s?.jobEndDate && s?.isJobCurrent">Present</span>
                </td>
                <td>
                    <span>
                        <a href="javascript: void(0)" class="delLink link" (click)="EditPlanEmployment(s.id)">Edit</a>
                    </span>
                    <span>
                        <a href="javascript: void(0)" (click)="deletePlanEmployment(s.id)" class="delLink link">Delete</a>
                    </span>
                </td>
            </tr>
        </tbody>

    </table>
</div>