import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from '../core/guards/authenticated.guard';
import { HomeComponent } from './home.component';

const routes: Routes = [
  { path: '', redirectTo: 'plan', pathMatch: 'full'},
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'plan',
        loadChildren: () => import('../features/plan/plan.module').then(mod => mod.PlanModule),
        data: { title: 'Plan' },
      },
      {
        path: 'wallet',
        loadChildren: () => import('../features/wallet/wallet.module').then(mod => mod.WalletModule),
        data: { title: 'Wallet' }
      },
      {
        path: 'useful-link',
        loadChildren: () => import('../features/useful-link/useful-link.module').then(mod => mod.UsefulLinkModule),
        data: { title: 'Useful links' }
      },
      {
        path: 'employment',
        loadChildren: () => import('../features/employment/employment.module').then(mod => mod.EmploymentModule),
        data: { title: 'Employment' },
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'content',
        loadChildren: () => import('../features/content/content.module').then(mod => mod.ContentModule),
        data: { title: 'Content' },
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'user-profile',
        loadChildren: () => import('../features/user-profile/user-profile.module').then(mod => mod.UserProfileModule),
        data: { title: 'User profile' },
        canActivate: [AuthenticatedGuard]
      },
      {
        path: 'content-unavailable',
        loadChildren: () => import('../shared/components/content-unavailable/content-unavailable.module')
          .then(mod => mod.ContentUnavailableModule),
        data: { title: 'Content unavailable' }
      },
      {
        path: 'chat',
        loadChildren: () => import('../features/srm/srm.module').then(mod => mod.SrmModule),
        data: { title: 'Secure messaging' }
      },

      {
        path: 'srm',
        loadChildren: () => import('../features/srm/srm.module').then(mod => mod.SrmModule),
        data: { title: 'Secure messaging' }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
