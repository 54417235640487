import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MainService } from '../../../core/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl} from '@angular/forms';
import { Location } from '@angular/common';
import { ConfirmService } from '../../../shared/components/confirm-box/confirm-box.service';
import { SrmService } from '../srm.service';
import { TabsService } from '../../../shared/components/tabs/tabs.service';


export interface MessageFormValues {
  messageText: any;
  messageToId: number;
  conersationWith: string;
}


@Component({
  selector: 'vc-create-srm',
  templateUrl: './create-srm.component.html',
  styleUrls: ['./create-srm.component.scss']
})
export class CreateSrmComponent implements OnInit {
  userList: any;
  messageForm: FormGroup;
  selectedKw = '';


  constructor(
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly confirmService: ConfirmService,
    private readonly location: Location,
    private readonly srmService: SrmService,
    private readonly builder: FormBuilder,
    private readonly tabService: TabsService,

  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
  }

  ngOnInit() {
    this.tabService.emitChatFocus(true);
    this.srmService.getKeworkerList().subscribe(data => {
      this.userList = data;
    });
    this.initMessagesendForm();
    this.messageForm.get('keyWorker').valueChanges
      .subscribe( val => this.updateReference(val) );
  }

  updateReference(val) {
    const user = this.userList.find( obj => obj.id === +val);
    this.selectedKw = user ? user.userFullName : '';
  }

  cancelMessage() {
    if(this.messageForm.get('keyWorker').valid || this.messageForm.get('message').valid){
      this.confirmService.confirm({
        message: 'Are you sure you want to cancel?',
        header: '',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.router.navigate(['/chat']);
        }
      });
    }else{
      this.router.navigate(['/chat']);
    }
  }
  initMessagesendForm() {
    this.messageForm = this.builder.group({
      keyWorker: ['0', [Validators.required, this.validateKeyWorker]],
      message: ['', [Validators.required]]
    });
  }

  sendMessage() {
    const data = {
      status: 'SU',
      messageText: this.messageForm.get('message').value,
      messageToId: this.messageForm.get('keyWorker').value
    };
    this.srmService.create(data).subscribe(() => {
      this.messageForm.reset();
      this.router.navigate(['/chat']);
    });
  }

  validateKeyWorker(control : FormControl) : {[key: string] : boolean} | null{
    if(control.value !== undefined && control.value === '0'){
      return { 'keyWorker': true };
    }
    return null;
  }

  ngOnDestroy(): void {
    this.tabService.emitChatFocus(false);
  }
}
