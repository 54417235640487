/* 
	* Validation error messages for Change Password component
*/

export const CHANGE_PASSWORD_VALIDATION_CONTEXT = {

	required : {
		existingPassword: 'Enter your current password',
    newPassword: 'Enter your new password',
    confirmPassword: 'Confirm your new password'
	},
	minlength: {
		newPassword: 'New password must be 6 characters or more',
    confirmPassword: 'New password must be 6 characters or more'
	},
	maxlength: {
		newPassword: 'New password must be 12 characters or fewer',
    confirmPassword: 'New password must be 12 characters or fewer'
	},
	invalidPassword: {
		newPassword: 'Enter your new password in the correct format, must only contain letters and/or numbers',
		confirmPassword: 'Confirm your new password in the correct format, must only contain letters and/or numbers',
	},
	invalidMatchPassword: {
		newPassword: 'New password and confirm new password must match',
		confirmPassword: 'Confirm new password and new password must match'
	},
	existingPassInvalid: {
		existingPassword: 'Confirm and enter your current password'
	},
	newPasswordSameAsCurrent: {
		newPassword: 'New password must be different to current password',
		confirmPassword: 'New password must be different to current password'
	},
	newPassUsedRecently: {
		newPassword: 'New password has been used as a password recently, must be new',
		confirmPassword: 'New password has been used as a password recently, must be new' 
	}
	
}
