import { Url } from './../../../../shared/constants/url-constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class EducationService {

  constructor(private readonly http: HttpClient) { }

  getEducationDetails(id: number): Observable<any> {
    const href = `${Url.CVB}/education/${id}`;
    return this.http.get<any>(href);
  }

  refEducationType() {
    const href = `${Url.CVB}/ref-data/education-types`;
    return this.http.get<any>(href);
  }

  saveAndContinue(data: any) {
    const href = `${Url.CVB}/education/save`;
    return this.http.post<any>(href, data);
  }

  saveAndLater(data: any) {
    const href = `${Url.CVB}/education/draft`;
    return this.http.post<any>(href, data);
  }

  getEducationInfo() {
    const href = `${Url.CVB}/education`;
    return this.http.get(href);
  }

  deleteEducation(eduId: any): any {
    const href = `${Url.CVB}/education/${eduId}`;
    return this.http.delete<any>(href);
  }



 saveStudying(data) {
    const href = `${Url.CVB}/education/uneducation/save`;
    return this.http.post<any>(href, data);
  }

   draftStudying(data) {
    const href = `${Url.CVB}/education/uneducation/draft`;
    return this.http.post<any>(href, data);
  }
}
