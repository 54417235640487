import { Component, OnInit, ViewEncapsulation, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { IAssessmentTemplate } from '../assessment.interface';
import { AssessmentService } from '../assessment.service';
import { ConfirmService } from "../../../shared/components/confirm-box/confirm-box.service";
import { NotificationService } from './../../../core/services/notification.service';
import { MainService } from './../../../core/services/main.service';
import * as Survey from 'survey-angular';
import { Location } from '@angular/common';
import { TogetherModeModalService } from '../../../shared/components/together-mode-modal/together-mode-modal.service';

@Component({
  selector: 'app-complete-assessment',
  templateUrl: './complete-assessment.component.html',
  styleUrls: ['./complete-assessment.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CompleteAssessmentComponent implements OnInit, OnDestroy {
  readonly serviceUserUrl: string = "./assessments";
  fname: string;
  lname: string;
  prn: string;
  userId: string;
  json;
  username: string;
  answerJson;
  assessmentItem;
  assessmentTemplate;
  assessmentName;
  surveyItem;
  length = 0;
  isError: boolean = false;
  errorMessage: string;
  surveyErrors;
  updatedBy;
  updatedDate;
  assessmentTemplates: IAssessmentTemplate;
  filteredAssessmentTemplate: IAssessmentTemplate;
  completedAssessmentTemplate: IAssessmentTemplate;
  private assessmentTemplateUserId: any;
  private assessmentTemplateId: any;
  public isCompleted: any;
  private isPartiallyCompleted: any;
  todostatus: any;
  completedstatus: any;
  noAssessmentMessage = 'Ups! The requested assessment is not available.';
  svQuestion = '.sv_q';
  errorsBox = false;
  private score: number = 0;
  routeTo = '/wallet/assessments';
  isSaveSubmitOperation: boolean = false;
  togetherModeUserName;
  isExitClick: boolean = false;
  constructor(
    private readonly assessmentService: AssessmentService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly confirmService: ConfirmService,
    private readonly notificationService: NotificationService,
    private readonly renderer: Renderer2,
    private readonly location: Location,
    private readonly eRef: ElementRef,
    private readonly mainService: MainService,
    private readonly togetherMode: TogetherModeModalService
  ) {
    this.mainService.pageTitle = this.route.snapshot.queryParamMap.get('assessmentName');
    this.mainService.pageMainTitle = 'Assessments';
  }
  ngOnDestroy(): void {
    this.mainService.pageMainTitle = '';
    sessionStorage.clear();
  }
  ngOnInit() {
    this.getUserAssessmentJsons();
    this.isSaveSubmitOperation = false;
    this.isExitClick = false;
    if (!this.isCompleted) {
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }


  togetherModeExit() {
    if (this.isExitAllowed(this.surveyItem) && this.togetherMode.getTogetherModeEnabledStatus() && !this.isSaveSubmitOperation && !this.isCompleted) {
      this.confirmService.confirm({
        message: 'Please save your progress before exiting assessment',
        header: 'Progress not saved',
        showClose: false,

      });
      return this.confirmService.navigateSelection;
    } else if (!this.isExitClick && this.isExitAllowed(this.surveyItem) && !this.isSaveSubmitOperation && !this.isCompleted) {
      this.confirmService.confirm({
        message: 'You have not saved your progress, do you want to continue without saving?',
        header: '',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => this.confirmService.choose(true),
        reject: () => this.confirmService.choose(false),
      });
      return this.confirmService.navigateSelection;
    }
    this.isExitClick = false;
    return true;
  }

  canExit(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    return this.togetherModeExit();
  }

  getUserAssessmentJsons() {
    this.route.queryParams.subscribe((params: any) => {
      this.assessmentTemplateId = params.assessmentTemplateId;
      this.assessmentTemplateUserId = params.assessmentTemplateUserId;
      this.isCompleted = JSON.parse(params.isCompleted);
      this.isPartiallyCompleted = JSON.parse(params.isPartiallyCompleted);

      this.todostatus = params.todo;
      this.completedstatus = params.completed;


      if (this.isCompleted) {
        this.setCompletedAssessment();
      } else {
        this.setNewAssessment();
      }
    }
    );
  }
  setNewAssessment() {
    this.assessmentService.getAssessment(this.assessmentTemplateId).subscribe(
      data => {
        this.assessmentTemplate = data;
        this.assessmentName = this.assessmentTemplate.assessmentName;
        this.json = JSON.parse(this.assessmentTemplate.questionJson);
        this.surveyItem = new Survey.Model(this.json);
        this.renderAssessment();
        if (this.isPartiallyCompleted) {
          this.assessmentService.getCompletedAssessment(this.assessmentTemplateUserId, this.assessmentTemplateId).subscribe(
            answerData => {
              this.answerJson = JSON.parse(answerData.answerJson);
              this.surveyItem.data = this.answerJson;
              this.length = Object.keys(this.answerJson).length;
            }
          )
        }
      },
      err => this.notificationService.error([err.error.errorMessage], true),
    );
  }

  setCompletedAssessment() {
    this.assessmentService.getCompletedAssessment(this.assessmentTemplateUserId, this.assessmentTemplateId).subscribe(
      answerData => {
        this.updatedDate = answerData.updatedDate;
        this.updatedBy = answerData.updatedBy;
        this.togetherModeUserName = answerData.togetherModeUserName;
        this.assessmentService.getAssessment(this.assessmentTemplateId).subscribe(
          data => {
            this.assessmentTemplate = data;
            this.assessmentName = this.assessmentTemplate.assessmentName;
            this.json = JSON.parse(data.questionJson);
            const answerJson = JSON.parse(answerData.answerJson);
            this.surveyItem = new Survey.Model(this.json);
            this.surveyItem.mode = 'display';
            this.renderAssessment();
            this.surveyItem.data = answerJson;
          },
          err => this.notificationService.error([err.error.errorMessage], true),
        );
      },
      err => this.notificationService.error([err.error.errorMessage], true),
    );
  }

  validateErrors(sender) {
    document.getElementById('error-message-list').innerHTML = '';
    const errorList: HTMLDivElement[] = [];
    for (let index = 0; index < sender.currentPage.questions.length; index++) {
      const question = sender.currentPage.questions[index];
      if (question.errors.length > 0) {
        const error = document.createElement('div');
        error.setAttribute('id', question.name);
        error.innerText = 'There is an issue with ' + question.title;
        errorList.push(error);
        document.getElementById('error-message-list').appendChild(error);
      }
    }
    if (errorList.length > 0) {
      document.getElementById('error-message-summary').style.display = 'block';
      const htmlErrorListNP = document.getElementsByClassName("sv_qstn_error_top");	
      for (let index = 0; index < htmlErrorListNP.length; index++) {	
        const element = htmlErrorListNP[index];	
        element.parentElement.parentElement.classList.add("myCustomClass");	
      }
    } else {
      document.getElementById('error-message-summary').style.display = 'none';
    }
  }

  renderAssessment() {
    const surveyValueChanged = function (sender, options) {
      const question = sender.getQuestionByName(options.name);
      if (question && question.isRequired && options.value) {
        question.runValidators();
        question.clearErrors();
      } else if (question && question.isRequired) {
        question.hasErrors();
      }
      validateErrors(sender);
      if (isFormValueChanged(sender)) {
        sessionStorage.setItem('isAssessmentChanged', 'true');
      } else {
        sessionStorage.setItem('isAssessmentChanged', 'false');
      }
    };

    const validateErrors = this.validateErrors.bind(this);
    const isFormValueChanged = this.isExitAllowed.bind(this);

    const defaultThemeColorsEditor = Survey
      .StylesManager
      .ThemeColors['default'];
    defaultThemeColorsEditor['$primary-color'] = '#2b769b';
    defaultThemeColorsEditor['$secondary-color'] = '#2b769b';
    defaultThemeColorsEditor['$main-color'] = '#2b769b';
    Survey.StylesManager.applyTheme();

    Survey.SurveyNG.render('surveyElement', {
      model: this.surveyItem,
      onValueChanged: surveyValueChanged,
      onValidatedErrorsOnCurrentPage: validateErrors
    });
  }

  saveSurveyResponse(result) {
    this.resolveUserName();
    const resultData = {
      userId: this.userId,
      userName: this.username,
      assessmentTemplateId: this.assessmentTemplateId,
      assessmentTemplateUserId: this.assessmentTemplateUserId,
      answerJson: result.surveyItem.data,
      isCompleted: false,
      isPartiallyCompleted: true
    };

    if (this.isFormValueChanged(result)) {
      this.saveAssessmentResult(resultData);

    } else {
      this.notificationService.error(["Select minimum of one answer!"], true);
    }
  }

  completeSurveyResponse(result) {
    this.score = 0;
    this.resolveUserName();
    const scoreQuestion = result.surveyItem.getQuestionByName("score");
    if (scoreQuestion) {
      const keys = Object.keys(result.surveyItem.data);
      const pages = this.json.pages;
      pages.forEach(page => {
        const elements = page.elements;
        elements.forEach(question => {
          if (keys.includes(question.name) && result.surveyItem.getQuestionByName(question.name) !== null) {
            this.score += this.getScoreByQuestion(result, question);
          }
        });
      });
      scoreQuestion.value = this.score;
    }

    const resultData = {
      userId: this.userId,
      userName: this.username,
      assessmentTemplateId: this.assessmentTemplateId,
      assessmentTemplateUserId: this.assessmentTemplateUserId,
      answerJson: result.surveyItem.data,
      isCompleted: true,
      isPartiallyCompleted: false
    };
    if (!result.surveyItem.checkIsCurrentPageHasErrors()) {
      this.surveyErrors = new Array();
      this.saveAssessmentResult(resultData)
    } else {
      this.executeOnError(result);
    }
  }

  executeOnError(result) {
    this.errorsBox = true;
    this.surveyErrors = new Array();
    result.surveyItem.getCurrentPageQuestions().forEach(element => {
      if (element.hasErrors()) {
        this.surveyErrors.push('There is an issue with ' + element.title);
        const questionList = document.getElementsByClassName("sv_qstn");
        for (let index = 0; index < questionList.length; index++) {
          const question = questionList[index];
          question.parentElement.parentElement.classList.remove("myCustomClass");
        }
        const htmlErrorList = document.getElementsByClassName("sv_qstn_error_top");
        for (let index = 0; index < htmlErrorList.length; index++) {
          const element = htmlErrorList[index];
          element.parentElement.parentElement.classList.add("myCustomClass");
        }
      }
    });
  }

  saveAssessmentResult(resultData) {
    this.assessmentService.saveAssessmentResult(resultData).subscribe(
      (data: any) => {
        this.isSaveSubmitOperation = true;
        this.location.back();
      },
      err => this.notificationService.error([err.error.errorMessage], true)
    );
  }

  onExitClicked(result) {
    const isTogetherMode = this.togetherMode.getTogetherModeEnabledStatus();
    if (this.isCompleted) {
      this.location.back();
    } else if (isTogetherMode && this.isPartiallyCompleted) {
      this.location.back();
    }
    else if (isTogetherMode && !this.isPartiallyCompleted) {
      this.location.back();
    }
    else if (isTogetherMode) {
      this.togetherModeExit();
    } else if (this.isExitAllowed(result.surveyItem)) {
      this.isExitClick = true;
      this.confirmService.confirm({
        message: 'You have not saved your progress, do you want to continue without saving?',
        header: '',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.location.back();
        },
        reject: () => this.isExitClick = false
      });
    } else {
      this.location.back();
    }
  }


  isFormValueChanged(result) {
    let isFormValueChanged = false;
    if (result.surveyItem.data) {
      const keys = Object.keys(result.surveyItem.data);
      if (keys && keys.length > 0) {
        keys.forEach(element => {
          const question = result.surveyItem.getQuestionByName(element);
          if (question && question.getType() !== 'expression') {
            isFormValueChanged = true;
          }
        });
      }
    }

    return isFormValueChanged;
  }

  isExitAllowed(surveyItem) {
    let isFormValueChanged = false;
    const keys = Object.keys(surveyItem.data);
    let answerKeys = null;
    if (this.answerJson !== null && this.answerJson !== undefined) {
      answerKeys = Object.keys(this.answerJson);
    }

    if (keys && answerKeys && keys.length !== answerKeys.length) {
      isFormValueChanged = true;
    }

    if (keys && keys.length > 0 && !isFormValueChanged) {
      keys.forEach(element => {
        const question = surveyItem.getQuestionByName(element);
        if (keys && keys.length > 0 && (answerKeys === null || answerKeys === undefined) && question && question.getType() !== 'expression') {
          isFormValueChanged = true;
        } else if (question && question.getType() !== 'expression' && !isFormValueChanged && this.answerJson[element] !== surveyItem.data[element]) {
          isFormValueChanged = true;
        }
      });
    }
    return isFormValueChanged;
  }

  resolveUserName() {
    if (localStorage.getItem('sessionToken')) {
      const parsedToken = JSON.parse(Base64.decode(localStorage.getItem('sessionToken').split('.')[1])) || null;
      parsedToken.firstName && parsedToken.lastName ?
        this.username = `${parsedToken.firstName} ${parsedToken.lastName}` :
        this.username = `${parsedToken.userName}`;
    }
  }

  getScoreByQuestion(result: any, question: any) {
    const questionName = question.name;
    const elementType = result.surveyItem.getQuestionByName(questionName).getType();
    let count = 0;
    if (elementType === 'checkbox') {
      const itemValue = result.surveyItem.data[questionName];
      itemValue.forEach(checkBoxOptions => {
        count += this.getCount(question, checkBoxOptions);
      });
    } else if (elementType === 'bootstrapslider') {
      const itemValue = result.surveyItem.data[questionName];
      count = Number(itemValue);
    } else if (elementType !== 'expression' && elementType !== 'html' && elementType !== 'comment') {
      const itemValue = result.surveyItem.data[questionName];
      count = this.getCount(question, itemValue);
    }
    return count;
  }

  getCount(question, itemValue) {
    let count = 0;
    if (question.rateValues && question.type === 'rating') {
      question.rateValues.forEach(choice => {
        if (choice.value === itemValue && choice.score) {
          count = Number(choice.score);
        }
      });
    } else {
      if (question.choices) {
        question.choices.forEach(choice => {
          if (choice.score && choice.value === itemValue) {
            count = Number(choice.score);
          }
        });
      }
    }
    return count;
  }
}
