import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MainService } from '../../../../core/services/main.service';
import { EmploymentService } from '../../employment.service';
import { AuthenticationService } from '../../../../authentication/authentication.service';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { FormUtil } from '../../../../shared/components/form-control/form.util';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { JobAttachmentService } from '../../job-application/job-attachment/job-attachment.service';
import { INTERESTED_JOB_APPLICATION_VALIDATION_CONTEXT } from './expression-of-interest.validation';
import { ExpressedInterest } from '../../employment.interface';

const APPLICATION_VALIDATORS = {
  attachments: [ValidationService.arrayEmpty]
}

@Component({
  selector: 'vc-expression-of-interest',
  templateUrl: './expression-of-interest.component.html',
  styleUrls: ['./expression-of-interest.component.scss']
})
export class ExpressionOfInterestComponent implements OnInit , OnDestroy, AfterViewInit {

  application: FormGroup;
  job: any;
  currentUser: any;
  message: string;
  hasModified: boolean = false;

  private attachmentSub: Subscription;
  jobId: any;
  rotl: any = 'Yes';
  expressedInterest: ExpressedInterest;


  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly mainService: MainService,
    private readonly attachmentService: JobAttachmentService,
    private readonly empService: EmploymentService,
    private readonly auth: AuthenticationService,
    private readonly validation: ValidationService,
    private readonly confirmService: ConfirmService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = this.route.snapshot.data.mainTitle;
    this.validation.setContext(INTERESTED_JOB_APPLICATION_VALIDATION_CONTEXT );
    this.currentUser = this.auth.currentUser();
    this.initForm();
    this.route.queryParams.subscribe(params => {
      if (!params.job) {
        this.router.navigate(['/employment']);
      } else {
        this.jobId = params.job;
        this.empService.getAdvertNfnJobDetails(params.job)
          .subscribe(job => {
            if (!job) {
              this.router.navigate(['/employment']);
            } else {
              this.job = job;
              if (!this.job.availableOnROTL) {
                this.rotl = 'No';
              }
            }
          });
          this.empService.getAdvertNFNJobStatus(params.job).subscribe(response => {
            this.expressedInterest = response;
          });
      }
    });
  }

  ngOnInit() {

    this.attachmentSub = this.attachmentService.onChangeApplicationInfo()
      .subscribe(data => {
        if (data && data.jobId === this.jobId) {
          this.application.get('attachments').setValue(data.attachments);
        } 
      });
  }

  ngAfterViewInit() {
    window.scroll({ top: 0, left: 0 });
  }

  ngOnDestroy() {
    this.mainService.pageMainTitle = '';
    if (this.attachmentSub) {
      this.attachmentSub.unsubscribe();
    }
  }

  private initForm() {
    this.application = this.fb.group({
      attachments: this.fb.control([])
    });
  }


  goToAttachment() {
    this.router.navigate(['/employment/jobs-for-prison-leavers/expression-of-interest/attachment'], { queryParams : { job: this.jobId} });
  }

  dropAttachment(index: number) {
    const attachments = this.application.get('attachments').value as any[];
    attachments.splice(index, 1);
    const appInfo = {
      jobId: this.job.id,
      attachments: attachments,
    }
    this.attachmentService.attach(appInfo);
  }

  updateState(val?: any) {
    const attachments = this.application.get('attachments').value as any[];
    const appInfo = {
      jobId: this.job.id,
      attachments: attachments
    }
    this.attachmentService.attach(appInfo);
  }


  back() {
    this.location.back();
  }

  sendApplication() {
    FormUtil.setValidators(this.application, APPLICATION_VALIDATORS);
    if (this.application.valid) {
      const payLoad = this.parseSendData(this.application.getRawValue());
      this.empService.expressInterestNFN(payLoad).subscribe(res=> {
        this.revertToJobDetails(this.job.id);
      });
    }
    this.ngAfterViewInit();
  }

  revertToJobDetails(job: string) {
    this.attachmentService.attach(null);
    this.router.navigate([`/employment/jobs-for-prison-leavers/view-jobs-for-prison-leavers/${ this.job.id }`]);
  }

  cancel() {
    FormUtil.clearAllValidatorsAndErrors(this.application);
    if (this.application.valid || this.application.touched) {
      this.confirmService.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to cancel your application?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.revertToJobDetails(this.job.id);
        },
        reject: () => {
          this.confirmService.choose(false);
        }
      });
    } else {
      this.revertToJobDetails(this.job.id);
    }
  }


  private parseSendData(formData: any): any {
    const data: any = {};
    data.jobId = this.job.id;
    data.attachments = (formData.attachments as any[]).map(each => {
      return {
        attachmentId: each.id,
        attachmentType: each.type
      }
    });
    return data;
  }

}
