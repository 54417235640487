<div id="fav-jobs">
    <h2 class="fav-job">Favourite jobs</h2>
    <table>
        <caption></caption>
        <thead>
            <tr>
                <th scope="colgroup">Jobs</th>
                <th scope="colgroup"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let s of favJobList | paginate: { itemsPerPage: size, currentPage: (page + 1), totalItems: totalItems }">
                <td scope="row" [ngClass]="{'disable': s?.expired}">
                    <a class='link job-title' href="javascript: void(0);" [routerLink]="['/employment/job-details']" [queryParams]="{job: s.id, what: s.job_title, where: s.location_name}">{{s?.job_title | truncate:[90, '...']}}</a>
                    <span *ngIf="s?.expired" class="status-tag status-expired">EXPIRED</span>
                    <span>{{s?.date | date : 'dd MMMM yyyy'}}</span>
                    <span>{{s?.contract_type}}</span>
                    <span>{{s?.organization_name}}</span>
                    <span>{{s?.location_name}}</span>
                    <span>{{s?.salary | currency: 'GBP' : 'symbol' : '3.0'}}</span>
                    <span>{{s?.job_description | truncate:[180, '...']}}</span>
                </td>
                <td class="remove-from-my-content-container">
                    <div class="heart-favourite-container">
                        <a class="heart-favourite-true" aria-hidden="true" title='Remove from favourite jobs' (click)="deleteFavJob(s.id)"></a>
                        <a class="favourite-link" title="Remove from favourite jobs" (click)="deleteFavJob(s.id)">Remove from favourite jobs</a>
                    </div>
                </td>
            </tr>
            <tr *ngIf="totalItems === 0">
                <p class="tabsMessage">No favourite jobs found</p>
            </tr>
        </tbody>
    </table>
    <div class="grid-row" *ngIf="totalItems > size">
        <p></p>
        <pagination-controls [maxSize]="10" (pageChange)="pageChange($event)">
        </pagination-controls>
    </div>
</div>