import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MainService } from '../../../core/services/main.service';
import { WalletService } from '../../wallet/wallet.service';


@Component({
  selector: 'vc-job-activity',
  templateUrl: './job-activity.component.html',
  styleUrls: ['./job-activity.component.scss']
})
export class JobActivityComponent implements OnInit, OnDestroy {

  state: string = 'favourite';

  constructor(
    private readonly main: MainService,
    private readonly location: Location,
    private readonly walletService: WalletService,
    ) {
    this.main.pageTitle = 'Job activity';
    this.main.pageMainTitle = 'Wallet';
  }
  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }
  ngOnInit() {
    
  }

  setActive(state: string) {
    this.state = state;
    this.updateNotificatonDetails(state);
  }

  updateNotificatonDetails(state){
    if(state==="applied"){
      this.walletService.updateNotificationCount({ partitionKey: "Job_Activity" })
    .subscribe(data => {
      this.walletService.emitWalletNotification(data.walletFlag);
    });
    }
  }

  gotoPrev() {
    this.location.back();
  }

}
