import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationControlsComponent } from './comps/pagination-controls.component';
import { PaginatePipe } from './pipes/paginate.pipe';
import { PaginationControlsDirective } from './dirs/pagination-controls.directive';
import { PaginationService } from './pagination.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PaginatePipe,
    PaginationControlsComponent,
    PaginationControlsDirective,
  ],
  exports: [
    PaginatePipe,
    PaginationControlsComponent,
    PaginationControlsDirective
  ],
  providers: [
    PaginationService
  ],
})
export class PaginationModule { }
