import { Component, OnInit, NgZone } from '@angular/core';
import { EmploymentService } from '../employment.service';

@Component({
  selector: 'vc-prison-leavers-jobs',
  templateUrl: './prison-leavers-jobs.component.html',
  styleUrls: ['./prison-leavers-jobs.component.scss']
})
export class PrisonLeaversJobsComponent implements OnInit {

 
  page: number = 0;
  size: number = 10;
  sort: any = 'dateExpressed,desc';
  expressedJobList: any[];
  totalItems: number = 0;
  first: Boolean = false;
  last: Boolean = false;

  constructor(
    private readonly employmentService: EmploymentService,
    private readonly zone: NgZone) { }

  ngOnInit() {
    this.fetchNFNJobList(this.page, this.size, this.sort);
  }

  pageChange(page: number) {
    this.page = page - 1;
    this.fetchNFNJobList((page - 1), this.size, this.sort);
    window.scrollTo(0, 0);
  }

  fetchNFNJobList(page, size, sort) {
    this.employmentService.getInterestedNFNJobList(page, size, sort).subscribe(resp => {
      this.expressedJobList = resp.content;
      this.totalItems = resp.totalElements;
      this.first = resp.first;
      this.last = resp.last;
    });
  }

}
