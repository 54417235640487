import { Itabs } from './tabs.interface';
import { Component, OnInit, Input, ElementRef, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TabsService } from './tabs.service';

@Component({
  selector: 'vc-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  @Input() profileTab: Itabs[];

  constructor(private readonly tabService: TabsService) {
    this.tabService.currentTab.subscribe(tab => {
      this.profileTab = tab;
    });
  }

  ngOnInit() {

  }

}
