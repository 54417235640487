import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vc-plan-history',
  templateUrl: './plan-history.component.html',
  styleUrls: ['./plan-history.component.scss']
})
export class PlanHistoryComponent implements OnInit {

  @Input() historyData: any;

  maxHistoryShown: number;
  loadMoreConstant = 15;

  constructor() { }

  ngOnInit() {
    this.maxHistoryShown = this.loadMoreConstant;
  }

  loadMore() {
    this.maxHistoryShown = this.maxHistoryShown + this.loadMoreConstant;
  }

  setUpperHistoryEntrySpacer(historyEntry, i) {
    if (this.historyData[i-1] && this.historyData[i-1].startDate === historyEntry.startDate && this.historyData[i-1].courseCode === null) {
      return 'history-entry-spacer-combined'
    } else {
      return 'history-entry-spacer'
    }
  }

  setLowerHistoryEntrySpacer(historyEntry, i) {
    if (this.historyData[i+1] && this.historyData[i+1].startDate === historyEntry.startDate && this.historyData[i+1].courseCode === null) {
      return 'history-entry-spacer-combined'
    } else {
      return 'history-entry-spacer'
    }
  }

}
