<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30 question-four">
  <p class="number-of-pages">Page 4 of 13</p>
  <vc-all-errors [groups]="[pageFourForm]" [fromContext]="true"></vc-all-errors>
  <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>
  <form [formGroup]="pageFourForm" (submit)="onClickContinue()">
    <vc-month-form-control [control]="pageFourForm.get('releaseDate')" [controlName]="'releaseDate'" id="id-releaseDate"
      [fromContext]="true" hint="For example, 11 2021">
    </vc-month-form-control>
    <div class="radio-conditional">
      <div class="form-hint">PLEASE CHECK PNOMIS FOR CORRECT KEY DATES</div>
    </div>
    <div class="grid-row footer-div">
      <button id="id-continue" class="button button-link ml0" type="submit" name="continue">Continue</button>
      <button *ngIf="routeState !== 'changeAnswer'" id="id-save-as-draft" class="button button-secondary" type="button" name="save-as-draft"
        (click)="onClickSave()">Save as draft</button>
    </div>
  </form>
</div>