import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, Form } from '@angular/forms';
import { MainService } from './../../../core/services/main.service';
import { ValidationService } from './../../../shared/components/form-control/validation.service';
import { InductionCheckAnswersService } from './induction-check-answers.service';
import { INDUCTION_QUESTIONS_VALIDATION_CONTEXT } from './../induction-questions-list/induction-questions-list.validation';

const CHECKANSWERSFORM_VALIDATORS_CREATE = {
  agreement: [Validators.required]
}

@Component({
  selector: 'vc-induction-check-answers',
  templateUrl: './induction-check-answers.component.html',
  styleUrls: ['./induction-check-answers.component.scss']
})
export class InductionCheckAnswersComponent implements OnInit {

  checkAnswersData = [];
  pageNumbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen']
  questionErrors = [];
  planAgreeFlag = null;

  checkAnswersForm: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly renderer: Renderer2,
    private readonly validation: ValidationService,
    private readonly mainService: MainService,
    private readonly inductionCheckAnswersService: InductionCheckAnswersService,
  ) {
    this.mainService.pageTitle = 'Induction';
  }

  ngOnInit() {
    window.scroll(0,0);
    sessionStorage.setItem('isAssessmentInProgress', 'false');
    this.checkIfPlanAgreed();
    this.getInductionAnswers();
    this.initForm();
    this.validation.setContext(INDUCTION_QUESTIONS_VALIDATION_CONTEXT);
  }

  checkIfPlanAgreed() {
    this.inductionCheckAnswersService.checkPlanAgreed().subscribe(data => {
      this.planAgreeFlag = data.isMainPlanAgree;
    })
  }

  getInductionAnswers() {
    this.inductionCheckAnswersService.getCheckAnswersData().subscribe(data => {
      data.forEach(pageData => {
        this.checkAnswersData.push(
          {
            label: pageData.sectionDescription,
            value: pageData.ansList.length === 0 ? 
              this.formatEmptyValue(pageData.ansList, pageData.sectionDescription) : 
              this.formatValue(pageData.sectionIdentifier, pageData.ansList, pageData.sectionDescription),
            panelStateOpen: false,
            pageNumber: this.setPageNumber(pageData.sectionIdentifier)
          }
        )
      });
    })
  }

  setPageNumber(sectionIdentifier): string | undefined {
    switch(sectionIdentifier) {
      case "1":
        return 'one'
      case "2":
        return 'two'
      case "3":
        return 'three'
      case "4":
        return 'four'
      case "5":
        return 'five'
      case "6":
        return 'six'
      case "7":
        return 'seven'
      case "8":
        return 'nine'
      case "9":
        return 'ten'
      case "10":
        return 'eleven'
      case "11":
        return 'twelve'
      case "12":
        return 'thirteen'
      case "13":
        return 'eight'
    }
  }

  formatEmptyValue(answerArr, label) {
    answerArr.push(`There is no entry in '${label}'`);
    return answerArr
  }

  formatValue(pageNumber, answerArr, label) {
    switch(pageNumber) {
      case "1":
        if (answerArr[1] === '') {
          answerArr.pop();
          return answerArr
        } else {
          const formattedTempOneArr = [];
          answerArr.forEach(element => {
            formattedTempOneArr.push(element.charAt(0).toUpperCase() + element.slice(1));
          });
          return formattedTempOneArr
        }
      case "2":
        const tempTwoArr = [];
        answerArr.forEach(element => {
          const colonIndex = element.indexOf(':');
          const formattedString = element.slice(0, colonIndex - 1) + element.slice(colonIndex, element.length);
          tempTwoArr.push(formattedString)
        });
        return tempTwoArr
      case "3":
        return this.formatAnswerThree(answerArr)
      case "4":
        return this.formatAnswerFour(answerArr, label)
      case "5":
      case "6":
        if (answerArr.length > 0) {
          const tempFiveArr = this.formatAnswerWithCommas(answerArr);
          const formattedTempFiveArr = [];
          tempFiveArr.forEach(element => {
            formattedTempFiveArr.push(element.charAt(0).toUpperCase() + element.slice(1));
          });
          return formattedTempFiveArr
        } else {
          return answerArr
        }
      case "7":
      case "13":
        return this.formatAnswerSeven(answerArr)
      case "8":
        if (answerArr[0] === '') {
          answerArr[0] = `There is no entry in '${label}'`;
          return answerArr
        } else {
          const tempEightArr = [];
          answerArr.forEach(element => {
            tempEightArr.push(element.charAt(0).toUpperCase() + element.slice(1).toLowerCase())
          });
          return tempEightArr
        }
      case "9":
        if (answerArr.length === 1) {
          const formattedArr = this.checkAndFormatOtherValue(answerArr);
          return formattedArr[0].split(',')
        } else {
          return this.checkAndFormatOtherValue(answerArr)
        }
      case "10":
        const tempTenArr = [];
        answerArr.forEach(element => {
          tempTenArr.push(element.charAt(0).toUpperCase() + element.slice(1).toLowerCase().split(',').join(', '))
        });
        return tempTenArr
      case "12":
        return this.formatAnswerWithCommas(answerArr)
      default:
        return this.formatAnswerDefault(answerArr, label)
    }
  }

  formatAnswerDefault(answerArr, label) {
    if (answerArr[0] === '') {
      answerArr[0] = `There is no entry in '${label}'`;
      return answerArr
    } else { 
      return answerArr
    }
  }

  formatAnswerThree(answerArr) {
    if (answerArr.length === 1 || answerArr.length === 2 && answerArr[0] === 'Yes' && answerArr[1] === 'Yes') {
      answerArr.push(`There is no entry in 'What languages do you speak'`)
      return answerArr
    } else if (answerArr.length === 2 && answerArr[1] === '') {
      answerArr.push(`There is no entry in 'Do you speak any other languages'`)
      return answerArr
    } else {
      answerArr[1] = answerArr[1].split(',').join(', ');
      return answerArr
    }
  }

  formatAnswerFour(answerArr, label) {
    if (answerArr[0].charAt(0) === '-') {
      answerArr[0] = '';
      return this.formatAnswerDefault(answerArr, label)
    } else {
      return this.formatAnswerDefault(answerArr, label)
    }
  }

  formatAnswerSeven(answerArr) {
    if (answerArr.length > 0) {
      const splitAnswerArr = this.formatAnswerWithCommas(answerArr);
      const tempSevenArr = [];
      const formattedTempSevenArr = [];
      splitAnswerArr.forEach(element => {
        element.length > 30 ? tempSevenArr.push(element.substring(0, 30) + '...') : tempSevenArr.push(element);
      })
      tempSevenArr.forEach(element => {
        formattedTempSevenArr.push(element.charAt(0).toUpperCase() + element.slice(1).toLowerCase());
      })
      return formattedTempSevenArr
    } else {
      return answerArr
    }
  }

  formatAnswerWithCommas(answerArr) {
    if (answerArr.length > 0) {
      const tempCommaSplitIndex = [];
      const tempCommaNotSplitIndex = [];
  
      let i = 0;
      for (i = 0; i < answerArr[0].length; i++) {
        if (answerArr[0][i] === ',') {
          answerArr[0][i+1] === ' ' ? tempCommaNotSplitIndex.push([i]) : tempCommaSplitIndex.push([i]);
        }
      }

      return this.setTempFormattedAnswerArr(tempCommaSplitIndex, answerArr)
    } else {
      return answerArr
    }
  }

  setTempFormattedAnswerArr(tempCommaSplitIndex, answerArr) {
    const tempFormattedAnswerArr = [];
    switch(tempCommaSplitIndex.length) {
      case 0:
        tempFormattedAnswerArr.push(answerArr[0]);
        break;
      case 1:
        tempFormattedAnswerArr.push(answerArr[0].substring(0, tempCommaSplitIndex[0]))
        tempFormattedAnswerArr.push(answerArr[0].substring(parseInt(tempCommaSplitIndex[0])+1, answerArr[0].length))
        break;
      default:
        let i = 0;
        for (i = 0; i < tempCommaSplitIndex.length; i++) {
          if (i === 0) {
            tempFormattedAnswerArr.push(answerArr[0].substring(0, tempCommaSplitIndex[i]))
          } else if (i === tempCommaSplitIndex.length - 1) {
            tempFormattedAnswerArr.push(answerArr[0].substring(parseInt(tempCommaSplitIndex[i-1])+1, parseInt(tempCommaSplitIndex[i])))
            tempFormattedAnswerArr.push(answerArr[0].substring(parseInt(tempCommaSplitIndex[i])+1, answerArr[0].length))
          } else {
            tempFormattedAnswerArr.push(answerArr[0].substring(parseInt(tempCommaSplitIndex[i-1])+1, parseInt(tempCommaSplitIndex[i])))
          }
        }
    }
    return tempFormattedAnswerArr
  }

  checkAndFormatOtherValue(answerArr) {
    const tempNineArr = [];
    answerArr.forEach(element => {
      const otherStringChecker = 'Other :';
      const arrayItemStringToCheck = element.substring(0,7);
      if (otherStringChecker === arrayItemStringToCheck) {
        element.length > 38 ? tempNineArr.push(element.substring(8, 38) + '...') : tempNineArr.push(element.substring(8, 38))
      } else {
        tempNineArr.push(element)
      }
    });
    return tempNineArr
  }

  initForm() {
    this.checkAnswersForm = this.formBuilder.group({
      agreement: this.formBuilder.control(''),
    })
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  togglePanelState(panelLabel) {
    this.checkAnswersData = this.checkAnswersData.map(question => {
      if (question.label === panelLabel) {
        return {
          ...question, 
          panelStateOpen: !question.panelStateOpen
        } 
      } else {
        return question
      }
    });
  }

  onClickStartFromBeginning() {
    sessionStorage.removeItem('routeState');
    this.router.navigateByUrl('/plan/induction/page-one');
  }

  onClickChange(pageNumber) {
    sessionStorage.setItem('routeState', 'changeAnswer');
    this.router.navigate([`/plan/induction/page-${pageNumber}`]);
    window.scroll(0,0);
  }

  onClickCreatePlan() {
    this.questionErrors = [];
    this.setValidators(this.checkAnswersForm, CHECKANSWERSFORM_VALIDATORS_CREATE);
    if (this.checkAnswersForm.valid) {
      const payload = {
        "isPlanAgree": this.checkAnswersForm.controls.agreement.value,
        "planTypeIdentifier": "1"
      }
      this.inductionCheckAnswersService.agreePlan(payload).subscribe(result => {
        sessionStorage.removeItem('routeState');
        this.router.navigateByUrl('/plan');
        window.scroll(0,0);
      }, error => {
        const colonIndex = error.error.errorMessage.indexOf(':');
        const formattedString = error.error.errorMessage.slice(colonIndex + 1, error.error.errorMessage.length);
        const errorQuestionsArr = formattedString.split(',');

        errorQuestionsArr.forEach(element => {
          const pageNumberString = this.formatPageNumberString(element)
          const pageDataArr = this.checkAnswersData.filter(section => section.pageNumber === pageNumberString)
          this.questionErrors.push(
            {
              label: pageDataArr[0].label,
              pageNumber: pageNumberString
            }
          )
        });

        setTimeout(() => {
          const checkAnswersErrorSummary = this.renderer.selectRootElement('.check-answers-error-summary', true);
          checkAnswersErrorSummary.focus();
        })
      })
    } else {
      this.setFocusToErrorSummary();
    }
  }

  formatPageNumberString(pageNumberString) {
    const pageNumber = parseInt(pageNumberString);
    switch(pageNumber) {
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
        return this.pageNumbers[pageNumber]
      case 13:
        return this.pageNumbers[7];
      default:
        return this.pageNumbers[pageNumber-1]
    }
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  checkIfAnswersShouldBeAccordion(questionNumber, answerArr) {
    switch(questionNumber) {
      case "one":
        if (answerArr.length > 1) {
          return true
        } else {
          return false
        }
      case "five":
      case "six":
      case "ten":
      case "twelve":
        if (answerArr.length > 3) {
          return true
        } else {
          return false
        }
      default:
        return false
    }
  }

  backClicked() {
    this.router.navigateByUrl('/plan/induction/page-thirteen');
  }

  setAnswerMargin(index) {
    return {
      'margin-top': index > 0 ? '10px' : '0px',
      'margin-bottom': '0px' 
    }
  }

  setBorderBottom(panelStateOpen) {
    return {
      'border-bottom': panelStateOpen === true ? 'none' : '1px solid #b1b4b6',
      'padding-bottom': panelStateOpen === true ? '0px' : '10px'
    }
  }

  setMarginBottom(panelStateOpen) {
    return {
      'margin-bottom': panelStateOpen === true ? '0px' : '15px'
    }
  }

  setErrorMessageFocus(pageNumber) {
    const changeLink = this.renderer.selectRootElement(`#change-${pageNumber}`, true);
    changeLink.focus();
  }

}
