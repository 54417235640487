/*
	* Validation messages for Work Experience
*/

export const WORK_EXPERIENCE_VALIDATION_CONTEXT = {
    ifChecked: {
        hasAddedValue: `Please select a work experience type`
    },
    employmentCheck: {
        hasAddedValue: `Unselect 'I do not have any employment and work experience' first, before selecting to add an employment experience`
    },
}
