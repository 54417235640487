<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="additiona-info clear mt25">
    <vc-all-errors [groups]="[additionalInfoForm, additionalInfoForm.get('hobbies')]" [fromContext]="true">
    </vc-all-errors>
    <div class="grid-row">
        <form [formGroup]="additionalInfoForm" autocomplete="off">
            <div class="column-full">
                <vc-form-control [control]="additionalInfoForm.get('isLicenced')" [controlName]="'isLicenced'"
                    [label]="'Do you have a full driving licence?'" [fromContext]="true" id="id-isLicenced">
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="additionalInfoForm.get('isLicenced')" value="Y" id="id-isLicenced-true" name="isLicenced"
                            aria-describedby="id-section-help-isLicenced-true id-section-error-isLicenced-true" />
                        <label for="id-isLicenced-true">Yes</label>
                    </div>
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="additionalInfoForm.get('isLicenced')" value="N" id="id-isLicenced-false" name="isLicenced"
                            aria-describedby="id-section-help-isLicenced-false id-section-error-isLicenced-false" />
                        <label for="id-isLicenced-false">No</label>
                    </div>
                </vc-form-control>
            </div>
            <div class="column-full">
                <vc-input-chips [control]="additionalInfoForm.get('languages')" id="id-languages" [fromContext]="true"
                    controlName="languages" label="What languages do you speak?" hint="Add up to five languages"
                    [restrictMax]="true" [dataList]="languages">
                </vc-input-chips>
            </div>
            <div class="column-full">
                <vc-multi-select-options [formGroup]="additionalInfoForm.get('hobbies')" id="id-answers"
                    [hint]="'Please select up to 3 options'" [restrictMax]="true" [max]="3"></vc-multi-select-options>
            </div>
            <div class="column-full" *ngIf="additionalInfoForm.get('otherHobbies')">
                <vc-form-control [control]="additionalInfoForm.get('otherHobbies')" [controlName]="'otherHobbies'"
                    [label]="'Please specify other hobbies and interests'" [fromContext]="true">
                    <textarea formControlName="otherHobbies" rows="5" class="form-control" type="text"
                        id="id-otherHobbies" name="otherHobbies"
                        aria-describedby="id-section-help-otherHobbies id-section-error-otherHobbies">
                    </textarea>

                    <div *ngIf="additionalInfoForm.get('otherHobbies').value.length <= 200"
                        class="form-label form-label-count">You
                        have
                        {{200 - additionalInfoForm.get('otherHobbies').value.length}} characters remaining</div>
                    <div *ngIf="additionalInfoForm.get('otherHobbies').value.length === 201" class="error-message">You
                        have {{ additionalInfoForm.get('otherHobbies').value.length - 200}} character too many</div>
                    <div *ngIf="additionalInfoForm.get('otherHobbies').value.length > 201" class="error-message">You
                        have {{ additionalInfoForm.get('otherHobbies').value.length - 200}} characters too many</div>
                </vc-form-control>
            </div>
        </form>
    </div>

    <div class="grid-row mb25">
        <button id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
            (click)="saveAndContinue()">Save and
            continue</button>
        <button id="id-save-and-come-back-later" class="button button-secondary mr15" type="button"
            name="save-and-come-back-later" (click)="saveAndLater()">Save and come back
            later</button>
        <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
            (click)="cvSummaryPage()">CV summary</button>
    </div>
</div>