/* 
	* Validation error messages for personal details page
*/

export const PERSONAL_DETAILS_VALIDATION_CONTEXT = {

	required: {
		firstName: 'Please enter your first name',
		lastName: 'Please enter your last name',
	},
	email: {
		emailAddress: 'Please enter your email address in correct format',
	},
	maxlength: {
		title: 'Please enter 100 or less characters in title',
		firstName: 'Please enter 100 or less characters in first name',
		lastName: 'Please enter 100 or less characters in last name',
		addressLineOne: 'Please enter 100 or less characters in address line one',
		town: 'Please enter 50 or less characters in town or city',
  		county: 'Please enter 50 or less characters in county',
		postCode: 'Please enter 20 or less characters in post code'
	},
	invalidtelephoneNumber: {
		telephone: 'Please enter a valid telephone number'
	},
	invalidmobileNumber: {
		mobileNumber: 'Please enter a valid mobile number'
	}
}
