<form [formGroup]="searchJob" autocomplete="off" class="clear mt25" (ngSubmit)="goToSearch()">
    <div class="grid-row">
        <div class="column-full nomarginbottom search-container">
                <div class="form-group border-box form-control-2-1 left ">
                        <label class="heading-large nomargintop">What?</label>
                        <input class="form-control left form-control-1-1" formControlName="what" placeholder="Job title" (keyup)="onKeyJob($event)" list="jobtitles" name="jobNames" required/>
                        <datalist id="jobtitles" >
                            <option *ngFor="let job of jobTitle; let index;" value="{{job}}">{{job}}</option>
                        </datalist>
                    </div>
                
                    <div class="form-group border-box form-control-2-1 left ">
                        <label class="heading-large nomargintop">Where?</label>
                        <input class="form-control left form-control-1-1" formControlName="where" placeholder="Location" (keyup)="onKeyLocation($event)" list="jobLocation" name="jobLoc" required/>
                        <datalist id="jobLocation" >
                            <option *ngFor="let jobLoc of jobLocation; let index;" value="{{jobLoc}}">{{jobLoc}}</option>
                        </datalist>
                    </div>
                
                    <div class="left search-button">
                        <span>
                            <button id="id-submit-button" class="button" type="submit" [disabled]="searchJob.invalid" >Search</button>
                        </span>
                    </div>
        </div>
    </div>    
</form>