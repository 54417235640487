import { Component, OnInit, ViewChild, ElementRef, Renderer2, Output, OnDestroy, AfterViewChecked } from '@angular/core';
import { InductionQuestionnaireService } from './../../induction-questionnaire/induction-questionnaire.service';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { EducationService } from '../../../cv-builder/guided-cv-builder/education/education.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from './../../../../shared/components/form-control/validation.service';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { Utility } from '../../../../shared/utils/utility';
import { EDUCATION_VALIDATION_CONTEXT } from '../../../cv-builder/guided-cv-builder/education/education.validation';
import { PageFiveService } from './page-five.service';
import { ICanDeactivate } from '../../../../core/guards/deactivate.interface';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';

export interface IRefData {
  educationId: number;
  identifier: string;
  educationTypeId: number;
}

const VALIDATORS = {
  hasAddedValue: [ValidationService.ifChecked]
}

const EDUCATIONFORM_VALIDATORS_SAVE = {
  courseStartDate: [Validators.required, ValidationService.monthYearValidator, ValidationService.futureDate, ValidationService.pastYear(80)],
  courseEndDate: [ValidationService.monthYearValidator,
  ValidationService.invalidEndDateOrCurrent('courseStartDate', 'courseEndDate', 'isStudying'),
  ValidationService.invalidEndDateAndCurrent('courseEndDate', 'isStudying'),
  ValidationService.futureDate],
  institute: [Validators.required, Validators.maxLength(100)],
  courseTitle: [Validators.required, Validators.maxLength(100)],
  otherEducationType: [Validators.required, Validators.maxLength(200), Validators.pattern('[a-zA-Z0-9\\-\\s\&\(\)\/\.\'\,\"\:\;]*')],
  grade: [Validators.maxLength(100)],
  educationType: [Validators.required]
}

const PAGEFIVEFORM_DIAGNOSED_YES_VALIDATORS_SAVE = {
  lldDetails: [Validators.required, Validators.maxLength(200)]
}

const PAGEFIVEFORM_DIAGNOSED_YES_VALIDATORS_DRAFT = {
  lldDetails: [Validators.maxLength(200)]
}

const PAGEFIVEFORM_VALIDATORS_SAVE = {
  diagnosed: [Validators.required]
}


@Component({
  selector: 'vc-page-five',
  templateUrl: './page-five.component.html',
  styleUrls: ['./page-five.component.scss']
})
export class PageFiveComponent implements OnInit, AfterViewChecked, OnDestroy {

  form: FormGroup;
  currentPageNumber: number;
  currentPageData = [] as any;
  refData = [] as any;
  data: any = [];
  educationTypes: any[] = [];
  isDialogOpen = false;
  educationList = [] as any;
  educationId: number;
  isAssessmentInProgressFlag = false;

  routeState: any;

  pageFiveForm: FormGroup;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-four';
  routeNext = '/plan/induction/page-six';

  @ViewChild("question") questionDiv: ElementRef;
  @Output() isEditEducationClicked : boolean;

  educationForm: FormGroup;
  createAnother = new FormControl('N');


  constructor(
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly formBuilder: FormBuilder,
    private readonly renderer: Renderer2,
    private readonly educationService: EducationService,
    private readonly eduService: EducationService,
    private readonly validation: ValidationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fBuilder: FormBuilder,
    private readonly confirm: ConfirmService,
    private readonly pageFiveService: PageFiveService,
    private readonly togetherMode: TogetherModeModalService,
  ) {
    this.validation.setContext(EDUCATION_VALIDATION_CONTEXT);
    this.initForm();
    this.initPageFiveForm();
  }

  ngOnInit() {
    this.getRefData();
    this.resolveRefData();
    this.getEducationPlanList();
    this.resolveRouteState();

    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.educationId = params.id;
        this.isEditEducationClicked = true;
        this.resolveEducation(+params.id);
      }
      else{
        this.educationId = null;
        this.isEditEducationClicked = false;
      }
    });
    this.subscribeValueChanges();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngAfterViewChecked() {
    if (this.pageFiveForm.dirty && this.isAssessmentInProgressFlag === false || this.educationForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  ngOnDestroy(): void {
      sessionStorage.removeItem('routeState');
  }
  private subscribeValueChanges() {
    this.educationForm.get('educationType').valueChanges
      .subscribe(value => {
        if (value === 'Other' || value === 19) {
          this.educationForm.addControl('otherEducationType', this.fBuilder.control(''));
        }
        else {
          this.educationForm.removeControl('otherEducationType');
        }
      });
    this.educationForm.get('courseStartDate').valueChanges
      .subscribe(data => {
        this.educationForm.get('courseEndDate').updateValueAndValidity();
      });
    this.educationForm.get('isStudying').valueChanges
      .subscribe(data => {
        this.educationForm.get('courseEndDate').updateValueAndValidity();
      });
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  setEducationType(value) {
    const obj = Utility.getObjectFromArrayByKeyAndValue(this.educationTypes, 'educationType', value);
    if (obj == null) {
      this.educationForm.get('educationTypeId').setValue(null);
    } else {
      this.educationForm.get('educationTypeId').setValue(obj.id);
    }
  }
  getEducationPlanList() {
    this.pageFiveService.getPlanEducationList().subscribe(Response => {
      this.educationList = Response;
    });
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      this.pageFiveForm.get('diagnosed').setValue(data[0].answer);
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          },
        )
      });
    })
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.currentPageNumber = 5;
      this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  setFocusToQuestionDiv() {
    this.questionDiv.nativeElement.focus();
  }

  initForm() {
    this.educationForm = this.fBuilder.group({
      educationId: this.fBuilder.control(''),
      educationTypeId: this.fBuilder.control(''),
      educationType: this.fBuilder.control(''),
      institute: this.fBuilder.control(''),
      courseTitle: this.fBuilder.control(''),
      grade: this.fBuilder.control(''),
      isStudying: this.fBuilder.control(null),
      courseStartDate: this.fBuilder.control(''),
      courseEndDate: this.fBuilder.control(''),
    });
  }

  private initPageFiveForm() {
    this.pageFiveForm = this.formBuilder.group({
      diagnosed: this.formBuilder.control(''),
      lldDetails: this.formBuilder.control(''),
    });
  }

  resolveEducation(educationId) {
    this.pageFiveService.getEducationById(educationId).subscribe(res => {
      this.educationForm.patchValue(res);
    });
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  formatPayload(saveAsDraft) {
    return {
      "isSaveAsDraft": saveAsDraft,
      "planTypeIdentifier": "1",
      "questionDTOList": [
        {
          "answer": this.pageFiveForm.controls.diagnosed.value,
          "id": 9
        },
      ],
      "sectionIdentifier": this.currentPageData[0].sectionIdentifier
    }
  }

  private educationTypeValidator(list: string[]) {
    const validator = (control: AbstractControl) => {
      if (!list.includes(control.value)) {
        return { invalidEducationType: true }
      } else {
        return null;
      }
    }
    return validator;
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  onClickContinue() {
      const formattedPayload = this.formatPayload(false);
      this.isSaveSubmitOperation = true;
      this.saveInductionPlan(formattedPayload);
  }

  onClickSave() {
    const formattedPayload = this.formatPayload(true);
    this.isSaveSubmitOperation = true;
    this.canExit(formattedPayload);
    this.saveInductionPlan(formattedPayload);
  }

  onDiagnosedChange(value) {
    if (value === '2' && this.educationList.length > 0) {
      this.pageFiveForm.get('diagnosed').setValue('1');
      this.confirm.confirm({
        header: 'Warning',
        message: 'You must delete all Education entries before selecting no',
        showClose: false,
        acceptLabel: 'Ok',
        accept: () => {
          this.confirm.choose(true);
        }
      });
    }

  }
  
  saveInductionPlan(formattedPayload){
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      if(formattedPayload.isSaveAsDraft){
        this.router.navigateByUrl(this.routeTo);
      window.scrollTo(0, 0);
      }else
      {
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-six');
      }
    });  
  }

  resolveRefData() {
    this.pageFiveService.getEducationsTypeList().subscribe((data: any[]) => {
      this.educationTypes = data;
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      if (control) {
        control.clearValidators();
        control.setErrors(null);
        control.markAsTouched();
      }
    });
  }

  addNewEducation() {
    EDUCATIONFORM_VALIDATORS_SAVE.educationType.push(this.educationTypeValidator(this.educationTypes.map(each => each.educationType)));
    this.setValidators(this.educationForm, EDUCATIONFORM_VALIDATORS_SAVE);
    if(this.educationForm.valid) {
      const formattedPayload = this.formatEducationPayload(null);
      this.pageFiveService.createPlanEducation(formattedPayload).subscribe(result => {
        this.getEducationPlanList();
        this.resetEducationForm();
        window.scrollTo(0, 0);
        this.clearAllValidatorsAndErrors(this.pageFiveForm);
      })
    }else {
      this.setFocusToErrorSummary();
    }
    window.scrollTo(0, 0);
  }

  editEducation() {
    EDUCATIONFORM_VALIDATORS_SAVE.educationType.push(this.educationTypeValidator(this.educationTypes.map(each => each.educationType)));
    this.setValidators(this.educationForm, EDUCATIONFORM_VALIDATORS_SAVE);
    const formattedPayload = this.formatEducationPayload(this.educationId);
    if(this.educationForm.valid) {
    this.pageFiveService.editPlanEducation(formattedPayload).subscribe(result => {
      this.getEducationPlanList();
      this.router.navigate(['.'], {relativeTo: this.route});
      this.resetEducationForm();
      window.scrollTo(0, 0);
    });
  }else {
    this.setFocusToErrorSummary();
  }
  }

  
  canExit(formattedPayload) {
    if (this.educationForm.dirty && this.route.url) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.saveInductionPlan(formattedPayload);
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    } else  if (
      (this.pageFiveForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      this.isAssessmentInProgressFlag === true && this.isSaveSubmitOperation === false) ||
      (this.pageFiveForm.controls.diagnosed.value === '1' && this.isSaveSubmitOperation === false && this.educationList.length === 0 && this.isAssessmentInProgressFlag === true)) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  resetEducationForm() {
    this.initForm();
    this.subscribeValueChanges();
  }

  formatEducationPayload(id) {
    const educationType = Utility.getObjectFromArrayByKeyAndValue(this.educationTypes, 'educationType', this.educationForm.controls.educationType.value);
    return {
      "courseEndDate": this.educationForm.controls.courseEndDate.value,
      "courseStartDate": this.educationForm.controls.courseStartDate.value,
      "courseTitle": this.educationForm.controls.courseTitle.value,
      "createInductionPlanDTO": {
        "isSaveAsDraft": true,
        "planTypeIdentifier": "1",
        "questionDTOList": [
          {
            "answer": this.pageFiveForm.controls.diagnosed.value,
            "id": 9
          }
        ],
        "sectionIdentifier": this.currentPageData[0].sectionIdentifier
      },
      "educationId": id,
      "educationTypeId": educationType.id,
      "grade": this.educationForm.controls.grade.value,
      "institute": this.educationForm.controls.institute.value,
      "isStudying": this.educationForm.get('isStudying').value,
      "otherEducationType": this.educationForm.contains('otherEducationType') ? this.educationForm.get('otherEducationType').value : null,
      "planTypeId": 1
    }
  }

 
}

