import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

/*
  <> restrictMax: this flag will define whether to restric user to select more than the maximum allowed option;
  <> max: this is the flag for defining the maximum number allowed to select. if the restrictMax is true. default is 3;
*/

@Component({
  selector: 'vc-multi-select-options',
  templateUrl: './multi-select-options.component.html',
  styleUrls: ['./multi-select-options.component.scss']
})
export class MultiSelectOptionsComponent implements OnInit, OnChanges {

  @Input() formGroup: FormGroup;
  @Input() hint: string;
  @Input() restrictMax: boolean = false;
  @Input() max: number = 3

  ngOnChanges(changes: SimpleChanges): void { }

  constructor() { }

  ngOnInit() { }

  private getValue(i: number, name: string) {
    return (this.formGroup.get('answers') as FormArray).controls[i].get(name).value;
  }

  private toggle(i: number, name: string, value: any) {
    const ctr = (this.formGroup.get('answers') as FormArray).controls[i].get(name);
    ctr.setValue(value);
    ctr.markAsTouched();
    ctr.markAsDirty();
    this.formGroup.markAllAsTouched();
  }

  update(i: number, name: string): void {
    if (!this.restrictMax) {
      this.toggle(i, name, !this.getValue(i, name));
    } else {
      const checked = (this.formGroup.get('answers') as FormArray).controls
        .filter(each => each.value.checked === true);
      if ((checked && checked.length < this.max) || this.getValue(i, name)) {
        this.toggle(i, name, !this.getValue(i, name));
      }
    }
  }

}
