<a href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="check-answers" class="mb30 mr15">
  <vc-all-errors [groups]="[checkAnswersForm]" [fromContext]="true"></vc-all-errors>
  <div class="check-answers-error-summary" *ngIf="questionErrors.length > 0" tabindex="1">
    <h2 class="error-summary-title">There is a problem</h2>
    <div class="error-summary-body">
      <ul class="error-summary-list">
        <li *ngFor="let error of questionErrors">
          <a href="javascript: void(0)" (click)="setErrorMessageFocus(error.pageNumber)">Please select Change and confirm your answers in '{{error.label}}'</a>
        </li>
      </ul>
    </div>
  </div>
  <h1 class="heading">Check your answers before creating your plan</h1>
  <button 
    id="id-start-from-beginning" 
    class="button button-secondary start-from-beginning-btn" 
    type="button"
    name="start-from-beginning"
    (click)="onClickStartFromBeginning()"
  >Start from beginning</button>
  <dl class="summary-list">
    <ng-container *ngFor="let question of checkAnswersData">
      <div class="summary-list-row" *ngIf="question.pageNumber !== 'three'">
        <dt class="summary-list-key" id="question-label-{{question.pageNumber}}">
          {{question.label}}
        </dt>
        <dd class="summary-list-value">
          <ng-container *ngFor="let value of question.value; let i = index">
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="question.pageNumber === 'one'">
                <p *ngIf="i < 1 && question.panelStateOpen === false" [ngStyle]="setAnswerMargin(i)">
                  {{value}}
                </p>
                <p *ngIf="question.panelStateOpen === true" [ngStyle]="setAnswerMargin(i)">
                  {{value}}
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="question.pageNumber === 'four'">
                <p *ngIf="value.charAt(0) === 'T'" [ngStyle]="setAnswerMargin(i)">
                  {{value}}
                </p>
                <p *ngIf="value.charAt(0) !== 'T'" [ngStyle]="setAnswerMargin(i)">
                  {{value | date: 'LLLL yyyy'}}
                </p>
              </ng-container>
              <ng-container *ngSwitchCase="question.pageNumber === 'five' || question.pageNumber === 'six' || question.pageNumber === 'ten' || question.pageNumber === 'twelve'">
                <p *ngIf="i < 3 && question.panelStateOpen === false" [ngStyle]="setAnswerMargin(i)">
                  {{value}}
                </p>
                <p *ngIf="question.panelStateOpen === true" [ngStyle]="setAnswerMargin(i)">
                  {{value}}
                </p>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <p [ngStyle]="setAnswerMargin(i)">
                  {{value}}
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
        </dd>
        <dd class="summary-list-actions">
          <a class="change-link" href="javascript:void(0)" (click)="onClickChange(question.pageNumber)" id="change-{{question.pageNumber}}">Change</a>
          <ng-container [ngSwitch]="true">
            <ng-container *ngSwitchCase="checkIfAnswersShouldBeAccordion(question.pageNumber, question.value)">
              <img 
                *ngIf="!question.panelStateOpen"
                class="accordion-icon"
                src="./../../.././../assets/images/icon-plus.png" 
                alt="Toggle panel Open Icon" 
                id="panel-open-icon-{{question.pageNumber}}"
                (click)="togglePanelState(question.label)"
                (keyup.enter)="togglePanelState(question.label)"
                tabindex="0"
              >
              <img 
                *ngIf="question.panelStateOpen"
                class="accordion-icon"
                src="./../../.././../assets/images/icon-minus.png" 
                alt="Toggle panel Close Icon" 
                id="panel-close-icon-{{question.pageNumber}}"
                (click)="togglePanelState(question.label)"
                (keyup.enter)="togglePanelState(question.label)"
                tabindex="0"
              >
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class='accordion-empty-placeholder'></div>
            </ng-container>
          </ng-container>
        </dd>
      </div>
      <ng-container *ngIf="question.pageNumber === 'three'">
        <div class="summary-list-row" [ngStyle]="setMarginBottom(question.panelStateOpen)">
          <dt class="summary-list-key" [ngStyle]="setBorderBottom(question.panelStateOpen)" id="question-label-{{question.pageNumber}}">
            {{question.label}}
          </dt>
          <dd class="summary-list-value" [ngStyle]="setBorderBottom(question.panelStateOpen)" id="question-answer-{{question.pageNumber}}">
            <p [ngStyle]="setAnswerMargin(i)">
              {{question.value[0]}}
            </p>
          </dd>
          <dd class="summary-list-actions" [ngStyle]="setBorderBottom(question.panelStateOpen)">
            <a class="change-link" href="javascript:void(0)" (click)="onClickChange(question.pageNumber)" id="change-{{question.pageNumber}}">Change</a>
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="question.value[1] !== 'No'">
                <img 
                  *ngIf="!question.panelStateOpen"
                  class="accordion-icon"
                  src="./../../.././../assets/images/icon-plus.png" 
                  alt="Toggle panel Open Icon" 
                  id="panel-open-icon-{{question.pageNumber}}"
                  (click)="togglePanelState(question.label)"
                  (keyup.enter)="togglePanelState(question.label)"
                  tabindex="0"
                >
                <img 
                  *ngIf="question.panelStateOpen"
                  class="accordion-icon"
                  src="./../../.././../assets/images/icon-minus.png" 
                  alt="Toggle panel Close Icon" 
                  id="panel-close-icon-{{question.pageNumber}}"
                  (click)="togglePanelState(question.label)"
                  (keyup.enter)="togglePanelState(question.label)"
                  tabindex="0"
                >
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class='accordion-empty-placeholder'></div>
              </ng-container>
            </ng-container>
          </dd>
        </div>
        <div class="summary-list-row" *ngIf="question.panelStateOpen === true">
          <dt class="summary-list-key">
            I speak:
          </dt>
          <dd class="summary-list-value">
            <ng-container *ngFor="let value of question.value; let i = index">
              <p *ngIf="i > 0 && value !== 'Yes'" style="margin: 0px;">
                {{value}}
              </p>
            </ng-container>
          </dd>
          <dd class="summary-list-actions"></dd>
        </div>
      </ng-container>
    </ng-container>
  </dl>
  <legend class="agreement-question" #question tabindex="0">Do you agree to this plan?</legend>
  <form [formGroup]="checkAnswersForm" (submit)="onClickCreatePlan()" class="mb30">
    <vc-form-control [control]="checkAnswersForm.get('agreement')" [controlName]="'agreement'" [fromContext]="true">
      <div class="two-radio-container">
        <div class="radio-wrapper mr20">
          <input type="radio" [formControl]="checkAnswersForm.get('agreement')" [value]="true" id="id-agreement" name="agreement" formControlName="agreement">
          <label class="radio-label" for="id-agreement">Yes</label>
        </div>
        <div class="radio-wrapper">
          <input type="radio" [formControl]="checkAnswersForm.get('agreement')" [value]="false" id="id-agreement-no" name="agreement" formControlName="agreement">
          <label class="radio-label" for="id-agreement-no">No</label>
        </div>
      </div>
    </vc-form-control>
    <div class="grid-row alignLeft mt30">
      <button 
        id="id-create-plan" 
        class="button button-link ml0" 
        type="submit" 
        name="create-plan"
      >{{planAgreeFlag === true ? 'Update plan' : 'Create plan'}}</button>
    </div>
  </form>
</div>
