import { Component, OnInit, NgZone, OnDestroy, HostListener, Renderer2 } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserActivityService } from './user-activity/user-activity.service';
import { ConfirmService } from '../shared/components/confirm-box/confirm-box.service';
import { TogetherModeModalService } from './../shared/components/together-mode-modal/together-mode-modal.service';

@Component({
  selector: 'vc-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit, OnDestroy {

  private timerSubscription: Subscription;
  private timer: Observable<number>;
  isDialogOpen = false;
  togetherModeModalVisible = false;

  constructor(
    private readonly userActivityService: UserActivityService,
    private readonly authenticationService: AuthenticationService,
    private readonly confirmService: ConfirmService,
    private readonly togetherModeModalService: TogetherModeModalService,
    private readonly zone: NgZone,
    private readonly renderer: Renderer2
  ) {
    userActivityService.lastActivity = localStorage.getItem('lastActivity') 
      ? +localStorage.getItem('lastActivity') 
      : new Date().getTime();
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  setUserLastActivity() {
    this.userActivityService.lastActivity = new Date().getTime();
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      if (localStorage.getItem('sessionToken')) {
        //this.startTimer();
      }
    });
    // this.togetherModeModalService.showEnterTogetherMode$.subscribe(() => {
    //   this.togetherModeModalVisible = true;
    //   this.renderer.addClass(document.body, 'modal-open');
    // });
    // this.togetherModeModalService.hideEnterTogetherMode$.subscribe(() => {
    //   this.togetherModeModalVisible = false;
    //   this.renderer.removeClass(document.body, 'modal-open');
    // });
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resolveSessionParams() {
    let sessionParams;
    localStorage.getItem('sessionParams') ?
      sessionParams = JSON.parse(atob(localStorage.getItem('sessionParams'))) :
      sessionParams = { 'idleTime': 1200, 'inactiveNotificationTime': 120, 'heartbeat': 60 };
    return sessionParams;
  }

  startTimer() {

    const sessionParams = this.resolveSessionParams();
    this.timer = timer(0, 60 * 1000);

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timerSubscription = this.timer.subscribe(n => {

      localStorage.setItem('lastActivity', '' + this.userActivityService.lastActivity);

      if ((new Date().getTime() - +localStorage.getItem('lastActivity')) / 1000 >
        (sessionParams.idleTime - sessionParams.inactiveNotificationTime) && !this.isDialogOpen) {

        this.isDialogOpen = true;

        this.zone.run(() => {

          this.confirmService.confirm({
            header: 'Your session will expire!',
            message: 'You will be signed out in ',
            showTimer: true,
            countdown: sessionParams.inactiveNotificationTime,
            acceptLabel: 'Continue',
            rejectLabel: 'Sign out',
            accept: () => {
              this.isDialogOpen = false;
              this.userActivityService.lastActivity = new Date().getTime();
              localStorage.setItem('lastActivity', '' + this.userActivityService.lastActivity);
            },
            reject: () => {
              this.isDialogOpen = false;
              localStorage.removeItem('lastActivity');
              this.timerSubscription.unsubscribe();
              this.authenticationService.signout().subscribe();
            }
          });
        });
      }
      this.sendHeartbeat(n,sessionParams.heartbeat)
    });

  }
  sendHeartbeat(time , heartbeat){
        if (time * 60 % heartbeat === 0) {
            this.userActivityService.checkHeartbeat().subscribe(
                response=>{
                    if (response.isRefreshRequred === true) {
                        this.authenticationService.refreshToken().subscribe();
                    }
                }
            );
        }
    }

}
