import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../shared/constants/url-constants';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GuidedCvBuilderService {

  _validationNeeded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get validationNeeded() {
    return this._validationNeeded;
  }

  setConditionalValidation(val: boolean): void {
    this._validationNeeded.next(val);
  }

  constructor(private readonly http: HttpClient) { }

  listSections() {
    const href = `${Url.CVB}/ref-section`;
    return this.http.get<any>(href);
  }
  
}
