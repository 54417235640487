<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<vc-all-errors [groups]="[form]" [fromContext]="true"></vc-all-errors>
<div class="training mt25">
  
    <div *ngIf="model.option=='Y' || revisedData.length !== 0 " class="grid-row">
        <div class="column-full form-group">
            <button id="id-submit" class="button button-secondary" type="button" name="add-tra"
                (click)="addNewTraining()">Add training</button>
        </div>
    </div>

    <div class="grid-row">
        <table *ngIf="revisedData.length !== 0">
            <caption></caption>
            <thead>
                <tr>
                    <th scope="colgroup">Qualification</th>
                    <th scope="colgroup">Training body</th>
                    <th scope="colgroup">Date completed</th>
                    <th scope="colgroup" class="width-10"></th>
                    <th scope="colgroup"></th>
                </tr>
            </thead>
    
            <tbody>
                <tr *ngFor="let s of revisedData">
                    <td><span *ngIf="s?.qualification">{{s.qualification | truncate:[20, '...'] }}</span></td>
                    <td><span *ngIf="s?.body">{{s.body | truncate:[20, '...'] }}</span></td>
                    <td>
                        <span *ngIf="s?.endDate">{{s.endDate | date : 'MMMM yyyy'}}</span>
                        <span *ngIf="!s?.endDate && s?.isCurrent">Currently training</span>
                    </td>
                    <td>
                        <span class="status-tag blue" *ngIf="s?.complete">COMPLETED</span>
                        <span class="status-tag green" *ngIf="!s?.complete">IN PROGRESS</span>
                    </td>
                    <td>
                        <span>
                            <a href="javascript: void(0)" class="delLink link" (click)="goToEditSection(s.id)">Edit</a>
                        </span>
                        <span>
                            <a href="javascript: void(0)" (click)="deleteTraining(s.id)" class="delLink link">Delete</a>
                        </span>
                    </td>
                </tr>
            </tbody>
    
        </table>
    </div>


    <div *ngIf="revisedData.length == 0" class="grid-row">
        <form [formGroup]="form">
            <div *ngIf="model.option=='N' " class="home-yes-no">
                <label class="form-label-b19"> Do you have any formal training? </label>
                <div class="rwrap form-group">
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="form.get('hasNoTraining')" id="id-createAnother-true"
                            name="createAnother" (click)="setOption('Y')" [checked]="model.option=='Y'"
                            [value] = 'false'
                            aria-describedby="id-section-help-createAnother-true id-section-error-createAnother-true" />
                        <label for="id-createAnother-true">Yes</label>
                    </div>
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="form.get('hasNoTraining')"  id="id-createAnother-false"
                            [value]='true'
                            name="createAnother" (click)="setOption('N')" [checked]="model.option=='N'"
                            aria-describedby="id-section-help-createAnother-false id-section-error-createAnother-false" />
                        <label for="id-createAnother-false">No</label>
                    </div>
                </div>
            
            </div>
    </form>
    </div>

    <div class="grid-row mb25">
        <button *ngIf="revisedData.length !== 0" id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
            (click)="save()">Continue</button>
        <button *ngIf="revisedData.length == 0" id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
        (click)="save()">Save and continue</button>
        <button *ngIf="form.get('hasNoTraining') && revisedData.length == 0" id="id-save-and-come-back-later" class="button button-secondary mr15" type="button"
            name="save-and-come-back-later" (click)="draft()">Save and come back
            later</button>
        <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
            (click)="cvSummaryPage()">CV summary</button>
    </div>

</div>
