<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
  <p class="number-of-pages">Page 1 of 13</p>
  <vc-all-errors [groups]="[pageOneForm]" [fromContext]="true"></vc-all-errors>
  <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>
  <form [formGroup]="pageOneForm" (submit)="onClickContinue()">
    <vc-form-control [control]="pageOneForm.get('diagnosed')" [controlName]="'diagnosed'" [fromContext]="true">
      <div class="two-radio-container" *ngIf="currentPageData[0]">
        <div class="radio-wrapper mr20">
          <input type="radio" [formControl]="pageOneForm.get('diagnosed')" [value]="currentPageData[0]?.answersValues[0]" id="id-diagnosed" name="diagnosed" formControlName="diagnosed">
          <label class="radio-label" for="id-diagnosed" >{{currentPageData[0]?.answersLabels[0]}}</label>
        </div>
        <div class="radio-wrapper" (click)="resetLldDetails()">
          <input type="radio" [formControl]="pageOneForm.get('diagnosed')" [value]="currentPageData[0]?.answersValues[1]" id="id-diagnosed-{{currentPageData[0]?.answersLabels[1]}}" name="diagnosed" formControlName="diagnosed">
          <label class="radio-label" for="id-diagnosed-{{currentPageData[0]?.answersLabels[1]}}">{{currentPageData[0]?.answersLabels[1]}}</label>
        </div>
      </div>
    </vc-form-control>
    <ng-container *ngIf="this.pageOneForm.value.diagnosed === '1'">
      <div class="radio-conditional">
        <vc-form-control [control]="pageOneForm.get('lldDetails')" [controlName]="'lldDetails'" [label]="currentPageData[1]?.question" [fromContext]="true">
          <textarea 
            formControlName="lldDetails" 
            rows="6" 
            class="form-control" 
            type="text"
            id="id-lldDetails" 
            name="lldDetails"
          >
          </textarea>
          <div 
            *ngIf="pageOneForm.get('lldDetails').value.length <= 200" 
            class="form-label form-label-count"
          >You have {{ 200 - pageOneForm.get('lldDetails').value.length }} characters remaining
          </div>
          <div 
            *ngIf="pageOneForm.get('lldDetails').value.length > 200" class="error-message"
          >You have {{ pageOneForm.get('lldDetails').value.length - 200 }} characters too many
          </div>
        </vc-form-control>
      </div>
    </ng-container>
    <div class="grid-row alignLeft mt30">
      <button 
        id="id-continue" 
        class="button button-link ml0" 
        type="submit" 
        name="continue"
      >Continue</button>
      <button
        *ngIf="routeState !== 'changeAnswer'"
        id="id-save-as-draft" 
        class="button button-secondary" 
        type="button"
        name="save-as-draft"
        (click)="onClickSave()"
      >Save as draft</button>
    </div>
  </form>
</div>
