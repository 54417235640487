import { Component, OnInit } from '@angular/core';
import { ContentService } from '../content.service';
import { ICategory, ICourse, IContent } from '../content.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../core/services/main.service';
import {Location} from '@angular/common';
import { Utility } from '../../../shared/utils/utility';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vc-content-category',
  templateUrl: './content-category.component.html',
  styleUrls: ['./content-category.component.scss']
})
export class ContentCategoryComponent implements OnInit {

  subcategories: ICategory[] = [];
  reccomendedCourses: ICourse[] = [];
  content: IContent[] = [];
  categoryId: number;
  categoryName: string;
  activeCategoryName: string;
  activeCategoryId: number;
  scrollable = true;
  seeAllButton = false;
  subCategory: any = {};
  mainCategory: any = {};
  defaultContent: number;
  seeTop8 = false;
  isOpen = false;
  addScroll = false;
  scrollalign = false;
  scrollalignCourse = false;
  fixmarright = false;
  

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly mainService: MainService,
    private readonly contentService: ContentService,
    private readonly location: Location,
    private readonly spinner: SpinnerVisibilityService,
    private sanitizer: DomSanitizer
  ) {
    this.categoryId = +this.route.snapshot.paramMap.get('categoryId');
    this.categoryName = this.route.snapshot.paramMap.get('categoryName');
    this.mainService.pageTitle = this.categoryName;
  }

  ngOnInit() {
    this.mainService.showPlanBar();
    this.resolveReccomendedCourses();
    this.resolveSubCategories();
    this.defaultContent = 8;
  }

  resolveSubCategories() {

    this.contentService.getSubCategories(this.categoryId).subscribe(
      async (data: any) => {

        for (const subcat of data) {
          const attachment = await subcat.getAttachment(subcat.id);
          const subcategory = subcat.toJSON();
          if (subcategory.categoryImgUrl) {
            const data = await attachment.getData();
            const blobUrl = URL.createObjectURL(data);
            subcategory.categoryImgUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
          }
          subcategory.categoryColor = this.route.snapshot.paramMap.get('categoryColor');
          await this.contentService.hasContents(subcat.id).then(async rsp => {
            if (rsp)
              this.subcategories.push(subcategory);
          });
        }
        if (this.subcategories.length > 5) {
          this.addScroll = true;
          this.scrollalign = true;
        }
        if (this.subcategories.length > 0) {
          this.subcategories[0].active = true;
          this.activeCategoryName = this.subcategories[0].categoryTitle;
          this.activeCategoryId = this.subcategories[0].id;
          this.resolveCourses(this.subcategories[0].id);
        }
      });
  }

  resolveReccomendedCourses() {
    this.contentService.getReccomendationsByCategory(this.categoryId).subscribe(data => {
      data.forEach(async cat => {
        const attachment = await cat.getAttachment(cat.contentID);
        const category = cat.toJSON();
        if(attachment) {
          const data = await attachment.getData();
          const blobUrl = URL.createObjectURL(data)
          category.imagePath = this.sanitizer.bypassSecurityTrustUrl(blobUrl);  
        }
        this.reccomendedCourses.push(category);
      })
    });
}


  resolveCourses(subCategoryId: number, all?: boolean) {
    this.spinner.show();
    this.contentService.getContents(subCategoryId).subscribe(
     async (data:any) => {
        await data.forEach(async(element:any) => {
          await this.contentService.getContentDetails(element.contentID).subscribe(async rsp => {
            const attachment = await rsp.getAttachment(''+element.contentID);
            if(attachment) {
              const imageData = await attachment.getData();
              const blobUrl = URL.createObjectURL(imageData)
              element.courseImgUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl); 
            }
          });
          // element.imagePath = Utility.replaceString('original' ,'495_330',element.imagePath)
        });
        this.content = data;
        if (this.content.length > 8) {
          this.seeAllButton = true;
          this.scrollalignCourse = true;
        } else {
          this.seeAllButton = false;
          this.seeTop8 = false;
        }
        const index = this.subcategories.findIndex(subCategory => subCategory.id === subCategoryId);
        this.subcategories.forEach(category => category.active = false);
        this.subcategories[index].active = true;
        this.activeCategoryName = this.subcategories[index].categoryTitle;
        this.activeCategoryId = this.subcategories[index].id;
        this.spinner.hide();
      });
  }

  showSubcategoryCourses(subCategory) {
    this.resolveCourses(subCategory.id);
    this.defaultContent = 8;
    this.scrollable = true;
    this.isOpen = false;
    this.seeTop8 = false;
  }

  showAllSubcategoryCourses(activeCategoryId) {
    this.resolveCourses(activeCategoryId, true);
    this.scrollable = !this.scrollable;
    this.defaultContent = this.content.length;
    this.isOpen = true;
    this.seeTop8 = true;
    this.scrollalignCourse = false;
    this.fixmarright = true;
  }

  showTopCourses(activeCategoryId) {
    this.resolveCourses(activeCategoryId, true);
    this.scrollable = true;
    this.defaultContent = 8;
    this.isOpen = false;
    this.seeTop8 = false;
    this.fixmarright = false;
  }

  showCourseDetails(course) {
    const courseTitle =encodeURIComponent(course.title);
    this.router.navigate([`/content/course/${courseTitle}/${course.contentID}`]);
  }
   
  backClicked() {
    this.location.back();
  }
}
