<pagination-template class="paginationBlock" #p="paginationApi" [id]="id" [maxSize]="maxSize" (pageChange)="pageChange.emit($event)">
  <ul class="ngx-pagination" role="navigation" [attr.aria-label]="screenReaderPaginationLabel"
    [class.responsive]="responsive" *ngIf="!(autoHide && p.pages.length <= 1)">

    <li class="pagination-previous" [class.hidden]="p.isFirstPage()" *ngIf="directionLinks">
      <a class="link" href="javascript:void(0)" tabindex="0" *ngIf="1 < p.getCurrent()" (keyup.enter)="p.previous()" (click)="p.previous()"
        [attr.aria-label]="previousLabel + ' ' + screenReaderPageLabel">
        {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
      </a>
      <span *ngIf="p.isFirstPage()">
        {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }</span>
      </span>
    </li>

    <li class="small-screen">
      {{ p.getCurrent() }} / {{ p.getLastPage() }}
    </li>

    <li [class.current]="p.getCurrent() === page.value" [class.ellipsis]="page.label === '...'"
      *ngFor="let page of p.pages">
      <a class="link" href="javascript:void(0)" tabindex="0" (keyup.enter)="p.setCurrent(page.value)" (click)="p.setCurrent(page.value)"
        *ngIf="p.getCurrent() !== page.value">
        <span class="show-for-sr">{{ screenReaderPageLabel }} </span>
        <span>{{ (page.label === '...') ? page.label : (page.label | number:'') }}</span>
      </a>
      <ng-container *ngIf="p.getCurrent() === page.value">
        <span class="show-for-sr">{{ screenReaderCurrentLabel }} </span>
        <span>{{ (page.label === '...') ? page.label : (page.label | number:'') }}</span>
      </ng-container>
    </li>

    <li class="pagination-next" [class.hidden]="p.isLastPage()" *ngIf="directionLinks">
      <a class="link" href="javascript:void(0)" tabindex="0" *ngIf="!p.isLastPage()" (keyup.enter)="p.next()" (click)="p.next()"
        [attr.aria-label]="nextLabel + ' ' + screenReaderPageLabel">
        {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
      </a>
      <span *ngIf="p.isLastPage()">
        {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
      </span>
    </li>

  </ul>
</pagination-template>