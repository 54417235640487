<div *ngIf="notification" class="{{notification?.type}}-summary" id="{{notification?.type}}-summary"
  attr.aria-labelledby="{{notification?.type}}-summary-heading" role="alert" tabindex="-1">
  <div class="{{notification?.type}}-summary-body">
    <h2 *ngIf="notification?.type==='error'" class="error-summary__title" id="error-summary-title">
      There is a problem
    </h2>
    <h2 *ngIf="notification?.type==='error'" class="{{notification?.type}}-summary-heading"
      id="{{notification?.type}}-summary-heading">
    </h2>

    <ul class="{{notification?.type}}-summary-list">
      <li *ngFor="let message of notification?.message">
        {{ message }}
      </li>
    </ul>
  </div>
</div>