import { Component, OnInit, NgZone } from '@angular/core';
import { EmploymentService } from '../employment.service';
import { ConfirmService } from '../../../shared/components/confirm-box/confirm-box.service';

@Component({
  selector: 'vc-prison-tab',
  templateUrl: './prison-tab.component.html',
  styleUrls: ['./prison-tab.component.scss']
})
export class PrisonTabComponent implements OnInit {

  
  page: number = 0;
  size: number = 10;
  sort: any = 'dateExpressed,desc';
  prisonJobList: any[];
  totalItems: number = 0;
  first: Boolean = false;
  last: Boolean = false;
  isDialogOpen: Boolean = false;

  constructor(
    private readonly employmentService: EmploymentService,
    private readonly zone: NgZone,
    private readonly confirmService:  ConfirmService) { }

  ngOnInit() {
    this.fetchPrisonJobList(this.page, this.size, this.sort);
  }

  pageChange(page: number) {
    this.page = page - 1;
    this.fetchPrisonJobList((page - 1), this.size, this.sort);
    window.scrollTo(0, 0);
  }

  fetchPrisonJobList(page, size, sort) {
    this.employmentService.getInterestedPrisonJobList(page, size, sort).subscribe(resp => {
      this.prisonJobList = resp.content;
      this.totalItems = resp.totalElements;
      this.first = resp.first;
      this.last = resp.last;
    });
  }

}
