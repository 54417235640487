<div id="guided-cv">
    <div class="grid-row scrollable">
        <article class="cv-wrapper column-half recommended" tabindex="0" [routerLink]="['/cv-builder/guided']" >
            <figure class="cv-builder">
                <img alt="Guided CV"
                    src="../../../assets/images/cv-builder/guided-cv2.jpg">
                <figcaption class="light-green">
                    <div class="caption-div">
                        <div class="light-green">
                            <span>Use the guided CV builder to create a step-by-step CV</span>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </article>
    </div>
</div>