<div [formGroup]="formGroup">
    <div [ngClass]="{'form-group-error': !formGroup.valid}">
        <label for="questions" class="form-label-b19"
        [ngClass]="{'error-message': !formGroup.valid}">{{formGroup.get('question').value}}</label>

        <div *ngIf="hint" id="id-section-help" class="form-hint">{{hint}}</div>

        <div formArrayName="answers" class="selectOptions">
            <button type="button" [ngClass]="{'decorated': getValue(i,'checked')}"
                *ngFor="let item of formGroup.get('answers')['controls']; let i = index" class="column-fifth"
                [formGroupName]="i" (click)="update(i, 'checked')">
                {{getValue(i, 'text' ) | truncate:[42, '...']}}
            </button>
        </div>    
    </div>
</div>