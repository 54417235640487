import { Component, OnInit, Input } from '@angular/core';
import { IDocViewerConfig } from './doc-viewer.config';

@Component({
  selector: 'vc-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit {

  //@Input() src: Blob;
  @Input()
  get src(): Blob { return this._src; }
  set src(src: Blob) {
    this._src = src;
  }
  private _src = new Blob([]);
  @Input() height: string;
  @Input() config: IDocViewerConfig;

  showPrintButton: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }
  
  onPagesLoad(event: any) {
    if(event) {
      this.showPrintButton = this.config.showPrintButton;
    }
  }

}
