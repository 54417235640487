import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from './../../../shared/constants/url-constants';
import { Observable } from 'rxjs';

@Injectable()
export class InductionQuestionnaireService {

  constructor(private readonly http: HttpClient) { }

  getQuestion(sectionIdentifier) {
    const href = `${Url.PLAN}/induction-Plan/1/${sectionIdentifier}`;
    return this.http.get<any>(href)
  }

  getAnsweredQuestion() {
    const href = `${Url.PLAN}/induction-Plan/1`;
    return this.http.get<any>(href)
  }

  getRefData() {
    const href = `${Url.PLAN}/plan/refData`;
    return this.http.get<any>(href)
  }

  createInductionPlan(data) {
    const href = `${Url.PLAN}/induction-Plan`;
    return this.http.post<any>(href, data)
  }

  saveAndContinue(data) {
    const href = `${Url.PLAN}/education`;
    return this.http.post<any>(href, data);
  }

  /* start api for plan previous employment*/

  getPlanEmploymentList() {
    const href = `${Url.PLAN}/employment/all/1`;
    return this.http.get<any>(href)
  }

  getEmploymentById(id){
    const href = `${Url.PLAN}/employment/${id}`;
    return this.http.get<any>(href);
  }

  saveAndContinueEmp(data) {
    const href = `${Url.PLAN}/employment`;
    return this.http.post<any>(href, data);
  }

  deleteEmployment(id){
    const href = `${Url.PLAN}/employment/${id}`;
    return this.http.delete<any>(href);
  }

  createPlanEmployment(data){
    const href = `${Url.PLAN}/employment`;
    return this.http.post<any>(href,data);
  }

  editPlanEmployment(data){
    const href = `${Url.PLAN}/employment`;
    return this.http.put<any>(href,data);
  }

  /* end api for plan previous employment*/
  
  resolveQuestionType(identifier, refData) {
    const filteredQuestionTypeArr = refData.questionTypeList.filter(type => type.identifier === identifier.toString());
    return filteredQuestionTypeArr[0].planTypeDescription;
  }

  resolveQuestionAnswersLabels(identifier, refData) {
    const filteredDomainListArr = refData.domainList.filter(domain => domain.identifier === identifier.toString());
    const choiceList = filteredDomainListArr[0].choiceList;
    const answersLabelsArr = [];
    choiceList.forEach(choice => answersLabelsArr.push(choice.choiceDescription));
    return answersLabelsArr;
  }

  resolveQuestionAnswersValues(identifier, refData) {
    const filteredDomainListArr = refData.domainList.filter(domain => domain.identifier === identifier.toString());
    const choiceList = filteredDomainListArr[0].choiceList;
    const answersValuesArr = [];
    choiceList.forEach(choice => answersValuesArr.push(choice.choiceId.toString()));
    return answersValuesArr;
  }

  

}
