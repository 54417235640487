<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
    <p class="number-of-pages">Page 11 of 13</p>
    <legend class="question" #question tabindex="0">What are your personal attributes?</legend>
    <vc-all-errors [groups]="multiselectform.get('questions')['controls']" [fromContext]="true"
        [topLabel]="'Please answer all mandatory questions / select 3 options'"></vc-all-errors>

    <form [formGroup]="multiselectform" autocomplete="off">
        <ng-container *ngFor="let item of multiselectform.get('questions')['controls']; let i = index"
            formArrayName="questions">
            <div [formGroupName]="i">
                <vc-multi-select-options [formGroup]="item" id="id-answers-{{i}}" [hint]="'Please select 3 options'"
                    [restrictMax]="true" [max]="3">
                </vc-multi-select-options>
            </div>
        </ng-container>
    </form>
    <div class="grid-row alignLeft">
        <button id="id-continue" class="button button-link ml0" type="submit" name="continue" (click)="save(multiselectform)">Continue</button>
        <button *ngIf="routeState !== 'changeAnswer'" id="id-save-as-draft" class="button button-secondary" type="button" name="save-as-draft"
        (click)="draft(multiselectform)">Save as draft</button>
      </div>
</div>