<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
  <p class="number-of-pages">Page 6 of 13</p>
  <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>
  <form [formGroup]="pageSixForm" autocomplete="off" (submit)="onClickContinue()">
    <vc-form-control [control]="pageSixForm.get('workExperience')" [controlName]="'workExperience'" [fromContext]="true">
      <div class="two-radio-container" *ngIf="currentPageData[0]">
        <div class="radio-wrapper mr20">
          <input type="radio" [formControl]="pageSixForm.get('workExperience')"
            [value]="currentPageData[0]?.answersValues[0]" id="id-workExperience" name="workExperience"
            formControlName="workExperience" (change)="onSelectionChange(currentPageData[0]?.answersValues[0])">
          <label class="radio-label" for="id-workExperience">{{currentPageData[0]?.answersLabels[0]}}</label>
        </div>
        <div class="radio-wrapper">
          <input type="radio" [formControl]="pageSixForm.get('workExperience')"
            [value]="currentPageData[0]?.answersValues[1]" id="id-workExperience-{{currentPageData[0]?.answersLabels[1]}}"
            name="workExperience" formControlName="workExperience" (change)="onSelectionChange(currentPageData[0]?.answersValues[1])">
          <label class="radio-label"
            for="id-workExperience-{{currentPageData[0]?.answersLabels[1]}}">{{currentPageData[0]?.answersLabels[1]}}</label>
        </div>
      </div>
    </vc-form-control>
    <ng-container *ngIf="this.pageSixForm.value.workExperience === '1'">
      <div *ngIf="employmentList.length !=='0'">
        <vc-plan-employment [employmentDetails]="employmentList" (refreshList)="refrestList()"></vc-plan-employment>
      </div>
      <div class="radio-conditional">
        <vc-form-control [control]="pageSixForm.get('lldDetails')" [controlName]="'lldDetails'"
          [label]="currentPageData[1]?.question" [fromContext]="true">

          <div class="education mt25">
            <div class="grid-row">
              <legend class="question" tabindex="0" *ngIf="!isEditEmploymentClicked">Add work experience</legend>
              <legend class="question" tabindex="0" *ngIf="isEditEmploymentClicked">Edit work experience</legend>
              <vc-all-errors [groups]="[employmentForm, employmentForm.get('skillDescription')]" [fromContext]="true"></vc-all-errors>
              <form [formGroup]="employmentForm" autocomplete="off">

                <div class="column-full">
                  <vc-form-control [control]="employmentForm.get('employerName')" [controlName]="'employerName'"
                    [label]="'Organisation / Employer'" [fromContext]="true">
                    <input formControlName="employerName" class="form-control" type="text" id="id-employerName"
                      name="employerName" aria-describedby="id-section-help-employerName id-section-error-employerName">
                  </vc-form-control>
                </div>

                <div class="column-full">
                  <vc-form-control [control]="employmentForm.get('jobTitle')" [controlName]="'jobTitle'" [label]="'Job title'"
                    [fromContext]="true">
                    <input formControlName="jobTitle" class="form-control" type="text" id="id-jobTitle" name="jobTitle"
                      aria-describedby="id-section-help-jobTitle id-section-error-jobTitle">
                  </vc-form-control>
                  
                </div>

                <div class="column-full">
                  <vc-form-control [control]="employmentForm.get('isJobFulltime')" [controlName]="'isJobFulltime'"
                    [fromContext]="true">
                    <div class="checkbox-wrapper">
                      <input id="id-isJobFulltime-check" name="isJobFulltime" type="checkbox"
                        formControlName="isJobFulltime">
                      <label class="form-label-b19" for="id-isJobFulltime-check">
                        Is the job full time?
                      </label>
                    </div>
                  </vc-form-control>
                </div>

                <div class="column-full">
                  <vc-month-form-control [control]="employmentForm.get('jobStartDate')" [controlName]="'jobStartDate'"
                    id="id-jobStartDate" [label]="'Start date'" [fromContext]="true">
                  </vc-month-form-control>
                </div>

                <div class="column-full">
                  <vc-month-form-control [control]="employmentForm.get('jobEndDate')" [controlName]="'jobEndDate'"
                    id="id-jobEndDate" [label]="'End date'" [fromContext]="true">
                  </vc-month-form-control>
                </div>

                <div class="column-full">
                  <vc-form-control [control]="employmentForm.get('isJobCurrent')" [controlName]="'isJobCurrent'"
                    [fromContext]="true">
                    <div class="checkbox-wrapper">
                      <input id="id-isJobCurrent-check" name="isJobCurrent" type="checkbox"
                        formControlName="isJobCurrent">
                      <label class="form-label-b19" for="id-isJobCurrent-check">
                        Current employment
                      </label>
                    </div>
                  </vc-form-control>
                </div>

                <div class="column-full">
                  <vc-multi-select-options [formGroup]="employmentForm.get('skillDescription')" id="id-answers"
                    [hint]="'Please select 3 options'" [restrictMax]="true" [max]="3"></vc-multi-select-options>
                </div>
          
                <div class="column-full" *ngIf="employmentForm.get('otherSkillDescription')" >
                  <vc-form-control [control]="employmentForm.get('otherSkillDescription')" [controlName]="'otherSkillDescription'" [label]="'Please specify other skills'" [fromContext]="true">
                    <textarea formControlName="otherSkillDescription" rows="5" class="form-control" type="text" id="id-otherSkillDescription" name="otherSkillDescription"
                      aria-describedby="id-section-help-otherSkillDescription id-section-error-otherSkillDescription" [ngClass]="{'errorfocus': employmentForm.get('otherSkillDescription')?.errors }" >
                    </textarea>
          
                    <div *ngIf = "employmentForm.get('otherSkillDescription').value?.length <= 200" class="form-label form-label-count">You have {{200 - employmentForm.get('otherSkillDescription').value?.length}} characters remaining</div>
                    <div *ngIf = "employmentForm.get('otherSkillDescription').value?.length === 201" class="error-message">You have {{ employmentForm.get('otherSkillDescription').value?.length - 200}} character too many</div>
                    <div *ngIf = "employmentForm.get('otherSkillDescription').value?.length > 201" class="error-message">You have {{ employmentForm.get('otherSkillDescription').value?.length - 200}} characters too many</div>
                  </vc-form-control>
                </div>

              </form>
              <div class="column-full">
                <div class="grid-row">
                  <div class="column-full">
                    <button id="id-submit" class="button button-secondary" type="button" name="add-edu"
                      (click)="addNewEmployment()" *ngIf="!isEditEmploymentClicked">Add </button>
                    <button id="id-submit" class="button button-secondary" type="button" name="add-edu"
                      (click)="editEmployment()" *ngIf="isEditEmploymentClicked">Save </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vc-form-control>
      </div>
    </ng-container>
    <div class="grid-row alignLeft">
      <button id="id-continue" class="button button-link ml0" type="submit" name="continue">Continue</button>
      <button *ngIf="routeState !== 'changeAnswer'" id="id-save-as-draft" class="button button-secondary" type="button" name="save-as-draft"
        (click)="onClickSave()">Save as draft</button>
    </div>
  </form>
</div>