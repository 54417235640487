import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const fStructureSchema: RxCollectionCreatorBase = {
    schema: {
        title: 'FStructure',
        version: 0,
        description: 'Track details',
        type: 'object',
        properties: {
            activityId: {
                type: ['number', 'null']
            },
            description: {
                type: ['string', 'null']
            },
            id: {
                type: ['number', 'null']
            },
            interventionName: {
                type: ['string', 'null']
            },
            interventionType: {
                type: ['string', 'null']
            },
            title: {
                type: ['string', 'null']
            },
            outcome: {
                type: ['string', 'null']
            },
            pathway: {
                type: ['string', 'null']
            },
            status: {
                type: ['string', 'null']
            },
            subTitle: {
                type: ['string', 'null']
            },
            order: {
                type: ['number', 'null']
            }
        }
    }
}