import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WalletRoutingModule } from './wallet-routing.module';
import { WalletComponent } from './wallet.component';
import { MyContentComponent } from '../content/my-content/my-content.component';
import { PipesModule } from '../../core/pipes/pipes.module';
import { JobActivityComponent } from '../employment/job-activity/job-activity.component';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { FavouriteJobsComponent } from '../employment/favourite-jobs/favourite-jobs.component';
import { JobAppliedComponent } from '../employment/job-applied/job-applied.component';
import { WalletMyCvsComponent } from './wallet-my-cvs/wallet-my-cvs.component';
import { FormControlModule } from '../../shared/components/form-control/form-control.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyCvsService } from '../cv-builder/guided-cv-builder/my-cvs/my-cvs.service';
import { MyCVsModule } from '../cv-builder/guided-cv-builder/my-cvs/my-cvs.module';
import { PrisonTabComponent } from '../employment/prison-tab/prison-tab.component';
import { PrisonLeaversJobsComponent } from '../employment/prison-leavers-jobs/prison-leavers-jobs.component';

@NgModule({
  declarations: [
    WalletComponent,
    MyContentComponent,
    JobActivityComponent,
    FavouriteJobsComponent,
    JobAppliedComponent,
    PrisonTabComponent,
    PrisonLeaversJobsComponent,
    WalletMyCvsComponent],
  imports: [
    CommonModule,
    WalletRoutingModule,
    PipesModule,
    PaginationModule,
    FormControlModule,
    FormsModule,
    ReactiveFormsModule,
    MyCVsModule,
  ],
  providers: [MyCvsService],
})
export class WalletModule { }
