import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MainService } from '../../../../core/services/main.service';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { INDUCTION_QUESTIONS_VALIDATION_CONTEXT } from '../induction-questions-list.validation';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from "../../../../shared/components/confirm-box/confirm-box.service";

const PAGETWELVEFORM_VALIDATORS_SAVE = {
  learningStyle: [Validators.required]
}

@Component({
  selector: 'vc-page-twelve',
  templateUrl: './page-twelve.component.html',
  styleUrls: ['./page-twelve.component.scss']
})
export class PageTwelveComponent implements OnInit, AfterViewChecked {

  currentPageNumber: number;
  currentPageData = [] as any;
  refData = [] as any;
  routeState: any;

  pageTwelveForm: FormGroup;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-eleven';
  routeNext = '/plan/induction/page-thirteen';
  isAssessmentInProgressFlag = false;

  @ViewChild("question") questionDiv: ElementRef;

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly mainService: MainService,
    private readonly formBuilder: FormBuilder,
    private readonly validation: ValidationService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly togetherMode: TogetherModeModalService,
    private readonly confirm: ConfirmService,
  ) {
    this.mainService.pageTitle = 'Induction';
    this.initForm();
    this.validation.setContext(INDUCTION_QUESTIONS_VALIDATION_CONTEXT);
  }

  ngOnInit() {
    this.getRefData();
    this.resolveRouteState();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageTwelveForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  initForm() {
    this.pageTwelveForm = this.formBuilder.group({
      learningStyle: this.formBuilder.control('')
    })
  }

  onClickContinue() {
    this.setValidators(this.pageTwelveForm, PAGETWELVEFORM_VALIDATORS_SAVE);
    if (this.pageTwelveForm.valid) {
      const formattedPayload = this.formatPayload(false);
      this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
        this.isSaveSubmitOperation = true;
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-thirteen');
        window.scrollTo(0, 0);
      })
    } else {
      this.setFocusToErrorSummary();
    }
  }

  onClickSave() {
    const formattedPayload = this.formatPayload(true);
    const navigationUrl = '/plan';
    this.invokeCreateInductionPlanThenNavigate(formattedPayload, navigationUrl);
  }

  invokeCreateInductionPlanThenNavigate(formattedPayload, navigationUrl) {
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      this.isSaveSubmitOperation = true;
      this.router.navigateByUrl(navigationUrl);
      window.scrollTo(0, 0);
    })
  }

  canExit(route, state, nextState) {
    if (this.pageTwelveForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }


  formatPayload(saveAsDraft) {
    return {
      "isSaveAsDraft": saveAsDraft,
      "planTypeIdentifier": "1",
      "questionDTOList": [
        {
          "answer": this.pageTwelveForm.controls.learningStyle.value,
          "id": 19
        }
      ],
      "sectionIdentifier": this.currentPageData[0].sectionIdentifier
    }
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          }
        )
      });
      this.pageTwelveForm.controls.learningStyle.setValue(data[0].answer ? data[0].answer : '');
      this.setFocusToQuestionDiv();
    })
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.currentPageNumber = 11;
    this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  setFocusToQuestionDiv() {
    this.questionDiv.nativeElement.focus();
  }

}
