import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, finalize, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Url } from '../shared/constants/url-constants';
import { Base64 } from 'js-base64';

const authHeaders = new HttpHeaders({
  'Content-Type': 'application/json'
});

const exposeXAuthHeader = new HttpHeaders({
  'Access-Control-Expose-Headers': 'X-Authorization',
});

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  appId: string = "SU-APP";
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly zone: NgZone
  ) { }
  
  get isLoggedIn() {
    return localStorage.getItem('sessionToken') ? true : false;
  }
  
  signIn(formData: any): any {
    
    const body = JSON.stringify(formData);
    return this.http.post<any>(`${Url.AUTHENTICATION}/service-user/login`, body, { headers: authHeaders, observe: 'response' })
    .pipe(delay(1000), 
      tap(resp => {
        this.setAuth(resp);
      }));
    }
    
    signout() {
      return this.http.get(`${Url.AUTHENTICATION}/service-user/logout`)
      .pipe(finalize(() => this.purgeAuth()));
    }
    
    refreshToken() {
    return this.http.get<any>(`${Url.AUTHENTICATION}/service-user/refresh`, { headers: exposeXAuthHeader, observe: 'response' })
    .pipe(
      tap(resp => {
          this.setAuth(resp);
        }));
      }
      
  resolveTokenRefreshInterval() {
    if (localStorage.getItem('sessionToken')) {
      const tokenExpiry = (+JSON.parse(Base64.decode(localStorage.getItem('sessionToken').split('.')[1])).exp) * 1000;
      return Math.round((new Date(new Date(tokenExpiry).setMilliseconds(0)).getTime() -
      new Date((new Date().setMilliseconds(0))).getTime() - 3 * 60 * 1000) / (60 * 1000));
    }
    return null;
  }
  
  private purgeAuth() {
    const deviceId = localStorage.getItem('deviceId');
    localStorage.clear();
    localStorage.setItem('deviceId', deviceId);
    this.zone.run(() => this.router.navigate(['/sign-in']));
  }
  
  private setAuth(resp) {
    const xAuthToken = resp.headers.get('X-Authorization').split(' ');
    const token = xAuthToken[1];
    localStorage.setItem('sessionToken', token);
    if (resp.body.heartbeat) {
      localStorage.setItem('sessionParams', Base64.encode(JSON.stringify(resp.body)));
    }
  }

  noticeBoardMessage() {
    const href = `${Url.AUTHENTICATION}/notice-board/` +this.appId;
    return this.http.get<any>(href);
  }

  currentUser() {
    if (localStorage.getItem('sessionToken')) {
      const payload = atob(localStorage.getItem('sessionToken').split('.')[1]);
      return JSON.parse(payload);
    }
    return null;
  }

  getToken() {
    return localStorage.getItem('sessionToken');
  }
}
