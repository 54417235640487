<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>

<div class="generate-cv mt25">
    <vc-all-errors [groups]="[ fileDetailsForm ]" [fromContext]="true"></vc-all-errors>

    <div class="my-cv">

        <div class="grid-row">
            <table *ngIf="cvs?.length > 0">
                <caption></caption>
                <thead>
                    <tr>
                        <th scope="colgroup">File</th>
                        <th scope="colgroup">Uploaded / Generated date</th>
                        <th scope="colgroup">Description</th>
                        <th scope="colgroup"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let cv of cvs">
                        <td><span *ngIf="cv?.cvName"><a href="javascript: void(0)"
                                    (click)="download(cv.id)">{{cv.cvName}}</a></span></td>
                        <td><span *ngIf="cv?.createdDate">{{cv.createdDate | date : 'dd MMMM yyyy'}}</span></td>
                        <td><div class="description" *ngIf="cv?.cvDescription">{{cv.cvDescription}}</div></td>
                        <td>
                            <a href="javascript: void(0)" (click)="delete(cv.id, cv.cvName)"
                                class="delLink link">Delete</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>










    <div class="grid-row">
        <form [formGroup]="fileDetailsForm" autocomplete="off">
            <vc-form-control [control]="fileDetailsForm.get('fileName')" [controlName]="'fileName'"
                [label]="'File name'" [fromContext]="true">
                <input formControlName="fileName" class="form-control" type="text" id="id-fileName" name="fileName"
                    value="" [ngClass]="{'errorfocus': fileDetailsForm.get('fileName')?.errors }"
                    aria-describedby="id-section-help-fileName id-section-error-fileName">
                <div *ngIf="fileDetailsForm.get('fileName').value.length <= 30
                    && 30 - fileDetailsForm.get('fileName').value.length != 1" class="form-label form-label-count">
                    You have {{30 - fileDetailsForm.get('fileName').value.length}} characters remaining</div>
                <div *ngIf="30 - fileDetailsForm.get('fileName').value.length == 1 "
                    class="form-label form-label-count">You have
                    {{30 - fileDetailsForm.get('fileName').value.length}} character remaining</div>
                <div *ngIf="fileDetailsForm.get('fileName').value.length === 31" class="error-message">You have
                    {{ fileDetailsForm.get('fileName').value.length - 30}} character too many</div>
                <div *ngIf="fileDetailsForm.get('fileName').value.length > 31" class="error-message">You have
                    {{ fileDetailsForm.get('fileName').value.length - 30}} characters too many</div>
            </vc-form-control>

            <vc-form-control [control]="fileDetailsForm.get('description')" [controlName]="'description'"
                [label]="'Description'" [fromContext]="true" [optional]="'(optional)'">
                <textarea formControlName="description" class="form-control" id="id-description" name="description"
                    rows="5" aria-describedby="id-section-help-description id-section-error-description"></textarea>
                <div *ngIf="fileDetailsForm.get('description').value.length <= 100 
                        && 100 - fileDetailsForm.get('description').value.length != 1"
                    class="form-label form-label-count">
                    You have {{100 - fileDetailsForm.get('description').value.length}} characters remaining</div>
                <div *ngIf="100 - fileDetailsForm.get('description').value.length == 1 "
                    class="form-label form-label-count">You have
                    {{100 - fileDetailsForm.get('description').value.length}} character remaining</div>
                <div *ngIf="fileDetailsForm.get('description').value.length === 101" class="error-message">You have
                    {{ fileDetailsForm.get('description').value.length - 100}} character too many</div>
                <div *ngIf="fileDetailsForm.get('description').value.length > 101" class="error-message">You have
                    {{ fileDetailsForm.get('description').value.length - 100}} characters too many</div>
            </vc-form-control>
        </form>
    </div>
    <div class="grid-row mb25">
        <button id="id-save-btn" type="button" class="button button-link" (click)="save()">Save</button>
        <button id="id-cancel-btn" type="button" class="button button-secondary" (click)="cancel()">Cancel</button>
    </div>
</div>