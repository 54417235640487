import { Component, OnInit } from '@angular/core';

import { BannerService } from './../../services/banner.service';
import { IBanner } from './banner.interface';

@Component({
  selector: 'vc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  banner: IBanner;

  constructor(private readonly bannerService: BannerService) { }

  ngOnInit() {
    this.bannerService.currentBanner.subscribe(
      banner => {
        this.banner = banner;
        if (banner) {
          window.scrollTo(0, 0);
        }
      }
    )
  }

  onClose() {
    this.bannerService.close();
  }
}
