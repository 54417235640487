import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SignInRoutingModule } from './sign-in-routing.module';
import { SignInComponent } from './sign-in.component';
import { AuthenticationService } from '../authentication.service';
import { CoreModule } from '../../core/core.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { FormControlModule } from '../../shared/components/form-control/form-control.module';
import { PipesModule } from '../../core/pipes/pipes.module';
import { ConfirmBoxModule } from 'src/app/shared/components/confirm-box/confirm-box.module';

@NgModule({
  declarations: [SignInComponent],
  imports: [
    CommonModule,
    CoreModule,
    SignInRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    FormControlModule,
    PipesModule,
    NgHttpLoaderModule.forRoot(),
    ConfirmBoxModule
  ],
  providers: [AuthenticationService]
})
export class SignInModule { }
