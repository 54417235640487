<a (click)="back()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="job-application" id="job-application">
    <div class="grid-row">
        <vc-all-errors [groups]="[application]" [fromContext]="true"></vc-all-errors>
        <form [formGroup]="application" autocomplete="off">
            <div class="column-full">
                <vc-form-control [control]="application.get('attachments')" [controlName]="'attachments'" [label]="''"
                    [fromContext]="true">
                    <button id="id-attachments" class="button button-secondary" type="button" name="attachment"
                        (click)="goToAttachment()">Attach CVs / Documents</button>
                    <div class="attachment-list chips-list">
                        <div *ngFor="let attachment of application.get('attachments').value; let i = index"
                            class="roundedcorner" class="chips">
                            {{attachment.name}} <span (click)="dropAttachment(i)" class="close_icon">X</span>
                        </div>
                    </div>
                </vc-form-control>
            </div>
        </form>
    </div>

    <div class="column-two-thirds">
        <h3 class="heading-large">{{job?.jobTitle}}</h3>
        <div class="grid-row ">
            <table class="table-font-small">
                <tbody>
                    <tr *ngIf="job?.postingDate">
                        <th>Posting date:</th>
                        <td>{{job?.postingDate | date : 'dd MMMM yyyy'}}</td>
                    </tr>
                    <tr *ngIf="job?.jobTitle">
                        <th>Job title:</th>
                        <td>{{job?.jobTitle}}</td>
                    </tr>
                    <tr *ngIf="job?.company">
                        <th>Company name:</th>
                        <td>{{job?.company}}</td>
                    </tr>
                    <tr *ngIf="job?.location">
                        <th>Location:</th>
                        <td>{{job?.location}}</td>
                    </tr>
                    <tr *ngIf="job?.pay">
                        <th>Pay:</th>
                        <td>{{job?.pay}}</td>
                    </tr>
                    <tr>
                        <th>ROTL:</th>
                        <td>{{rotl}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="clear grid-row mt25 mb25">
        <button id="id-send" *ngIf="!expressedInterest?.status" class="button button-link" type="button" name="send"
        (click)="sendApplication()">Send</button>
        <button type="button" *ngIf="expressedInterest?.status" [disabled]="true" class="button button-link">Send</button>
        <button id="id-cancel" class="button button-secondary" type="button" name="cancel" 
            (click)="cancel()">Cancel</button>
    </div>

</div>