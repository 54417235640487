<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>

<div class="tabWrapper">
	<ul>
		<li class="tabHead active" tabindex="0" [ngClass]="{'active': state === 'favourite'}" (click)="setActive('favourite')">
			<a href="javascript:void(0)" class="link" id="favourite-tab" attr.aria-label="tab favourite job">Favourite jobs</a>
		</li>
		<li class="tabHead" tabindex="0" [ngClass]="{'active': state === 'applied'}" (click)="setActive('applied')">
			<a href="javascript:void(0)" class="link" id="applied-tab" attr.aria-label="tab applied job">Job applications</a>
		</li>
		<li class="tabHead active" tabindex="0" [ngClass]="{'active': state === 'prison'}" (click)="setActive('prison')">
			<a href="javascript:void(0)" class="link" id="prison-tab" attr.aria-label="tab prison job">Prison jobs</a>
		</li>
		<li class="tabHead" tabindex="0" [ngClass]="{'active': state === 'nfn'}" (click)="setActive('nfn')">
			<a href="javascript:void(0)" class="link" id="nfn-tab" attr.aria-label="tab nfn job">Jobs for prison leavers</a>
		</li>
	</ul>
	<section class="tabs-panel" *ngIf="state === 'favourite'">
		<vc-favourite-jobs></vc-favourite-jobs>
	</section>
	<section class="tabs-panel" *ngIf="state === 'applied'">
		<vc-job-applied></vc-job-applied>
	</section>
	<section class="tabs-panel" *ngIf="state === 'prison'">
		<vc-prison-tab></vc-prison-tab>
	</section>
	<section class="tabs-panel" *ngIf="state === 'nfn'">
		<vc-prison-leavers-jobs></vc-prison-leavers-jobs>
	</section>
</div>