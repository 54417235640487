import { Component, OnInit } from '@angular/core';
import { MainService } from './../../../core/services/main.service';


@Component({
  selector: 'vc-employment-dashboard',
  templateUrl: './employment-dashboard.component.html',
  styleUrls: ['./employment-dashboard.component.scss']
})
export class EmploymentDashboardComponent implements OnInit {

  constructor(    private readonly main: MainService,
    ) { 
    this.main.pageTitle = 'Employment';

  }

  ngOnInit() {
  }

  scrollTop(){
    window.scrollTo(0, 0);
  }
}
