import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';

import { MainService } from '../../../../core/services/main.service';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { ICanDeactivate } from '../../../../core/guards/deactivate.interface';
import { AdditionalInfoService } from './additional-info.service';
import { ADDINFO_VALIDATION_CONTEXT } from './additional-info.validation';
import { GuidedCvBuilderService } from '../guided-cv-builder.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

const ADDINFO_VALIDATORS_SAVE = {
  isLicenced: [Validators.required],
  languages: [ValidationService.minInserted(1)],
  otherHobbies: [Validators.required, Validators.maxLength(200), Validators.pattern('[a-zA-Z0-9\\-\\s\&\(\)\/\.\'\,\"\:\;\!\?]*')]
};

const ADDINFO_VALIDATORS_DRAFT = {
  otherHobbies: [Validators.maxLength(200)]
};

const HOBBIES_VALIDATORS_SAVE = {
  answers: [ValidationService.minSelected(1)]
};

@Component({
  selector: 'vc-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent implements OnInit, ICanDeactivate, OnDestroy {
  
  additionalInfoForm: FormGroup;

  languages: string[] = [];

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly main: MainService,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly confirm: ConfirmService,
    private readonly validationService: ValidationService,
    private readonly addInfoService: AdditionalInfoService,
    private readonly gcvService: GuidedCvBuilderService
  ) {
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'CV builder';
    this.validationService.setContext(ADDINFO_VALIDATION_CONTEXT);
    this.initForm();
    this.resolveRefData();
  }
  ngOnDestroy(): void {
  this.main.pageMainTitle = '';
  }

  ngOnInit() {
    this.addInfoService.getAdditionalInfoDetails()
      .subscribe((data: any) => {
        this.loadAndResolve(data);
      });
  }

  resolveRefData() {
    this.addInfoService.getAllLanguages()
      .subscribe(data => this.languages = data.map(each => each.description)); 
  }

  resolveData(data: any): void {
    this.additionalInfoForm.get('id').setValue(data.id);
    this.additionalInfoForm.get('isLicenced').setValue(data.isLicenced);
    (data.languages ? data.languages as any[] : []).forEach(lang => (this.additionalInfoForm.get('languages') as FormArray).push(this.fb.control(lang)));
    this.resolveSelectedHobbies(data.hobbies ? data.hobbies : []);
    if (data.hobbies && data.hobbies.includes('114')) {
      this.additionalInfoForm.addControl('otherHobbies', this.fb.control(''));
      this.additionalInfoForm.get('otherHobbies').setValue(data.otherHobbies);
    }
  }

  resolveSelectedHobbies(ids: string[]) {
    ((this.additionalInfoForm.get('hobbies') as FormGroup)
      .get('answers') as FormArray).controls.forEach(each => {
        if (ids.includes(each.get('id').value)) {
          each.get('checked').setValue(true);
        }
      });
  }

  async loadAllAnswers() {
    await this.addInfoService.getAllQas()
      .then((data: any) => {
        const fr = (this.additionalInfoForm.get('hobbies') as FormGroup).get('answers') as FormArray;
        (Object.values(data)[0] as any[]).forEach(each => fr.push(this.getSingleAnswerForm(each)));
      })
  }

  async loadAndResolve(data: any) {
    await this.loadAllAnswers();
    if (data) {
      this.resolveData(data);
    }
  }

  initForm() {
    this.additionalInfoForm = this.fb.group({
      id: this.fb.control(''),
      isLicenced: this.fb.control(''),
      languages: this.fb.array([]),
      hobbies: this.fb.group({
        id: this.fb.control('1'),
        question: this.fb.control('What are your hobbies and interests?'),
        answers: this.fb.array([]),
      }),
    });

    (this.additionalInfoForm.get('hobbies') as FormGroup).get('answers').valueChanges
      .subscribe((answers: any[]) => {
        const filtered = answers.filter(each => each.checked);
        if (filtered.find(each => each.text === 'Other')) {
          this.additionalInfoForm.addControl('otherHobbies', this.fb.control(''));
        } else {
          this.additionalInfoForm.removeControl('otherHobbies');
        }
      });
  }

  getSingleAnswerForm(data: any) {
    const form = this.fb.group({
      id: this.fb.control(data.identifier),
      text: this.fb.control(data.text),
      checked: this.fb.control(data.selected)
    });
    form.markAsTouched();
    return form;
  }

  gotoPrev() {
    this.location.back();
  }

  cvSummaryPage() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  saveAndLater() {
    this.clearAllValidatorsAndErrors(this.additionalInfoForm);
    this.clearAllValidatorsAndErrors(this.additionalInfoForm.get('hobbies') as FormGroup);
    this.setValidators(this.additionalInfoForm, ADDINFO_VALIDATORS_DRAFT);
    if (this.additionalInfoForm.valid) {
      const data = this.parseSaveData();
      this.addInfoService.saveAndLater(data)
        .subscribe(res => {
          this.resetAllForms();
          this.router.navigate(['..'], { relativeTo: this.route });
        });
    }
    window.scrollTo(0, 0);
  }

  saveAndContinue() {
    this.setValidators(this.additionalInfoForm, ADDINFO_VALIDATORS_SAVE);
    this.setValidators(this.additionalInfoForm.get('hobbies') as FormGroup, HOBBIES_VALIDATORS_SAVE);
    if (this.additionalInfoForm.valid) {
      const data = this.parseSaveData();
      this.addInfoService.saveAndContinue(data)
        .subscribe(res => {
          this.resetAllForms();
          this.gcvService.listSections().subscribe((data: any[]) => {
            if (data.filter(each => each.status !== 'Completed').length === 0) {
              this.router.navigate(['../cv-templates'], { relativeTo: this.route });
            } else {
              this.router.navigate(['..'], { relativeTo: this.route });
            }
          });
        });
    }
    window.scrollTo(0, 0);
  }

  canExit(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    if (this.additionalInfoForm.dirty) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.clearValidators();
        control.setErrors(null);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    });
  }

  parseSaveData() {
    const addInfo = this.additionalInfoForm.value;
    const hobbies = addInfo.hobbies.answers
      .filter(each => each.checked)
      .map(answer => answer.id);
    addInfo.hobbies = hobbies;
    return addInfo;
  }

  resetAllForms() {
    this.initForm();
  }

}
