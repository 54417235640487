import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, lastValueFrom, of } from "rxjs";
import { Url } from "src/app/shared/constants/url-constants";
import { DatabaseService } from "./database.service";

import { ModuleTypes } from "src/app/shared/constants/types.constants";
import { catchError, map, tap } from "rxjs/operators";
import { Utility } from "src/app/shared/utils/utility";
import { AuthenticationService } from "src/app/authentication/authentication.service";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class OfflineService {
  constructor(
    private readonly databaseService: DatabaseService,
    private readonly http: HttpClient,
    private readonly authService: AuthenticationService,
    private sanitizer: DomSanitizer
  ) { }

  isOnline() {
    return false;
  }

  async setOfflineData(info) {
    await this.databaseService.insertProfile({
      id: "" + info.id,
      title: info.title,
      firstName: info.firstName,
      surName: info.surName,
      dateOfBirth: info.dateOfBirth,
      gender: info.gender,
      organizationName: info.organizationName,
      deviceId: info.deviceId,
      prn: info.prn,
      ethnicity: info.ethnicity,
      uln: info.uln,
      lrn: info.lrn,
      lrnPrevious: info.lrnPrevious,
      selfAssessmentLdd: info.selfAssessmentLdd,
      priorAttainment: info.priorAttainment,
      mathInitialAssessment: info.mathInitialAssessment,
      mathsAssessmentDate: info.mathsAssessmentDate,
      englishInitialAssessment: info.englishInitialAssessment,
      englishAssessmentDate: info.englishAssessmentDate,
      languageStatus: info.languageStatus,
      glhDtoList: info.glhDtoList,
      lldAndHealthProblemList: info.lldAndHealthProblemList,
      totalAttentedGLH: info.totalAttentedGLH,
      courseList: info.courseList
    });
    await this.setCourseInfo();
    await this.setFstructure(info.id);
    await this.setInductionPlan();
    // await this.setWalletDocuments();
    await this.setRefContentLevels();
    await this.setMetadata();
  }

  async setMetadata() {
    const href = `${Url.CONTENT_LIBRARY}/offline`;
    const metadata = await lastValueFrom(this.http.get<any>(href));
    const categories = metadata.categories;
    const categoryImages$: any = {}
    let sequence = 1;

    for (const category of categories) {
      if (category.categoryImgUrl) {
        categoryImages$[category.id] = this.getAttachment(category.categoryImgUrl, category.id);
      }
      category['sequence'] = sequence;
      sequence++;
    }
    const dbCategories = await (await this.databaseService.insertCategories(categories)).success;

    const catImages = await this.promisedProperties(categoryImages$);
    const catIds: any[] = Object.keys(catImages);
    for (let catId of catIds) {
      const cat = Utility.getObjectFromArrayByKeyAndValue(dbCategories, 'id', catId);
      const imageAttachement= await cat.putAttachment(catImages[catId])
      if(imageAttachement) {
        const imageData:any = await imageAttachement.getData();
        const imagePath=URL.createObjectURL(imageData);
        cat.atomicPatch({categoryImgUrl:imagePath});
      }
    }

    const contents: any[] = metadata.contents;
    const modules: any[] = metadata.modules;
    contents.map((o: any) => {
      o.modules = Utility.filterArrayByKeyAndValue(metadata.modules, 'contentId', o.contentID);
      o.contentID = "" + o.contentID;
      return o;
    });
    let moduleIndex = 1;
    for (let content of contents) {
      const dbContent = (await this.databaseService.insertContent(content));
      await dbContent.putAttachment(await this.getAttachment(content.imagePath, content.contentID));
      

      
      for (let module of content.modules) {
        console.log(`Downloading module ${moduleIndex++} of ${modules.length}`);
        const type = ModuleTypes.find(elem => elem.types.includes(module.type));

        const electrolUrl = `${Url.ELECTRON_APP}/loadHtml`;
        if (type && type.viewType === 'scorm') {
          // if(module.lmsCourseId === null) {
          // const moduleUrl = `${Url.CONTENT_LIBRARY}/offline/${content.contentID}/module/${module.id}/downloadZip`;
          // await lastValueFrom(this.http.post<any>(electrolUrl, { url: moduleUrl, type: type.viewType })).catch(err => console.log('Error calling electron app' + err));
            
          const url = new URL(module.path);
          const path = url.pathname.split(/\/contents\/zip\//);
          module.path = `${Url.ELECTRON_APP}/zip/${path[1]}`;
          // }
        } else {
          const fileName = module.path.substr(module.path.lastIndexOf('.') + 1);
          module.fileName = module.name + '.' + fileName;
          //TODO remove this line 
          // await lastValueFrom(this.http.post<any>(electrolUrl, { url: module.path, type: type.viewType })).catch(err => console.log('Error calling electron app' + err));
          const path = type.viewType+"/"+module.path.substring(module.path.lastIndexOf("/") + 1);
          module.path = `${Url.ELECTRON_APP}/${path}`;
        }
      }
      await dbContent.atomicPatch({modules: content.modules });
    }
  }
  async getAttachment(url, id) {
    const img = await lastValueFrom(
      this.http.get(url, { responseType: "blob", observe: 'response' })
    ).catch(this.handleImageError);
    let fileName = null;
    if (img) {
      fileName = img.headers.get('X-Doc-Name');
    }
    return { id: id, data: img.body, type: img.body?.type, fileName: fileName };
  }

  async setCourseInfo() {
    const href = `${Url.MOJ_PEF}/SUTimelineInfo?prn`;
    const courses = await lastValueFrom(this.http.get<any>(href));
    const dbCourses = (await this.databaseService.insertCourseInfo(courses.suCourseOutDTO)).success;
    for (let i = 1; i <= courses.categoryList.length; i++) {
      courses.categoryList[i - 1].sequence = i;
    }
    const dbRefCourseCategories = (await this.databaseService.insertRefCourseCategory(courses.categoryList)).success;
  }

  async setInductionPlan() {
    const href = `${Url.PLAN}/induction-Plan/goals/1?userId`;
    const plan = await lastValueFrom(this.http.get<any>(href));
    await this.databaseService.insertInductionPlan(plan);
  }

  async setWalletDocuments() {
    const href = `${Url.DOCUMENT}/documents`;
    const documents = await lastValueFrom(this.http.get<any>(href));
    const dbDocuments = (await this.databaseService.insertWalletDocuments(documents)).success;
    for (const doc of dbDocuments) {
      const href = `${Url.DOCUMENT}/download/users/documents/${doc.id}?access_token=Bearer ${this.authService.getToken()}`;
      const rsp: any = await (await lastValueFrom(this.http.post(href, {}, { responseType: "blob", observe: 'response' })));
      await doc.putAttachment({ id: '' + doc.id, data: rsp.body, type: rsp.body?.type });
    }
  }

  getWalletDocuments() {
    return this.databaseService.getWalletDocuments()
      .pipe(map((o) => o.map((o) => o.toJSON())));
  }

  async getWalletDocumentById(id) {
    const doc = await this.databaseService.getWalletDocumentById(id).exec();
    const attachment = await doc.getAttachment('' + id);
    const data = await attachment.getData();
    return { data: data, fileName: doc.name };
  }

  async setRefContentLevels() {
    const levels = await lastValueFrom(this.http.get<any>(`${Url.CONTENT_LIBRARY}/refData/levels`))
    await this.databaseService.insertRefLevel(levels);
  }

  async setFstructure(userId) {
    const fstructures = await lastValueFrom(this.http.get<any>(`${Url.PLAN}/fstructure?sort=sortDate,desc&sort=activityId,asc&page=0&size=15&userId=${userId}`))
    let sequence = 1;
    for (const fstructure of fstructures.content) {
      fstructure['order'] = sequence;
      sequence++;
    }
    await this.databaseService.insertFstructure(fstructures.content);
  }

  getProfileInfo() {
    return this.databaseService.getProfile();
  }

  getPrimaryCategories() {
    return this.databaseService
      .getCategoriesByLevel(1)
      .pipe(tap(results => {
        results.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
      }));
  }

  getRecommendedContents() {
    return this.databaseService.getRecommendedContents()
  }

  getRecommendedContentsByCatId(subCatId) {
    return this.databaseService.getRecommendedContentsByCatId(subCatId)
  }

  getSecondaryCategories(parentId) {
    return this.databaseService
      .getCategoriesByLevelAndParentId(2, parentId)
  }

  getContent(subCatId) {
    return this.databaseService.getContentBySubCategoryId(subCatId)
      .pipe(map((o) => o.map((o) => o.toJSON())));
  }

  getFstructures() {
    return this.databaseService.getFstructures()
      .pipe(tap(results => {
        results.sort((a, b) => (a.order > b.order) ? 1 : -1)
      }), map((o) => o.map((o) => o.toJSON())));
  }

  searchContent(keyword, page, size) {
    const count = this.databaseService.getSearchQuery(keyword).$.pipe(map(o => o.length), catchError(error => of(0)));
    const results = this.databaseService.getSearchQuery(keyword).limit(size).skip(page * size).$
      .pipe(map((o) => o.map((o) => o.toJSON())), catchError(error => of([])));
    return combineLatest({ totalElements: count, content: results });
  }

  getCourses() {
    return this.databaseService.getCoursesInfo()
      .pipe(map((o) => o.map((o) => {
        const json = o.toJSON();
        json['header'] = json.courseName;
        return json;
      })));
  }

  getRefCategoryList() {
    return this.databaseService.getRefCourseCategories()
      .pipe(map((o) => o.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1).map((o) => o.toJSON())));
  }

  getRefContentLevels() {
    return this.databaseService.getRefLevels()
      .pipe(map((o) => o.map((o) => o.toJSON())));
  }

  getContentDetails(contentId) {
    return this.databaseService.getContentByContentId(contentId).$
  }

  getGoals() {
    return this.databaseService.getGoals();
  }

  async getModuleAttachement(moduleId, contentId) {
    const content = await this.databaseService.getContentByContentId(contentId).exec();
    const attachment = await content.getAttachment(moduleId);
    const data = await attachment.getData();
    const module = Utility.getObjectFromArrayByKeyAndValue(content.modules, 'id', moduleId);
    const fileName = module.fileName ? module.fileName.substring(module.fileName.lastIndexOf('/') + 1) : '';
    return { data: data, fileName: fileName };
  }
  handleImageError = function (err) {
    console.error(err);
    return Promise.resolve(new HttpResponse<Blob>());
  }

  promisedProperties(object) {

    let promisedProperties = [];
    const objectKeys = Object.keys(object);

    objectKeys.forEach((key) => promisedProperties.push(object[key]));

    return Promise.all(promisedProperties)
      .then((resolvedValues) => {
        return resolvedValues.reduce((resolvedObject, property, index) => {
          resolvedObject[objectKeys[index]] = property;
          return resolvedObject;
        }, object);
      });

  }

  async clearDB() {
    return this.databaseService.clearDB();
  }
  
  async hasContents(subCatId) {
    const subCategory= await this.databaseService.hasContentSubCategoryId(subCatId).exec();
    if(subCategory)
      return true;
    else
      return false;
  }
}
