import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vc-induction-questions-list',
  templateUrl: './induction-questions-list.component.html',
  styleUrls: ['./induction-questions-list.component.scss']
})
export class InductionQuestionsListComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {}

}
