import { ICanDeactivate } from '../../../../../core/guards/deactivate.interface';
import { ConfirmService } from '../../../../../shared/components/confirm-box/confirm-box.service';
import { MainService } from '../../../../../core/services/main.service';
import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { EducationService } from '../education.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../../../../../shared/components/form-control/validation.service';
import { EDUCATION_VALIDATION_CONTEXT } from '../education.validation';


export interface IRefData {
  id: number,
  identifier: string,
  description: string,
}

const EDUCATIONFORM_VALIDATORS_SAVE = {
  courseStartDate: [Validators.required, ValidationService.monthYearValidator, ValidationService.futureDate,ValidationService.pastYear(80)],
  courseEndDate: [ValidationService.monthYearValidator,
    ValidationService.invalidEndDateOrCurrent('courseStartDate', 'courseEndDate', 'isStudying'),
    ValidationService.invalidEndDateAndCurrent('courseEndDate', 'isStudying'),
    ValidationService.futureDate],
  institute: [Validators.required, Validators.maxLength(100)],
  courseTitle: [Validators.required, Validators.maxLength(100)],
  otherEducationType: [Validators.required, Validators.maxLength(200), Validators.pattern('[a-zA-Z0-9\\-\\s\&\(\)\/\.\'\,\"\:\;]*')],
  grade: [Validators.maxLength(100)],
  educationType: [Validators.required]
}

const PAIDFORM_VALIDATORS_DRAFT = {
  courseStartDate: [ValidationService.monthYearValidator],
  courseEndDate: [ValidationService.monthYearValidator],
}

const SKILL_DESCRIPTION_VALIDATORS = {
  answers: [ValidationService.minSelected(3), ValidationService.maxSelected(3)],
}

@Component({
  selector: 'vc-edit-education',
  templateUrl: './edit-education.component.html',
  styleUrls: ['./edit-education.component.scss']
})
export class EditEducationComponent implements OnInit, ICanDeactivate, OnDestroy {


  educationForm: FormGroup;
  createAnother = new FormControl('N');
  educationTypes: IRefData[] = [];
  routeTo = '/employment/cv-builder/guided/education-home';

  constructor(
    private readonly fBuilder: FormBuilder,
    private readonly eduService: EducationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly validation: ValidationService,
    private readonly location: Location,
    private readonly main: MainService,
    private readonly confirm: ConfirmService) {
    this.initForm();
    this.validation.setContext(EDUCATION_VALIDATION_CONTEXT);
    this.resolveRefData();
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'Education';
  }
  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.resolveEducation(+params.id);
      }
    });

    this.educationForm.get('educationType').valueChanges
      .subscribe(value => {
        if (value === 'Other' || value === '19') {
          this.educationForm.addControl('otherEducationType', this.fBuilder.control(''));
        } else {
          this.educationForm.removeControl('otherEducationType');
        }
      });

    this.educationForm.get('courseStartDate').valueChanges
      .subscribe(data => {
        this.educationForm.get('courseEndDate').updateValueAndValidity();
      });

    this.educationForm.get('isStudying').valueChanges
      .subscribe(data => {
        this.educationForm.get('courseEndDate').updateValueAndValidity();
      });

  }
  initForm() {
    this.educationForm = this.fBuilder.group({
      id: this.fBuilder.control(''),
      educationType: this.fBuilder.control(''),
      institute: this.fBuilder.control(''),
      courseTitle: this.fBuilder.control(''),
      grade: this.fBuilder.control(''),
      isStudying: this.fBuilder.control(null),
      courseStartDate: this.fBuilder.control(''),
      courseEndDate: this.fBuilder.control(''),
    });
  }

  saveAndContinue() {
    EDUCATIONFORM_VALIDATORS_SAVE.educationType.push(this.educationTypeValidator(this.educationTypes.map(each => each.description)));
    this.setValidators(this.educationForm, EDUCATIONFORM_VALIDATORS_SAVE);
    const data = this.parseSaveData();
    if (this.educationForm.valid) {
      this.eduService.saveAndContinue(data).subscribe((rsp: any) => {
        this.checkToAddNewEducation();
      });
    }
    window.scrollTo(0, 0);
  }

  parseSaveData() {
    const education = this.educationForm.value;
    const educationType = this.educationTypes.filter( e => education.educationType === e.description);
    if (educationType.length > 0) {
     education.educationType = educationType[0].identifier;
    }
    return education;
  }

  private educationTypeValidator(list: string[]) {
    const validator = (control: AbstractControl) => {
      if (!list.includes(control.value)) {
        return { invalidEducationType: true }
      } else {
        return null;
      }
    }
    return validator ; 
    }

  checkToAddNewEducation() {
    this.resetConditionalForm();
    if (this.createAnother.value === 'N') {
      this.router.navigate(['../'], { queryParams: {}, relativeTo: this.route });
    } else {
      this.router.navigate([], { queryParams: {} });
    }
  }

  saveAndLater() {
    this.setValidators(this.educationForm, PAIDFORM_VALIDATORS_DRAFT);
    if (this.educationForm.valid) {
      if (this.isEmptyForm(this.educationForm.value)) {
        this.router.navigateByUrl(this.routeTo);
      } else {
        this.parseSaveData();
        this.eduService.saveAndLater(this.educationForm.value).subscribe((rsp: any) => {
          this.checkToAddNewEducation();
        });
      }
    }
  }

  isEmptyForm(data) {
    const filterData = Object.keys(data).filter(name => (data[name]));
    return filterData.length === 0;
  }

  resolveEducation(educationId) {
    this.eduService.getEducationDetails(educationId).subscribe(res => {
      this.educationForm.patchValue(res);
    });
  }

  cvSummaryPage() {
    this.router.navigateByUrl('/employment/cv-builder/guided');
  }

  gotoPrev() {
    this.location.back();
  }

  resetConditionalForm() {
    this.initForm();
  }

  resolveRefData() {
    this.eduService.refEducationType()
      .subscribe((data: any[]) => {
        this.educationTypes = data;
      });

  }

  canExit(route, state, nextState) {
    if (this.educationForm.dirty) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      if (control) {
        control.clearValidators();
        control.setErrors(null);
        control.markAsTouched();
      }
    });
  }

}


