import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vc-plan-track',
  templateUrl: './plan-track.component.html',
  styleUrls: ['./plan-track.component.scss']
})
export class PlanTrackComponent implements OnInit {

  @Input() fStructureData: any;
  @Input() loadMoreVisible: boolean;
  @Output() loadMore = new EventEmitter();
  @Output() refreshFStructureData = new EventEmitter();
  serviceUserId: number;
  currentDate = new Date(new Date().setHours(0, 0, 0, 0));

  constructor() { }

  ngOnInit() {
  }

  setEntryMarginBottom(index) {
    if (index + 1 === this.fStructureData.length) {
      return '0px'
    } else {
      return '60px'
    }
  }

  setInductionEntryMarginBottom(index) {
    if (
      this.fStructureData[index + 1] && 
      this.fStructureData[index + 1].activityId === -1 && 
      this.fStructureData[index + 1].entryDateUnix === this.fStructureData[index].entryDateUnix
    ) {
      return '-10px'
    } else if (index + 1 === this.fStructureData.length) {
      return '0px'
    } else {
      return '60px'
    }
  }

  decideIfShouldShowInductionIcon(index) {
    if (
      this.fStructureData[index - 1].activityId === -1 && 
      this.fStructureData[index - 1].entryDateUnix === this.fStructureData[index].entryDateUnix
    ) {
      return 'none'
    } else {
      return 'flex'
    }
  }

  setFLineHeight(index) {
    if (index + 1 === this.fStructureData.length && !this.loadMoreVisible) {
      return '0px'
    } else {
      if (this.fStructureData[index].activityId === -1) {
        let allInductionEntriesFlag = true;
        for (let i = 1; i < this.fStructureData.length; i++) {
          if (this.fStructureData[i].activityId !== -1) {
            allInductionEntriesFlag = false;
          }
        }
        return this.setFLineHeightIfAllEntriesAreInduction(allInductionEntriesFlag)
      }
      return 'auto'
    }
  }

  setFLineHeightIfAllEntriesAreInduction(allInductionEntriesFlag) {
    if (allInductionEntriesFlag) {
      return '0px'
    } else {
      return 'auto'
    }
  }

  onClickLoadMore() {
    this.loadMore.emit();
  }

  shouldShowTodayLineBrackets(i) {
    if (i  < this.fStructureData.length - 1) {
      if (this.fStructureData[i + 1].entryDateUnix === this.fStructureData[i].entryDateUnix) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

}
