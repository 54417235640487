import { Component, OnInit, Renderer2, AfterViewChecked } from '@angular/core';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { PageTenService } from './page-ten.service';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Utility } from '../../../../shared/utils/utility';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { PLAN_HOBBIES_VALIDATIONS } from './page-ten.validation';
import { MainService } from '../../../../core/services/main.service';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from "../../../../shared/components/confirm-box/confirm-box.service";


const OTHER_HOBBIES_VALIDATORS = {
  otherHobbies: [Validators.required, Validators.maxLength(200), Validators.pattern('[a-zA-Z0-9\\-\\s\&\(\)\/\.\'\,\"\:\;]*')]
};

const HOBBIES_VALIDATORS = {
  answers: [ValidationService.minSelected(1), ValidationService.maxSelected(3)],
}


@Component({
  selector: 'vc-page-ten',
  templateUrl: './page-ten.component.html',
  styleUrls: ['./page-ten.component.scss']
})
export class PageTenComponent implements OnInit, AfterViewChecked {


  pageTenHobbiesForm: FormGroup;
  form: FormGroup;
  currentPageNumber = 9;
  currentPageData = [] as any;
  refData = [] as any;
  model = { option: '' };
  skillPresentOnLoadFlag: boolean = false;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-nine';
  routeNext = '/plan/induction/page-eleven';
  isAssessmentInProgressFlag = false;
  routeState: any;


  constructor(
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly pageTenService: PageTenService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly validation: ValidationService,
    private readonly renderer: Renderer2,
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly togetherMode: TogetherModeModalService,
    private readonly confirm: ConfirmService,
  ) {
    this.mainService.pageTitle = 'Induction';
    this.validation.setContext(PLAN_HOBBIES_VALIDATIONS);
    this.pageTenHobbiesForm = new FormGroup({
      id: new FormControl(),
      hasAddedValue: new FormControl(),
      hobbies: new FormGroup({
        question: new FormControl(''),
        answers: new FormArray([]),
      })
    });
    this.pageTenHobbiesForm.get('hobbies').get('answers').valueChanges
      .subscribe((data: any[]) => {
        const filtered = data.filter(each => each.checked);
        if (filtered.find(each => each.id === 159)) {
          this.pageTenHobbiesForm.addControl('otherHobbies', this.fb.control(''));
        } else {
          this.pageTenHobbiesForm.removeControl('otherHobbies');
        }
      });
    this.loadSkillsAndResolve();
  }

  ngOnInit() {
    this.getRefData();
    this.resolveRouteState();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageTenHobbiesForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
    })
  }

  async loadSkillsAndResolve() {
    await this.loadAllSkills();
    this.resolveCurrentPageData(this.currentPageNumber);
  }

  async loadAllSkills() {
    await this.pageTenService.getRefData()
      .then(res => {
        const answers = Utility.getObjectFromArrayByKeyAndValue(res.domainList, 'identifier', '5')['choiceList'];
        this.loadAnswers(answers)
      });
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
       data.forEach(question => {
        if(question.answer!== null && JSON.parse(question.answer).hasOwnProperty('choiceIds')){
          this.resolveSelectedHobbies( JSON.parse(question.answer));
        }
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          }
        )
      });
    })
  }

  resolveSelectedHobbies(data: any) {
    if(data){
    ((this.pageTenHobbiesForm.get('hobbies') as FormGroup)
      .get('answers') as FormArray).controls.forEach(each => {
        if (data.choiceIds.includes(each.get('id').value)) {
          each.get('checked').setValue(true);
        }
        if(data.choiceIds.includes(159)){
          this.pageTenHobbiesForm.addControl('otherHobbies', this.fb.control(''));
          this.pageTenHobbiesForm.get('otherHobbies').setValue(data.Other);
        }
      });
    }
  }


  loadAnswers(answers: any[]) {
    const farr = this.pageTenHobbiesForm.get('hobbies').get('answers') as FormArray;
    answers.map(answer => {
      return {
        id: answer.choiceId,
        text: answer.choiceDescription,
        checked: false,
      }
    }).forEach(answer => {
      farr.push(this.singleAnswerForm(answer));
    });
  }

  singleAnswerForm(data: any) {
    return new FormGroup({
      id: new FormControl(data.id),
      text: new FormControl(data.text),
      checked: new FormControl(data.checked),
    });
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  draft() {
    this.isSaveSubmitOperation = true;
    const formattedPayload = this.formatPayload(true);
    this.saveInductionPlan(formattedPayload);
  }

  onClickContinue() {
    this.setValidators(this.pageTenHobbiesForm.get('hobbies') as FormGroup, HOBBIES_VALIDATORS);
    this.setValidators(this.pageTenHobbiesForm, OTHER_HOBBIES_VALIDATORS);
    if(this.pageTenHobbiesForm.valid){
    this.isSaveSubmitOperation = true;
    const formattedPayload = this.formatPayload(false);
    this.saveInductionPlan(formattedPayload);
    } else {
      this.setFocusToErrorSummary();
    }
  }

  canExit(route, state, nextState) {
    if (this.pageTenHobbiesForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  saveInductionPlan(formattedPayload) {
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      if (formattedPayload.isSaveAsDraft) {
        this.router.navigateByUrl('/plan');
        window.scrollTo(0, 0);
      } else {
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-eleven');
      }
    });
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }


  formatPayload(saveAsDraft) {
    const skills = this.pageTenHobbiesForm.get('hobbies').value.answers
      .filter(each => each.checked)
      .map(answer => answer.id);

    return {
      isSaveAsDraft: saveAsDraft,
      planTypeIdentifier: '1',
      questionDTOList: [
        {
          answer: JSON.stringify({
            choiceIds: skills,
            Other: this.pageTenHobbiesForm.contains('otherHobbies') ?
              this.pageTenHobbiesForm.controls.otherHobbies.value : null,
          }),
          id: 13
        }
      ],
      sectionIdentifier: this.currentPageData[0].sectionIdentifier
    }
  }

}
