<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<vc-all-errors [groups]="[form]" [fromContext]="true"></vc-all-errors>
<div class="education mt25">

    <div class="grid-row">
        <div *ngIf="model.option=='Y' || eduList.length !== 0 "class="column-full form-group">
            <button id="id-submit" class="button button-secondary" type="button" name="add-edu"
                (click)="addNewEducation()">Add education</button>
        </div>
    </div>

    <div class="grid-row">
        <table *ngIf="eduList.length !== 0">
            <caption></caption>
            <thead>
                <tr>
                    <th scope="colgroup">Education type</th>
                    <th scope="colgroup">Course title</th>
                    <th scope="colgroup">Date completed</th>
                    <th scope="colgroup" class="width-10"></th>
                    <th scope="colgroup"></th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let s of eduList">
                    <td>
                        <span *ngIf="s?.educationType && s?.educationType == 'Other' ">
                            {{s.otherEducationType | truncate:[40, '...'] }}</span>
                        <span *ngIf="s?.educationType && s?.educationType != 'Other'">
                            {{s.educationType | truncate:[40, '...'] }}</span>
                    </td>
                    <td><span *ngIf="s?.courseTitle">{{s.courseTitle | truncate:[40, '...']  }}</span></td>
                    <td>
                        <span *ngIf="s?.courseEndDate">{{s.courseEndDate | date : 'MMMM yyyy'}}</span>
                        <span *ngIf="!s?.courseEndDate && s?.isStudying">Currently studying</span>
                    </td>
                    <td>
                        <span class="status-tag blue" *ngIf="s?.status">COMPLETED</span>
                        <span class="status-tag green" *ngIf="!s?.status">IN PROGRESS</span>
                    </td>
                    <td>
                        <span>
                            <a href="javascript: void(0)" class="delLink link" (click)="goToEditSection(s.id)">Edit</a>
                        </span>
                        <span>
                            <a href="javascript: void(0)" (click)="deleteEducation(s.id)"
                                class="delLink link">Delete</a>
                        </span>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>

    <div  *ngIf="eduList.length == 0" class="grid-row">
        <form [formGroup]="form">
            <div *ngIf="model.option=='N'"class="home-yes-no" >
                <label class="form-label-b19"> Do you have any formal education? </label>
                    <div class="rwrap form-group">
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="form.get('hasAddedValue')" [value]='false' id="id-createAnother-true"
                            name="createAnother" (click)="setOption('Y')"
                            [checked]="model.option=='Y'"
                            aria-describedby="id-section-help-createAnother-true id-section-error-createAnother-true" />
                        <label for="id-createAnother-true">Yes</label>
                    </div>
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="form.get('hasAddedValue')" [value]='true' id="id-createAnother-false"
                            name="createAnother" (click)="setOption('N')" [checked]="model.option=='N'"
                            aria-describedby="id-section-help-createAnother-false id-section-error-createAnother-false" />
                        <label for="id-createAnother-false">No</label>
                    </div>
                </div>
                
            </div>
        </form>
    </div>

    <div class="grid-row mb25">
        <button *ngIf="eduList.length !== 0" id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
        (click)="save()">Continue</button>
        <button *ngIf="eduList.length == 0" id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
        (click)="save()">Save and
        continue</button>
        <button *ngIf="eduList.length == 0" id="id-save-and-come-back-later mr15" class="button button-secondary" type="button" name="save-and-come-back-later"
            (click)="draft()">Save and come back
            later</button>
        <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
            (click)="cvSummaryPage()">CV summary</button>

    </div>
</div>