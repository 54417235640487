
export const EDITTRAINING_VALIDATION_CONTEXT = {
	required: {
		startDate: 'Start date cannot be blank',
		qualification: `Qualification cannot be blank`,
		body: `Training body cannot be blank`,
		details: `Description cannot be blank`,
	},
	invalidDate: {
		startDate: 'Enter a valid start date',
		endDate: 'Enter a valid end date',
	},
	futureDate: {
		startDate: 'Start date cannot be in the future',
		endDate: 'End date cannot be in the future'
	},
	invalidPastYear: {
		startDate: 'Training start year cannot be more than 80 years in the past',
	},
	invalidEndDateOrCurrent: {
		endDate: 'Start date must be accompanied by either an end date or currently under training selection',
	},
	afterEndDate: {
		endDate: 'Start date cannot be after end date',
	},
	invalidEndDateAndCurrent: {
		endDate: 'Start date cannot be accompanied by both end date and currently under training selection',
	},
	maxlength: {
		qualification: 'Please enter 100 or less characters in qualification',
		body: 'Please enter 100 or less characters in training body',
		grade: 'Please enter 100 or less characters in grade',
		details: `Please enter 200 or less characters in description`,
	},
}