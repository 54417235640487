import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const inductionPlanSchema: RxCollectionCreatorBase = {
    schema: {
        title: 'Induction Plan',
        version: 0,
        description: 'Induction Plan details',
        type: 'object',
        properties: {
            planGoal: {
                type: ['array', 'null'],
                items: {
                    type: ['string', 'null']
                }
            }
        },
    }
}