import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ICanDeactivate } from '../../../../core/guards/deactivate.interface';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { MainService } from '../../../../core/services/main.service';
import { IRefData } from '../education/edit-education/edit-education.component';
import { EDUCATION_VALIDATION_CONTEXT } from './education.validation';
import { EducationService } from './education.service';


const VALIDATORS = {
  hasAddedValue: [ValidationService.ifChecked]
}

@Component({
  selector: 'vceducation',
  templateUrl: './education-home.component.html',
  styleUrls: ['./education-home.component.scss']
})
export class EducationHomeComponent implements OnInit, ICanDeactivate, OnDestroy {
  form: FormGroup;
  data: any = [];
  isDialogOpen = false;
  routeTo = '/employment/cv-builder/guided/education-home/education';
  eduList: any = [];
  educationTypes: IRefData[] = [];
  newEduList: any;
  model   = {option: ''};

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly main: MainService,
    private readonly educationService: EducationService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly confirm: ConfirmService,
    private readonly validationService: ValidationService
  ) {
    this.form = new FormGroup({
      id: new FormControl(),
      hasAddedValue: new FormControl()
    });
    this.main.pageTitle = this.route.snapshot.data.title;
    this.main.pageMainTitle = 'CV builder';
    this.validationService.setContext(EDUCATION_VALIDATION_CONTEXT);
  }

  ngOnDestroy(): void {
    this.main.pageMainTitle = '';
  }
  ngOnInit() {
    this.getData();
  }

  getData() {
    this.educationService.getEducationInfo().subscribe(res => {
      if (res) {
        this.data = res;
        this.form.get('hasAddedValue').setValue(this.data.hasAddedValue);
        if(this.eduList.length === 0 ){
          this.model.option = 'N';
          this.form.get('hasAddedValue').setValue(true);
        }
        this.eduList = this.data.educations;
      }
    })
  }

  gotoPrev() {
    this.location.back();
  }

  cvSummaryPage() {
    this.router.navigate(['/employment/cv-builder/guided']);
  }

  canExit(route, state, nextState) {
    if (this.form.dirty && nextState.url !== this.routeTo) {
      this.confirm.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to leave without saving changes?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.confirm.choose(true);
        },
        reject: () => {
          this.confirm.choose(false);
        }
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  addNewEducation() {
      this.router.navigateByUrl(this.routeTo);
  }

  save() {
    this.setValidators(this.form, VALIDATORS);
    if (this.form.valid && this.eduList.length === 0) {
      this.educationService.saveStudying(this.form.value).subscribe(res => {
        this.resetAllForms();
        this.router.navigate(['../training-home'], { relativeTo: this.route });
      });
    } else if (this.eduList.length !== 0) {
      this.resetAllForms();
      this.router.navigate(['../training-home'], { relativeTo: this.route });
    }
    window.scrollTo(0, 0);
  }

  draft() {
    this.clearAllValidatorsAndErrors(this.form);
    if (this.form.valid && this.eduList.length === 0) {
      this.educationService.draftStudying(this.form.value).subscribe(res => {
        this.resetAllForms();
        this.router.navigate(['../'], { relativeTo: this.route });
      });
    }

    window.scrollTo(0, 0);
  }

  resetAllForms() {
    this.form.markAsPristine();
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  goToEditSection(eduId) {
    this.router.navigate([this.routeTo], { queryParams: { id: eduId }, relativeTo: this.route });
  }

  deleteEducation(eduId) {
    this.confirm.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete the educational qualification details?',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.isDialogOpen = false;
        this.educationService.deleteEducation(eduId).subscribe(res => {
          this.getData();
        });
      },
      reject: () => {
        this.isDialogOpen = false;
      }
    });
    return this.confirm.navigateSelection;
  }

  resolveRefData() {
    this.educationService.refEducationType()
      .subscribe((data: any[]) => {
        this.educationTypes = data;
      });
  }

  setOption(option){
    this.model.option = option;

    if(option === 'N'){
      this.form.get('hasAddedValue').setValue(true);
    }
    else if(option === 'Y'){
      this.form.get('hasAddedValue').setValue(false);
    }
  }
      

}
