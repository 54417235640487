/* 
	* Validation error messages for paid works page
*/

export const EDUCATION_VALIDATION_CONTEXT = {

	required: {
		educationType: 'Education type cannot be blank',
		institute: 'School, college or university name cannot be blank',
		courseTitle: 'Course title cannot be blank',
		courseStartDate: 'Start date cannot be blank',
		otherEducationType: `Enter 'other' education type description, it cannot be blank`
	},
	invalidDate: {
		courseStartDate: 'Enter a valid start date',
		courseEndDate: 'Enter a valid end date',
	},
	futureDate: {
		courseStartDate: 'Start date cannot be in the future',
		courseEndDate: 'End date cannot be in the future'
	},
	invalidEndDateOrCurrent: {
		courseEndDate: 'Start date must be accompanied by either an end date or current education',
	},
	afterEndDate: {
		courseEndDate: 'Start date cannot be after end date',
	},
	invalidEndDateAndCurrent: {
		courseEndDate: 'Start date cannot be accompanied by both end date and currently studying selection',
	},
	maxlength: {
		otherEducationType: 'Please enter 200 or less characters in other education type description',
		institute:'Please enter 100 or less characters in school, college or university name',
		courseTitle:'Please enter 100 or less characters in course title',
		grade:'Please enter 100 or less characters in grade',
	},
  ifChecked: {
      hasAddedValue: `Please select 'Add education'`
  },
  educatedCheck: {
      hasAddedValue: `Unselect 'I do not have any formal education' first, before selecting 'Add new education'`
	},
	invalidEducationType: {
		educationType: 'Please select education type from the list',
  },
	invalidPastYear: {
		courseStartDate: 'Course start year cannot be more than 80 years in the past',
  },
  pattern: {
		otherEducationType: 'Other education type description contains disallowed characters'
	}
}