import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from './../../../../shared/components/form-control/validation.service';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { MainService } from './../../../../core/services/main.service';
import { INDUCTION_QUESTIONS_VALIDATION_CONTEXT } from './../induction-questions-list.validation';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from "../../../../shared/components/confirm-box/confirm-box.service";

const PAGEFOURFORM_VALIDATORS = {
  releaseDate: [
    Validators.required,
    ValidationService.monthYearValidator,
    ValidationService.pastDate
    ],
}
@Component({
  selector: 'vc-page-four',
  templateUrl: './page-four.component.html',
  styleUrls: ['./page-four.component.scss']
})
export class PageFourComponent implements OnInit, AfterViewChecked {

  currentPageNumber: number;
  currentPageData = [] as any;
  refData = [] as any;
  routeState: any;
  pageFourForm: FormGroup;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-three';
  routeNext = '/plan/induction/page-five';
  isAssessmentInProgressFlag = false;

  @ViewChild("question") questionDiv: ElementRef;
  
  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly validation: ValidationService,
    private readonly renderer: Renderer2,
    private readonly mainService: MainService,
    private readonly route: ActivatedRoute,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly togetherMode: TogetherModeModalService,
    private readonly confirm: ConfirmService,
  ) { 
    this.getRefData();
    this.initForm();
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.validation.setContext(INDUCTION_QUESTIONS_VALIDATION_CONTEXT);
  }

  ngOnInit() {
    this.resolveRouteState();
    this.currentPageNumber = 4;
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageFourForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  parseSaveData() {
    const isRelaseDate = this.pageFourForm.value;
    return isRelaseDate;
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          }
        )
      });
      this.pageFourForm.controls.releaseDate.setValue(data[0].answer ? data[0].answer : '');
      this.setFocusToQuestionDiv();
    })
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  initForm() {
    this.pageFourForm = this.formBuilder.group({
      releaseDate: this.formBuilder.control('')
    })
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  canExit(route, state, nextState) {
    if (this.pageFourForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  onClickContinue() {
    this.setValidators(this.pageFourForm, PAGEFOURFORM_VALIDATORS);
    if (this.pageFourForm.valid) {
      const formattedPayload = this.formatPayload(false);
      this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
        this.isSaveSubmitOperation = true;
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-five');
        window.scrollTo(0, 0);
      })
    } else {
      this.setFocusToErrorSummary();
    }
  }

  onClickSave() {
    const formattedPayload = this.formatPayload(true);
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      this.isSaveSubmitOperation = true;
      this.router.navigateByUrl(this.routeTo);
      window.scrollTo(0, 0);
    })
  }

  formatPayload(saveAsDraft) {
    return {
      "isSaveAsDraft": saveAsDraft,
      "planTypeIdentifier": "1",
      "questionDTOList": [
        {
          "answer": this.pageFourForm.controls.releaseDate.value,
          "id": 8
        }
      ],
      "sectionIdentifier": this.currentPageData[0].sectionIdentifier
    }
  }

  setFocusToQuestionDiv() {
    this.questionDiv.nativeElement.focus();
  }
}
