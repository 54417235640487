<a href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div class="induction-page" *ngIf="showInductionPage">
    <p class="mt30">Use this assessment to:</p>

    <ul class="instruction-list">
        <li>create your plan</li>
        <li>update your plan</li>
        <li>set your goals</li>
    </ul>

    <p>This will take around 30 minutes.</p>

    <a routerLink="/plan/induction/page-one" role="button" draggable="false" class="induction-start-button">
        Start now
        <svg class="govuk-button__start-icon" xmlns="http://www.w3.org/2000/svg" width="17.5" height="19" viewBox="0 0 33 40" aria-hidden="true" focusable="false">
            <path fill="currentColor" d="M0 0h13l20 20-20 20H0l20-20z" />
        </svg>
    </a>
      
</div>