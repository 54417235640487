import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserProfileComponent } from './user-profile.component';
import { LearnerInfoComponent } from './learner-info/learner-info.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PlanInductionComponent } from './plan-induction/plan-induction.component';

const routes: Routes = [
  {
    path: '',
    component: UserProfileComponent,
    children: [
      {
        path: '',
        component: ProfileDetailsComponent
      },
      {
        path: 'about-me',
        component: ProfileDetailsComponent
      },
      {
        path: 'plan-induction',
        component: PlanInductionComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserProfileRoutingModule { }
