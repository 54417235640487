import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../../core/services/main.service';
import { EmploymentService } from '../../employment.service';
import { Location } from '@angular/common';
import { NfnJob } from './jobs-for-prison-leavers.interface';
import { ExpressedInterest } from '../../employment.interface';
import { environment } from '../../../../../environments/environment';
import { Utility } from '../../../../shared/utils/utility';

@Component({
  selector: 'vc-view-jobs-for-prison-leavers',
  templateUrl: './view-jobs-for-prison-leavers.component.html',
  styleUrls: ['./view-jobs-for-prison-leavers.component.scss']
})
export class ViewJobsForPrisonLeaversComponent implements OnInit {

  advertNfnJobDetails: NfnJob;
  rotl: any = 'Yes';
  expressedInterest: ExpressedInterest;

  constructor(private readonly mainservice: MainService,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly employmentService: EmploymentService,
    private readonly router: Router,
    private readonly renderer: Renderer2
  ) {
    this.mainservice.pageTitle = this.route.snapshot.data.title;
    this.mainservice.pageMainTitle = this.route.snapshot.data.mainTitle;
    this.renderer.addClass(document.body, 'changeCaseOfTitle');
  }

  ngOnDestroy(): void {
    this.mainservice.pageMainTitle = '';
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      if (params.hasOwnProperty('Id')) {
        this.employmentService.getAdvertNfnJobDetails(params['Id']).subscribe(response => {
          this.advertNfnJobDetails = response;
          if (!this.advertNfnJobDetails.availableOnROTL) {
            this.rotl = 'No';
          }
        });
        this.employmentService.getAdvertNFNJobStatus(params['Id']).subscribe(response => {
          this.expressedInterest = response;
        });
      }
    })
    window.scrollTo(0,0);
  }

  backClicked() {
    this.location.back();
  }

  expressInterest(id: any){
    this.router.navigate(['/employment/jobs-for-prison-leavers/expression-of-interest'], { queryParams : { job: id}});
  }

  getImagePath(imagePath: string) {
    imagePath = Utility.replaceString('original', '495_330',imagePath);
    return `${environment.azureBlobStorage}${imagePath}`;
  }
}



