import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewComponent } from './image-view.component';
import { ImageViewerModule } from 'ngx-image-viewer';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [ImageViewComponent],
  imports: [
    CommonModule,
    PipesModule,
    ImageViewerModule.forRoot()
  ],
  exports: [
    ImageViewComponent
  ]
})
export class ImageViewModule { }
