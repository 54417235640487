<div class="header">
  <div class="tabWrapper">
    <ul>
      <li 
        routerLink="/user-profile" 
        class="tabHead" 
      ><a class="tab-item" id="profile-tab" attr.aria-label="tab Profile">Profile</a>
      </li>
      <li 
        routerLink="/user-profile/plan-induction" 
        class="tabHead active" 
      ><a class="tab-item" id="plan-induction-tab" attr.aria-label="tab Plan induction">Plan induction</a>
    </ul>
  </div>
</div>
<div class="profileWrapper">
  <h3 class="tab-subtitle">
    Plan induction
  </h3>
  <div class="inset-text-container" *ngIf="!isPlanCreated">
    <p class="inset-text">
      No Plan has been created.
    </p>
  </div>
  <div class="inset-text-container" *ngIf="isPlanCreated">
    <p class="inset-text">
      {{planDate | date:'dd MMMM yyyy'}}
    </p>
    <div class="badge-style" [class.agreed]="planStatus === 'AGREED'" *ngIf="planStatus">
      {{planStatus}}
    </div>
  </div>
  <dl class="summary-list" *ngIf="inductionData.length > 0">
    <ng-container *ngFor="let question of inductionData">
      <div class="summary-list-row" *ngIf="question.pageNumber !== 'three'">
        <dt class="summary-list-key" id="question-label-{{question.pageNumber}}" [class.no-border-bottom-summary-list]="question.panelStateOpen">
          {{question.label}}
        </dt>
        <dd class="summary-list-value" [class.no-border-bottom-summary-list]="question.panelStateOpen">
          <ng-container *ngFor="let value of question.value; let i = index">
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="question.pageNumber === 'four'">
                <p *ngIf="value.charAt(0) === 'T'" [ngStyle]="setAnswerMargin(i)">
                  {{value}}
                </p>
                <p *ngIf="value.charAt(0) !== 'T'" [ngStyle]="setAnswerMargin(i)">
                  {{value | date: 'LLLL yyyy'}}
                </p>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <p [ngStyle]="setAnswerMargin(i)">
                  {{value}}
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
        </dd>
        <dd class="summary-list-actions" [class.no-border-bottom-summary-list]="question.panelStateOpen">
          <ng-container [ngSwitch]="true">
            <ng-container *ngSwitchCase="question.hasHistory">
              <button class="button button-secondary" (click)="togglePanelState(question.pageNumber, question.panelStateOpen, question.sectionIdentifier, question.sectionDescription)">History</button>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class='accordion-empty-placeholder'></div>
            </ng-container>
          </ng-container>
        </dd>
      </div>
      <ng-container *ngIf="question.panelStateOpen">
        <div class="summary-list-row" *ngIf="question.pageNumber !== 'three'">
          <dt class="summary-list-key" id="question-label-{{question.pageNumber}}" [class.no-border-bottom-summary-list]="question.panelStateOpen">
            {{question.label}} history
          </dt>
          <dd class="summary-list-value" id="question-answer-{{question.pageNumber}}" [class.no-border-bottom-summary-list]="question.panelStateOpen"></dd>
          <dd class="summary-list-actions" [class.no-border-bottom-summary-list]="question.panelStateOpen"></dd>
        </div>
        <div class="summary-list-row" *ngFor="let record of inductionHistory">
          <dt class="summary-list-key-history" id="question-label-{{question.pageNumber}}">
            {{record.date | date:'dd MMMM yyyy'}}
          </dt>
          <dd class="summary-list-value" id="question-answer-{{question.pageNumber}}">
            <ng-container *ngFor="let answer of record.formattedAnsList; let i = index">
              <p [ngStyle]="setAnswerMargin(i)">
                {{answer}}
              </p>
            </ng-container>
          </dd>
          <dd class="summary-list-actions">
          </dd>
        </div>
      </ng-container>
      <ng-container *ngIf="question.pageNumber === 'three'">
        <div class="summary-list-row">
          <dt class="summary-list-key" id="question-label-{{question.pageNumber}}" [class.no-border-bottom-summary-list]="question.value[1] !== 'No'">
            {{question.label}}
          </dt>
          <dd class="summary-list-value" id="question-answer-{{question.pageNumber}}" [class.no-border-bottom-summary-list]="question.value[1] !== 'No'">
            <p [ngStyle]="setAnswerMargin(i)">
              {{question.value[0]}}
            </p>
          </dd>
          <dd class="summary-list-actions" [class.no-border-bottom-summary-list]="question.value[1] !== 'No'">
          </dd>
        </div>
        <div class="summary-list-row" *ngIf="question.value[1] !== 'No'">
          <dt class="summary-list-key">
            I speak:
          </dt>
          <dd class="summary-list-value">
            <ng-container *ngFor="let value of question.value; let i = index">
              <p *ngIf="i > 0 && value !== 'Yes'" style="margin: 0px;">
                {{value}}
              </p>
            </ng-container>
          </dd>
          <dd class="summary-list-actions"></dd>
        </div>
      </ng-container>
    </ng-container>
  </dl>
</div>