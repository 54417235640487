import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService } from '../assessment.service';
import * as Survey from 'survey-angular';
import * as widgets from 'surveyjs-widgets';
import { MainService } from '../../../core/services/main.service';

widgets.bootstrapslider(Survey);


@Component({
  selector: 'app-view-assessment',
  templateUrl: './view-assessment.component.html',
  styleUrls: ['./view-assessment.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ViewAssessmentComponent implements OnInit, OnDestroy {
  json;
  surveyItem;
  assessmentTemplate;
  assessmentName;
  assessmentTemplateId: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly assessmentService: AssessmentService,
    private readonly mainService: MainService,
  ) {
    this.mainService.pageMainTitle = 'Assessments';
   }
   ngOnDestroy(): void {
    this.mainService.pageMainTitle = '';
  }
  ngOnInit() {
    this.getAssessmentJson();
  }

  getAssessmentJson() {
    this.route.params.subscribe((params: any) => {
      if (params.hasOwnProperty('assessmentTemplateId')) {
        this.assessmentTemplateId = params['assessmentTemplateId'];
        this.assessmentService.getAssessment(this.assessmentTemplateId).subscribe((data: any) => {
          this.assessmentTemplate = data;
          this.assessmentName = this.assessmentTemplate.assessmentName;
          this.json = JSON.parse(this.assessmentTemplate.questionJson.replace(/"isRequired":true/g, '"isRequired":false'));
          this.surveyItem = new Survey.Model(this.json).completedHtml;
        },
          err => console.error(err),
          () => this.renderDummyAssessment()
        );
      }
    });
  }

  renderDummyAssessment() {

    const defaultThemeColorsEditor = Survey
    .StylesManager
    .ThemeColors['default'];
    defaultThemeColorsEditor['$primary-color'] = '#2b769b';
    defaultThemeColorsEditor['$secondary-color'] = '#2b769b';
    defaultThemeColorsEditor['$main-color'] = '#2b769b';
    Survey.StylesManager.applyTheme();

    Survey.SurveyNG.render('surveyElement', {
      model: this.surveyItem,
      showNavigationButtons: true,
      showTitle: false,
      showProgressBar: 'none'
    });
  }

}
