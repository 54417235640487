import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MainService } from './../../../../core/services/main.service';
import { ValidationService } from './../../../../shared/components/form-control/validation.service';
import { InductionQuestionnaireService } from './../../induction-questionnaire/induction-questionnaire.service';
import { INDUCTION_QUESTIONS_VALIDATION_CONTEXT } from './../induction-questions-list.validation';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';
import { ConfirmService } from "../../../../shared/components/confirm-box/confirm-box.service";

const PAGETWOFORM_VALIDATORS_SAVE = {
  mathsScore: [Validators.required],
  englishScore: [Validators.required]
}
@Component({
  selector: 'vc-page-two',
  templateUrl: './page-two.component.html',
  styleUrls: ['./page-two.component.scss']
})
export class PageTwoComponent implements OnInit, AfterViewChecked {

  currentPageNumber: number;
  currentPageData = [] as any;
  refData = [] as any;
  routeState: any;

  pageTwoForm: FormGroup;
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-one';
  routeNext = '/plan/induction/page-three';
  isAssessmentInProgressFlag = false;

  @ViewChild("question") questionDiv: ElementRef;

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly mainService: MainService,
    private readonly formBuilder: FormBuilder,
    private readonly validation: ValidationService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly togetherMode: TogetherModeModalService,
    private readonly confirm: ConfirmService,
  ) {
    
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.initForm();
    this.validation.setContext(INDUCTION_QUESTIONS_VALIDATION_CONTEXT);
  }

  ngOnInit() {
    this.getRefData();
    this.resolveRouteState();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.pageTwoForm.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  initForm() {
    this.pageTwoForm = this.formBuilder.group({
      mathsScore: this.formBuilder.control(''),
      englishScore: this.formBuilder.control('')
    })
  }

  canExit(route, state, nextState) {
    if (this.pageTwoForm.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  onClickContinue() {
    this.setValidators(this.pageTwoForm, PAGETWOFORM_VALIDATORS_SAVE);
    if (this.pageTwoForm.valid) {
      const formattedPayload = this.formatPayload(false);
      this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
        this.isSaveSubmitOperation = true;
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-three');
        window.scrollTo(0, 0);
      })
    } else {
      this.setFocusToErrorSummary();
    }
  }

  onClickSave() {
    const formattedPayload = this.formatPayload(true);
    this.inductionQuestionnaireService.createInductionPlan(formattedPayload).subscribe(result => {
      this.isSaveSubmitOperation = true;
      this.router.navigateByUrl(this.routeTo);
      window.scrollTo(0, 0);
    })
  }

  formatPayload(saveAsDraft) {
    return {
      "isSaveAsDraft": saveAsDraft,
      "planTypeIdentifier": "1",
      "questionDTOList": [
        {
          "answer": this.pageTwoForm.controls.mathsScore.value,
          "id": 3
        },
        {
          "answer": this.pageTwoForm.controls.englishScore.value,
          "id": 4
        },
      ],
      "sectionIdentifier": this.currentPageData[0].sectionIdentifier
    }
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      data.forEach(question => {
        this.currentPageData.push(
          {
            question: question.questionDescription,
            type: question.questionTypeIdentifier ? this.inductionQuestionnaireService.resolveQuestionType(question.questionTypeIdentifier, this.refData) : null,
            answersLabels: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersLabels(question.domainIdentifier, this.refData) : null,
            answersValues: question.domainIdentifier ? this.inductionQuestionnaireService.resolveQuestionAnswersValues(question.domainIdentifier, this.refData) : null,
            sectionIdentifier: question.sectionIdentifier
          }
        )
      });
      this.pageTwoForm.controls.mathsScore.setValue(data[0].answer ? data[0].answer : '');
      this.pageTwoForm.controls.englishScore.setValue(data[1].answer ? data[1].answer : '');
      this.setFocusToQuestionDiv();
    })
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.currentPageNumber = 2;
    this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }

  setFocusToQuestionDiv() {
    this.questionDiv.nativeElement.focus();
  }

}
