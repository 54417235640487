import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { EducationService } from '../../../../cv-builder/guided-cv-builder/education/education.service';
import { ValidationService } from '../../../../../shared/components/form-control/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InductionQuestionnaireService } from '../../../induction-questionnaire/induction-questionnaire.service';
import { ConfirmService } from '../../../../../shared/components/confirm-box/confirm-box.service';
import { PageFiveService } from '../page-five.service';

export interface IRefData {
  id: number,
  identifier: string,
  description: string,
}

const VALIDATORS = {
  hasAddedValue: [ValidationService.ifChecked]
}

const EDUCATIONFORM_VALIDATORS_SAVE = {
  courseStartDate: [Validators.required, ValidationService.monthYearValidator, ValidationService.futureDate, ValidationService.pastYear(80)],
  courseEndDate: [ValidationService.monthYearValidator,
  ValidationService.invalidEndDateOrCurrent('courseStartDate', 'courseEndDate', 'isStudying'),
  ValidationService.invalidEndDateAndCurrent('courseEndDate', 'isStudying'),
  ValidationService.futureDate],
  institute: [Validators.required, Validators.maxLength(100)],
  courseTitle: [Validators.required, Validators.maxLength(100)],
  otherEducationType: [Validators.required, Validators.maxLength(200), Validators.pattern('[a-zA-Z0-9\\-\\s\&\(\)\/\.\'\,\"\:\;]*')],
  grade: [Validators.maxLength(100)],
  educationType: [Validators.required]
}


@Component({
  selector: 'vc-plan-education',
  templateUrl: './plan-education.component.html',
  styleUrls: ['./plan-education.component.scss']
})
export class PlanEducationComponent implements OnInit {

  @Input('educationDetails') public eduList;
  @Output() refreshList = new EventEmitter<any>();
  @Output() refreshFormFields = new EventEmitter<any>();

  

  educationForm: FormGroup;
  educationTypes: IRefData[] = [];
  educationList = [] as any;
  isDialogOpen = false;
  routeTo = '/plan/induction/page-five';
  isEditEducationClicked: boolean = false;

  constructor(
    private readonly educationService: EducationService,
    private readonly route: ActivatedRoute,
    private readonly fBuilder: FormBuilder,
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly confirm: ConfirmService,
    private readonly router: Router,
    private readonly pageFiveService: PageFiveService
  ) { }

  ngOnInit() {}

  deletePlanEducation(id) {
    this.confirm.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete an entry?',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        sessionStorage.setItem('isAssessmentInProgress', 'true');
        this.isDialogOpen = false;
        this.pageFiveService.deleteEducation(id).subscribe(Response => {
          this.refreshList.emit({});
          this.refreshFormFields.emit({});
          this.router.navigate(['.'], {relativeTo: this.route});
        });
      },
      reject: () => {
        this.isDialogOpen = false;
      }
    });
    return this.confirm.navigateSelection;
  }

  EditPlanEducation(id){
    this.isEditEducationClicked = true;
    this.router.navigate([this.routeTo], { queryParams: { id: id }, relativeTo: this.route });
  }
}
