<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30 mr15">
    <p class="number-of-pages">Page 7 of 13</p>
    <vc-all-errors [groups]="[pageSevenForm]" [fromContext]="true"></vc-all-errors>
    <div class="error-summary" *ngIf="goals.length === 0 && continueValidation === true">
        <h2 class="error-summary-title" id="error-summary-title">There is a problem</h2>
        <div class="error-summary-body">
            <ul class="list error-summary-list">
                <li>Please add at least one entry</li>
            </ul>
        </div>
    </div>
    <legend class="question" #question tabindex="0">{{currentPageData[0]?.questionDescription}}</legend>
    <div class="form-hint mb30">Please add up to 3 goals</div>
    <div class="grid-row">
        <table>
            <tbody>
                <tr *ngFor="let goal of goals; let i = index">
                    <td class="goal-column" [class.active]="selectedIndex === i"><span>{{goal.employementGoal}}</span></td>
                    <td>
                        <span>
                            <a href="javascript: void(0)" class="delLink link" (click)="edit(goal.id); setIndex(i); updateAssessmentInProgressFlag();">Edit</a>
                        </span>
                    </td>
                    <td>
                        <span>
                            <a href="javascript: void(0)" (click)="delete(goal.id); updateAssessmentInProgressFlag();" class="delLink link">Delete</a>
                        </span>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <form [formGroup]="pageSevenForm" (submit)="addGoal()" *ngIf="goals.length < 3 || isEditActive">
        <div class="grid-row">
            <div class="goal-input">
                <vc-form-control [control]="pageSevenForm.get('goal')" [controlName]="'goal'" [label]="''"
                    [fromContext]="true">
                    <input formControlName="goal" [ngClass]="{'errorfocus': pageSevenForm.get('goal')?.errors }"
                        class="form-control" type="text" id="id-goal" rows="5" name="goal" value=""
                        aria-describedby="id-section-help-goal id-section-error-goal" spellcheck="false">
                    <div *ngIf="pageSevenForm.get('goal').value?.length <= 100" class="form-hint mt10">
                        You have {{ 100 - pageSevenForm.get('goal').value?.length }} characters remaining
                    </div>
                    <div *ngIf="pageSevenForm.get('goal').value?.length > 100" class="error-message mt10">
                        You have {{ pageSevenForm.get('goal').value?.length - 100 }} characters too many
                    </div>
                </vc-form-control>
            </div>
            <button id="id-add" class="button button-secondary add-button-align" type="submit" name="add" *ngIf="!isEditActive">Add</button>
            <button id="id-save" class="button button-secondary add-button-align" type="submit" name="add" *ngIf="isEditActive">Save</button>
        </div>
    </form>
    <div class="grid-row alignLeft">
        <button id="id-continue" class="button button-link ml0" type="button" (click)="continue()"
            name="continue">Continue</button>
        <button *ngIf="routeState !== 'changeAnswer'" id="id-save-as-draft" class="button button-secondary" type="button" name="save-as-draft"
            (click)="saveDraft()">Save as draft</button>
    </div>
</div>