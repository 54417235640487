export const ENTER_TOGETHER_MODE_VALIDATION_CONTEXT = {

	required: {
		username: 'Enter your username',
    pass: 'Enter your password',
  },
  invalidCredentials: {
    username: 'Confirm and enter your username',
    pass: 'Confirm and enter your password'
  }
  
}
