<ng-http-loader spinner="sk-rotationg-plane" [backgroundColor]="'#00823b'"
    [filteredUrlPatterns]="['/*']">
</ng-http-loader>
<vc-confirm-box></vc-confirm-box>
<vc-together-mode-modal [style.display]="togetherModeModalVisible ? 'block' : 'none'"></vc-together-mode-modal>
<vc-header></vc-header>
<vc-main>
    <vc-notification></vc-notification>
    <vc-banner></vc-banner>
    <router-outlet></router-outlet>
</vc-main>
<vc-footer></vc-footer>
<vc-file-util [style.display]="'none'"></vc-file-util>