import { Component, OnInit, Renderer2 } from '@angular/core';
import { MainService } from '../../../core/services/main.service';
import { ActivatedRoute } from '@angular/router';
import { EmploymentService } from '../employment.service';
import { Location } from '@angular/common';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'vc-jobs-for-prison-leavers',
  templateUrl: './jobs-for-prison-leavers.component.html',
  styleUrls: ['./jobs-for-prison-leavers.component.scss']
})
export class JobsForPrisonLeaversComponent implements OnInit {

  featuredNFNJobs: any[];
  page: number = 0;
  size: number = 10;
  sort: any = 'createdDate,desc';
  maxSize: number = 10;
  nfnJobList: any[];
  NFNsearchResult:  any[];
  first: Boolean = false;
  last: Boolean = false;
  totalItems: number = 0;
  hideInActive: any = true;
  noresults: boolean = false;
  nfnSearchJob: FormGroup;
  filteredLocations: any;
  allLocationsList: any;
  isPageChange: boolean;
  constructor(
    private readonly fb: FormBuilder,
    private readonly mainservice: MainService,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly employmentService: EmploymentService,
    private readonly renderer: Renderer2
  ) {
    this.mainservice.pageTitle = this.route.snapshot.data.title;
    this.mainservice.pageMainTitle = this.route.snapshot.data.mainTitle;
    this.getNFNLocations();
    this.employmentService.getFeaturedNFNJobs().subscribe(response => {
      this.featuredNFNJobs = response;
    })
  }

  ngOnInit() {
    this.initForm();
    this.getnfnJobList(this.page, this.size, this.sort, this.hideInActive);
    this.renderer.addClass(document.body, 'changeCaseOfTitle');
  }
  ngOnDestroy(): void {
    this.mainservice.pageMainTitle = '';
    this.renderer.removeClass(document.body, 'changeCaseOfTitle');
  }

  pageChange(page: number) {
    this.page = page - 1;
    const data = this.nfnSearchJob.getRawValue();
    if (!data.what && !data.where) {
      this.getnfnJobList((page - 1), this.size, this.sort, this.hideInActive);
    } else {
      this.isPageChange = true;
      this.goToSearch();
    }
    window.scrollTo(0, 0);
  }

  getnfnJobList(page: any, size: any, sort: any, hideInActive: any) {
    this.employmentService.getNFNJobList(page, size, sort, hideInActive).subscribe(response => {
      this.filterResponse(response);
    })
  }

  filterResponse(response: any) {
    this.nfnJobList = response.content;
    this.totalItems = response.totalElements;
    this.first = response.first;
    this.last = response.last;
  }

  back() {
    this.location.back();
  }

  getNFNLocations() {
    this.employmentService.getNFNJobLocation().subscribe(response => {
      this.allLocationsList = response;
    });
  }

  initForm() {
    this.nfnSearchJob = this.fb.group({
      what: [''],
      where: ['']
    });

  }

  onKeyJob(event: any) {
    const data = this.nfnSearchJob.getRawValue();
    if (!data.what && !data.where) {
      this.page = 0;
      this.getnfnJobList(this.page, this.size, this.sort, this.hideInActive);
    }
  }

  onKeyLocation(event: any) {
    const val = event.target.value;
    const data = this.nfnSearchJob.getRawValue();
    if (!data.what && !data.where) {
      this.page = 0;
      this.getnfnJobList(this.page, this.size, this.sort, this.hideInActive);
    }
    this.filteredLocations = this.findOption(val);
  }

  findOption(val: any) {
    if (val && this.allLocationsList) {
      if (val.description) {
        val = val.description
      }
      return this.allLocationsList.filter(option =>
        option.description.toLowerCase().indexOf(val.toLowerCase()) === 0);
    }
    return null;
  }

  goToSearch() {
    const data = this.nfnSearchJob.getRawValue();
    this.refreshPage();
    this.employmentService.searchNFNJobs(data, this.page, this.size, this.sort,
      this.hideInActive).subscribe(response => {
        this.filterResponse(response);
        this.NFNsearchResult = response.content;
        (this.NFNsearchResult && this.NFNsearchResult.length > 0) ? this.noresults = false : this.noresults = true;  
      })
  }

  refreshPage() {
    if (!this.isPageChange && this.page !== 0) {
      this.page = 0;
    }
  }
}
