import { Component, OnInit, AfterViewInit, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AssessmentService } from './assessment.service';
import { IAssessmentTemplate } from './assessment.interface';
import { MainService } from '../../core/services/main.service';
import { Location } from '@angular/common';
import { WalletService } from '../wallet/wallet.service';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})

export class AssessmentComponent implements OnInit, OnDestroy {
  public items: any[];
  completedAssessmentsSource: any[];
  toDoAssessmentsSource: any[];
  page = 1;
  public pageSize = 10;
  maxSize = 10;
  public showTextField: boolean;
  public isActiveObject: object;
  publishToAll = true;
  displayedColumns: string[] = ['assessmentName', 'createdDate', 'modifiedDate', 'status'];
  dataSource: IAssessmentTemplate[];
  allAssessments = false;
  toDoAssessments = false;
  completedAssessments = false;
  todo = 'todo';
  completed = 'completed';
  all = 'all';
  status: any;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly locations: Location,
    private readonly _assessmentService: AssessmentService,
    private readonly mainService: MainService,
    private readonly walletService: WalletService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = 'Wallet';
  }
  ngOnDestroy(): void {
    this.mainService.pageMainTitle = '';
  }
  ngOnInit() {
    this.walletService.updateNotificationCount({ partitionKey: "Assessment" })
    .subscribe(data => { 
      this.walletService.emitWalletNotification(data.walletFlag);
    });
    this.route.queryParams.subscribe((params: any) => {
      this.status = params.status;
    });
    if (this.status === this.all) {
      this.getAllAssessments();
    } else if (this.status === this.completed) {
      this.getCompletedAssessments();
    } else {
      this.getToDOAssessments();
    }
  }

  getAllAssessments() {
    this.page = 1;
    this.allAssessments = true;
    this.completedAssessments = false;
    this.toDoAssessments = false;
    this.router.navigate([], { queryParams: { status: this.all, page: this.page } });
    this._assessmentService.getAssessmentsTable().subscribe(
      data => {
        this.dataSource = data;
        this.items = data;
        this.maxSize = this.dynamicMaxSizeBasedOnPage(this.page - 1, this.items.length, this.pageSize);
      },
    );
    this.route.queryParams.subscribe((params: any) => {
      this.page = params.page ? params.page : this.page;
    });
  }
  getToDOAssessments() {
    this.page = 1;
    this.toDoAssessments = true;
    this.allAssessments = false;
    this.completedAssessments = false;
    this.router.navigate([], { queryParams: { status: this.todo, page: this.page } });
    this._assessmentService.getAssessmentsTable().subscribe(
      data => {
        this.dataSource = data;
        this.items = this.dataSource.filter
          (item => item.isCompleted === false && (item.isPartiallyCompleted === true || item.updatedDate === null));
        this.maxSize = this.dynamicMaxSizeBasedOnPage(this.page - 1, this.items.length, this.pageSize);
      },
    );
    this.route.queryParams.subscribe((params: any) => {
      this.page = params.page ? params.page : this.page;
    });
  }
  getCompletedAssessments() {
    this.page = 1;
    this.completedAssessments = true;
    this.toDoAssessments = false;
    this.allAssessments = false;
    this.router.navigate([], { queryParams: { status: this.completed, page: this.page } });
    this._assessmentService.getAssessmentsTable().subscribe(
      data => {
        this.dataSource = data;
        this.items = this.dataSource.filter(item => item.isCompleted === true);
        this.maxSize = this.dynamicMaxSizeBasedOnPage(this.page - 1, this.items.length, this.pageSize);
      },
    );
    this.route.queryParams.subscribe((params: any) => {
      this.page = params.page ? params.page : this.page;
    });
  }


  pageChange(page) {
    this.page = page;
    this.route.queryParams.subscribe(params => {
      this.status = params.status;
    });
    if (this.status === this.todo) {
      this.pageChangeMethod2(this.page, this.status);
    } else if (this.status === this.completed) {
      this.pageChangeMethod2(this.page, this.status);
    } else {
      this.router.navigate([], {
        queryParams: {
          status: this.status,
          page: this.page
        }
      });
    }
    this.maxSize = this.dynamicMaxSizeBasedOnPage(this.page - 1, this.items.length, this.pageSize);
  }

  pageChangeMethod2(page, status) {
    this.router.navigate([], {
      queryParams: {
        status: this.status,
        page: this.page
      }
    });
  }

  dynamicMaxSizeBasedOnPage(currentPage, totalItems, size): number {
    const totalPages = Math.ceil(totalItems / size);
    if (currentPage <= 3 || currentPage >= (totalPages - 4)) {
      return 10;
    } else if ((currentPage - 6) < 1) {
      return 10 + (currentPage - 3);
    } else if (currentPage < (totalPages - 4) && currentPage > (totalPages - 7)) {
      return 10 + (totalPages - 4) - currentPage;
    } else {
      return 13;
    }
  }
  backClicked() {
   this.router.navigate(['wallet']);
  }
}
