import { Component, OnInit, Renderer2 } from '@angular/core';
import { MainService } from '../../../core/services/main.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EmploymentService } from '../employment.service';

@Component({
  selector: 'vc-prison-jobs',
  templateUrl: './prison-jobs.component.html',
  styleUrls: ['./prison-jobs.component.scss']
})
export class PrisonJobsComponent implements OnInit {

  page: number = 0;
  size: number = 10;
  sort: any = 'createdDate,desc';
  maxSize: number = 10;
  prisonJobList: any[];
  first: Boolean = false;
  last: Boolean = false;
  totalItems: number = 0;
  hideInActive: any = true;
  constructor(
    private readonly mainservice: MainService,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly employmentService: EmploymentService,
    private readonly renderer: Renderer2
  ) {
    this.mainservice.pageTitle = this.route.snapshot.data.title;
    this.mainservice.pageMainTitle = this.route.snapshot.data.mainTitle;
    this.renderer.addClass(document.body, 'changeCaseOfTitle');
  }

  ngOnInit() {
    this.getPrisonJobList(this.page, this.size, this.sort, this.hideInActive);
  }
  ngOnDestroy(): void {
    this.mainservice.pageMainTitle = '';
    this.renderer.removeClass(document.body, 'changeCaseOfTitle');
  }
  pageChange(page: number) {
    this.page = page - 1;
    this.getPrisonJobList((page - 1), this.size, this.sort, this.hideInActive);
    window.scrollTo(0, 0);
  }
  getPrisonJobList(page: any, size: any, sort: any, hideInActive: any) {
    this.employmentService.getPrisonJobList(page, size, sort, hideInActive).subscribe(data => {
      this.prisonJobList = data.content;
      this.totalItems = data.totalElements;
      this.first = data.first;
      this.last = data.last;
    })
  }

  back() {
    this.location.back();
  }

}
