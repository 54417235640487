<div id="applied-jobs">
	<h2 class="job-app">Jobs for prison leavers</h2>
	<table>
		<caption></caption>
		<thead>
			<tr>
				<table>
					<tr>
						<th scope="colgroup">Date</th>
						<th scope="colgroup">Jobs</th>
						<th scope="colgroup"></th>
						<th scope="colgroup"></th>
					</tr>
				</table>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let s of expressedJobList | paginate: { itemsPerPage: size, currentPage: (page + 1), totalItems: totalItems }"
				[ngClass]="{'disable': s.expired}">
				<table>
					<tr>
						<td>
							<span>{{s?.dateExpressed | date : 'dd MMMM yyyy'}}</span>
						</td>
						<td>
							<span>
								<a class='link' href="javascript: void(0);"[routerLink]="['/employment/jobs-for-prison-leavers/view-jobs-for-prison-leavers',s?.id]">{{s?.jobTitle}}
									<br /> {{s?.location}}</a>
							</span>
						</td>
						<td>
							<span class="status-tag" [ngStyle]="{'background-color': (s?.status=='DECLINED') ? '#d4351c' : (s?.status=='SUCCESSFUL') ?
								 '#00703c' : (s?.status=='INTERVIEW') ? '#f47738' : (s?.status=='APPLIED') ? '#6f777b' :
								  (s?.status=='UNSUCCESSFUL') ? '#d4351c' : '#1d70b8'}">{{s?.status}}</span>
						</td>
					</tr>
					<tr *ngIf="s?.responseDescription">
						<td colspan="2" class="noPadding">
							<div class="inset-text">
								{{s?.responseDescription}}
							</div>
						</td>
					</tr>
				</table>

			</tr>

			<tr *ngIf="totalItems === 0">
				<p class="tabsMessage">You have not expressed interest in any jobs for prison leavers</p>
			</tr>
		</tbody>
	</table>
	<div class="grid-row" *ngIf="totalItems > size">
		<p></p>
		<pagination-controls [maxSize]="10" (pageChange)="pageChange($event)">
		</pagination-controls>
	</div>
</div>