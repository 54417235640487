<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<vc-all-errors [groups]="[form]" [fromContext]="true"></vc-all-errors>
<div class="computer-skills mt25">

    <div class="grid-row">
        <form [formGroup]="form">
            <div *ngIf="showFlag"  (click)="setValue(isSkillsPresent())" class="column-full">
                <vc-multi-select-options [formGroup]="form.get('skills')" id="id-answers" [hint]="'Please select at least 1 skill'" ></vc-multi-select-options>
            </div>
            <hr *ngIf="showFlag">
            <div *ngIf="model.option=='N' || !showFlag" class="home-yes-no" >
                <label class="form-label-b19"> Do you have any computer skills? </label>
                    <div class="rwrap form-group">
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="form.get('hasAddedValue')" [value]='false' id="id-createAnother-true"
                            name="form.get('hasAddedValue')" (click)="setOption('Y')"
                            [checked]="model.option=='Y'"
                            aria-describedby="id-section-help-createAnother-true id-section-error-createAnother-true" />
                        <label for="id-createAnother-true">Yes</label>
                    </div>
                    <div class="radio-wrapper">
                        <input type="radio" [formControl]="form.get('hasAddedValue')" [value]='true' id="id-createAnother-false"
                            name="form.get('hasAddedValue')" (click)="setOption('N')" [checked]="model.option=='N'"
                            aria-describedby="id-section-help-createAnother-false id-section-error-createAnother-false" />
                        <label for="id-createAnother-false">No</label>
                    </div>
                </div>
                
            </div>
        </form>
    </div>



    <div class="grid-row mb25">
        <button id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue" (click)="save()">Save and continue</button>
        <button id="id-save-and-come-back-later" class="button button-secondary mr15" type="button" name="save-and-come-back-later" (click)="draft()">Save and come back
            later</button>
        <button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary" (click)="cvSummaryPage()">CV summary</button>
    </div>
</div>