import { ConfirmService } from './../../../shared/components/confirm-box/confirm-box.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { EmploymentService } from '../employment.service';

@Component({
  selector: 'vc-job-applied',
  templateUrl: './job-applied.component.html',
  styleUrls: ['./job-applied.component.scss']
})
export class JobAppliedComponent implements OnInit {

  page: number = 0;
  size: number = 10;
  sort: any = 'applicationStatusDate,desc';
  appliedJobList: any[];
  totalItems: number = 0;
  first: Boolean = false;
  last: Boolean = false;
  isDialogOpen: Boolean = false;

  constructor(
    private readonly employmentService: EmploymentService,
    private readonly zone: NgZone,
    private readonly confirmService:  ConfirmService) { }

  ngOnInit() {
    this.fetchAppliedJobList(this.page, this.size, this.sort);
  }

  pageChange(page: number) {
    this.page = page - 1;
    this.fetchAppliedJobList((page - 1), this.size, this.sort);
    window.scrollTo(0, 0);
  }

  fetchAppliedJobList(page, size, sort) {
    this.employmentService.getAppliedJobList(page, size, sort).subscribe(resp => {
      this.appliedJobList = resp.content;
      this.totalItems = resp.totalElements;
      this.first = resp.first;
      this.last = resp.last;
    });
  }

  deleteAppliedJob(jobId: string) {
    this.isDialogOpen = true;
    this.zone.run(() => {
      this.confirmService.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to remove this application?',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.isDialogOpen = false;
          this.employmentService.removeJobApplication(jobId).subscribe(resp => {
            this.page = 0;
            this.fetchAppliedJobList(this.page, this.size, this.sort);
          });
        },
        reject: () => {
          this.isDialogOpen = false;
        }
      });
    });
  }
}
