<div class="relative" id="image-viewer">
    <!-- <ngx-image-viewer  [src]="images | safe" [config]="config" (customEvent)="handleEvent($event)"></ngx-image-viewer> -->
    <img [src]="images | safe" />
    <!-- <button type="button" title="Zoom in" class="btn zoomin"><img src="assets/images/zoom_in.png"></button>
    <button type="button" title="Zoom out" class="btn zoomout"><img src="assets/images/zoom_out.png"></button>
    <button type="button" title="Rotate right" class="btn rotate-right"><img src="assets/images/rotate_right.png"></button>
    <button type="button" title="Rotate left" class="btn rotate-left"><img src="assets/images/rotate_left.png"></button>
    <button type="button" title="Print" class="btn print"><img src="assets/images/print.png"></button>  -->
</div>

<div id="image_print" #image_print style="display: none;" hidden="true"></div>