import { PlanService } from './../plan/plan.service';
import { PipesModule } from './../../core/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRoutingModule } from './content-routing.module';
import { ContentComponent } from './content.component';
import { ContentCardModule } from './content-card/content-card.module';
import { ContentService } from './content.service';
import { ContentCategoryComponent } from './content-category/content-category.component';
import { ContentDetailsComponent } from './content-details/content-details.component';
import { ContentModuleComponent } from './content-module/content-module.component';
import { FormsModule } from '@angular/forms';
import { VideoPlayerModule } from '../../shared/components/video-player/video-player.module';
import { DocViewerModule } from '../../shared/components/doc-viewer/doc-viewer.module';
import { SearchResultModule } from '../../shared/components/search-result/search-result.module';
import { ContentSearchComponent } from './content-search/content-search.component';
import { ImageViewModule } from '../../shared/components/image-viewer/image-viewer.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [
    ContentComponent,
    ContentCategoryComponent,
    ContentDetailsComponent,
    ContentModuleComponent,
    ContentSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ContentRoutingModule,
    ContentCardModule,
    VideoPlayerModule,
    DocViewerModule,
    SearchResultModule,
    PipesModule,
    ImageViewModule,
    NgxExtendedPdfViewerModule
  ],
  entryComponents: [
    ContentModuleComponent
  ],
  providers: [ContentService]
})
export class ContentModule { }
