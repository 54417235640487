<div id='view_assessments'>
    <div *ngIf="assessmentTemplate">
        <span> {{assessmentName}}</span>
        <span class="spacer"></span>
        <span>
            <button id="exit_button" mat-stroked-button color="primary" [routerLink]="['../../']">Exit</button>
        </span>
    </div>
    <div class="view_assessments_container">
            <div id="surveyElement"> </div>
    </div>

</div>
