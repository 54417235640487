<a *ngIf="showChangePassword" class="link" (click)="goToProfile()" class="link-back nomargintop">Back</a>
<div class="header">
    <div class="tabWrapper">
        <ul>
            <li 
                routerLink="/user-profile" 
                class="tabHead active" 
            ><a class="tab-item" id="profile-tab" attr.aria-label="tab Profile">Profile</a>
            </li>
            <!-- <li 
                routerLink="/user-profile/plan-induction" 
                class="tabHead" 
            ><a class="tab-item" id="plan-induction-tab" attr.aria-label="tab Plan induction">Plan induction</a>
            </li> -->
        </ul>
    </div>
</div>

<div class="profileWrapper bottom-padding" *ngIf="showChangePassword">
    <app-change-password></app-change-password>
</div>

<div class="profileWrapper" *ngIf="!showChangePassword">
    <div class="grid-row learnerDetails">
        <div class="flex-row">
            <div class="details-container">
                <div class="grid-row flex-row">
                    <label for="title" class="panel-label">Title</label>
                    <span class="panel-content" id="title">{{learner?.title | titlecase}}</span>
                </div>
                <div class="grid-row flex-row">
                    <label for="first name" class="panel-label">Name</label>
                    <span class="panel-content" id="first name">{{learner?.firstName | titlecase}} {{learner?.middleName | titlecase}} {{learner?.surName | titlecase}}</span>
                </div>
                <div class="grid-row flex-row">
                    <label for="date of birth" class="panel-label">Date of birth</label>
                    <span class="panel-content" id="date of birth">{{learner?.dateOfBirth | date: 'dd LLLL yyyy'}}</span>
                </div>
                <div class="grid-row flex-row" *ngIf="learner?.gender">
                    <label for="learner gender" class="panel-label">Gender</label>
                    <span class="panel-content" id="learner gender">{{learner?.gender}}</span>
                </div>
                <div class="grid-row flex-row">
                    <label for="prn" class="panel-label">PRN</label>
                    <span class="panel-content" id="prn">{{learner?.prn}}</span>
                </div>
                <div class="grid-row flex-row">
                    <label for="establishment" class="panel-label">Establishment</label>
                    <span class="panel-content" id="establishment">{{learner?.organizationName}}</span>
                </div>
                <div class="grid-row flex-row" *ngIf="learner?.ethnicity">
                    <label for="ethnicity" class="panel-label">Ethnicity</label>
                    <span class="panel-content" id="ethnicity">{{learner.ethnicity}}</span>
                </div>
            </div>
        <div>
            <span class="icon-user big-icon"></span>
        </div>
    </div>
    <div class="grid-row">
        <vc-accordion [learner]="learner" [courseSize]="learner?.courseList?.length" [lddSize]="learner?.lldAndHealthProblemList?.length"></vc-accordion>
    </div>
    <!-- <div class="accordion-divider" *ngIf="!learner?.uln"></div>
    <div class="grid-row">
        <button class="change-password-link" (click)="goToChange()">Change my password</button>
    </div> -->
</div>