<div class="together-mode-modal-background">
  <div class="modal-container">
    <div class="together-mode-modal">
      <span class="top-panel"></span>
      <div class="content-container">
        <h1 title="Enter together mode">Enter together mode</h1>
        <vc-all-errors *ngIf="!invalidCredentials" [groups]="[enterTogetherModeForm]" [fromContext]="true"></vc-all-errors>
        
        <div *ngIf="invalidCredentials" class="error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
          data-module="error-summary">
          <h2 class="error-summary-title" id="error-summary-title">
            There is a problem
          </h2>
          <div class="error-summary-body">
            <ul class="list error-summary-list">
              <li >
                <a href="javascript:void(0)" (click)="setFocusToUsernameField()">Incorrect username or password entered</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="moj-banner-warning" *ngIf="backendWarningMessage">
          <svg class="moj-banner-icon" fill="currentColor" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" height="25" width="25">
          <path d="M13.6,15.4h-2.3v-4.5h2.3V15.4z M13.6,19.8h-2.3v-2.2h2.3V19.8z M0,23.2h25L12.5,2L0,23.2z" /></svg>
          <div class="moj-banner-message">
          <span class="moj-banner__assistive">Warning</span>{{ backendWarningMessage }}</div>
        </div> 

        <form 
          [formGroup]="enterTogetherModeForm" 
          autocomplete="off" 
          (ngSubmit)="submitEnterTogetherModeForm()"
          class="margin-top"
        >
          <vc-form-control 
            [control]="enterTogetherModeForm.get('username')" 
            [controlName]="'username'" 
            [label]="'Username'" 
            [fromContext]="true"
          >
            <input 
              formControlName="username" 
              [ngClass]="{'errorFocus': enterTogetherModeForm.get('username')?.errors }" 
              class="form-control left" 
              type="text" 
              id="id-username" 
              name="serviceUserUsername" 
              spellcheck="false" 
              type="text"
              #username
              autocomplete="new-password"
            >
          </vc-form-control>
          <vc-form-control 
            [control]="enterTogetherModeForm.get('pass')" 
            [controlName]="'pass'" 
            [label]="'Password'" 
            [fromContext]="true"
          >
            <input 
              formControlName="pass" 
              [ngClass]="{'errorFocus': enterTogetherModeForm.get('pass')?.errors }" 
              class="form-control left" 
              type="text" 
              id="id-pass" 
              name="serviceUserPassword" 
              spellcheck="false" 
              type="password"
              autocomplete="new-password"
            >
          </vc-form-control>
          <div class="actions-container">
            <button class="button button-primary margin-right" type="submit" id="submit-together-mode-form-button">Enter</button>
            <button class="button button-secondary" (click)="closeTogetherModeModal()" type="button" id="close-together-mode-modal-button">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>