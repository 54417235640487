<a href="javascript:void(0)" [routerLink]="['/employment/job-details']" [queryParams]="{job: job.id, what: job.job_title, where: job.location_name}">
    <article class="job-card column-fifth subcategory mb15">
        <figure class="job-card-wrapper">
            <img src='/assets/images/Featured-Jobs.jpg' alt="Featured Jobs" />
            <figcaption class="jobinfo">
                <h1 class="position">{{job.job_title | truncate:[34, '...'] }} </h1>
                <p class="location">{{job.location_name}}</p>
            </figcaption>
        </figure>
    </article>
</a>