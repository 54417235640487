import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { InductionQuestionnaireService } from '../../induction-questionnaire/induction-questionnaire.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '../../../../shared/components/form-control/validation.service';
import { PERSONAL_ATTRIBUTES_VALIDATION_CONTEXT } from './page-eleven.validation';
import { MainService } from '../../../../core/services/main.service';
import { ConfirmService } from '../../../../shared/components/confirm-box/confirm-box.service';
import { Utility } from '../../../../shared/utils/utility';
import { PageElevenService } from './page-eleven.service';
import { TogetherModeModalService } from '../../../../shared/components/together-mode-modal/together-mode-modal.service';


const PERSONAL_ATTRIBUTES_VALIDATORS = {
  answers: [ValidationService.minSelected(3), ValidationService.maxSelected(3)],
}

@Component({
  selector: 'vc-page-eleven',
  templateUrl: './page-eleven.component.html',
  styleUrls: ['./page-eleven.component.scss']
})
export class PageElevenComponent implements OnInit, AfterViewChecked {

  multiselectform: FormGroup;
  currentPageNumber: number;
  currentPageData = [] as any;
  ps: any = [];
  questions: any[];
  refData: any = [];
  public isCompleted: any;
  isSaveSubmitOperation: boolean = false;
  routeTo = '/plan';
  routeBack = '/plan/induction/page-ten';
  routeNext = '/plan/induction/page-twelve';
  isAssessmentInProgressFlag = false;

  routeState: any;

  constructor(
    private readonly inductionQuestionnaireService: InductionQuestionnaireService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly main: MainService,
    private readonly validationService: ValidationService,
    private readonly confirm: ConfirmService,
    private readonly pageElevenService: PageElevenService,
    private readonly togetherMode: TogetherModeModalService,
  ) {
    this.validationService.setContext(PERSONAL_ATTRIBUTES_VALIDATORS);
    this.main.pageTitle = 'Induction';
    this.multiselectform = this.formBuilder.group({
      questions: this.formBuilder.array([])
    });
   }

  ngOnInit() {
    this.getRefData();
    this.resolveRouteState();
    this.isSaveSubmitOperation = false;
    sessionStorage.setItem('isAssessmentInProgress', 'false');
  }

  ngOnDestroy(){
    sessionStorage.removeItem('routeState');
  }

  ngAfterViewChecked() {
    if (this.multiselectform.dirty && this.isAssessmentInProgressFlag === false) {
      this.isAssessmentInProgressFlag = true;
      sessionStorage.setItem('isAssessmentInProgress', 'true');
    }
  }

  resolveRouteState() {
    if (sessionStorage.getItem('routeState')) {
      this.routeState = sessionStorage.getItem('routeState')
    }
  }

  resolveCurrentPageData(questionNumber) {
    this.inductionQuestionnaireService.getQuestion(questionNumber).subscribe(data => {
      this.ps = data;
      this.ps.forEach(question => {
        const domainRef = Utility.getObjectFromArrayByKeyAndValue(this.refData.domainList, 'identifier', question.domainIdentifier);
        question.answers = domainRef.choiceList.map(answer => {
          return {
            id: answer.choiceId,
            text: answer.choiceDescription,
            checked: false,
          }
        });
        const qf = this.getSingleQuestionForm(question);
        question.answers.forEach(answer => {
          (qf.get('answers') as FormArray).push(this.getSingleAnswerForm(answer));
        });
        (this.multiselectform.get('questions') as FormArray).push(qf);
        if(question.answer != null && JSON.parse(question.answer).hasOwnProperty('choiceIds')){
          this.resolveSelectedSkills(qf, JSON.parse(question.answer).choiceIds);
        } else{
          this.resolveSelectedSkills(qf, []);  
        }
      });
    })
  }

  getRefData() {
    this.inductionQuestionnaireService.getRefData().subscribe(data => {
      this.refData = data;
      this.currentPageNumber = 10;
      this.resolveCurrentPageData(this.currentPageNumber);
    })
  }

  resolveSelectedSkills(question: FormGroup, ids: any[]) {
    if(ids){
      (question.get('answers') as FormArray).controls.forEach(each => {
          if (ids.includes(each.get('id').value)) {
            each.get('checked').setValue(true);
          }
        });
      }
  }

  getSingleQuestionForm(data: any) {
    return this.formBuilder.group({
      id: this.formBuilder.control(data.id),
      question: this.formBuilder.control(data.questionDescription),
      answers: this.formBuilder.array([])
    });
  }

  getSingleAnswerForm(data: any) {
    const form =  this.formBuilder.group({
      id: this.formBuilder.control(data.id),
      text: this.formBuilder.control(data.text),
      checked: this.formBuilder.control(data.selected)
    });
    form.markAsTouched();
    return form;
  }

  processChecked(data, i) {
    (this.multiselectform.get('questions') as FormArray).controls[i].get('answers').setValue(data);
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        if (control) {
          control.setValidators(validators[name]);
          control.updateValueAndValidity();
          control.markAsTouched();
        }
      }
    });
  }

  save(form: FormGroup) {
    this.isSaveSubmitOperation = true;
    this.setValidator(form);
    const dataToSave = this.formatPayload(false);
    if (form.valid) {
      this.inductionQuestionnaireService.createInductionPlan(dataToSave).subscribe(data => {
        this.routeState === 'changeAnswer' ? this.router.navigateByUrl('/plan/induction/check-answers') : this.router.navigateByUrl('/plan/induction/page-twelve');
      });
    }
    window.scrollTo(0, 0);
  }

  draft(form) {
    this.isSaveSubmitOperation = true;
    const dataToSave = this.formatPayload(true);
    this.inductionQuestionnaireService.createInductionPlan(dataToSave).subscribe(data => {
      this.router.navigateByUrl('/plan');
    });

  }

  canExit(route, state, nextState) {
    if (this.multiselectform.dirty &&
      this.togetherMode.getTogetherModeEnabledStatus() &&
      !this.isCompleted &&
      this.isSaveSubmitOperation === false) {
      this.confirm.confirm({
        message: 'Please save your progress before exiting induction',
        header: 'Progress not saved',
        showClose: false,
      });
      return this.confirm.navigateSelection;
    }
    return true;
  }

  formatPayload(saveAsDraft) {
    const questions = this.multiselectform.get('questions') as FormArray;
    const questionDTOList = [];
    for (let index = 0; index < questions.length; index++) {
      const question = questions.at(index);
      questionDTOList[index] = {
        id: question.get('id').value,
        answer: JSON.stringify({
          choiceIds: question.get('answers').value.filter(each => each.checked)
           .map(answer => answer.id)
        })
      }
    }
    return {
      isSaveAsDraft: saveAsDraft,
      planTypeIdentifier: '1',
      questionDTOList: questionDTOList,
      sectionIdentifier: this.ps[0].sectionIdentifier
    }
    
  }

  clearAllValidatorsAndErrors(form: FormArray): void {
    for (let index = 0; index < form.length; index++) {
      const element = form.at(index) as FormGroup;
      this.clearFormGrp(element);
    }

  }

  clearFormGrp(form: FormGroup) {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  private setValidator(form: FormGroup) {
    const questions = form.get('questions') as FormArray;
    for (let index = 0; index < questions.length; index++) {
      const element = questions.at(index);
      element.get('answers').setValidators([ValidationService.minSelected(3), ValidationService.maxSelected(3)]);
      element.get('answers').updateValueAndValidity();
    }
  }

  backClicked() {
    this.router.navigateByUrl(this.routeBack);
    this.isSaveSubmitOperation = true;
  }

  get errors() {
    const err = (this.multiselectform.get('questions') as FormArray).controls;
    return err;
  }

}
