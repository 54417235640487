<div class="personal-details mt25">
	<div class="grid-row">
		<form [formGroup]="paidForm" autocomplete="off">

			<div class="column-full">
				<vc-form-control [control]="paidForm.get('employerName')" [controlName]="'employerName'"
					[label]="'Organisation / Employer'" [fromContext]="true">
					<input formControlName="employerName" class="form-control" type="text" id="id-employerName"
						name="employerName" aria-describedby="id-section-help-employerName id-section-error-employerName">
				</vc-form-control>
			</div>

			<div class="column-full">
				<vc-form-control [control]="paidForm.get('jobTitle')" [controlName]="'jobTitle'" [label]="'Job title'" [fromContext]="true">
					<input formControlName="jobTitle" class="form-control" type="text" id="id-jobTitle" name="jobTitle"
						aria-describedby="id-section-help-jobTitle id-section-error-jobTitle" (keyup)="onKeyJob($event)" list="jobtitles">	
				</vc-form-control>
				<datalist id="jobtitles" >
					<option *ngFor="let job of jobTitle; let index;" value="{{job}}">{{job}}</option>
				</datalist>
			</div>

			<div class="column-full">
				<vc-month-form-control [control]="paidForm.get('jobStartDate')" [controlName]="'jobStartDate'"
					id="id-jobStartDate" [label]="'Start date'" [fromContext]="true">
				</vc-month-form-control>
			</div>

			<div class="column-full">
				<vc-month-form-control [control]="paidForm.get('jobEndDate')" [controlName]="'jobEndDate'" id="id-jobEndDate"
					[label]="'End date'" [fromContext]="true">
				</vc-month-form-control>
			</div>

			<div class="column-full">
				<vc-form-control [control]="paidForm.get('isJobCurrent')" [controlName]="'isJobCurrent'" [fromContext]="true">
					<div class="checkbox-wrapper">
						<input id="id-isJobCurrent-check" name="isJobCurrent" type="checkbox" formControlName="isJobCurrent">
						<label class="form-label-b19" for="id-isJobCurrent-check">
							Current employment
						</label>
					</div>
				</vc-form-control>
			</div>

			<div class="column-full">
				<vc-multi-select-options [formGroup]="paidForm.get('skillDescription')" id="id-answers"
					[hint]="'Please select 3 options'" [restrictMax]="true" [max]="3"></vc-multi-select-options>
			</div>

			<div class="column-full" *ngIf="paidForm.get('otherSkillDescription')" >
				<vc-form-control [control]="paidForm.get('otherSkillDescription')" [controlName]="'otherSkillDescription'" [label]="'Please specify other skills'" [fromContext]="true">
					<textarea formControlName="otherSkillDescription" rows="5" class="form-control" type="text" id="id-otherSkillDescription" name="otherSkillDescription"
						aria-describedby="id-section-help-otherSkillDescription id-section-error-otherSkillDescription" [ngClass]="{'errorfocus': paidForm.get('otherSkillDescription')?.errors }" >
					</textarea>

					<div *ngIf = "paidForm.get('otherSkillDescription').value.length <= 200" class="form-label form-label-count">You have {{200 - paidForm.get('otherSkillDescription').value.length}} characters remaining</div>
					<div *ngIf = "paidForm.get('otherSkillDescription').value.length === 201" class="error-message">You have {{ paidForm.get('otherSkillDescription').value.length - 200}} character too many</div>
					<div *ngIf = "paidForm.get('otherSkillDescription').value.length > 201" class="error-message">You have {{ paidForm.get('otherSkillDescription').value.length - 200}} characters too many</div>
				</vc-form-control>
			</div>

		</form>

		<div class="column-full">
			<vc-form-control [control]="createAnother" [controlName]="'createAnother'"
				[label]="'Do you have any other paid work experience?'" [fromContext]="true">
				<div class="radio-wrapper">
					<input type="radio" [formControl]="createAnother" value="Y" id="id-createAnother-true" name="createAnother"
						aria-describedby="id-section-help-createAnother-true id-section-error-createAnother-true" />
					<label for="id-createAnother-true">Yes</label>
				</div>
				<div class="radio-wrapper">
					<input type="radio" [formControl]="createAnother" value="N" id="id-createAnother-false" name="createAnother"
						aria-describedby="id-section-help-createAnother-false id-section-error-createAnother-false" />
					<label for="id-createAnother-false">No</label>
				</div>
			</vc-form-control>
		</div>
	</div>
	<div class="grid-row mb25">
		<button id="id-save-and-continue" class="button button-link" type="button" name="save-and-continue"
		(click)="saveAndContinue()">Save and
		continue</button>
		<button id="id-save-and-come-back-later" class="button button-secondary mr15" type="button"
			name="save-and-come-back-later" (click)="saveAndLater()">Save and come back
			later</button>
		<button id="id-cv-summary" class="button button-secondary" type="button" name="cv-summary"
			(click)="cvSummaryPage()">CV summary</button>
	</div>
</div>