<div>
    <a [routerLink]="['/content', 'course', item.title, item.contentID]" class="link courseTitle">{{item.title  | truncate:[35, '...']}}</a>
    <p class="description">
        <figure class="result-card-img-wrapper right">
            <img [src]="item.imagePath | safe">
            <figcaption></figcaption>
        </figure>
        {{item.description | truncate:[130, '...'] }}
    </p>
    <div class="actions heart-favourite-container">
        <!-- <a [ngClass]="isFavourite ? 'heart-favourite-true' : 'heart-favourite-false'" aria-hidden="true"
            [title]="isFavourite ? 'Remove from my content' : 'Add to my content'"
            (click)="isFavourite ? removeFromPlan() : addToPlan()"></a>
        <a class="favourite-link" [title]="isFavourite ? 'Remove from my content' : 'Add to my content'"
            (click)="isFavourite ? removeFromPlan() : addToPlan()">{{ isFavourite ? 'Remove from my content' : 'Add to my content' }}</a> -->
    </div>
    <div *ngIf="getContentKeywords(item).length > 0" class="keywords">
        <label>Keywords: </label>
        <div>
            <span *ngFor="let keyword of getContentKeywords(item); let i = index">
                <a class="link" [routerLink]="['/content/search']" [queryParams]="{keyword: keyword.trim()}">
                    {{keyword.trim()}}</a>
            <span *ngIf="i !== (getContentKeywords(item).length - 1)">, </span>
            </span>
        </div>
    </div>
</div>