/* 
	* Validation error messages for paid works page
*/

export const ADDINFO_VALIDATION_CONTEXT = {
	required: {
		isLicenced: 'Do you have a full driving licence?',
		otherHobbies: 'Other hobbies and interests cannot be blank',
	},
	maxlength: {
		otherHobbies: 'Please enter 200 or less characters in other hobbies and interests',
	},
	minSelected: {
		answers: 'What are your hobbies and interests?',
	},
	minInserted: {
		languages: 'What language do you speak?',
  },
  pattern: {
    otherHobbies: 'Other hobbies and interests contains disallowed characters'
  }
}