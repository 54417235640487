<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="clear mt25">

    <vc-all-errors [groups]="multiselectform.get('questions')['controls']" [fromContext]="true"
        [topLabel]="'Please answer all mandatory questions / select 3 options'"></vc-all-errors>

    <form [formGroup]="multiselectform" autocomplete="off">
        <ng-container *ngFor="let item of multiselectform.get('questions')['controls']; let i = index"
            formArrayName="questions">
            <div [formGroupName]="i">
                <vc-multi-select-options [formGroup]="item" id="id-answers-{{i}}" [hint]="'Please select 3 options'" [restrictMax]="true" [max]="3">
                </vc-multi-select-options>
            </div>
        </ng-container>
    </form>
</div>

<div class="grid-row">
    <button id="continue-button" class="button button-link" type="button" name="continue"
        (click)="save(multiselectform)">Save and continue</button>
    <button id="return-button" class="button button-secondary mr15" type="button" name="return"
        (click)="draft(multiselectform)">Save and come back later</button>
    <button id="cv-summary-button" class="button button-secondary" type="button" name="cv-summary"
        (click)="cvSummaryPage()">CV summary</button>
</div>