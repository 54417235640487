import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../../shared/constants/url-constants';


 
 @Injectable()
 export class PageFiveService{

    constructor(private readonly http: HttpClient) { }


    getPlanEducationList() {
        const href = `${Url.PLAN}/education/all/1`;
        return this.http.get<any>(href)
      }
    
      getEducationsTypeList() {
        const href = `${Url.PLAN}/plan/educationTypes`;
        return this.http.get<any>(href);
      }
    
      deleteEducation(id){
        const href = `${Url.PLAN}/education/${id}`;
        return this.http.delete<any>(href);
      }
    
      createPlanEducation(data){
        const href = `${Url.PLAN}/education`;
        return this.http.post<any>(href,data);
      }
    
      editPlanEducation(data){
        const href = `${Url.PLAN}/education`;
        return this.http.put<any>(href,data);
      }
    
      getEducationById(id){
        const href = `${Url.PLAN}/education/${id}`;
        return this.http.get<any>(href);
      }
    

 }