/* 
	* Validation error messages for Generate CV page
*/

export const FILEDETAILS_VALIDATORS_CONTEXT = {
	required: {
		fileName: 'File name cannot be blank'
	},
	pattern: {
		fileName: 'File name must not contain following characters < > : " / \\ | ? \*',
	},
	maxlength: {
        fileName: 'Please enter 30 or less characters in file name',
        description: 'Please enter 100 or less characters in description'
	}
}