/* 
	* Validation error messages for personal details page
*/

export const PERSONAL_STATEMENT_VALIDATION_CONTEXT = {

	minSelected : {
		answers: 'Please answer all the mandatory questions/ select 3 options',
	},
	maxSelected : {
		answers: 'Please answer all the mandatory questions/ select 3 options',
	}
}
