/*
	* Validation messages for Work Experience
*/

export const COMPUTER_SKILLS_VALIDATION_CONTEXT = {
    unChecked: {
        hasAddedValue: `Please select at least one computer skill(s) from the available`
    },
    skillsCheck: {
        hasAddedValue: `Unselect 'I do not have any computer skills' first, before selecting to add computer skills`
    },
}