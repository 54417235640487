import { Injectable } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { NotificationService } from '../../../core/services/notification.service';
import { BehaviorSubject } from 'rxjs';

export class FileEvent {
  url: string;
  state: boolean;
  method: string;

  constructor(url: string, state: boolean, method: ('POST' | 'GET' | 'PUT')) {
    this.url = url;
    this.state = state;
    this.method = method;
  }
}

@Injectable()
export class FileUtilService {

  private readonly _activator: BehaviorSubject<FileEvent> = new BehaviorSubject(null);

  constructor(
    private readonly spinnerService: SpinnerVisibilityService,
    private readonly authService: AuthenticationService,
    private readonly notify: NotificationService) { }

  public get activator() {
    return this._activator;
  }

  public activate(url: string, state: boolean, method:  ('POST' | 'GET' | 'PUT')) {
    this._activator.next(new FileEvent(url, state, method));
  }

  public uploadFile(url: string, method: ('POST' | 'PUT'), formData: FormData) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr);
          } else if (xhr.status === 401) {
            this.authService.signout().subscribe();
            this.notify.error(xhr.response.error.errorMessage || xhr.response.message || 'UnAuthorized User', true);
          } else if (xhr.status === 500) {
            this.notify.error(xhr.response.error.errorMessage || xhr.response.message, true);
          } else {
            reject(JSON.parse(xhr.response));
          }
        }
      }
      xhr.open(method, url, true);
      xhr.setRequestHeader("X-Authorization", 'Bearer ' + localStorage.getItem('sessionToken'));
      xhr.send(formData);
    });
  }

  public uploadFileSynced(url: string, method: ('POST' | 'PUT'), formData: FormData) {
    return new Promise((resolve, reject) => {
      this.spinnerService.show();
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          this.spinnerService.hide();
          if (xhr.status === 200) {
            resolve(xhr);
          } else if (xhr.status === 401) {
            this.authService.signout().subscribe();
            this.notify.error(xhr.response.error.errorMessage || xhr.response.message || 'UnAuthorized User', true);
          } else if (xhr.status === 500) {
            this.notify.error(xhr.response.error.errorMessage || xhr.response.message, true);
          } else {
            reject(xhr);
          }
        }
      }
      xhr.open(method, url, true);
      xhr.setRequestHeader("X-Authorization", 'Bearer ' + localStorage.getItem('sessionToken'));
      xhr.send(formData);
    });
  }

  public downloadWithDynamicForm(url: string) {
    this.activate(url, true, 'POST');
  }

}
