<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="my-cv">
    <vc-all-errors [groups]="[ cvUploadForm ]" [fromContext]="true"></vc-all-errors>
    <div class="grid-row">
        <table *ngIf="cvs?.length > 0">
            <caption></caption>
            <thead>
                <tr>
                    <th scope="colgroup">File</th>
                    <th scope="colgroup">Uploaded / Generated date</th>
                    <th scope="colgroup">Description</th>
                    <th scope="colgroup"></th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let cv of cvs">
                    <td><span *ngIf="cv?.cvName"><a href="javascript: void(0)"
                                (click)="download(cv.id)">{{cv.cvName }}</a></span></td>
                    <td><span *ngIf="cv?.createdDate">{{cv.createdDate | date : 'dd MMMM yyyy'}}</span></td>
                    <td>
                        <div class="description" *ngIf="cv?.cvDescription">{{cv.cvDescription}}</div>
                    </td>
                    <td>
                        <a href="javascript: void(0)" (click)="delete(cv.id, cv.cvName)" class="delLink link">Delete</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="upload-section">
        <div class="grid-row">
            <div class="column-full">
                <vc-form-control [control]="cvUploadForm.get('file')" [controlName]="'file'" [label]="'Upload a file'"
                    [fromContext]="true">
                    <input class="form-control" type="file" #fileInput (change)="onFileChange()"
                        aria-describedby="id-section-help-file id-section-error-file" id="id-file">
                </vc-form-control>
            </div>
            <form [formGroup]="cvUploadForm" autocomplete="off">
                <div class="column-full">
                    <vc-form-control [control]="cvUploadForm.get('fileName')" [controlName]="'fileName'"
                        [label]="'File name'" [fromContext]="true">
                        <input formControlName="fileName" class="form-control" type="text" id="id-fileName"
                            name="fileName" value="" [ngClass]="{'errorfocus': cvUploadForm.get('fileName')?.errors }"
                            aria-describedby="id-section-help-fileName id-section-error-fileName">
                        <div *ngIf="cvUploadForm.get('fileName').value.length <= 30 && 30 - cvUploadForm.get('fileName').value.length != 1"
                            class="form-label form-label-count">You have
                            {{30 - cvUploadForm.get('fileName').value.length}} characters remaining</div>
                        <div *ngIf="cvUploadForm.get('fileName').value.length <= 30 && 30 - cvUploadForm.get('fileName').value.length === 1"
                            class="form-label form-label-count">You have
                            {{30 - cvUploadForm.get('fileName').value.length}} character remaining</div>
                        <div *ngIf="cvUploadForm.get('fileName').value.length === 31" class="error-message">You
                            have {{ cvUploadForm.get('fileName').value.length - 30}} character too many</div>
                        <div *ngIf="cvUploadForm.get('fileName').value.length > 31" class="error-message">You have
                            {{ cvUploadForm.get('fileName').value.length - 30}} characters too many</div>
                    </vc-form-control>
                </div>
                <div class="column-full">
                    <vc-form-control [control]="cvUploadForm.get('description')" [controlName]="'description'"
                        [label]="'Description'" [fromContext]="true" [optional]="'(optional)'">
                        <textarea id="id-description" name="description" formControlName="description" rows="5"
                            class="form-control" aria-describedby="id-section-description"></textarea>
                        <div *ngIf="cvUploadForm.get('description').value.length <= 100"
                            class="form-label form-label-count">You have
                            {{100 - cvUploadForm.get('description').value.length}} characters remaining</div>
                        <div *ngIf="cvUploadForm.get('description').value.length === 101" class="error-message">You
                            have {{ cvUploadForm.get('description').value.length - 100}} character too many</div>
                        <div *ngIf="cvUploadForm.get('description').value.length > 101" class="error-message">You have
                            {{ cvUploadForm.get('description').value.length - 100}} characters too many</div>
                    </vc-form-control>
                </div>
            </form>
        </div>
    </div>

    <div class="grid-row mb25">
        <button id="id-cv-upload" class="button button-link" type="button" name="cv-upload"
            (click)="upload()">Upload</button>
    </div>
</div>