import { PipesModule } from './../../core/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmploymentComponent } from './employment.component';
import { EmploymentRoutingModule } from './employment.route';
import { FormControlModule } from '../../shared/components/form-control/form-control.module';
import { SearchJobComponent } from './search-job/search-job.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmploymentService } from './employment.service';
import { NewJobComponent } from './new-job/new-job.component';
import { JobOrganizationsComponent } from './job-organizations/job-organizations.component';
import { JobOrganizationsLinkComponent } from './job-organizations-link/job-organizations-link.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { PaginationModule } from '../../shared/components/pagination/pagination.module';
import { JobDetailsComponent } from './job-details/job-details.component';
import { TabsModule } from '../../shared/components/tabs/tabs.module';
import { EmploymentDashboardComponent } from './employment-dashboard/employment-dashboard.component';
import { CvBuilderModule } from '../cv-builder/cv-builder.module';
import { GuidedCvBuilderModule } from '../cv-builder/guided-cv-builder/guided-cv-builder.module';
import { JobApplicationComponent } from './job-application/job-application.component';
import { JobAttachmentComponent } from './job-application/job-attachment/job-attachment.component';
import { JobAttachmentService } from './job-application/job-attachment/job-attachment.service';
import { PrisonJobsComponent } from './prison-jobs/prison-jobs.component';
import { PrisonJobDetailComponent } from './prison-jobs/prison-job-detail/prison-job-detail.component';
import { JobsForPrisonLeaversComponent } from './jobs-for-prison-leavers/jobs-for-prison-leavers.component';
import { ViewJobsForPrisonLeaversComponent } from './jobs-for-prison-leavers/view-jobs-for-prison-leavers/view-jobs-for-prison-leavers.component';
import { FeaturedJobsForPrisonLeaversComponent } from './jobs-for-prison-leavers/featured-jobs-for-prison-leavers/featured-jobs-for-prison-leavers.component';
import { ExpressionOfInterestComponent } from './jobs-for-prison-leavers/expression-of-interest/expression-of-interest.component';

@NgModule({
  declarations: [
    EmploymentComponent,
    SearchJobComponent,
    NewJobComponent,
    JobOrganizationsComponent,
    JobOrganizationsLinkComponent,
    SearchResultComponent,
    JobDetailsComponent,
    EmploymentDashboardComponent,
    JobApplicationComponent,
    JobAttachmentComponent,
    PrisonJobsComponent,
    JobsForPrisonLeaversComponent,
    PrisonJobDetailComponent,
    ViewJobsForPrisonLeaversComponent,
    FeaturedJobsForPrisonLeaversComponent,
    ExpressionOfInterestComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EmploymentRoutingModule,
    FormControlModule,
    PaginationModule,
    PipesModule,
    TabsModule,
    CvBuilderModule,
    GuidedCvBuilderModule,
  ],
  providers: [
    EmploymentService,
    JobAttachmentService
  ]
})
export class EmploymentModule { }
