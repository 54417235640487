

export const PLAN_HOBBIES_VALIDATIONS = {

    required: {
        otherHobbies: `Enter other hobbies description, it cannot be blank`
    },
    maxlength: {
        otherHobbies: 'Please enter 200 or less characters in other hobbies description'
    },
    minlength: {
        otherHobbies: 'Please enter atleast 1 or more characters in other hobbies description'
    },
    pattern: {
        otherHobbies: 'Other hobbies and interest option contains disallowed characters'
    },
    minSelected: {
        answers: `Please select up to 3 hobbies from the available options`
    },
    maxSelected: {
        answers: `Please select up to 3 hobbies from the available options`
    },
}