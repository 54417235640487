import { Component, OnInit, ElementRef, ViewChild, AfterViewChecked, ChangeDetectorRef, Input, HostListener } from '@angular/core';
@Component({
  selector: 'vc-plan-schedule',
  templateUrl: './plan-schedule.component.html',
  styleUrls: ['./plan-schedule.component.scss']
})
export class PlanScheduleComponent implements OnInit, AfterViewChecked {
  @HostListener('document:click', ['$event'])
  documentClick(event) {
    const clickedClassList = Array.from(event.target.classList);
    const clickedClassListFiltered = clickedClassList.filter(className => className === 'course-bar' || className === 'course-label-content');
    if (clickedClassListFiltered.length === 0) {
      this.infoPanelCourse = null;
    }
  }

  @Input() categoryList: string;

  monthsNames = new Array<string>();
  monthDivWidth: number;
  monthDividerWidth: number;

  todaysDate: Date;
  leftPixelsTodayLine: number;
  leftPixelBufferConstant: number;

  previousMonthLabel: string;
  previousMonthLabelUnixFormat: string;
  previousMonthNum: number;
  previousMonthYear: number;
  previousMonthTotalDays: number;
  previousMonthDayPixels: number;
  previousMonthStartUnix: number;
  previousMonthEndUnix: number;
  
  currentMonthLabel: string;
  currentMonthLabelUnixFormat: string;
  currentMonthNum: number;
  currentMonthTotalDays: number;
  currentMonthDayPixels: number;
  currentMonthStartUnix: number;
  currentMonthEndUnix: number;

  nextMonthLabel: string;
  nextMonthLabelUnixFormat: string;
  nextMonthNum: number;
  nextMonthYear: number;
  nextMonthTotalDays: number;
  nextMonthDayPixels: number;
  nextMonthStartUnix: number;
  nextMonthEndUnix: number;

  infoPanelCourse: any;

  @ViewChild('monthDiv', { static: false }) monthDiv: ElementRef;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.monthsNames[0] = "January";
    this.monthsNames[1] = "February";
    this.monthsNames[2] = "March";
    this.monthsNames[3] = "April";
    this.monthsNames[4] = "May";
    this.monthsNames[5] = "June";
    this.monthsNames[6] = "July";
    this.monthsNames[7] = "August";
    this.monthsNames[8] = "September";
    this.monthsNames[9] = "October";
    this.monthsNames[10] = "November";
    this.monthsNames[11] = "December";

    this.monthDividerWidth = 5;
    this.leftPixelBufferConstant = 20;
    this.setDefaultMonths();
  }
  
  ngAfterViewChecked() {
    if (this.categoryList) {
      this.calcTodayLinePosition();
      this.changeDetectorRef.detectChanges();
    }
  }

  setDefaultMonths() {
    this.todaysDate = new Date();
    this.currentMonthLabel = `${this.monthsNames[this.todaysDate.getMonth()]} ${this.todaysDate.getFullYear()}`
    this.currentMonthLabelUnixFormat = `${this.monthsNames[this.todaysDate.getMonth()]}/${this.todaysDate.getFullYear()}`
    this.currentMonthNum = this.todaysDate.getMonth();
    this.previousMonthLabel = this.setPreviousMonth();
    this.nextMonthLabel = this.setNextMonth();
    this.setMonthsTotalDays();
    this.setMonthsUnixTimestamps();
  }

  setPreviousMonth() {
    if (this.todaysDate.getMonth() === 0) {
      this.previousMonthNum = 11;
      this.previousMonthYear = this.todaysDate.getFullYear() - 1;
      this.previousMonthLabelUnixFormat = `${this.monthsNames[11]}/${this.todaysDate.getFullYear() - 1}`
      return `${this.monthsNames[11]} ${this.todaysDate.getFullYear() - 1}`
    } else {
      this.previousMonthNum = this.todaysDate.getMonth() - 1;
      this.previousMonthYear = this.todaysDate.getFullYear();
      this.previousMonthLabelUnixFormat = `${this.monthsNames[this.todaysDate.getMonth() - 1]}/${this.todaysDate.getFullYear()}`
      return `${this.monthsNames[this.todaysDate.getMonth() - 1]} ${this.todaysDate.getFullYear()}`
    }
  }

  setNextMonth() {
    if (this.todaysDate.getMonth() === 11) {
      this.nextMonthNum = 0;
      this.nextMonthYear = this.todaysDate.getFullYear() + 1;
      this.nextMonthLabelUnixFormat = `${this.monthsNames[0]}/${this.todaysDate.getFullYear() + 1}`;
      return `${this.monthsNames[0]} ${this.todaysDate.getFullYear() + 1}`
    } else {
      this.nextMonthNum = this.todaysDate.getMonth() + 1;
      this.nextMonthYear = this.todaysDate.getFullYear();
      this.nextMonthLabelUnixFormat = `${this.monthsNames[this.todaysDate.getMonth() + 1]}/${this.todaysDate.getFullYear()}`
      return `${this.monthsNames[this.todaysDate.getMonth() + 1]} ${this.todaysDate.getFullYear()}`
    }
  }

  setMonthsTotalDays() {
    this.previousMonthTotalDays = this.getDaysInMonth(this.previousMonthNum + 1, this.todaysDate.getFullYear());
    this.currentMonthTotalDays = this.getDaysInMonth(this.currentMonthNum + 1, this.todaysDate.getFullYear());
    this.nextMonthTotalDays = this.getDaysInMonth(this.nextMonthNum + 1, this.todaysDate.getFullYear());
  }

  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  setMonthsUnixTimestamps() {
    const startDateOfPreviousMonth = `1/${this.previousMonthLabelUnixFormat} 14:00:00 GMT+00:00`;
    this.previousMonthStartUnix = new Date(startDateOfPreviousMonth).getTime() / 1000;
    const endDateOfPreviousMonth = `${this.previousMonthTotalDays}/${this.previousMonthLabelUnixFormat} 14:00:00 GMT+00:00`
    this.previousMonthEndUnix = new Date(endDateOfPreviousMonth).getTime() / 1000;

    const startDateOfCurrentMonth = `1/${this.currentMonthLabelUnixFormat} 14:00:00 GMT+00:00`
    this.currentMonthStartUnix = new Date(startDateOfCurrentMonth).getTime() / 1000;
    const endDateOfCurrentMonth = `${this.currentMonthTotalDays}/${this.currentMonthLabelUnixFormat} 14:00:00 GMT+00:00`
    this.currentMonthEndUnix = new Date(endDateOfCurrentMonth).getTime() / 1000;

    const startDateOfNextMonth = `1/${this.nextMonthLabelUnixFormat} 14:00:00 GMT+00:00`
    this.nextMonthStartUnix = new Date(startDateOfNextMonth).getTime() / 1000;
    const endDateOfNextMonth = `${this.nextMonthTotalDays}/${this.nextMonthLabelUnixFormat} 14:00:00 GMT+00:00`
    this.nextMonthEndUnix = new Date(endDateOfNextMonth).getTime() / 1000;
  }

  calcTodayLinePosition() {
    this.monthDivWidth = this.monthDiv.nativeElement.offsetWidth - this.monthDividerWidth;
    this.previousMonthDayPixels = this.monthDivWidth / (this.previousMonthTotalDays - 1);
    this.currentMonthDayPixels = this.monthDivWidth / (this.currentMonthTotalDays - 1);
    this.nextMonthDayPixels = this.monthDivWidth / (this.nextMonthTotalDays - 1);
    this.leftPixelsTodayLine = this.currentMonthDayPixels * (this.todaysDate.getDate() - 1);
  }

  calcCourseLabelLeft(courseStartUnix, courseStartDateStr) {
    if (courseStartUnix < this.previousMonthStartUnix) {
      return this.calcCourseBarLeft(courseStartUnix, courseStartDateStr) + 15
    } else {
      return this.calcCourseBarLeft(courseStartUnix, courseStartDateStr)
    }
  }

  calcCourseBarLeft(courseStartUnix, courseStartDateStr) {
    const courseStartDate = new Date(courseStartDateStr)
    const courseStartDayNum = courseStartDate.getDate();

    switch(true) {
      // course starts before previous month
      case courseStartUnix < this.previousMonthStartUnix:
        return 0 - this.leftPixelBufferConstant;
      // course starts during previous month
      case courseStartUnix >= this.previousMonthStartUnix && courseStartUnix <= this.previousMonthEndUnix:
      return (courseStartDayNum -1) * this.previousMonthDayPixels 
      // course starts during current month
      case courseStartUnix >= this.currentMonthStartUnix && courseStartUnix <= this.currentMonthEndUnix:
        return this.monthDivWidth + this.monthDividerWidth + ((courseStartDayNum - 1) * this.currentMonthDayPixels)
      // course starts during next month but not on last day of next month
      case courseStartUnix >= this.nextMonthStartUnix && courseStartUnix < this.nextMonthEndUnix:
        return (this.monthDivWidth * 2) + (this.monthDividerWidth * 2) + ((courseStartDayNum - 1) * this.nextMonthDayPixels)
      // course starts on last day of next month
      case courseStartUnix >= this.nextMonthStartUnix && courseStartUnix === this.nextMonthEndUnix:
          return (this.monthDivWidth * 2) + (this.monthDividerWidth * 2) + ((courseStartDayNum - 1) * this.nextMonthDayPixels) - 5
  
      default:
        return 0
    }
  }

  calcCourseBarWidth(courseStartUnix, courseEndUnix, courseStartDateStr, courseEndDateStr) {
    const courseStartDate = new Date(courseStartDateStr)
    const courseStartDayNum = courseStartDate.getDate();
    const courseEndDate = new Date(courseEndDateStr)
    const courseEndDayNum = courseEndDate.getDate();

    switch(true) {
      case this.startsBeforePreviousMonthAndEndsInPreviousMonth(courseStartUnix, courseEndUnix):
        if (courseEndDayNum === 1) {
          return 4 + this.leftPixelBufferConstant
        } else {
          return ((courseEndDayNum - 1) * this.previousMonthDayPixels) + this.leftPixelBufferConstant
        }

      case this.startsBeforePreviousMonthAndEndsInCurrentMonth(courseStartUnix, courseEndUnix):
        return this.monthDivWidth + this.monthDividerWidth + ((courseEndDayNum - 1) * this.currentMonthDayPixels) + this.leftPixelBufferConstant
      
      case this.startsBeforePreviousMonthAndEndsInNextMonth(courseStartUnix, courseEndUnix):
        return (this.monthDivWidth * 2) + (this.monthDividerWidth * 2) + ((courseEndDayNum - 1) * this.nextMonthDayPixels) + this.leftPixelBufferConstant
      
      case this.startsBeforePreviousMonthAndEndsAfterNextMonth(courseStartUnix, courseEndUnix):
        return (this.monthDivWidth * 3) + (this.monthDividerWidth * 2) + (this.leftPixelBufferConstant * 2)
      
      case this.startsInPreviousMonthAndEndsInPreviousMonth(courseStartUnix, courseEndUnix):
        if (courseStartDateStr === courseEndDateStr) {
          return this.previousMonthDayPixels
        } else {
          return (courseEndDayNum - courseStartDayNum) * this.previousMonthDayPixels
        }
      
      case this.startsInPreviousMonthAndEndsInCurrentMonth(courseStartUnix, courseEndUnix):
        return ((this.previousMonthTotalDays - courseStartDayNum) * this.previousMonthDayPixels) + this.monthDividerWidth + ((courseEndDayNum - 1) * this.currentMonthDayPixels)
      
      case this.startsInPreviousMonthAndEndsInNextMonth(courseStartUnix, courseEndUnix):
        return ((this.previousMonthTotalDays - courseStartDayNum) * this.previousMonthDayPixels) + 
          this.monthDividerWidth + 
          this.monthDivWidth + 
          this.monthDividerWidth + 
          ((courseEndDayNum - 1) * this.nextMonthDayPixels)
      
      case this.startsInPreviousMonthAndEndsAfterNextMonth(courseStartUnix, courseEndUnix):
        return (this.monthDivWidth * 3) + (this.monthDividerWidth * 2) + (this.leftPixelBufferConstant * 20)
      
      case this.startsInCurrentMonthAndEndsInCurrentMonth(courseStartUnix, courseEndUnix):
        if (courseStartDateStr === courseEndDateStr) {
          return this.currentMonthDayPixels
        } else {
          return (courseEndDayNum - courseStartDayNum) * this.currentMonthDayPixels
        }
      
      case this.startsInCurrentMonthAndEndsInNextMonth(courseStartUnix, courseEndUnix):
        return ((this.currentMonthTotalDays - courseStartDayNum) * this.currentMonthDayPixels) + this.monthDividerWidth + ((courseEndDayNum - 1) * this.nextMonthDayPixels)
      
      case this.startsInCurrentMonthAndEndsAfterNextMonth(courseStartUnix, courseEndUnix):
        return (this.monthDivWidth * 2) + this.monthDividerWidth + this.leftPixelBufferConstant
      
      case this.startsInNextMonthAndEndsInNextMonth(courseStartUnix, courseEndUnix):
        if (courseStartDateStr === courseEndDateStr) {
          return this.nextMonthDayPixels
        } else {
          return (courseEndDayNum - courseStartDayNum) * this.nextMonthDayPixels
        }
      
      case this.startsInNextMonthAndEndsAfterNextMonth(courseStartUnix, courseEndUnix):
        return this.monthDivWidth + this.leftPixelBufferConstant
      default:
        return null
    }
  }

  startsBeforePreviousMonthAndEndsInPreviousMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix < this.previousMonthStartUnix && courseEndUnix <= this.previousMonthEndUnix;
  }

  startsBeforePreviousMonthAndEndsInCurrentMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix < this.previousMonthStartUnix && courseEndUnix <= this.currentMonthEndUnix;
  }

  startsBeforePreviousMonthAndEndsInNextMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix < this.previousMonthStartUnix && courseEndUnix <= this.nextMonthEndUnix;
  }

  startsBeforePreviousMonthAndEndsAfterNextMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix < this.previousMonthStartUnix && courseEndUnix > this.nextMonthEndUnix;
  }

  startsInPreviousMonthAndEndsInPreviousMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.previousMonthStartUnix && courseStartUnix <= this.previousMonthEndUnix && courseEndUnix <= this.previousMonthEndUnix;
  }

  startsInPreviousMonthAndEndsInCurrentMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.previousMonthStartUnix && courseStartUnix <= this.previousMonthEndUnix && courseEndUnix <= this.currentMonthEndUnix;
  }

  startsInPreviousMonthAndEndsInNextMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.previousMonthStartUnix && courseStartUnix <= this.previousMonthEndUnix && courseEndUnix <= this.nextMonthEndUnix;
  }

  startsInPreviousMonthAndEndsAfterNextMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.previousMonthStartUnix && courseStartUnix <= this.previousMonthEndUnix && courseEndUnix > this.nextMonthEndUnix;
  }

  startsInCurrentMonthAndEndsInCurrentMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.currentMonthStartUnix && courseStartUnix <= this.currentMonthEndUnix && courseEndUnix <= this.currentMonthEndUnix;
  }

  startsInCurrentMonthAndEndsInNextMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.currentMonthStartUnix && courseStartUnix <= this.currentMonthEndUnix && courseEndUnix <= this.nextMonthEndUnix;
  }

  startsInCurrentMonthAndEndsAfterNextMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.currentMonthStartUnix && courseStartUnix <= this.currentMonthEndUnix && courseEndUnix > this.nextMonthEndUnix;
  }

  startsInNextMonthAndEndsInNextMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.nextMonthStartUnix && courseStartUnix <= this.nextMonthEndUnix && courseEndUnix <= this.nextMonthEndUnix;
  }

  startsInNextMonthAndEndsAfterNextMonth(courseStartUnix, courseEndUnix) {
    return courseStartUnix >= this.nextMonthStartUnix && courseStartUnix <= this.nextMonthEndUnix && courseEndUnix > this.nextMonthEndUnix;
  }

  calculatePathwayContainerHeight(coursesData) {
    let categoryRowCount = 0;
    coursesData.forEach(rowArr => {
      if (rowArr.length > 0) {
        categoryRowCount++
      }
    });
    if (categoryRowCount === 0) {
      return 90
    } else {
      return 55 + (categoryRowCount * 35)
    }
  }

  calCourseLabelWidth(courseStartUnix, courseStartDateStr, nextCourseStartUnix, nextCourseStartDateStr) {
    if (!nextCourseStartUnix) {
      return ((this.monthDivWidth * 3) + (this.monthDividerWidth * 2)) - this.calcCourseLabelLeft(courseStartUnix, courseStartDateStr) - 10
    } else {
      if ((this.calcCourseLabelLeft(nextCourseStartUnix, nextCourseStartDateStr) - this.calcCourseLabelLeft(courseStartUnix, courseStartDateStr) - 15) < 0) {
        return 0
      }
      return this.calcCourseLabelLeft(nextCourseStartUnix, nextCourseStartDateStr) - this.calcCourseLabelLeft(courseStartUnix, courseStartDateStr) - 15;
    }
  }

  selectCourse(courseData) {
    this.infoPanelCourse = courseData;
  }

}
