import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidedCvBuilderComponent } from './guided-cv-builder.component';
import { GuidedCvBuilderRoutingModule } from './guided-cv-builder-routing.module';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { PersonalDetailsService } from './personal-details/personal-details.service';
import { PersonalStatementComponent } from './personal-statement/personal-statement.component';
import { MultiSelectOptionsModule } from '../../../shared/components/multi-select-options/multi-select-options.module';
import { PersonalStatementService } from './personal-statement/personal-statement.service';
import { WorkExperienceComponent } from './work-experience/work-experience.component';
import { WorkExperienceService } from './work-experience/work-experience.service';
import { FormControlModule } from '../../../shared/components/form-control/form-control.module';
import { GuidedCvBuilderService } from './guided-cv-builder.service';
import { EditWorkExperienceComponent } from './work-experience/edit-work-experience/edit-work-experience.component';
import { PaidWorkComponent } from './work-experience/edit-work-experience/paid-work/paid-work.component';
import { VolunteerWorkComponent } from './work-experience/edit-work-experience/volunteer-work/volunteer-work.component';
import { PrisonWorkComponent } from './work-experience/edit-work-experience/prison-work/prison-work.component';
import { PipesModule } from './../../../core/pipes/pipes.module';
import { EducationService } from './education/education.service';
import { EducationHomeComponent } from './education/education-home.component';
import { EditEducationComponent } from './education/edit-education/edit-education.component';
import { TrainingComponent } from './training/training.component';
import { EditTrainingComponent } from './training/edit-training/edit-training.component';
import { TrainingService } from './training/training.service';
import { ComputerSkillsHomeComponent } from './computer-skills/computer-skills-home.component';
import { ComputerSkillsService } from './computer-skills/computer-skills.service';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { InputChipsModule } from '../../../shared/components/input-chips/input-chips.module';
import { AdditionalInfoService } from './additional-info/additional-info.service';
import { CvTemplatesComponent } from './cv-templates/cv-templates.component';
import { GenerateCvComponent } from './cv-templates/generate-cv/generate-cv.component';
import { CvTemplatesService } from './cv-templates/cv-templates.service';
import { MyCvsService } from './my-cvs/my-cvs.service';
import { MyCVsModule } from './my-cvs/my-cvs.module';
import { CvBuilderMyCvsComponent } from './wrapper/cvs/cv-builder-my-cvs.component';

@NgModule({
  declarations: [
    GuidedCvBuilderComponent,
    PersonalDetailsComponent,
    PersonalStatementComponent,
    WorkExperienceComponent,
    WorkExperienceComponent,
    EditWorkExperienceComponent,
    PaidWorkComponent,
    VolunteerWorkComponent,
    PrisonWorkComponent,
    EducationHomeComponent,
    EditEducationComponent,
    TrainingComponent,
    EditTrainingComponent,
    ComputerSkillsHomeComponent,
    AdditionalInfoComponent,
    CvTemplatesComponent,
    GenerateCvComponent,
    CvBuilderMyCvsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectOptionsModule,
    GuidedCvBuilderRoutingModule,
    FormControlModule,
    PipesModule,
    InputChipsModule,
    MyCVsModule
  ],
  providers: [
    PersonalDetailsService,
    PersonalStatementService,
    WorkExperienceService,
    GuidedCvBuilderService,
    EducationService,
    TrainingService,
    ComputerSkillsService,
    AdditionalInfoService,
    CvTemplatesService,
    MyCvsService,
  ]
})
export class GuidedCvBuilderModule { }
