<div class="grid-row">
    <table *ngIf="eduList.length !== 0">
        <caption></caption>
        <thead>
            <tr>
                <th scope="colgroup">Education type</th>
                <th scope="colgroup">Course title</th>
                <th scope="colgroup">Date completed</th>
                <th scope="colgroup" class="width-10"></th>
                <th scope="colgroup"></th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let s of eduList">
                <td>
                    <span *ngIf="s?.educationType && s?.educationType == 'Other' ">
                        {{s.otherEducationType | truncate:[40, '...'] }}</span>
                    <span *ngIf="s?.educationType && s?.educationType != 'Other'">
                        {{s.educationType | truncate:[40, '...'] }}</span>
                </td>
                <td><span *ngIf="s?.courseTitle">{{s.courseTitle | truncate:[40, '...']  }}</span></td>
                <td>
                    <span *ngIf="s?.courseEndDate">{{s.courseEndDate | date : 'MMMM yyyy'}}</span>
                    <span *ngIf="!s?.courseEndDate && s?.isStudying">Currently studying</span>
                </td>
                <td>
                    <span>
                        <a href="javascript: void(0)" class="delLink link" (click)="EditPlanEducation(s.id)">Edit</a>
                    </span>
                    <span>
                        <a href="javascript: void(0)" (click)="deletePlanEducation(s.id)" class="delLink link">Delete</a>
                    </span>
                </td>
            </tr>
        </tbody>

    </table>
</div>