import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentComponent } from './document.component';
import { DOCUMENT } from '@angular/common';

const routes: Routes = [
  {
    path:'',
    component:DocumentComponent,
    data: { title: 'Documents', mainTitle: 'Wallet' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRoutingModule { }
