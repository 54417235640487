<div class="profileWrapper">

  <vc-all-errors [groups]="[changePasswordForm]" [fromContext]="true"></vc-all-errors>

  <form [formGroup]="changePasswordForm" autocomplete="off" (ngSubmit)="changePassword()">

    <div>

      <vc-form-control [control]="changePasswordForm.get('existingPassword')" [controlName]="'existingPassword'" [label]="'Enter your current password'" [fromContext]="true">
        <input formControlName="existingPassword" [ngClass]="{'errorFocus': changePasswordForm.get('existingPassword')?.errors }" class="form-control left" type="text" id="id-existingPassword" name="existingPassword" spellcheck="false" type="password">
      </vc-form-control>
  
      <vc-form-control [control]="changePasswordForm.get('newPassword')" [controlName]="'newPassword'" [label]="'Enter your new password'" [fromContext]="true">
        <input formControlName="newPassword" [ngClass]="{'errorFocus': changePasswordForm.get('newPassword')?.errors }" class="form-control" type="text" id="id-newPassword" name="newPassword" spellcheck="false" type="password">
      </vc-form-control>
  
      <vc-form-control [control]="changePasswordForm.get('confirmPassword')" [controlName]="'confirmPassword'" [label]="'Confirm your new password'" [fromContext]="true">
        <input formControlName="confirmPassword" [ngClass]="{'errorFocus': changePasswordForm.get('confirmPassword')?.errors }" class="form-control" type="text" id="id-confirmPassword" name="confirmPassword" spellcheck="false" type="password">
      </vc-form-control>

    </div>

    <div>
      <button id="id-submit-button" class="button button-link button-align" type="submit" name="changePassword" #submitFormButton>Submit</button>
    </div>

  </form>

</div>
