<section class="entry-panel">
  <div class="left-container">
    <h2 class="entry-title">{{entryData?.title}}</h2>
    <div class="entry-date-container">
      <p class="entry-subtitle" [style.color]="setDateFontColor(entryData.interventionType, 'Start')">{{entryData?.startDate}}</p>
      <pre class="entry-subtitle" [style.color]="setDateFontColor(entryData.interventionType, 'Separator')">{{dateSeparator}}</pre>
      <p class="entry-subtitle" [style.color]="setDateFontColor(entryData.interventionType, 'End')">{{entryData?.endDate}}</p>
    </div>
    <div class="entry-pathway-container">
      <div 
        class="entry-pathway-label"
        [style.background-color]="setPathwayBackgroundColor(entryData?.pathway)"
      >
        <p>{{entryData?.pathway | uppercase}}</p>
      </div>
    </div>
  </div>
  <div class="right-container">
    <span>
      <div *ngIf="entryData?.status === 'Completed'" class="entry-tag status-completed">{{entryData?.status  | uppercase}}</div>
      <div *ngIf="entryData?.status === 'Withdrawn'" class="entry-tag status-withdrawn">{{entryData?.status  | uppercase}}</div>
      <div *ngIf="entryData?.status !== 'Completed' && entryData?.status !== 'Withdrawn'" class="entry-tag status-spacer"></div>
    </span>
  </div>
</section>
