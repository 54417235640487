import { Itabs } from './../../shared/components/tabs/tabs.interface';
import { Injectable } from '@angular/core';

const dataInputRoute = '/plan';

@Injectable()
export class PlanTabNavigationData {
    planTabNavigation: Itabs[] = [
        {
          id: 1,
          name: 'All',
          state: `${dataInputRoute}`,
        },
        {
          id: 2,
          name: 'Accredited',
          state: `${dataInputRoute}/accredited`,
        },
        {
          id: 2,
          name: 'Non - Accredited',
          state: `${dataInputRoute}/non-accredited`,
        }
      ];
}
