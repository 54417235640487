import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlModule } from '../../components/form-control/form-control.module';
import { TogetherModeModalComponent } from './together-mode-modal.component';
import { TogetherModeModalService } from './together-mode-modal.service';

@NgModule({
  declarations: [
    TogetherModeModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlModule
  ],
  exports: [
    TogetherModeModalComponent
  ],
  providers: [
    TogetherModeModalService
  ]
})
export class TogetherModeModalModule { }
