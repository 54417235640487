import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../core/services/main.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'vc-wallet-my-cvs',
  templateUrl: './wallet-my-cvs.component.html'
})
export class WalletMyCvsComponent implements OnInit {

  constructor(private readonly mainService: MainService,
    private readonly route: ActivatedRoute) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = this.route.snapshot.data.mainTitle;
  }

  ngOnInit() {
  }

}
