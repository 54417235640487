import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../../shared/constants/url-constants';

@Injectable()
export class AdditionalInfoService {

  constructor(private readonly http: HttpClient) { }

  getAllLanguages() {
    const href = `${Url.CVB}/ref-data/languages`;
    return this.http.get<any>(href);
  }

  getAllQas() {
    const href = `${Url.CVB}/ref-data/hobbies/questions`;
    return this.http.get<any>(href).toPromise();
  }

  getAdditionalInfoDetails() {
    const href = `${Url.CVB}/additional-info`;
    return this.http.get<any>(href);
  }

  saveAndLater(payload: any): Observable<any> {
    const href = `${Url.CVB}/additional-info/draft`;
    return this.http.post(href, payload);
  }

  saveAndContinue(payload: any): Observable<any> {
    const href = `${Url.CVB}/additional-info/save`;
    return this.http.post(href, payload);
  }

}
