import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from './../../../shared/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class InductionCheckAnswersService {

  constructor(private readonly http: HttpClient) { }

  getCheckAnswersData() {
    const href = `${Url.PLAN}/induction-Plan/checkList/1`;
    return this.http.get<any>(href);
  }

  agreePlan(data) {
    const href = `${Url.PLAN}/induction-Plan/planAgree`;
    return this.http.post<any>(href, data)
  }

  checkPlanAgreed() {
    const href = `${Url.PLAN}/induction-Plan/planAgree/1`;
    return this.http.get<any>(href);
  }
}
