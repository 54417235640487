import { BannerService } from './../../../../core/services/banner.service';
import { ContentService } from './../../../../features/content/content.service';
import { Component, OnInit, Input } from '@angular/core';
import { IResult } from '../search-result.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'vc-result-card',
  templateUrl: './result-card.component.html',
  styleUrls: ['./result-card.component.scss']
})
export class ResultCardComponent implements OnInit {

  @Input() item: IResult
  isFavourite: boolean = null

  constructor(
    private readonly router: Router,
    private readonly contentService: ContentService,
    private readonly bannerService: BannerService
  ) { }

  async ngOnInit() {

      await this.contentService.getContentDetails(this.item.contentID).subscribe(async rsp => {
        const attachment = await rsp.getAttachment(''+this.item.contentID);
        if(attachment) {
          const imageData = await attachment.getData();
          this.item.imagePath = URL.createObjectURL(imageData)
        }
      });

    if(this.item.imagePath === null || this.item.imagePath === ''){
      this.item.imagePath = '/assets/images/coming-soon.jpg';
    }
    this.isFavourite = this.item.isFavourite;
  }

  goToDetails() {
    this.router.navigate([`/content/course/${this.item.title}/${this.item.contentID}`]);
  }

  addToPlan() {
    this.contentService.addToPlan(this.item.contentID).subscribe(response => {
      this.bannerService.success(response.successMessage, true);
      this.isFavourite = true;
    }, error => {
      this.bannerService.warning(error.error.errorMessage, true);
    });
  }

  getContentKeywords(item: any) {
    return item.contentKeyWords ? item.contentKeyWords.split(',') : [];
  }

  removeFromPlan() {
    this.contentService.removeMyContent(this.item.contentID).subscribe(response => {
        this.bannerService.success(response.successMessage, true);
        this.isFavourite = false;
      }, error => {
        this.bannerService.warning(error.error.errorMessage, true);
      }
    );
  }

}
