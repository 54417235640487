<a (click)="gotoPrev()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="my-cv mt25">
    <vc-all-errors [groups]="[ documentForm ]" [fromContext]="true"></vc-all-errors>
    <div class="grid-row">
        <table *ngIf="docs?.length > 0">
            <caption></caption>
            <thead>
                <tr>
                    <th scope="colgroup">File name</th>
                    <th scope="colgroup">Uploaded / Generated date</th>
                    <th scope="colgroup">Description</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let doc of docs">
                    <td><span *ngIf="doc?.name"><a href="javascript: void(0)"
                                (click)="download(doc.id)">{{doc.name }}</a></span></td>
                    <td><span *ngIf="doc?.createdDate">{{doc.createdDate | date : 'dd MMMM yyyy'}}</span></td>
                    <td>
                        <div class="description" *ngIf="doc?.description">{{doc.description }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="upload-section">
        <div class="grid-row">
            <div class="column-full">
                <vc-form-control [control]="documentForm.get('file')" [controlName]="'file'" [label]="'Upload a file'"
                    [fromContext]="true">
                    <input class="form-control" type="file" #fileInput (change)="onFileChange()"
                        aria-describedby="id-section-help-file id-section-error-file" id="id-file">
                </vc-form-control>
            </div>
            <div class="clearfix"></div>
            <form [formGroup]="documentForm" autocomplete="off">
                <vc-form-control [control]="documentForm.get('fileName')" [controlName]="'fileName'"
                    [label]="'File name'" [fromContext]="true">
                    <input formControlName="fileName" class="form-control" type="text" id="id-fileName" name="fileName"
                        value="" [ngClass]="{'errorfocus': documentForm.get('fileName')?.errors }"
                        aria-describedby="id-section-help-fileName id-section-error-fileName">
                    <div *ngIf="documentForm.get('fileName').value.length <= 30 && 30 - documentForm.get('fileName').value.length != 1"
                        class="form-label form-label-count">
                        You have
                        {{30 - documentForm.get('fileName').value.length}} characters remaining</div>
                        <div *ngIf="documentForm.get('fileName').value.length <= 30 && 30 - documentForm.get('fileName').value.length === 1"
                        class="form-label form-label-count">
                        You have
                        {{30 - documentForm.get('fileName').value.length}} character remaining</div>
                    <div *ngIf="documentForm.get('fileName').value.length === 31" class="error-message">You
                        have {{ documentForm.get('fileName').value.length - 30}} character too many</div>
                    <div *ngIf="documentForm.get('fileName').value.length > 31" class="error-message">You have
                        {{ documentForm.get('fileName').value.length - 30}} characters too many</div>


                </vc-form-control>
                <div class="column-full">
                    <vc-form-control [control]="documentForm.get('description')" [controlName]="'description'"
                        [label]="'Description'" [fromContext]="true" [optional]="'(optional)'">
                        <textarea formControlName="description" rows="5" class="form-control" type="text"
                            id="id-description" name="description"
                            aria-describedby="id-section-help-description id-section-error-description">
                        </textarea>
                        <div *ngIf="documentForm.get('description').value.length <= 100"
                            class="form-label form-label-count">You have
                            {{100 - documentForm.get('description').value.length}} characters remaining</div>
                        <div *ngIf="documentForm.get('description').value.length === 101" class="error-message">You
                            have {{ documentForm.get('description').value.length - 100}} character too many</div>
                        <div *ngIf="documentForm.get('description').value.length > 101" class="error-message">You have
                            {{ documentForm.get('description').value.length - 100}} characters too many</div>
                    </vc-form-control>
                </div>
            </form>
        </div>
    </div>

    <div class="grid-row">
        <button id="id-cv-upload" class="button button-link" type="button" name="cv-upload"
            (click)="upload()">Upload</button>
    </div>
</div>