import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Base64 } from 'js-base64';

import { ValidationService } from './../../../shared/components/form-control/validation.service';
import { TogetherModeModalService } from './together-mode-modal.service';

import { ENTER_TOGETHER_MODE_VALIDATION_CONTEXT } from './together-mode-modal.validation';

const ENTER_TOGETHER_MODE_FORM_VALIDATORS = {
  username: Validators.required,
  pass: Validators.required
}

@Component({
  selector: 'vc-together-mode-modal',
  templateUrl: './together-mode-modal.component.html',
  styleUrls: ['./together-mode-modal.component.scss']
})
export class TogetherModeModalComponent implements OnInit {
  enterTogetherModeForm: FormGroup;
  invalidCredentials: boolean;
  backendWarningMessage = undefined;
  @ViewChild("username") usernameField: ElementRef;

  constructor(
    private readonly builder: FormBuilder,
    private readonly validationService: ValidationService,
    private readonly togetherModeModalService: TogetherModeModalService,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    this.validationService.setContext(ENTER_TOGETHER_MODE_VALIDATION_CONTEXT);
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.invalidCredentials = false;
    this.enterTogetherModeForm = this.builder.group({
      username: this.builder.control(''),
      pass: this.builder.control('')
    }, {
      updateOn: 'submit'
    })
  }

  submitEnterTogetherModeForm() {
    this.invalidCredentials = false;
    this.backendWarningMessage = undefined;
    this.clearAllValidatorsAndErrors(this.enterTogetherModeForm);
    this.setValidators(this.enterTogetherModeForm, ENTER_TOGETHER_MODE_FORM_VALIDATORS)

    if (this.enterTogetherModeForm.pristine === false && this.enterTogetherModeForm.valid) {
      const formattedPayload = {
        password: Base64.encode(this.enterTogetherModeForm.get('pass').value),
        userName: this.enterTogetherModeForm.get('username').value
      };

      this.togetherModeModalService.enterTogetherMode(formattedPayload).subscribe(response => {
        this.clearAllValidatorsAndErrors(this.enterTogetherModeForm);
        this.initForm();
      }, error => {
        if (error.error.errorMessage === 'Incorrect user credentials, login failed') {
          this.invalidCredentials = true;
          this.enterTogetherModeForm.controls.username.setErrors({ invalidCredentials: true });
          this.enterTogetherModeForm.controls.pass.setErrors({ invalidCredentials: true });
          this.setFocusToErrorSummary();
        } else {
          this.backendWarningMessage = error.error.errorMessage;
          this.setFocusToUsernameField();
        }
      })

    } else {
      this.setFocusToErrorSummary();
    }
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
      }
    });
  }

  closeTogetherModeModal() {
    this.backendWarningMessage = undefined;
    this.clearAllValidatorsAndErrors(this.enterTogetherModeForm);
    this.initForm();
    this.togetherModeModalService.closeModal();
  }

  setFocusToUsernameField() {
    this.usernameField.nativeElement.focus();
  }

  setFocusToErrorSummary() {
    setTimeout(() => {
      const errorSummary = this.renderer.selectRootElement('.error-summary', true);
      errorSummary.focus();
    })
  }
}
