import { RxCollectionCreatorBase } from "rxdb/dist/types/types";

export const categorySchema: RxCollectionCreatorBase = {
    schema: {
        title: 'Category',
        version: 0,
        description: 'Category details',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                primary: true
            },
            level: {
                type: 'number'
            },
            parentId: {
                type: ['number', 'null']
            },
            categoryColor: {
                type: ['string', 'null']
            },
            categoryImgUrl: {
                type: ['string', 'null']
            },
            categoryTitle: {
                type: ['string', 'null']
            },
            sequence: {
                type: ['number', 'null']
            }
        },
        required: ['id'],
        attachments: {
            encrypted: false // if true, the attachment-data will be encrypted with the db-password
        }
    }
}