import { TabsService } from './../../../shared/components/tabs/tabs.service';
import { Component, OnInit } from '@angular/core';
import { ProfileTabNavigationData } from '../user-profile-nav';

@Component({
  selector: 'vc-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

  constructor( ) {
  }

  ngOnInit() {
  }

}
