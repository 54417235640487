<a routerLink="/employment" href="javascript:void(0)" class="link-back nomargintop">Back</a>

<div class="clear">
    <h3 class="heading-large nomargintop">Featured jobs for prison leavers</h3>
    <section class="mar-right15">
        <div class="grid-row scrollable">
            <vc-featured-jobs-for-prison-leavers *ngFor="let nfnJob of featuredNFNJobs" [job]="nfnJob"></vc-featured-jobs-for-prison-leavers>
        </div>
    </section>
</div>

<form [formGroup]="nfnSearchJob" autocomplete="off" class="clear mt25" (ngSubmit)="goToSearch()">
<div class="form-group border-box form-control-2-1 left ">
    <label class="heading-medium nomargintop">What?</label>
    <input class="form-control left form-control-1-1" formControlName="what" placeholder="Job title" (keyup)="onKeyJob($event)" name="jobNames"/>
</div>
<div class="form-group border-box form-control-2-1 left ">
    <label class="heading-medium nomargintop">Where?</label>
    <input class="form-control left form-control-1-1" formControlName="where" placeholder="Location" (keyup)="onKeyLocation($event)" list="jobLocation" name="jobLoc" />
    <datalist id="jobLocation" >
        <option *ngFor="let jobLoc of filteredLocations; let index;" value="{{jobLoc.description}}">{{jobLoc.description}}</option>
    </datalist>
</div>

<div class="left search-button">
    <button id="id-submit-button" class="button" type="submit"
     [disabled]="nfnSearchJob.get('what').value === '' && nfnSearchJob.get('where').value === ''" >Search</button>
</div>

</form>

<div *ngIf="noresults">
    <div class="grid-row">
        <div class="column-full">
            <h1 class="heading-large">No results found</h1>
            <p>Please enter a job title/location and try again.</p>
        </div>
    </div>
</div>
<div *ngIf="!noresults"> 
<div class="grid-row mt25">
    <div class="column-full nfn-job-list-table" id="nfn-job-list-table">
        <table>
            <thead>
                <tr>
                    <th scope="col">Job</th>
                    <th scope="col">Location</th>
                    <th scope="col">Company</th>
                    <th scope="col">Date added</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let s of nfnJobList | paginate: { itemsPerPage: size, currentPage: (page + 1), totalItems: totalItems  }">
                    <td scope="row" class="width-30">
                        <a id="jobTitle_{{s.id}}"
                            [routerLink]="['view-jobs-for-prison-leavers',s.id]">{{s?.jobTitle}}
                        </a>
                    </td>
                    <td class="width-20">
                        <span id="location_{{s.id}}">{{s?.location}}</span>
                    </td>
                    <td class="width-20">
                        <span id="company_{{s.id}}">{{s?.company}}</span>
                    </td>
                    <td class="width-20">
                        <span id="addedDate_{{s.id}}">{{s?.postingDate | date : 'dd MMMM yyyy'}}</span>
                    </td>
                    <td class="width-22">
                        <span id="status_{{s.id}}" class="status" *ngIf="s?.jobStatus != null">{{s?.jobStatus}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="grid-row" *ngIf="totalItems > size">
            <pagination-controls [maxSize]="maxSize" (pageChange)="pageChange($event)">
            </pagination-controls>
        </div>
    </div>
</div>
</div>