<a (click)="back()" href="javascript:void(0)" class="link-back nomargintop">Back</a>
<div class="job-application" id="job-application">
    <div class="grid-row">
        <vc-all-errors [groups]="[application]" [fromContext]="true"></vc-all-errors>
        <form [formGroup]="application" autocomplete="off">
            <div class="column-full">
                <vc-form-control [control]="application.get('su')" [controlName]="'su'" [label]="'From'"
                    [fromContext]="true">
                    <input formControlName="su" class="form-control" type="text" id="id-su" name="su"
                        aria-describedby="id-section-help-su id-section-error-su" />
                </vc-form-control>
            </div>

            <div class="column-full">
                <vc-form-control [control]="application.get('message')" [controlName]="'message'" [label]="'Message'"
                    [fromContext]="true" (input)="hasModified = true">
                    <textarea formControlName="message" rows="5" class="form-control" type="text" id="id-message"
                        name="message" aria-describedby="id-section-help-message id-section-error-message"
                        (change)="updateState()">
                    </textarea>

                    <div *ngIf="application.get('message').value?.length <= 500" class="form-label form-label-count">You
                        have
                        {{500 - application.get('message').value?.length}} characters remaining</div>
                    <div *ngIf="application.get('message').value?.length === 501" class="error-message">You have
                        {{ application.get('message').value?.length - 500}} character too many</div>
                    <div *ngIf="application.get('message').value?.length > 501" class="error-message">You have
                        {{ application.get('message').value?.length - 500}} characters too many</div>
                </vc-form-control>
            </div>
            <div class="column-full">
                <vc-form-control [control]="application.get('attachments')" [controlName]="'attachments'" [label]="''"
                    [fromContext]="true">
                    <button id="id-attachments" class="button button-secondary" type="button" name="attachment"
                        (click)="goToAttachment()">Attach CVs / Documents</button>
                    <div class="attachment-list chips-list">
                        <div *ngFor="let attachment of application.get('attachments').value; let i = index"
                            class="roundedcorner" class="chips">
                            {{attachment.name}} <span (click)="dropAttachment(i)" class="close_icon">X</span>
                        </div>
                    </div>
                </vc-form-control>
            </div>
        </form>
    </div>

    <div class="column-two-thirds">
        <h3 class="heading-large">{{job?.job_title}}</h3>
        <div class="grid-row ">
            <table class="table-font-small">
                <tbody>
                    <tr *ngIf="job?.date">
                        <th>Posting date:</th>
                        <td>{{job?.date | date : 'dd MMMM yyyy'}}</td>
                    </tr>
                    <tr *ngIf="job?.contract_type">
                        <th>Contract type:</th>
                        <td>{{job?.contract_type}}</td>
                    </tr>
                    <tr *ngIf="job?.organization_name">
                        <th>Company name:</th>
                        <td>{{job?.organization_name}}</td>
                    </tr>
                    <tr *ngIf="job?.location_name">
                        <th>Location:</th>
                        <td>{{job?.location_name}}</td>
                    </tr>
                    <tr *ngIf="job?.salary">
                        <th>Salary:</th>
                        <td>{{job?.salary | currency: 'GBP' : 'symbol' : '3.0'}}</td>
                    </tr>
                    <tr *ngIf="job?.advertiser_reference_number">
                        <th>Job reference:</th>
                        <td>{{job?.advertiser_reference_number}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="clear grid-row mt25 mb25">
        <button id="id-send" *ngIf="!job?.isApplied" class="button button-link" type="button" name="send"
        (click)="sendApplication()">Send</button>
        <button type="button" *ngIf="job?.isApplied" [disabled]="true" class="button button-link">Applied</button>
        <button id="id-cancel" class="button button-secondary" type="button" name="cancel"
            (click)="cancel()">Cancel</button>
    </div>
</div>