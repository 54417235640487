import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SrmService } from '../../../../features/srm/srm.service';
import { TabsService } from '../../../../shared/components/tabs/tabs.service';

@Component({
  selector: 'vc-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @Output() private toChat :boolean = false;
  @Output() private nChat :boolean = false;
  @Output() private readonly clickedChat = new EventEmitter<boolean>();

  @Input() showPanel: boolean =false;
  public chatConversations: any[];
  protected userId: number;
  public chat;
  public messageToName;
  chatDataSource;
  messagesDataSource;
  loadChatSubscription: any;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly srmService: SrmService,
    private readonly tabService: TabsService
  ) { }

  ngOnInit() {
    this.userId = JSON.parse(atob(localStorage.getItem('sessionToken').split('.')[1])).userId;
    this.tabService.hideChatCount.subscribe(flag =>{
        this.loadQuickView();
    });
    this.loadQuickView();
    this.tabService.chat.subscribe(chatId=>{
      this.chatDataSource.forEach(chat => {
        if (chat.chatId === chatId){
          chat.isRead = true;
        }
      });
    });
  }

  goToChat() {
    this.toChat = !this.toChat;
    this.clickedChat.emit(this.toChat);
  }

  newChat() {
   this.nChat = !this.nChat;
  }
  loadQuickView() {
    this.srmService.getTopThreeReceivedChat().subscribe(data => {
      const dataSource = data.filter(chat => chat.status === 'Received');
      this.chatDataSource = new Array();
      let index = 1;
      dataSource.forEach(chatObj => {
        if (index <= 3) {
          if (this.userId === chatObj.messageFromId) {
            chatObj.chatFrom = chatObj.messageToName;
          } else if (this.userId === chatObj.messageToId) {
           chatObj.chatFrom = chatObj.messageFromName;
          }
          this.chatDataSource.push(chatObj);
        }
        index ++;
      });
    });
  }


  goToChatInbox(id: number, name: any) {
    this.loadQuickView();
    this.showPanel = false;
    this.router.navigate(['/chat'], { queryParams : { active: id}});
  }
  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

}
