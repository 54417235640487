<a href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<h2 class="heading-large">Content</h2>
<vc-search-result #searchResult [resultBlock]="resultBlock" [totalCount]="totalCount" (loadMore)="onLoadMore($event)">
    <div class="grid-row">
        <div class="column-full">
            <p>You have searched for: <span class="title">{{keyword}}</span> <span
                    *ngIf="totalCount >= 0">({{resolveResultText()}})</span></p>
        </div>
        <div class="column-full suggestion" *ngIf="showSuggestions">
            <p>Did you mean: <a class="link" routerLink="" (click)="searchWithSuggestion(suggestion)"
                    *ngFor="let suggestion of suggestions">{{suggestion}}</a></p>
        </div>
    </div>
    
</vc-search-result>