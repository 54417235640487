import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'
@Component({
  selector: 'vc-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() learner: any;
  @Input() courseSize = 0;
  @Input() lddSize = 0;

  learnerInfoPanelIsOpen = false;
  llddPanelIsOpen = false;
  coursesPanelIsOpen = false;
  allPanelsOpen = false;
  planRouteActive = false;

  constructor(private readonly router: Router) {}

  ngOnInit() {
    this.planRouteActive = this.router.isActive('plan', false)
  }

  togglePanel(event) {
    switch (event.srcElement.id) {
      case 'accordion-button-learnerInfo':
      case 'accordion-heading-learnerInfo':
      case 'accordion-heading-learnerInfo-icon': 
        this.learnerInfoPanelIsOpen = !this.learnerInfoPanelIsOpen;
        this.checkForAllOpenPanels();
        break;
      case 'accordion-button-lldd-health-problems':
      case 'accordion-heading-lldd-health-problems':
      case 'accordion-heading-lldd-health-problems-icon':
        this.llddPanelIsOpen = !this.llddPanelIsOpen;
        this.checkForAllOpenPanels();
        break;
      case 'accordion-button-courses':
      case 'accordion-heading-courses':
      case 'accordion-heading-courses-icon':
        this.coursesPanelIsOpen = !this.coursesPanelIsOpen;
        this.checkForAllOpenPanels();
        break;
    }
  }

  toggleAllPanels() {
    if (!this.planRouteActive) {
      if (this.allPanelsOpen) {
        this.learnerInfoPanelIsOpen = false;
        this.llddPanelIsOpen = false;
        this.coursesPanelIsOpen = false;
        this.allPanelsOpen = false;
      } else {
        this.learnerInfoPanelIsOpen = true;
        this.llddPanelIsOpen = true;
        this.coursesPanelIsOpen = true;
        this.allPanelsOpen = true;
      }
    } else {
      if (this.allPanelsOpen) {
        this.learnerInfoPanelIsOpen = false;
        this.llddPanelIsOpen = false;
        this.allPanelsOpen = false;
      } else {
        this.learnerInfoPanelIsOpen = true;
        this.llddPanelIsOpen = true;
        this.allPanelsOpen = true;
      }
    }
  }

  checkForAllOpenPanels() {
    if (!this.planRouteActive) {
      if (this.learnerInfoPanelIsOpen && this.llddPanelIsOpen && this.coursesPanelIsOpen) {
        this.allPanelsOpen = true;
      } else {
        this.allPanelsOpen = false;
      }
    } else {
      if (this.learnerInfoPanelIsOpen && this.llddPanelIsOpen) {
        this.allPanelsOpen = true;
      } else {
        this.allPanelsOpen = false;
      }
    }
  }
  
}
