<div id="guided-cv">
    <div class="grid-row">
        <article class="cv-wrapper column-half recommended" tabindex="0" (click)="scrollTop()"
            [routerLink]="['cv-builder/guided']">
            <figure class="cv-builder">
                <img alt="Guided CV" src="../../../assets/images/employment/curriculum_vitae.jpg">
                <figcaption class="light-green">
                    <div class="caption-div">
                        <!-- <h2>Create your CV</h2> -->
                        <div class="light-green">
                            <span>CV builder</span>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </article>
        <article class="job-wrapper column-half recommended" tabindex="0" (click)="scrollTop()" [routerLink]="['jobs-for-prison-leavers']">
            <figure class="jobs-for-prison-leavers"> 
                <img alt="jobs-for-prison-leavers" src="../../../assets/images/employment/NFN.jpg">
                <figcaption class="light-pink">
                    <div class="caption-div">
                        <div class="light-pink">
                            <span>Jobs for prison leavers</span>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </article>
        <article class="job-wrapper column-half recommended" tabindex="0" (click)="scrollTop()"
            [routerLink]="['job-market']">
            <figure class="job-market">
                <img alt="job market" src="../../../assets/images/employment/Job Search.jpg">
                <figcaption class="bright-purple">
                    <div class="caption-div">
                        <div class="bright-purple">
                            <span>Job market</span>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </article>
        <article class="job-wrapper column-half recommended" tabindex="0" (click)="scrollTop()"
            [routerLink]="['prison-jobs']">
            <figure class="prison-jobs">
                <img alt="prison-jobs" src="../../../assets/images/employment/Prison_jobs.jpg">
                <figcaption class="turquoise">
                    <div class="caption-div">
                        <div class="turquoise">
                            <span>Prison jobs</span>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </article>
    </div>
</div>