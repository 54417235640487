import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../../../shared/components/form-control/validation.service';
import { CvTemplatesService } from '../cv-templates.service';
import { MainService } from '../../../../../core/services/main.service';
import { FILEDETAILS_VALIDATORS_CONTEXT } from './generate-cv.validation';
import { ConfirmService } from '../../../../../shared/components/confirm-box/confirm-box.service';
import { MyCvsService } from '../../my-cvs/my-cvs.service';
import { CV } from '../../my-cvs/my-cvs.component';
import { BannerService } from '../../../../../core/services/banner.service';

const FILEDETAILS_VALIDATORS = {
  fileName: [Validators.required, Validators.maxLength(30), Validators.pattern(/^[^<>:"\/\\\|\?\*]*$/)],
  description: [Validators.maxLength(100)],
}

@Component({
  selector: 'vc-generate-cv',
  templateUrl: './generate-cv.component.html',
  styleUrls: ['./generate-cv.component.scss']
})
export class GenerateCvComponent implements OnInit, OnDestroy {

  fileDetailsForm: FormGroup;
  templateIdentifier: string;
  cvs: CV[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly confirm: ConfirmService,
    private readonly mainService: MainService,
    private readonly templateService: CvTemplatesService,
    private readonly validation: ValidationService,
    private readonly cvservice: MyCvsService,
    private readonly bannerService: BannerService,
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = this.route.snapshot.data.mainTitle;
    this.initForm();
    this.route.queryParams.subscribe(params => {
      if (!params.template) {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
      this.templateIdentifier = params.template;
    })
  }

  ngOnInit() {
    this.templateService.getPersonalDetails().subscribe((res: any) => {
      const fileName = res.firstName + '_' + res.lastName + '_CV';
      this.fileDetailsForm.get('fileName').setValue(fileName);
    })
    this.validation.setContext(FILEDETAILS_VALIDATORS_CONTEXT);

    this.getMyCVs();
  }

  ngOnDestroy() {
    this.mainService.pageMainTitle = '';
  }

  initForm() {
    this.fileDetailsForm = this.fb.group({
      fileName: this.fb.control(''),
      description: this.fb.control(''),
    });
  }

  save() {
    this.setValidators(this.fileDetailsForm, FILEDETAILS_VALIDATORS);
    if (this.fileDetailsForm.valid) {
      const data = this.parseSaveData();
      this.templateService.save(data).subscribe(
        res1 => {
          this.router.navigate(['../cvs'], { queryParams: {}, relativeTo: this.route});
        },
        error => {
          if (error.status === 409) {
            this.confirm.confirm({
              header: 'Confirm',
              message: 'File with the same name already exists. Do you want to replace it?',
              acceptLabel: 'Yes',
              rejectLabel: 'No',
              accept: () => {
                this.templateService.replace(data).subscribe(res2 => {
                  this.router.navigate(['../cvs'], { queryParams: {}, relativeTo: this.route});
                });
              },
              reject: () => {
                this.confirm.choose(false);
              }
            });
          }
        }
      );
    }
  }

  cancel() {
    this.gotoPrev();
  }

  gotoPrev() {
    this.location.back();
  }

  parseSaveData() {
    const info = this.fileDetailsForm.value;
    info.templateIdentifier = this.templateIdentifier;
    return info;
  }

  private setValidators(form: FormGroup, validators: any): void {
    Object.keys(form.controls).forEach(name => {
      if (validators[name]) {
        const control = form.get(name);
        control.clearValidators();
        control.setErrors(null);
        control.setValidators(validators[name]);
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    });
  }

  clearAllValidatorsAndErrors(form: FormGroup): void {
    Object.keys(form.controls).forEach(name => {
      const control = form.get(name);
      control.clearValidators();
      control.setErrors(null);
      control.markAsTouched();
    });
  }

  getMyCVs() {
    this.cvservice.getUploadedcvs().subscribe((res: any[]) => {
      this.cvs = res;
    });
   
  }

  delete(id: number, name: string) {
    this.confirm.confirm({
      header: 'Confirm',
      message: `Are you sure you want to delete ${name}?`,
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.cvservice.delete(id).subscribe(res => {
          this.getMyCVs();
          this.bannerService.success(['File deleted successfully'], true);
        }, error => {
          this.bannerService.warning(error.error.errorMessage, true);
        });
      },
      reject: () => {
        this.confirm.choose(false);
      }
    });
  }

  download(id: number): void {
    this.cvservice.download(id);
  }


}
