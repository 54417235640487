<a *ngIf='!isOpenContent' href="javascript:void(0)" title="here" (click)="backClicked()" class="link-back nomargintop heading-secondary">Back</a>

<div class="grid-row">
    <ng-container *ngIf='!isOpenContent'>
        <div class="column-half">
            <p class="">{{course?.shortDesc}}</p>
            <div class="content-subhead">
            <h2 class="heading-large">Modules</h2>
            <div class="badge-style" *ngIf= "course?.contentLevelId != null">
                <span  id="level_id">{{course?.contentLevelId?.description}}</span>
            </div>
            </div>
            <div *ngFor="let module of course?.modules">
                <h3 class="heading-medium">{{module?.sequence}}. {{module?.name}}</h3>
                <p>{{module?.moduleDescription}}</p>
            </div>
            <div class="actions-container">
                <button title="Open" id="button-add-to-plan" class="button button-link" *ngIf="course?.modules?.length > 0" (click)="toggleLaunch()"> Open </button>
                <div *ngIf="isFavourite" class="heart-favourite-container">
                    <!-- <a class="heart-favourite-true" aria-hidden="true" title='Remove from my content' (click)="removeFromPlan()"></a>
                    <a class="favourite-link" title="Remove from my content" (click)="removeFromPlan()">Remove from my content</a> -->
                </div>
                <div *ngIf="!isFavourite" class="heart-favourite-container">
                    <!-- <a class="heart-favourite-false" aria-hidden="true" title="Add to my content" (click)="addToPlan()"></a>
                    <a class="favourite-link" title="Add to my content" (click)="addToPlan()">Add to my content</a> -->
                </div>
            </div>
        </div>
        
        <div class="column-half">
            <figure class="thumbnail-wrapper">
                <img alt="{{course?.courseTitle}}" [src]="course?.courseImgUrl" />
            </figure>
        </div>
        <div *ngIf="course?.contentKeyWords?.length > 0" class="keywords">
            <label>Keywords:</label>
            <div class="tag-list-keywords">
                <span *ngFor="let keyword of course?.contentKeyWords; let i=index" class="tag-keywords"> <a class="link"
                        [routerLink]="['/content/search']" [queryParams]="{keyword: keyword.trim()}">{{keyword}}</a>
                        <span *ngIf="i !== (course?.contentKeyWords.length - 1)">, </span>  
                    </span>
            </div>
        </div>
    </ng-container>

    <div class="back-container" *ngIf='isOpenContent'>
        <a (click)="toggleLaunch()" href="javascript: void(0)" title="Back to details" class="link-back" >Back</a>
    </div>

    <div class="back-nav-content-container">
        <div class="nav-content-container">
            <div class="nav-container" *ngIf='isOpenContent' tabindex="-1" #navContainer>
                <nav class="side-navigation" aria-label="Side navigation">
                    <ul class="side-navigation__list">
                        <li class="side-navigation__item" *ngFor="let module of course?.modules"
                            (click)="onModuleChange(module)">
                            <a [ngClass]="{'side-navigation__item-active' : module === selectedModule}"
                                [attr.aria-current]="module === selectedModule" [routerLink]="">
                                {{module?.name}}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="content-container">
                <ng-container #viewContainerRef></ng-container>
            </div>
        </div>
    </div>
</div>