<div class="container display-panel" *ngIf="showPanel">
    <nav class="moj-side-navigation" aria-label="Side navigation">
        <ul class="moj-side-navigation__list">
            <li *ngFor="let chat of chatDataSource" (click)="goToChatInbox(chat.chatId,chat.chatFrom)"
                id="chat_{{chat.chatId}}" [ngClass]="{'active': chat.active}"
                class=" active moj-side-navigation__item moj-side-navigation__item--active" id="{{chat.chatId}}">
                <div class="avatar-circle">
                    <span class="initials" id="initial_{{chat.chatId}}">{{ getShortName(chat.chatFrom)| uppercase }}</span>
                </div>

                <div class="message-item">
                    <span id="last_msg_{{chat.chatId}}"> Last Message {{chat.status}}</span>
                    <p><span id="date_{{chat.chatId}}">{{chat.lastMessageRecievedDate | date:'dd MMMM yyyy' }}</span> <span *ngIf= "!chat.isRead" class="status_mark"></span></p>
                    <p><span id="time_{{chat.chatId}}">{{chat.lastMessageRecievedDate | date:'HH:mm' }}</span></p>
                </div>
            </li>
        </ul>
    </nav>
    <div class="dropdown-footer">
        <button type="button" id="button-go-to-chat" class="button button-secondary marright13" (click)="goToChat()" ng-disabled="IsDisabled" routerLink="/chat">Go to SM</button>
        <button type="button" id="button-new-chat" class="button button-secondary" (click)="newChat()" routerLink="/srm/new-chat">New SM</button>
    </div>    
</div>
