<a *ngIf="routeState !== 'changeAnswer'" href="javascript: void(0)" (click)="backClicked()" class="link-back nomargintop">Back</a>
<div id="induction-questionnaire" class="induction-questions-list mb30">
  <p class="number-of-pages">Page 12 of 13</p>
  <vc-all-errors [groups]="[pageTwelveForm]" [fromContext]="true"></vc-all-errors>
  <legend class="question" #question tabindex="0">{{currentPageData[0]?.question}}</legend>
  <form [formGroup]=pageTwelveForm (submit)="onClickContinue()">
    <vc-form-control [control]="pageTwelveForm.get('learningStyle')" [controlName]="'learningStyle'" [fromContext]="true">
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwelveForm.get('learningStyle')" [value]="currentPageData[0]?.answersValues[0]" id="id-learningStyle" name="learningStyle" formControlName="learningStyle">
        <label class="radio-label" for="id-learningStyle-visual">{{currentPageData[0]?.answersLabels[0]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwelveForm.get('learningStyle')" [value]="currentPageData[0]?.answersValues[1]" id="id-learningStyle-auditory" name="learningStyle" formControlName="learningStyle">
        <label class="radio-label" for="id-learningStyle-auditory">{{currentPageData[0]?.answersLabels[1]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwelveForm.get('learningStyle')" [value]="currentPageData[0]?.answersValues[2]" id="id-learningStyle-reading-writting" name="learningStyle" formControlName="learningStyle">
        <label class="radio-label" for="id-learningStyle-reading-writting">{{currentPageData[0]?.answersLabels[2]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwelveForm.get('learningStyle')" [value]="currentPageData[0]?.answersValues[3]" id="id-learningStyle-kinesthetic" name="learningStyle" formControlName="learningStyle">
        <label class="radio-label" for="id-learningStyle-kinesthetic">{{currentPageData[0]?.answersLabels[3]}}</label>
      </div>
      <div class="radio-wrapper" *ngIf="currentPageData[0]">
        <input type="radio" [formControl]="pageTwelveForm.get('learningStyle')" [value]="currentPageData[0]?.answersValues[4]" id="id-learningStyle-dontknow" name="learningStyle" formControlName="learningStyle">
        <label class="radio-label" for="id-learningStyle-dontknow">{{currentPageData[0]?.answersLabels[4]}}</label>
      </div>
    </vc-form-control>

    <div class="grid-row alignLeft mt30">
      <button 
        id="id-continue" 
        class="button button-link ml0" 
        type="submit" 
        name="continue"
      >Continue</button>
      <button 
        *ngIf="routeState !== 'changeAnswer'"
        id="id-save-as-draft" 
        class="button button-secondary" 
        type="button"
        name="save-as-draft"
        (click)="onClickSave()"
      >Save as draft</button>
    </div>

  </form>
</div>
