import { PipesModule } from './../../../core/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectOptionsComponent } from './multi-select-options.component';


@NgModule({
  declarations: [
    MultiSelectOptionsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  exports: [
    MultiSelectOptionsComponent
  ]
})
export class MultiSelectOptionsModule { }
