import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../../../shared/constants/url-constants';
import { FileUtilService } from '../../../../shared/components/file-util/file-util.service';

@Injectable()
export class MyCvsService {

  constructor(
    private readonly http: HttpClient,
    private readonly fileUtils: FileUtilService
  ) { }

  getUploadedcvs() {
    const href = `${Url.CVB}/cvs`;
    return this.http.get(href);
  }

  upload(file: File, description: string, filename: string, method: 'POST' | 'PUT') {
    const href = `${Url.CVB}/upload`;
    const fd = new FormData();
    fd.append('file', file);
    fd.append('description', description);
    fd.append('fileName', filename);
    return this.fileUtils.uploadFileSynced(href, method, fd);
  }

  delete(id: number) {
    const href = `${Url.CVB}/cvs/${id}`;
    return this.http.delete<any>(href);
  }

  download(id: number): void {
    const href = `${Url.CVB}/download/users/cvs/${id}`;
    this.fileUtils.downloadWithDynamicForm(href);
  }

}
