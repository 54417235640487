<main id="main-content" class="main-content full-width-container">
  <div class="site-width-container">

    <div class="grid-row">
      <div class="column-one-half fullwidth nomarginbottom">
        <h1 class="main-heading">{{ breadcrumbs[breadcrumbs.length - 1]?.pageMainTitle }}</h1>
        <h1 class="heading-xlarge nomargintop">{{ breadcrumbs[breadcrumbs.length - 1]?.label }}</h1>
      </div>
    </div>
    <ng-content></ng-content>

  </div>
</main>
