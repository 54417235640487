import { Component, OnInit, ViewChild, ElementRef, Renderer2, NgZone, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { Base64 } from 'js-base64';
import { OfflineService } from '../../core/services/offline.service';
import { UserProfileService } from 'src/app/features/user-profile/user-profile.service';
import { ConnectionService } from 'ng-connection-service'; 
import { NotificationService } from 'src/app/core/services/notification.service';
import { ConfirmService } from 'src/app/shared/components/confirm-box/confirm-box.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { firstValueFrom, Observable, Subscription, timer } from 'rxjs';
import { UserActivityService } from 'src/app/home/user-activity/user-activity.service';

@Component({
  selector: 'vc-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit, OnDestroy {

  signInForm: FormGroup;
  @ViewChild("username") usernameField: ElementRef;
  @ViewChild("password") passwordField: ElementRef;
  @ViewChild("errorSummaryDiv") errorSummary: ElementRef;
  formSubmitted = false;
  usernameEmpty = false;
  passwordEmpty = false;
  passwordOrPRNInvalid = false;
  backendErrorMessage: string;
  noticeBoardMessage: any;
  isConnected = true;  
  noInternetConnection: boolean; 
  learner: any;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly builder: FormBuilder,
    private readonly authenticationService: AuthenticationService,
    private readonly offlineService: OfflineService,
    private readonly userProfile: UserProfileService,
    private connectionService: ConnectionService,
    private readonly confirmService: ConfirmService,
    private readonly spinnerService: SpinnerVisibilityService,
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly userActivityService: UserActivityService,
  ) { }

  ngOnInit() {
    
    this.initSignInForm();
    this.authenticationService.noticeBoardMessage().subscribe(noticeBoard =>{
      this.noticeBoardMessage = noticeBoard?.message;
    });
    this.userProfile.getCachedLearnerInfo().subscribe(info => {
      this.learner = info;
      this.connectionService.monitor().subscribe(isConnected => {  
        this.isConnected = isConnected;  
        if (this.isConnected && this.learner.deviceId != null) {
          this.noInternetConnection=false;
        }  
        else if (this.isConnected && this.learner.deviceId == null) {
          this.noInternetConnection=true; 
        }
        else if (!this.isConnected && this.learner.deviceId == null) {
          this.noInternetConnection=true; 
          this.confirmService.confirm({
            header: 'Device is not set up for offline usage.', 
            showOk: false,
            message: '', 
            showClose: false, 
            acceptVisible: false
          });
        }
        else {  
          this.noInternetConnection=true; 
          this.router.navigate(['/plan']);
          this.renderer.removeClass(document.body, 'vcShowAutho');
        } 
      });
    })
    this.renderer.addClass(document.body, 'vcShowAutho');
    this.connectionStatus();
  }

  connectionStatus(){
    const statusDisplay = document.getElementById("header-navigation-signout");
    if (navigator.onLine){
      statusDisplay.style.visibility = "visible";
    } else {
      statusDisplay.style.visibility = "hidden";
    }
  }

  initSignInForm() {
    this.signInForm = this.builder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      appId: ['11']
    }, { updateOn: 'submit' });
  }

  async signIn() {
    this.usernameEmpty = false;
    this.passwordEmpty = false;
    this.passwordOrPRNInvalid = false;
    this.backendErrorMessage = undefined;
    this.signInForm.markAllAsTouched();
    this.removeIncorrectUsernameError();
    this.removeIncorrectPasswordError();

    this.formSubmitted = true;

    if (this.signInForm.valid) {
      this.signInForm.get('password').setValue(Base64.encode(this.signInForm.get('password').value));
      const userProfile = await firstValueFrom(this.userProfile.getCachedLearnerInfo());
      const currentUsername = this.signInForm.get('userName').value;
      
      if(userProfile && userProfile.prn !== currentUsername) {
       /*
        * 'Skip This device is set up for offline' popup and redirect to 
        * sign in process
      */
        this.processSignInRequest();
      } else {
        this.processSignInRequest();
      }

      
    } else {
      this.signInForm.controls.userName.errors ? this.usernameEmpty = true : this.usernameEmpty = false
      this.signInForm.controls.password.errors ? this.passwordEmpty = true : this.passwordEmpty = false
      setTimeout(() => this.errorSummary.nativeElement.focus())
    }
  }

  removeIncorrectUsernameError() {
    if (this.signInForm.controls.userName.hasError('incorrectUsername')) {
      delete this.signInForm.controls.userName.errors['incorrectUsername'];
      this.signInForm.controls.userName.updateValueAndValidity();
    }
  }

  removeIncorrectPasswordError() {
    if (this.signInForm.controls.password.hasError('incorrectPassword')) {
      delete this.signInForm.controls.password.errors['incorrectPassword'];
      this.signInForm.controls.password.updateValueAndValidity();
    }
  }

  errorListClick(inputField) {
    if (inputField === 'username') {
      this.usernameField.nativeElement.focus();
    } else if (inputField === 'password') {
      this.passwordField.nativeElement.focus();
    }
  }

  setErrorsFromBackend() {
    this.signInForm.controls.userName.setErrors({ incorrectUsername: true })
    this.signInForm.controls.password.setErrors({ incorrectPassword: true })
  }

  processSignInRequest() {
    this.spinnerService.show();
    localStorage.removeItem('deviceId');
    this.authenticationService.signIn(this.signInForm.value)
        .subscribe(async (data) => {
          await this.offlineService.clearDB();
          sessionStorage.removeItem('routeState');
          const confirmBox = this.confirmService.confirm({
            header: 'Preparing for offline', 
            showOk: false,
            message: '', 
            showClose: false, 
            acceptVisible: false
          });
          this.userProfile.getUserLearnerInfo().subscribe(info => {
            this.zone.runOutsideAngular(() => {
              if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
              }
              if (localStorage.getItem('sessionToken')) {
                this.startTimer();
              }
            });
            this.offlineService.setOfflineData(info).then(rsp => {
              confirmBox.choose(true);
              this.renderer.removeClass(document.body, 'vcShowAutho');
              this.spinnerService.hide();
              this.router.navigateByUrl('/');
              localStorage.setItem('deviceId', info.deviceId);
            });
          }, error => {
            this.backendErrorMessage = 'Internal server error. Please contact system administrator.';
            this.spinnerService.hide();
            confirmBox.choose(true);
            this.signInForm.get('password').setValue(Base64.decode(this.signInForm.get('password').value));
            this.authenticationService.signout().subscribe();
          })
        },
        (error: any) => {
          this.spinnerService.hide();
          if (error.error.errorMessage === 'PRN or Password is not valid, please try again') {
            this.signInForm.get('password').setValue(Base64.decode(this.signInForm.get('password').value));
            this.setErrorsFromBackend(); 
            this.passwordOrPRNInvalid = true;
            setTimeout(() => this.errorSummary.nativeElement.focus())
          } else {
            this.signInForm.get('password').setValue(Base64.decode(this.signInForm.get('password').value));
            this.backendErrorMessage = error.error.errorMessage;
            window.scrollTo(0, 0);
          }
        });
  }

  startTimer() {

    const sessionParams = this.resolveSessionParams();
    this.timer = timer(0, 60 * 1000);

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timerSubscription = this.timer.subscribe(n => {

      localStorage.setItem('lastActivity', '' + this.userActivityService.lastActivity);

      if ((new Date().getTime() - +localStorage.getItem('lastActivity')) / 1000 >
        (sessionParams.idleTime - sessionParams.inactiveNotificationTime) && this.offlineService.isOnline()) {


        this.zone.run(() => {

          this.userActivityService.lastActivity = new Date().getTime();
          localStorage.setItem('lastActivity', '' + this.userActivityService.lastActivity);
        });
      }
      if(this.isConnected) {
        this.sendHeartbeat(n,sessionParams.heartbeat);
      }
    });

  }
  sendHeartbeat(time , heartbeat){
        if (time * 60 % heartbeat === 0) {
            this.userActivityService.checkHeartbeat().subscribe(
                response=>{
                    if (response.isRefreshRequred === true) {
                        this.authenticationService.refreshToken().subscribe();
                    }
                }
            );
        }
    }

    resolveSessionParams() {
      let sessionParams;
      localStorage.getItem('sessionParams') ?
        sessionParams = JSON.parse(atob(localStorage.getItem('sessionParams'))) :
        sessionParams = { 'idleTime': 1200, 'inactiveNotificationTime': 120, 'heartbeat': 60 };
      return sessionParams;
    }

    ngOnDestroy() {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
    }
}
