<div id="id-section-{{controlName}}" class="form-group border-box"
    [ngClass]="{'form-group-error': errorMessage !== null && formSubmitted === true}">
    <label [ngClass]="{'error-message': !control.valid}" class="form-label-b19" for="id-{{controlName}}"> {{label}}
        <span>{{optional}}</span></label>

    <div *ngIf="errorMessage === null && hint" id="id-section-help-{{controlName}}" class="form-hint">{{hint}}</div>

    <select class="form-control" type="text" id="id-selector" name="selector" [formControl]="selector"
        aria-describedby="id-section-help-selector id-section-error-selector" (change)="onSelectorKeydown()" #selectorRef>
        <option value="" style="display: none;"></option>
        <option *ngFor="let item of filtered" value="{{item}}">{{item}}</option>

    </select>

    <div class="chips-list">
        <div *ngFor="let input of control['controls']; let i = index" class="roundedcorner" class="chips">
            {{input.value}} <span (click)="drop(i)" *ngIf="removable" class="close_icon">X</span>
        </div>
    </div>
</div>