import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from './../../../shared/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class PlanInductionService {

  constructor(private readonly http: HttpClient) { }

  getPlanInductionDetails() {
    const href = `${Url.PLAN}/induction-Plan/details?planTypeIdentifier=1&userId`;
    return this.http.get<any>(href);
  }

  getPlanInductionHistory(sectionIdentifier) {
    const href = `${Url.PLAN}/induction-history?planTypeIdentifier=1&sectionIdentifier=${sectionIdentifier}&userId`;
    return this.http.get<any>(href);
  }
}
