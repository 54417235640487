import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmploymentService } from '../employment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { JobDetails } from '../employment.interface';
import { MainService } from '../../../core/services/main.service';

@Component({
  selector: 'vc-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy {

  job: JobDetails;
  similarList: any = [];
  data: any = {};
  clicked = false;
  applicationInfo: any = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly mainService: MainService,
    private readonly employmentService: EmploymentService,
    private readonly location: Location
  ) {
    this.mainService.pageTitle = this.route.snapshot.data.title;
    this.mainService.pageMainTitle = 'Job market';
    this.route.queryParams.subscribe(params => {
      this.resolveParameters(params);
      if (!params.job) {
        this.router.navigate(['../'], { relativeTo: this.route });
      } else {
        this.employmentService.getJobDetails(params.job)
          .subscribe(jobDetails => {
            this.job = jobDetails;
          });
      }
    });
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.mainService.pageMainTitle = '';
  }

  applyForJob(id: string) {
    this.router.navigate(['../application'], {
      relativeTo: this.route,
      queryParams: {
        job: id,
        what: this.data.what,
        where: this.data.where
      }
    });
  }

  resolveParameters(params) {
    this.data.what = params.what;
    this.data.where = params.where;
    this.data.exclude = [
      { field: '_id', value: params.job }
    ];

    this.employmentService.getSimilarJobs(this.data).subscribe(
      rsp => {
        this.similarList = rsp;
        window.scrollTo(0, 0);
      }
    );
  }

  saveToFavorite(job) {
    this.employmentService.addToFavJobs(job.id).subscribe(
      response => {
        job.isFavourite = true;
      }
    );
  }

  removeFromFavourite(job) {
    this.employmentService.deleteFavJob(job.id).subscribe(
      reponse => {
        job.isFavourite = false;
      }
    );
  }

  gotoPrev() {
    this.location.back();
  }

}
