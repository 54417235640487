import { BannerService } from './../../../core/services/banner.service';
import { ContentService } from './../content.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IContentCard } from './content-card.interface';
import { OfflineService } from 'src/app/core/services/offline.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'vc-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss', 'content-card-colors.component.scss']
})
export class ContentCardComponent implements OnInit {

  @Input() data: IContentCard[];
  @Input() scrollable = true;
  @Input() addScroll = true;
  @Input() gridColumn = 'column-quarter';
  @Input() type = 'regular';
  @Input() ifCourses = false;
  @Input() scrollalign = false;
  @Input() scrollalignCourse = false;
  @Input() fixmarright = false;

  @Output() titleClick = new EventEmitter<IContentCard>();

  isFavourite: boolean = null;

  constructor(private readonly contentService: ContentService,
    private readonly bannerService: BannerService,
    private readonly sanitizer: DomSanitizer) {
  }

  onTitleClicked(item) {
    if(!item.disabled){
      this.titleClick.emit(item);
    }
  }

  ngOnInit() {
  }

  goToPlan(item) {
    this.contentService.addToPlan(item.contentID).subscribe(response => {
      this.bannerService.success(response.successMessage, true);
      item.isFavourite = !item.isFavourite;
    }, error => {
      this.bannerService.warning(error.successMessage, true);
    });
  }

  removeFromPlan(item) {
    this.contentService.removeMyContent(item.contentID).subscribe(response => {
      this.bannerService.success(response.successMessage, true);
      item.isFavourite = !item.isFavourite;
    }, error => {
      this.bannerService.warning(error.error.errorMessage, true);
    });
  }

  getImage(item) {
    const url = item.courseImgUrl || item.categoryImgUrl || item.imagePath;
    if(url) {
      //return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      return url;
    }
  }
}
