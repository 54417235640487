<div class="track-tab-container">
  <div class="f-structure-container">
    <div class="f-structure-entry-container" *ngFor="let entryData of fStructureData; index as i">
      <ng-container [ngSwitch]="entryData.activityId">
        <ng-container *ngSwitchCase="'today'">
          <div class="f-line-container-today">
            <span class="f-line"></span>
          </div>
          <div class="today-line-panel-container">
            <div class="today-line-bracket-container" *ngIf="shouldShowTodayLineBrackets(i)">
              <div class="today-line-bracket"></div>
            </div>
            <div class="today-line-divider"></div>
            <p class="today-line-label">{{entryData.title}}</p>
            <div class="today-line-divider"></div>
            <div class="today-line-bracket-container" *ngIf="shouldShowTodayLineBrackets(i)">
              <div class="today-line-bracket"></div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="-1">
          <div class="f-line-container">
            <span 
              class="f-line"
              [style.height]="setFLineHeight(i)"
            ></span>
            <div class="f-line-icon-container" [style.display]="decideIfShouldShowInductionIcon(i)">
              <img src="./../../../../../assets/f-structure-icons/induction-icon.svg" alt="silhouette of person">
            </div>
          </div>
          <div 
            class="entry-panel-container"
            [style.margin-bottom]="setInductionEntryMarginBottom(i)"
          >
            <app-entry-induction [entryData]="entryData"></app-entry-induction>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="5">
          <div class="f-line-container">
            <span 
              class="f-line"
              [style.height]="setFLineHeight(i)"
            ></span>
            <div class="f-line-icon-container">
              <img  *ngIf="entryData.interventionType === 'Start'" src="./../../../../../assets/f-structure-icons/course-start-icon.svg" alt="silhouette of calendar with start icon">
              <img *ngIf="entryData.interventionType === 'End'" src="./../../../../../assets/f-structure-icons/course-end-icon.svg" alt="silhouette of calendar with end icon">
            </div>
          </div>
          <div 
            class="entry-panel-container"
            [style.margin-bottom]="setEntryMarginBottom(i)"
          >
            <app-entry-course [entryData]="entryData"></app-entry-course>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <div class="f-line-container">
            <span 
              class="f-line"
              [style.height]="setFLineHeight(i)"
            ></span>
            <div class="f-line-icon-container">
              <img class="vc-logo-f-structure-entry" src="./../../../../../assets/images/keyworker_brand.png" alt="virtual campus logo">
            </div>
          </div>
          <div 
            class="entry-panel-container"
            [style.margin-bottom]="setEntryMarginBottom(i)"
          >
            <app-entry-comment [entryData]="entryData"></app-entry-comment>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="4">
          <div class="f-line-container">
            <span 
              class="f-line"
              [style.height]="setFLineHeight(i)"
            ></span>
            <div class="f-line-icon-container">
              <img src="./../../../../../assets/f-structure-icons/system-icon.svg" alt="silhouette of computer and monitor">
            </div>
          </div>
          <div 
            class="entry-panel-container"
            [style.margin-bottom]="setEntryMarginBottom(i)"
          >
            <app-entry-system [entryData]="entryData"></app-entry-system>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="6">
          <div class="f-line-container">
            <span 
              class="f-line"
              [style.height]="setFLineHeight(i)"
            ></span>
            <div class="f-line-icon-container">
              <img class="review-f-structure-entry" src="./../../../../../assets/f-structure-icons/review-icon.svg" alt="speech bubble with star rating inside">
            </div>
          </div>
          <div 
            class="entry-panel-container"
            [style.margin-bottom]="setEntryMarginBottom(i)"
          >
            <app-entry-review [entryData]="entryData"></app-entry-review>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <!-- <div class="load-more-container" *ngIf="loadMoreVisible">
      <a href="javascript:void(0)" (click)="onClickLoadMore()">Load more</a>
    </div> -->
  </div>
</div>
