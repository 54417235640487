import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    const option = args[0];
    return this.getFileSizeParameterize(value, option);
  }

  getFileSizeParameterize(sizeInBytes: number, option: string) {
    switch(option) {
      case 'KB': return (sizeInBytes / 1024).toFixed(2) + ' KB';
      case 'MB': return (sizeInBytes / Math.pow(1024, 2)).toFixed(2) + ' MB';
      case 'GB': return (sizeInBytes / Math.pow(1024, 3)).toFixed(2) + ' GB';
      default: return this.getFileSize(sizeInBytes);
    };
  }

  getFileSize(value: number) {
    let size = this.getNextFileSize(value);
    if (size < 1000) {
      return size.toFixed(2) + ' KB';
    }
    size = this.getNextFileSize(size);
    if (size < 1000) {
      return size.toFixed(2) + ' MB';
    }
    size = this.getNextFileSize(size);
    return size.toFixed(2) + ' GB';
  }

  getNextFileSize(sizeInBytes: number) {
    return sizeInBytes / 1024;
  }


}
