import { Component, OnInit, Input } from '@angular/core';
import { Utility } from '../../../../shared/utils/utility';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'vc-featured-jobs-for-prison-leavers',
  templateUrl: './featured-jobs-for-prison-leavers.component.html',
  styleUrls: ['./featured-jobs-for-prison-leavers.component.scss']
})
export class FeaturedJobsForPrisonLeaversComponent implements OnInit {

  @Input() job: any;

  constructor() { }

  ngOnInit() {  }

  getImagePath(imagePath: string) {
    imagePath = Utility.replaceString('original', '495_330',imagePath);
    return `${environment.azureBlobStorage}${imagePath}`;
  }
}
