<a (click)="backClicked()" href="javascript:void(0);" id="back_button" class="link-back nomargintop">Back</a>

<div class="grid-row">
    <div class="panel-wrapper mt25" *ngIf="expressedInterest?.status">
        <h1 class="heading-xlarge">
            I'm interested
        </h1>
        <div class="panel-body heading-large">
            on <strong> {{expressedInterest?.dateExpressed | date :  "dd MMMM yyyy" }} </strong>
            at <strong> {{expressedInterest?.dateExpressed | date :  "HH:mm" }} </strong>
        </div>    
    </div>
</div>

<div class="grid-row" id="nfnViewJob">
        <h3 class="heading-large">{{advertNfnJobDetails?.jobTitle}}</h3>
        <div class="column-two-thirds">
            <table class="table-font-small">
                <tbody>
                    <tr *ngIf="advertNfnJobDetails?.postingDate">
                        <th>Posting date:</th>
                        <td id="posting_date">{{advertNfnJobDetails?.postingDate | date : 'dd MMMM yyyy'}}</td>
                    </tr>
                    <tr *ngIf="advertNfnJobDetails?.jobTitle">
                        <th>Job title:</th>
                        <td id="job_title">{{advertNfnJobDetails?.jobTitle}}</td>
                    </tr>
                    <tr *ngIf="advertNfnJobDetails?.company">
                        <th>Company:</th>
                        <td id="company">{{advertNfnJobDetails?.company}}</td>
                    </tr>
                    <tr *ngIf="advertNfnJobDetails?.location">
                        <th>Location:</th>
                        <td id="location">{{advertNfnJobDetails?.location}}</td>
                    </tr>
                    <tr *ngIf="advertNfnJobDetails?.pay">
                        <th>Pay:</th>
                        <td id="pay">{{advertNfnJobDetails?.pay}}</td>
                    </tr>
                    <tr>
                        <th>ROTL:</th>
                        <td id="rotl">{{rotl}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="grid-row descBlock">
                <div *ngIf="advertNfnJobDetails?.jobDescription">
                    <label>Job description:</label>
                    <p id="job_description">{{advertNfnJobDetails?.jobDescription}}</p>
                </div>
            </div>
        </div>
        <div class="column-one-third">
            
            <img class="nfn_image" [src]="getImagePath(advertNfnJobDetails?.path)" alt="{{advertNfnJobDetails?.path}}">
        </div>
</div>
<div class="grid-row actions">
    <p>
        <button type="button" id="interest_button" class="button button-link" (click)="expressInterest(advertNfnJobDetails?.id)"
            [disabled]="expressedInterest?.status">I'm interested</button>
    </p>
</div>