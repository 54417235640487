<button id="create-plan" class="button button-link create-plan" type="button" name="create-plan" *ngIf="togetherModeModalService.getTogetherModeEnabledStatus()" (click)="onClickCreatePlan()">{{planAgreeFlag === true ? 'Update plan' : 'Create plan'}}</button>

<ng-container>
  <ng-container *ngIf="goalsData?.length > 0">
    <div class="goals-wrapper">
      <div class="goals-container">
        <div class="goals-panel">
          <div class="title-container title-container-employment-goals">
            <h2 class="goals-alert-heading" id="employment-goals-heading">Employment goals</h2>
          </div>
          <div class="body-container body-container-employment-goals">
            <div class="body-goals-container">
              <p *ngFor="let goal of goalsData[0]; let i = index" class="body-goal" id="goal-{{i+1}}">{{goal}}</p>
            </div>
          </div>
        </div>
        <div class="goals-panel">
          <div class="title-container title-container-personal-goals">
            <h2 class="goals-alert-heading" id="personal-goals-heading">Personal goals</h2>
          </div>
          <div class="body-container body-container-personal-goals">
            <div class="body-goals-container">
              <p *ngFor="let goal of goalsData[1]; let i = index" class="body-goal" id="goal-{{i+1}}">{{goal}}</p>
            </div>
          </div>
        </div>
        <div class="goals-panel">
          <div class="title-container title-container-short-term-goals">
            <h2 class="goals-alert-heading" id="short-term-goals-heading">Short-term goals</h2>
          </div>
          <div class="body-container body-container-short-term-goals">
            <div class="body-goals-container">
              <p *ngFor="let goal of goalsData[3]; let i = index" class="body-goal" id="goal-{{i+1}}">{{goal}}</p>
            </div>
          </div>
        </div>
        <div class="goals-panel">
          <div class="title-container title-container-long-term-goals">
            <h2 class="goals-alert-heading" id="long-term-goals-heading">Long-term goals</h2>
          </div>
          <div class="body-container body-container-long-term-goals">
            <div class="body-goals-container">
              <p *ngFor="let goal of goalsData[2]; let i = index" class="body-goal" id="goal-{{i+1}}">{{goal}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div id="tabWrapper" class="tabWrapper">
    <ul>
      <li
        tabindex='0'
        (click)="setActiveTab('schedule')"
        (keyup.enter)="setActiveTab('schedule')"
        [class.active]="activeTab === 'schedule'"
        class="tabHead"
      ><a class="tab-item" id="schedule-tab" attr.aria-label="tab Schedule">Schedule</a>
      </li>
      <li
        tabindex='0'
        (click)="setActiveTab('track')"
        (keyup.enter)="setActiveTab('track')"
        [class.active]="activeTab === 'track'"
        class="tabHead"
      ><a class="tab-item" id="track-tab" attr.aria-label="tab Track">Track</a>
      </li>
    </ul>
  </div>
  <div class="planWrapper" [ngSwitch]="activeTab">
    <vc-plan-schedule 
      *ngSwitchCase="'schedule'" 
      [categoryList]="categoryList"
    ></vc-plan-schedule>
    <vc-plan-track 
      *ngSwitchCase="'track'" 
      [fStructureData]="fStructureData"
      [loadMoreVisible]="loadMoreVisible"
      (loadMore)="onLoadMoreClicked($event)"
    ></vc-plan-track>
  </div>
</ng-container>
