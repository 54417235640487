import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../../../../shared/constants/url-constants';

@Injectable()
export class PersonalStatementService {

  constructor(private readonly http: HttpClient) { }

  getPersonalStatementInfo() {
    const href = `${Url.CVB}/personal-statement`;
    return this.http.get(href);
  }

  savePersonalStatement(data) {
    const href = `${Url.CVB}/personal-statement/save`;
    return this.http.post<any>(href, data);
  }

  draftPersonalStatement(data) {
    const href = `${Url.CVB}/personal-statement/draft`;
    return this.http.post<any>(href, data);
  }

}
