import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from '../../shared/constants/url-constants';
import { OfflineService } from 'src/app/core/services/offline.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(
    private readonly http: HttpClient,
    private readonly offlineService: OfflineService) { }

  getUserLearnerInfo() {
    const href = `${Url.PROFILE}/su-learner/suLearnerInfo?id=`;
    return this.http.get<any>(href);
  }

  getGoals() {
    if(this.offlineService.isOnline()) {
      const href = `${Url.PLAN}/induction-Plan/goals/1?userId`;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getGoals();
    }
  }

  getCourses() {
    if(this.offlineService.isOnline()) {
      const href = `${Url.MOJ_PEF}/SUTimelineInfo?prn`;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getCourses();
    }

  }

  getRefCategoryList() {
    return this.offlineService.getRefCategoryList();
  }

  // getHistoryData() {
  //   const href = `${Url.PLAN}/induction-history/timeLineHistory?prn&userId`;
  //   return this.http.get<any>(href);
  // }

  getFstructureData(userId, pageNumber) {
    if(this.offlineService.isOnline()) {
      const href = `${Url.PLAN}/fstructure?sort=sortDate,desc&sort=activityId,asc&page=${pageNumber}&size=15&userId=${userId}`;
      return this.http.get<any>(href);
    } else {
      return this.offlineService.getFstructures();
    }
  }
}
