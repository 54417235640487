import { Injectable, NgModule } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})

export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(
        public router: Router,
        private readonly notificationService: NotificationService,
        private readonly autheticationService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.notificationService.close();

        let sessionToken = localStorage.getItem('sessionToken');    

        request = request.clone({
            headers: request.headers.set('X-Authorization', 'Bearer ' + sessionToken)
                .set('Content-Type', 'application/json')
        });
        return next.handle(request).pipe(
            tap(
                (response: HttpEvent<any>) => { },
                (error: any) => {
                    if (error instanceof HttpErrorResponse) {

                        if (error.status === 401) {
                            this.autheticationService.signout().subscribe();
                        } else if (error.status === 500) {
                            error.error.errorMessage = 'Sorry, there is a problem with the service';
                        } else if (error.status === 0) {
                            error.error.errorMessage = 'Please check your internet connection';
                        } else if (error.status === 409) {
                            // 409 should be handled at component level
                            return;
                        } else { 
                            this.notificationService.error([error.error.errorMessage], false);
                        }
                    }
                }
            )
        );
    }
}

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        }
    ]
})

export class HttpRequestInterceptorModule { }
