export const INDUCTION_QUESTIONS_VALIDATION_CONTEXT = {

	required: {
		diagnosed: 'Select if you have been diagnosed or not',
		lldDetails: 'Enter diagnosed LDD details, it cannot be blank',
		mathsScore: 'Select your Maths initial assessment score',
		englishScore: 'Select your English initial assessment score',
		englishPrimary: 'Select if English is your first language or not',
		otherLanguages: 'Select if you speak any other languages or not',
		learningStyle: 'Select your learning style',
		releaseDate: 'Release date cannot be blank',
		barriersToLearning: 'Select your barriers to learning',
		agreement: 'Select if you agree to this plan or not'
	},
	invalidDate: {
		releaseDate: 'Enter a valid release date'
	},
	pastDate: {
		releaseDate: 'Release date must be greater than system date'
	},
	maxlength: {
		lldDetails: 'Enter 200 or less characters',
	},
	minInserted: {
		languages: 'What languages do you speak?',
	},
	maxInserted: {
		languages: 'You must delete all selections before changing your answer',
	},
	maxSelected: {
		barriersToLearning: `You must unselect "Don't know" if any other barriers to learning are selected`
	}
}
